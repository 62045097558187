.ai-chat-message-page{
    width: 100%;
    min-height: 0;
    height: calc( 100vh - 90px);

    border-top: 1px solid #ddd;
    display: grid;
    grid-template-columns: minmax(200px, 300px) 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: 
    "session desc"
    "session message"
    "session footer";
    align-items: flex-start;

    .ai-chat-message-list{
     //   flex:1;
        grid-area: message; 
        position: relative;
        height: 100%;
        
    }
    .ai-chat-message-footer{
        border-top: 1px solid #ddd;
        grid-area: footer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
    }
    .ai-chat-desc{
        grid-area: desc; 
        padding: 8px;
        color: #00000066;
        font-size: 80%;
        border-bottom: 1px solid #ddd; 
    }
    .ai-chat-session-list{
        flex:1;
        grid-area: session;
        border-right: 1px solid #ddd;
        overflow: auto;
        height: 100%;
    }
    .ai-chat-session-new{
        border:1px solid #ddd;
        background-color: #eee;
        padding: 16px;
        cursor: pointer;
        &:hover{
            background-color: #e2e2e2;
        }
    }
    .ai-chat-messages-placeholder{
        width: 100%;
        flex:1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; 
        font-size: 120%;
        opacity: 0.4;
    }
}

.app.ai .page{
    padding-bottom: 0px;
    min-height: 0;
    padding: 0px;
    width: 100%;
    .page-footer{
        display: none;
    }
    .tab-header{
        margin-bottom: 0px;
    }
}



.ai-chat-session-list{ 
    .ai-chat-session{   
        padding: 8px 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
        cursor: pointer; 
        color:#000000aa;
        &:hover{
            box-shadow: inset 0px 0px 10000px 10000px #00000011;
        }
        &.active{
            background-color: #3d54b5;
            color:#fff;
        }

        .ai-chat-session-date{
            font-weight: normal;
            margin-top: 2px;
            font-size: 80%;
            opacity: 0.7;
        }
        .ai-chat-session-user{
            font-weight: normal;
            font-size: 80%;
            opacity: 0.7;
        }
    }
}


 
.ai-chat-message-list{
  

    // background-color: #000;
   // flex:1;
    display: flex;
    flex-direction: column-reverse; 
    // padding: 16px; 
    overflow: scroll;
    // padding-bottom: 40px; 
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    min-height: 10vh;
    .ai-chat-message{
        width: 100%;
        
        background-color: #eee;
        // border-bottom: 1px solid #ddd;
        display: flex; 
        align-items: center;
        justify-content: center;
        .ai-chat-message-inner{

            width: 100%;
            max-width: 1200px;

            padding: 16px; 
            position: relative; 
            display: grid;
            grid-gap: 4px 8px;
            gap: 4px 8px;
        
            grid-template-columns: 48px auto 1fr;
            grid-template-rows: auto 1fr;
            grid-template-areas: 
            "image name time"
            "image content content"
            "token token token";
        }

        .sender-image{ grid-area: image; background-color: #ddd; width:48px; height:48px; border-radius: 4px; border:1px solid #aaa; background-position: center; background-size: 90%; }
        .sender-name{ grid-area: name; font-size: 80%; font-weight: bold; line-height: 12px; opacity: 0.4;align-self: flex-end; margin-top:1px; }
        .sent-time{ grid-area: time; font-size:70%; line-height: 10px;  opacity: 0.5; align-self: flex-end; }
        .message-content{ grid-area: content; font-size: 110%; letter-spacing: 0.1; line-height: 1.5; max-width: 900px; } 
       .message-token{grid-area: token; margin-left: 50px; padding-left: 4px; font-size: 80%; opacity: 0.5; border-top:1px solid #ddd; padding-top: 8px; margin-top: 4px;  }
        .message-more{ 
            // grid-area: more; 
            display: none;
            font-size: 100%; 
            text-align: center;
            position: absolute;
            left: -20px;
            top:28px;
            width:24px;
            height: 24px;
            box-shadow:   0px 0px 0px 4px #00000011;
            border-radius: 50%;

            .iconbutton{
                font-size: 120%; 
                border-radius: 50%;
                width:24px;
                height: 24px;
                font-weight: bold;
                text-align: center;
                align-items: center;
                display:flex;
                justify-content: center;
                padding: 0px;
                background-color: #00000011;
            } 
        }

        &:hover{
            .message-more{  
                display: block;
            }       
        }

        &.me{
            background-color: #e2e2e2;
            border-bottom: 1px solid #ccc;
            // grid-template-columns: 20px 1fr auto 48px;
            // grid-template-rows: auto 1fr;
            // grid-template-areas: 
            // "more time name image"
            // "content content content image";
            // align-self:flex-end;
            // .sent-time{  justify-self: flex-end; }

            // .message-more{  
            //     left: 8px; 
            //     right:0px;
            // }

        }
    }


}



.ai-extra-button-popover{
padding: 8px;
.line{
    padding: 8px 16px;
    cursor: pointer;
    &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000022;
    }
}
}




.ai-chat-message-box{

padding: 0px 8px; 
// background-color: ; 
width:100%;
max-width: 1200px;

    .ai-chat-message-text{ 
        padding-top: 0px;
        display: grid;
        grid-gap: 8px;
        gap: 8px;
        padding-bottom: 20px;
    
        grid-template-columns: 120px 1fr 1fr 1fr auto;
        grid-template-rows: auto;
        grid-template-areas: 
        "template template tone style language" 
        "enable-extras prompt prompt prompt send"
        "extras extras extras extras extras"
        "desc desc desc desc desc";

        select{
            background-color: transparent;
            border: 0px none;
            outline: none;
            border-left:  1px solid #ddd;
            border-radius: 0px;
            &:first-child{
                border: 0px none;
            }
        }
        .ai-language{ grid-area: language; }
        .ai-tone{ grid-area: tone; }
        .ai-style{ grid-area: style; }
        .ai-extra{ grid-area: extra; }
        .ai-template{ grid-area: template;  }
        .enable-extras{ grid-area: enable-extras; margin-left: 4px; }
        .prompt{ grid-area: prompt; height: 40px; min-height: auto; width: auto; }
        .prompt.disabled{ user-select: none; pointer-events: none; opacity: 0.4; overflow: hidden; }
        .send-prompt{ grid-area: send; align-self: center; }
        .ai-chat-message-box-desc{
            grid-area: desc;
            opacity: 0.5;
            font-size: 80%;
            padding-top: 8px;
        }

        .ai-chat-message-box-extras{
            grid-area: extras;
            .ai-chat-message-box-extra-line{
              display:flex;
              padding: 4px;
              flex-direction: row;
              padding-right: 24px;
              .extra-check{
                width: 200px;
              } 
              .extra-data{
                 
              } 
            }
          }
    }
}


.ai-message-popover{
padding: 4px 0px;
.ai-message-popover-line{
    padding: 12px 16px;
    cursor: pointer;

    &:hover{
        box-shadow:  inset 0px 0px 1000px 1000px #00000011;
    }
}
}




@media only screen and (max-width: 768px) {
    .ai-chat-message-page{ 
        grid-template-columns: 0px 1fr; 
        height: calc( 100vh - 94px);
        .ai-chat-session-list{ 
            height: calc( 100% - 95px);
         }
    }
    .ai-chat-message-page.sessions{
        .ai-chat-session-list{ 
            height: calc( 100% );
         }
        grid-template-columns: 1fr 0px; 
        .ai-chat-message-list, .ai-chat-message-footer, .ai-chat-desc{
            display: none;
        }

    } 

    .ai-chat-message-page.panel-opened{ 
        grid-template-columns: 1fr 100px; 
        .ai-chat-session-list{ 
            // height: calc( 100% - 95px);
         }
         .ai-chat-message-list{
            position: absolute;
            display: none;
         }
         .ai-chat-desc{
            position: absolute;
            display: none;
         }
         .ai-chat-message-footer{
            position: absolute;
            display: none;
         }
    }



    .message-content{  max-width: 100%; word-wrap: wrap; word-break: break-all; } 

.ai-chat-message-box {
   
}
.ai-chat-message-box .ai-chat-message-text{ 
    padding-top: 0px;
    display: grid;
    grid-gap: 8px;
    gap: 8px;
     
    grid-template-columns:  1fr  auto;
    grid-template-rows: auto;
    grid-template-areas: 
    "template template"
    "tone tone"
    "style style"
    "language language" 
    "prompt send"
    "enable-extras enable-extras"
    "extras extras"
    "desc desc";
    padding-bottom: 8px;
}
.ai-chat-message-footer .ai-chat-message-box .ai-chat-message-text{ 
        padding-top: 0px;
        display: grid;
        grid-gap: 4px;
        gap: 4px;
         
        grid-template-columns:  1fr  auto;
        grid-template-rows: auto;
        grid-template-areas: 
        "template template"
        "prompt send"
        "enable-extras enable-extras"
        "extras extras"
        "desc desc";
        padding-bottom: 8px;
 
        .ai-language{ display: none; }
        .ai-tone{  display: none;  }
        .ai-style{  display: none; }
        .ai-extra{  display: none;  }
        .ai-template{ grid-area: template; 
            border-bottom: 1px solid #ddd;  }

        .enable-extras{ grid-area: enable-extras; margin-left: 4px; }
        .prompt{ grid-area: prompt;  min-height: 60px; width: auto; }
        .prompt.disabled{ user-select: none; pointer-events: none; opacity: 0.4; overflow: hidden; }
        .send-prompt{ grid-area: send; align-self: center; min-height: 60px; }
        .ai-chat-message-box-desc{
            grid-area: desc; 
            padding-top: 0px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
 
    }


}