.ecommerce:has(.overview-page) {
    .tab-header {
        margin: 0 8px 0 8px;
    }

    .page {
        padding: 16px 8px;
    }

    .toolbar-wrapper {
        margin: 0;
        border: none;

        .toolbar {
            margin: 0 8px 0 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid #ddd;
        }
    }
}
