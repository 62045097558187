.daily-visits-overview-card {
    .empty-state {
        height: 100%;
    }

    .card-content {
        padding: 0 !important;
    }

    .chart-wrapper {
        height: calc(100% - 40px);
    }

    .tab-content {
        transition: 0.3s ease;
    }

    .tab-header-wrapper {
      
        z-index: 1;
        padding-left: 16px;
    }

    .tab-header {
        margin-bottom: 0;
        border-bottom: 0;

        .tab-title {
            padding: 12px 16px 12px 0;
            border-bottom: 0;
            text-transform: capitalize;

            &:hover {
                box-shadow: none;
            }
        }

        .tab-title:not(.selected) {
            opacity: 0.5;
        }
    }
}

.overview-page {
    .overview-card.daily-visits-overview-card {
        .card-header {
            margin-bottom: 0;
        }

        .card-footer {
            margin-top: 0;
        }

        .card-content {
            padding: 0;

            &:has(.funnel-chart) {
                padding: 16px;
            }
        }

        &.large-chart {
            .card-content {
                min-height: 490px;
            }
        }

        &.mock {
            .card-footer {
                // TODO: uncomment this out when we implement status empty/needs setup for chart data on API
                // display: none;
                z-index: 2;
            }

            .wizard-link {
                text-decoration: none;
            }
        }
    }

    .one-day-doughnuts {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        & > * {
            flex: 1;
        }
    }
}

@media (max-width: 768px) {
    .daily-visits-overview-card {
        .tab-content {
            transition: 0.3s ease;
            min-height: 320px;
        }
    }
}
