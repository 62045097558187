.page-loading{  
  padding: 16px;
  background-color: #ddd;
  border-radius:  16px;
  width: 220px;
  flex: none;
  align-self: center;
  justify-self: center;
  text-align: center;
  margin: 32px auto;
  margin-bottom: 64px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #00000077;
  user-select: none;
  outline: none;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);

  box-shadow: 0px 0px 4000px 4000px #00000055;
  user-select: none;
  pointer-events: none;
  z-index: 1000;
  padding-bottom: 30px;
  font-size: 120%;
}

.page-loading-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  top:0px;
  left:0px;
  background-color: #00000022;
  z-index: 1000;
  user-select: none;
  pointer-events: all;

} 
@keyframes rotate-loading-icon {
  0% {  transform: rotate(0deg);} 
  100% {transform: rotate(359deg); } 
}
 
.page-loading .page-loading-icon {  
  font-size: 250%; 
  animation:  rotate-loading-icon 400ms linear;
  animation-iteration-count: infinite;
  margin-bottom: 8px;
} 
