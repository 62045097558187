
.application-choose-popup-content{
  width: 750px;
  display: flex;  
  flex-direction: row;
  flex-wrap: wrap;

  .application-type{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 134px;  
    height: 134px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 8px;
    padding: 8px;
    background: #fff;
    cursor: pointer;
    position: relative;
    &:hover{
      background: #f2f2f2;
    }
    &.selected{
      background-color: #3949ab;
      color:#fff;
      .ficon{ 
        background-color: #fff;
        color: #000;
      }
    }
    .ficon{ 
      height: 48px;
      width: 48px;
      font-size: 32px;
      margin-top: 16px;
      opacity: 0.8;
      background-color: #ffc003;
      background-color: #000;
      color: #000;
      color: #fff;
      padding: 8px;
      border-radius: 4px;
    }
    .parent-logo{
      font-size: 12px;
      height: 24px;
      width: 24px;
      position: absolute;
      top:8px;
      left:8px; 
      border-radius: 8px;
      box-shadow: 0px 0px 2px 1px #00000011;
      padding: 6px;
      margin-top: 0px;
      background-color: #fff;
      color:#000;
    }
    .application-type-name{
      padding: 8px 0px;  
    }
  }
}