.card{ 
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  border-radius: 4px;
  max-width: 100vw;
  border:1px solid #fafafa;
  background-color:#fff;
}

.card .card-header{
  padding: 12px 16px; 
  margin-bottom: 0px;
  border-bottom: 1px solid #eee;
  font-weight: bold;
} 

.card .card-header.has-margin{ 
  margin-bottom: 12px; 
} 


.card .card-content{
  // padding: 0px 16px; 
}
.card .card-content.has-padding{
  padding: 16px;
}
.card .card-content.less-padding{
  padding: 8px;
}
.card .card-content.scrolled{ 
  display: flex;
  position: relative;
  height: 100%;
}



.card .card-footer{
  padding: 12px 16px; 
  margin-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);  
  text-transform: uppercase;
}

.card .card-more-button{
  font-size: 130%;
  position: absolute;
  top:8px;
  margin-left: 8px;
  opacity: 0.5;
}