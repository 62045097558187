.article-comment-view-page {
    .article-comparison {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }

    .article-data .card-content,
    .article-user-data .card-content {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }
}

.article-comment-view-page .data-card .card-content,
.article-comment-view-page .article-data .article-data-card,
.article-comment-view-page .article-user-data .article-data-card {
    display: grid;
    grid: auto / auto;
    gap: 8px;
    grid-gap: 8px;
    align-items: center;
    align-content: center;
    justify-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    border-right: 1px solid #eee;

    &:last-child {
        border: 0 none;
    }

    .sub-title {
        font-size: 80%;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.7;
    }
    .value {
        font-size: 140%;
    }
    .sticker {
        width: auto;
        margin-bottom: 8px;
    }
    .sub-value {
    }
    .foot-title {
        color: #aaa;
    }
}

.article-comment-view-page .article-data .article-data-card,
.article-comment-view-page .article-user-data .article-data-card {
    justify-items: center;
    justify-content: center;
    padding: 8px 0;
}

.article-comment-view-page .article-data-cards {
    padding: 16px 0;
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;

    .article-data,
    .article-user-data {
        flex: 1;
    }
}

.article-data-charts {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;
    margin-bottom: 16px;
}

.article-logs {
    .card-settings {
        display: none;
    }
}
