.material-list-filter {
    border-right: 1px solid #ddd;
    width: 100%;

    .material-list-filter-group {
        border-bottom: 1px solid #ddd;
        padding-bottom: 8px;
        width: 100%;

        .material-list-filter-group-title {
            font-weight: bold;
            padding: 8px;
        }

        .material-list-filter-item {
            position: relative;
            padding: 4px 8px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap;

            .material-list-filter-item__actions {
                position: absolute;
                z-index: 1;
                right: 6px;
                display: none;
                opacity: 0.7;
                transition: 0.3s ease-in;

                .iconbutton {
                    font-size: 18px;
                }
            }

            &:hover {
                box-shadow: inset 0 0 1000px 1000px #00000011;

                .material-list-filter-item__actions {
                    display: flex;
                }
            }

            &.selected {
                background-color: #ddd;
            }
        }
    }
    .material-list-filter-group + .material-list-filter-group {
        padding-top: 8px;
    }

    .material-list-filter-item-input {
        padding: 8px 8px;
        margin: 0px -4px;
        outline: none;
    }

    .material-list-filter-item-input-save {
        opacity: 0.9;
        margin-left: 4px;
    }
}

.popup:has(.delete-tag-popover__content) {
    h2 {
        display: none;
    }
}
