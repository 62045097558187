.plan-select{
  .plan-select-title{
    font-size: 160%;
    padding: 8px 10px;
    padding-bottom: 0px;
  }
  .plan-select-desc{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

  .plan-select-footer{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

}



.registrar-types{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;

  &.locked .registrar-type:not(.selected){
    opacity: 0.4;
    pointer-events: none;
    &:hover{
      box-shadow: none;
    }
  }

  .registrar-type{
    padding: 16px;
    border:1px solid #eee;
    border-radius: 4px;
    margin: 8px;
    cursor: pointer;
    min-width: 200px;
    flex:1;

    display: grid;
    grid-template-columns: 48px 1fr;
    grid-template-areas: 
    "icon title"
    "icon desc"
    ;

    &:hover{
      box-shadow: inset 0px 0px 1000px 10000px #00000011;
    }
    
    &.selected{
      background-color: #1147bb;
      color: #fff;
      .ficon{
        color:#fff;
      }
    }

    
    .registrar-type-icon{
       grid-area: icon; 
       .ficon{
        font-size: 32px;
       }
    }
    .registrar-type-title{
      grid-area: title;
      font-weight: 500;
    }
    .registrar-type-desc{
      grid-area: desc;
      font-size: 80%;
    }
  }
}

.plans{
  display: flex;
}
.plans .plan{
  user-select: none;
  cursor: pointer;
  flex:1;
  background-color: #fff;
  border-radius: 4px;
  border:1px solid #ddd;
  padding: 16px;
  margin: 8px;
  min-width: 190px;

  &:hover{
    box-shadow: inset 0px 0px 1000px 10000px #00000011;
  }
  
  &.selected{
    background-color: #1147bb;
    color: #fff;
    .more-button{
      color:#fff;
    }
  }

  .plan-name{
    font-size: 130%;
    padding-bottom: 16px;
  }
  .plan-price{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .plan-price .plan-price-currency{

  }
  .plan-price .plan-price-value{
    font-size: 200%;
    margin: 0px 8px;
  }
  .plan-price .plan-price-time{} 
  .plan-desc{
    opacity: 0.5;
    font-size: 80%;
  } 
  .more-button{
    display: inline-block;
    margin-top: 16px;
    color: #666;
    cursor: help;
    margin-bottom: 8px;

  } 
}
