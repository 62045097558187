.activity-log-item {
 
    position: relative;
    // grid-template-columns: 60px auto 330px minmax(min-content, 100px);
    // grid-template-rows: auto;
    // grid-template-areas:
    //     'icon label chart expand'
    //     'icon desc chart expand'
    //     'content content content content';
    // padding: 16px;
    // gap: 4px 16px;
    // grid-gap: 4px 16px;
    // display: grid;
    // align-items: center;
    border-bottom: 1px solid #eee;

    .activity-log-item-header{
        position: relative;
        grid-template-columns: 60px auto 330px minmax(min-content, 100px);
        grid-template-rows: auto;
        grid-template-areas:
            'icon label chart expand'
            'icon desc chart expand'
            'content content content content';
        padding: 16px;
        gap: 4px 16px;
        grid-gap: 4px 16px;
        display: grid;
        align-items: center;
        border-bottom: 1px solid #eee; 
        cursor: pointer;
    }
    .list-icon {
        grid-area: icon;
        font-size: 28px;
        width: 60px;
        height: 60px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #fff;
    }

    .title {
        grid-area: label;
        font-size: 130%;
        align-self: flex-end;
    }

    .desc {
        grid-area: desc;
        font-size: 70%;
        align-self: flex-start;
        text-transform: uppercase;
        opacity: 0.7;
    }

    .link {
        grid-area: link;

        .button.with-icon {
            flex-direction: row-reverse;

            .ficon {
                margin-right: 0;
                margin-left: 8px;
            }
        }
    }

    .data-box {
        display: grid;
        grid-template-areas: 
        "label label"
        "value weight"
        ;
        grid-template-columns:auto 1fr;
        align-items: center;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: center;
        position: relative;
        
        
        .weight{
            grid-area: weight;
            font-size: 80%;
            font-weight: 500;
            color: #00000044;
            
        }

        label {
            grid-area: label;
            font-size: 70%;
            text-transform: uppercase;
            color: #546266;
            padding-bottom: 8px;
        }

        .value {
            grid-area: value;
            font-size: 120%;
            font-weight: 500;
        }
    }

   
    .score-chart {
        grid-area: chart;
        zoom: 1;
        position: absolute;
    }

    .expand {
        grid-area: expand;
        font-size: 80%;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        padding: 8px 16px;
        user-select: none;
        font-weight: 500;

        // &:hover {
        //     box-shadow: inset 1px 1px 1000px 1000px #00000011;
        // }

        .ficon {
            font-size: 150%;
            font-weight: bold;
        }
    }
}

.activity-log-item .content { 
    grid-area: content;
    // display: flex;
    // align-items: flex-start;
    // flex-direction: row;
    // flex-wrap: wrap;
    width: 100%; 
    // margin-top: 32px;
    padding: 16px;
    padding-left: 90px;
    .notice{
        
        font-size: 80%;
        font-style: italic;
        background-color: #eee;
        padding: 8px;
        opacity: 0.7;
        margin-bottom: 16px;
    }
    .info {
        flex: 1;
       
        gap: 16px;
        grid-gap: 16px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        align-items: flex-start;
        justify-items: flex-start;
        position: relative;
        flex-wrap: wrap;
        

        .data-box {
            text-align: left;
            align-items: flex-start;
            
            padding: 8px;
            min-width: 150px;
            flex:1;
            border-radius: 4px;
           
        }
 
    }
}

@media (max-width: 768px) {
    .activity-log-item {
        padding: 16px;
        gap: 4px 16px;
        grid-gap: 4px 16px;
        display: grid;
        align-items: center;
        position: relative;
        grid-template-columns: 60px 1fr;
        grid-template-rows: auto;
        grid-template-areas:
        'icon label'
        'icon desc'
        'chart chart'
        'expand expand'
        'content content'; 
 
        .score-chart {
            grid-area: chart;
            
            position: relative;
            align-self: flex-start;
            justify-self: flex-start;
            margin-left: -20px;
        }
    
        .expand {
            padding-top: 8px;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #fafafa;
        }

        .expand:hover {
            box-shadow: none;
        }

        .content {
            padding: 0;
        }
    }
}
