.login-page{

  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #eee; 
  height: 100vh;
  user-select: none;
  
  &.loading{
    pointer-events: none;
    user-select: none;
    .login-content{
      opacity: 0.5;
    }
    .qr-code{
      visibility: hidden;
    }
    .load-more-button{ 
      margin: 0px;
      display: inline-flex;
      border-radius: 4px;
      height: 40px;
    }
  }


    .logo{
      align-self: flex-start;
      justify-self: flex-start;
      background-color: #3d54b5;
       height: 80px;
       align-items: center;
       justify-content: flex-start;
       display: flex;
       width: 100%;
       padding-left: 16px;
      img{
        width: 200px;
        padding: 16px;
      }
    }

    .login-title{ 
      width: 100%;
      font-size:120%;
      padding: 24px 16px;
      padding-bottom: 16px;
    }

    .login-content{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex:1;
      width: 100%;
    }



}


.login-wrapper{
  display: flex;
  flex-direction: row;
   flex-wrap: wrap;
   
}
.login-qr{ 
  min-width: 280px; 
  max-width: 440px;

  flex:1;
  background-color: #fff; 
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 0px 4px 4px 0px;  
  overflow: hidden; 

  .login-title{ 
    border-left: 1px solid #eee; 
    text-align: center;
  }
  .login-content{ 
    border-left: 1px solid #eee; 
    padding-bottom: 32px; 
  }

  .login-qr-text{
    width: 200px; 
    font-size: 80%;
    text-align: center;
    opacity: 0.6;

  }
  .qr-code{
    opacity: 0.6;
    flex:1;
  } 

}







.login-device{ 
  width: 280px; 
  background-color: #fff; 
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 0px 4px 4px 0px;  

  overflow: hidden; 

  .login-title{ 
    border-left: 1px solid #eee; 
    text-align: center;
  }
  .login-content{ 
    border-left: 1px solid #eee; 
    padding-bottom: 32px; 
  }
 
  .loging-in-on-device{
    padding: 0px 16px;
    text-align: center; 
   
    
    u{
      display: inline-block;
      width: auto; 
      margin: 8px;
      text-align: center;
      font-size: 150%;
      text-decoration: none;
      background-color: #1d4eff;
      color:#fff;
      padding: 8px 16px;
      border-radius: 4px;
    }
    .loging-in-on-device-note{
      margin-top: 16px;
      font-size: 80%;
      opacity: 0.5;
    }
  }

}





.login{
  flex:1.2;
    background-color: #fff;
    padding: 0px;
    min-width: 300px;
    
    max-width: 440px;  
    overflow: hidden;
    border-radius: 4px 0px 0px 4px; 
    

    .login-title{
      font-size:120%;
      padding: 32px;
      padding-bottom: 16px;
    }
    .login-content{
      padding: 32px;
       
      .login-remember{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 8px 10px;
        margin-bottom: 8px;
        margin-top: -16px;
        background-color: #eee;
        border:1px solid #ddd;
        border-radius: 4px;
        user-select: none;
        .remember-email{
          flex:1;
        }
        .iconbutton{
          font-size: 150%;
          margin: -2px;
        }
      }
      .email{
         margin-bottom: 16px;
      }
      .password{
        margin-bottom: 16px;
      }
      .login-button{ 
        flex:1;
        width: 100%;
      }
     
      .register-button{ 
       
        margin-top: 8px;
        margin-bottom: 0px;
      }
      .social-login-button{ 
       
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .login-options{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
      }
      .remember-me{
        flex: 1; 
        opacity: 0.7;
        text-decoration: none; 
        text-align: right;
        cursor: pointer;
        &.remembered{
          opacity: 0.9;
        }
      }
      .forgot-link{  
        opacity: 0.7;
        text-decoration: none; 
        text-align: right;
        cursor: pointer;
      }
    }
}




@media (max-width: 768px) {
  .login-wrapper{
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .login{
    width: 100%;
    border-radius: 0px;
  }
  .login-qr{
    display: none;
  }
}