.file-selector {
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: 150ms;
    position: relative;

    &.error {
        border: 1px solid #d32f2f;
        border-left: 3px solid #d32f2f;

        &:after {
            content: 'Error: ' attr(data-error);
            position: absolute;
            padding: 0 6px;
            transition: 150ms;
            display: flex;
            align-items: center;
            height: auto;
            font-size: 65%;
            background-color: #fff;
            bottom: -5px;
            left: 9px;
            color: #d69d9d;
        }
    }

    &:hover {
        border-color: #666;
    }

    .label {
        position: absolute;
        padding: 0 6px;
        transition: 50ms;
        display: flex;
        align-items: center;
        pointer-events: none;
        height: auto;
        font-size: 65%;
        background-color: #fff;
        background: linear-gradient(0deg, #fff 60%, rgba(255, 255, 255, 0) 32%);
        top: -5px;
        left: 9px;
        color: #666;
    }

    .nofile-placeholder {
        padding: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        opacity: 0.5;

        .ficon {
            margin-right: 8px;
            font-size: 200%;
            font-weight: bold;
        }

        &:hover {
            opacity: 0.8;
        }
    }
    .selected-files {
        .material-item.horizontal {
            .material-item-image {
                width: 120px;
            }
        }
    }
}


.file-selector.disabled{
    user-select: none;
    pointer-events: none;
    background-color: #eee;
}