.button-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    user-select: none;
}

.button-wrapper.disabled .button.is-expandable .button-text {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
}
.button-wrapper.disabled .button:not(.is-expandable) {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
}

.button-wrapper input {
    margin: 0;
    width: 100%;
    height: 100%;
    flex: 1;
    border: 1px solid transparent;
}

.button-wrapper.line-button {
    width: 100%;
    align-items: center;
}

.button-wrapper.line-button:before {
    content: ' ';
    margin-top: 2px;
    height: 1px;
    background-color: #00000044;
    z-index: 0;
    flex: 1;
}

.button-wrapper.line-button:after {
    content: ' ';
    margin-top: 2px;
    height: 1px;
    background-color: #00000044;
    z-index: 0;
    flex: 1;
}

.button-wrapper.line-button .button,
.button-wrapper.line-button input {
    z-index: 1;
    flex: 2;
    display: inline-block;
    margin: 0 4px;
}

input[type='button'],
.button {
    cursor: pointer;
    font-weight: 500;
    padding: 10px 16px;
    min-width: 60px;
    border-radius: 4px;
    color: #fff;
    background-color: #3949ab;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%) !important;
}

input[type='button'].secondary,
.button.secondary {
    background-color: #fff;
    color: #666;
    font-weight: normal;
    border: 1px solid #ddd;
}

input[type='button'].warm-color,
.button.warm-color {
    background-color: #f3a830;
    color: #fff;
}

input[type='button'].color-green,
.button.color-green {
    background-color: #2b9a21;
    color: #fff;
}

input[type='button'].color-red,
.button.color-red {
    background-color: #bd0d0d;
    color: #fff;
}
input[type='button'].color-blue,
.button.color-blue {
    background-color: #0d3fbd;
    color: #fff;
}

input[type='button'].no-border:not(.colorful),
.button.no-border:not(.colorful) {
    background-color: transparent;
    color: #666;
    font-weight: 500;
    border: 0 none;
    box-shadow: none !important;
}



input[type='button'].--medium,
.button.--medium {
    padding: 8px 16px;
}

input[type='button'].--small,
.button.--small {
    padding: 5px 8px;
    font-size: 90%;
}


input[type='button'].--large,
.button.--large {
    padding: 16px 16px;
}


input[type='button'].line-button,
.button.line-button {
    background-color: transparent;
    color: #666;
    font-weight: normal;
    box-shadow: none !important;
    font-size: 80%;
    padding: 4px 8px;
    border: 1px solid transparent;
}

input[type='button']:hover,
.button:hover {
    box-shadow: inset 1px 1px 10000px 10000px #00000022 !important;
}
input[type='button']:active,
.button:active {
    box-shadow: inset 1px 1px 10000px 10000px #00000055 !important;
}

input[type='button'].remove,
.button.remove {
    background-color: #00000011;
    width: 24px;
    height: 24px;
    min-width: 24px;
    text-align: center;
    vertical-align: middle;
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    color: #000;
}

input[type='button'].remove:hover,
.button.remove:hover {
    box-shadow: inset 1px 1px 10000px 10000px #00000011;
}
input[type='button'].remove:active,
.button.remove:active {
    box-shadow: inset 1px 1px 10000px 10000px #00000022;
}

input[type='button']:disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
}

.button.with-icon {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}
.button.is-expandable {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
}
.button.is-expandable .button-text {
    padding: 10px 16px;
}

.button.is-expandable .button-arrow {
    padding: 10px;
    cursor: pointer;
    text-align: center;
    margin: 0;
    border-left: 1px solid #ffffff11;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}
.button.is-expandable .button-arrow .ficon {
    font-size: 120%;
    font-weight: bold;
}

.button.with-icon .ficon {
    vertical-align: middle;
    font-size: 120%;
    margin: -2px;
    margin-right: 8px;
    font-weight: 500;
}




.button.loading , input[type="button"].loading {
     content:' ';
     
    --c:no-repeat linear-gradient(#3d54b5 0 0);
    background: var(--c),var(--c),#ddd;
    background-size: 60% 100%;
    animation: l16 3s infinite;
    color:#fff;
    cursor: default;
    user-select: none;
    text-shadow: 0px 0px 1px #00000066;
    box-shadow: none !important;
    // border:0px;
    
    &:hover{
        box-shadow: none !important;
    }
}
 
  @keyframes l16 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
  }