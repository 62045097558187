.add-automation-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100px;
    width: 100%;

    .automation-item-drop-zone {
        position: absolute;
        z-index: 2;
        top: 0;
        margin: 0 auto;
        right: -50%;
        left: -50%;
        height: 100%;
        max-width: 830px;
        width: 100%;
        opacity: 0;

        &.hovered {
            opacity: 0.3;
            background: #3d54b5;
        }
    }

    .add-automation-divider {
        width: 50%;
        height: 100%;

        &:first-of-type {
            border-right: 1px solid #90a4ae;
        }
    }

    .button-wrapper {
        position: absolute;
        z-index: 3;
        top: calc(50px - 15px);
        left: calc(50% - 15px);

        .add-item {
            display: flex;
            align-items: center;
            padding: 0;
            border-radius: 0;
            justify-content: center;
            min-width: 30px;
            min-height: 30px;

            i {
                margin: 0;
                color: #546e7a;
            }
        }
    }

    &:last-child {
        .add-automation-divider {
            height: 50%;
        }
    }
}
