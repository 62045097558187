.recency,
.frequency,
.monetary {
    padding: 4px 16px;
    color: #fff;
    font-size: 80%;
    border-radius: 2px;
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.recency {
    background-color: #3a51bc;
}

.frequency {
    background-color: #d32f2f;
}

.monetary {
    background-color: #00b241;
}

.social-icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap; 
    gap:4px;
}

.social-icons i {
    font-size: 14pt;
    font-weight: bold;
    color: #adbec6;

    &:hover {
        color: #84969f;
    }
}

.social-icons > .app-name {
    
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;  
    padding: 2px;   
    pointer-events: none;
    position: relative;
    overflow: hidden;
    font-size: 90%;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.social-icons > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    border-color: #00000011;
    padding: 2px;
    border-radius: 8px;
    width: 28px;
    height: 28px;  
    pointer-events: none;
    position: relative;
}

.social-icons > span:last-child {
    margin-right: 0;
}

.social-icons > span:hover {
    border: 1px solid #aaa;
}

.social-icons > span[data-value]:after {
    content: attr(data-value);
    margin-bottom: -12px;
    transform: translate(-1px, -3px);
    margin-right: -24px;
    width: 12px;
    height: 12px;
    background-color: #145e9b;
    border-radius: 50%;
    font-size: 60%;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icons > span[data-value].timer:after {
    background-color: #ffffff88;
    left: 0;
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
    transform: none;
    border-radius: 0 0 6px 6px;
    color: #000;
    font-size: 8px;
    
}
.social-icons > .pipe{
    width: 1px ;
    height: 100%;
    border-left: 1px solid #ddd;
}

.social-icons > span.timer{
    font-size: 7px;
    font-weight: bold; 
    flex-wrap: wrap;
    white-space: break-spaces;
}

.social-icons > span[data-conversion] i {
    filter: grayscale(1);
    opacity: 0.5;
}

.social-icons > span[data-conversion='none'] i {
    filter: grayscale(1);
    opacity: 0.5;
}

.social-icons > span[data-conversion='followed'] i {
    filter: grayscale(0);
    opacity: 1;
}
.social-icons > span[data-conversion='followed_purchased'] i {
    filter: grayscale(0);
    opacity: 1;
}

.social-icons .timer i {
    color: #bbb;
}

.social-icons .total i {
    color: #0047b2;
}

.social-icons .wechat i {
    color: #00b241;
}

.social-icons .wxsa i {
    color: #00b241;
}

.social-icons .wxoa i {
    color: #00b241;
}
.social-icons .qq i {
    color: #222;
}

.social-icons .weibo i {
    color: #9a0707;
}

.social-icons .tiktok i {
    color: #222222;
}

.social-icons .linkedin i {
    color: #145e9b;
}

.social-icons .web i {
    color: #10638d;
}

.social-icons .shopnow i {
    color: #3d54b5;
}

.location > span {
    font-size: 75%;
    color: #577682;
    display: block;
    white-space: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid-detail-popoover {
    padding: 16px;
    // color: #577682;
    max-width: 300px;
    overflow: auto;

    .conversion-line{
        display: flex;
        align-items: center;
        margin: 8px 0px;
        .ficon{ 
            margin-right: 8px;
            font-size: 150%;
        }
        i{
            font-size: 80%;
            opacity: 0.7;
        }
    }
    .line {
        display: block;
        padding: 2px 0;
        // color: #577682;

        b {
            font-weight: bold;
            color: #3e55bd;
        }

        span {
            color: #577682;
            font-size: 90%;
        }
    }

    h3 {
        color: #37434a;
        font-size: 120%;
        margin-top: 16px;
        padding-bottom: 8px;
        font-weight: 500;
    }

    p {
        color: #577682;
        padding-bottom: 36px;
    }

    .setting-button {
        position: absolute;
        align-self: flex-end;
        right: 16px;
        bottom: 16px;
    }
}

.grid-open-button,
.grid-edit-button,
.grid-tag-button {
    font-size: 150%;
    font-weight: 500;
}

.contact {
    .ficon {
        font-size: 80%;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        vertical-align: middle;
        padding: 2px;
        border-radius: 50%;
        margin-right: 4px;
        opacity: 0.8;
    }
}

.session-info{
    font-size: 80%;
}