.register-page{

  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #eee; 
  height: 100vh;
}
.register-wrapper{
  display: flex;
  flex-direction: row;
   
  width: 840px;
}
.register-image{ 
  width: 400px; 
  background-color: #ddd;
  background-size: auto 100%;
  background-repeat:repeat-x;
  background-position: center; 
  border-radius: 0px 4px 4px 0px; 
}
.register{
  flex:1;
    background-color: #fff;
    padding: 0px;
    min-width: 300px;
    width: 40%; 
    max-width: 500px; 
    border-radius: 4px 0px 0px 4px; 
    

    .logo{
      height: 80px;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      background-color: #3d54b5;
      border-radius: 4px 0px 0px 0px; 
      padding-left: 16px;
      img{
        width: 200px;
        padding: 16px;
      }
    }
    .register-title{
      font-size:120%;
      padding: 24px 32px;
      padding-bottom: 8px;
    }
    .placeholder{
      height: 16px;
    }
    .error{
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 32px ;
      text-align: center;
      font-size: 80%;
      background-color: #ff000033;
      color: #000000aa;

    }
    .register-content{
      padding: 10px 32px;
       

      .email{
         margin-bottom: 16px;
      }
      .mobile-number{
         margin-bottom: 16px;
         .area-select{
          max-width: 150px;
         }
      }
      .name{
         margin-bottom: 16px;
      }
      .password{
        margin-bottom: 16px;
      }
      .register-button{ 
      }
      .terms{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        padding: 2px;

      }
      .gologin{
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px ;
      }
      .login-button{ 
        display: inline-block;
        margin-left: 8px;
      }
    }
}




@media (max-width: 768px) {
  .register-wrapper{
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .register{
    width: 100%;
    border-radius: 0px;
  }
  .register-image{
    display: none;
  }
}