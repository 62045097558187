.ping-charts{
    display: flex;
    flex-direction: row;
    width: 100%;
    .ping-chart{
        flex:1;
        padding: 8px;
    }
    
}



.view-page-header {
    grid-area: header;
    align-items: center;
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 3fr 8fr;
    grid-template-rows: auto;
    grid-template-areas: 'title right';
    border-bottom: 1px solid #ddd;
    margin-bottom: 8px;
    padding-bottom: 8px;
    width: 100%;

    .title {
        font-size: 180%;
        grid-area: title;
        display: flex;
        align-items: center;

        .iconbutton {
            margin-right: 8px;
        }

        span {
            white-space: nowrap;
        }
    }

    .right-side {
        grid-area: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > * {
            margin-left: 8px;
        }
    }
}