.overview-card:has(.best-list-items) {
    .card-header {
        margin-bottom: 0;
    }

    .card-content {
        //  overflow-y: auto;
        max-height: 255px;
        padding: 0;
    }

    .card-footer {
        margin-top: 0;
    }

    &.mock {
        .card-content {
            overflow: hidden;
        }

        .card-footer {
            // TODO: uncomment this out when we implement status empty/needs setup for chart data on API
            // display: none;
            z-index: 2;
        }

        .wizard-link {
            text-decoration: none;
        }
    }
}

.best-list-items {
    display: flex;
    flex-direction: column;

    .list-item {
        padding: 8px 16px;

        &:first-of-type {
            margin-top: 4px;
        }

        &:last-of-type {
            margin-bottom: 12px;
        }

        &.has-desc {
            .item-label,
            .item-desc {
                width: calc(100% - 100px);
                padding-right: 32px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .item-image {
            background-size: cover;
            border-radius: 4px;
            width: 40px;
            height: 40px;
        }

        &.customer {
            .item-image {
                border-radius: 50%;
            }
        }
    }

    .best-datas {
        display: flex;
        align-items: center;

        .best-numbers {
            line-height: 1.2;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            opacity: 0.5;
           
            flex: 1;
            padding-right: 8px;
            width: 75px;
        }

        .best-rate {
            display: flex;
            justify-content: flex-end;
            width: 40px;
        }

        .best-value {
            font-size: 70%;
        }
    }
}

.overview-card-show-all-link {
    cursor: pointer;
    opacity: 0.8;
    font-size: 90%;
    padding: 8px 8px;
    margin-top: 8px;
    text-align: center;
    user-select: none;

    &:hover {
        opacity: 1;
        background-color: #00000011;
    }
}

.more-items-popover {
    overflow: auto;
    width: 360px;
    padding: 0 8px;

    .list-item {
        padding: 8px 0;

        .item-image{
            border-radius: 50%;
            width: 40px;
            height: 40px;
        }
        &:last-of-type {
            border-bottom: 0;
        }

        &.has-desc {
            .item-label,
            .item-desc {
                width: calc(100% - 110px);
                padding-right: 32px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .best-value {
                padding-left: 4px;
            }

            .item-sticker {
                .sticker {
                    transform: scale(0.8);
                }
            }
        }
    }

    .best-datas {
        display: flex;
        align-items: center;

        .best-numbers {
            font-size: 70%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            opacity: 0.5;
            border-right: 1px solid #eee;
            flex: 1;
            padding: 4px 0;
            width: 80px;
        }

        .best-rate {
            display: flex;
            justify-content: flex-end;
            width: 40px;
        }
    }
}
