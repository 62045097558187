.data-card-container{
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;  
  padding: 8px 8px; 
  position: relative;




    .placeholder-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        left:0px;
        top:0;
        z-index: 1;
        background: rgb(255,255,255, 0.5);
        background: linear-gradient(1deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 100%);
      
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-shadow: 1px 1px #00000022;

        .placeholder-overlay-title{
            font-size: 160%;
            font-weight: 500;
            padding: 8px;
            color:#00000099;
          
        }
        .placeholder-overlay-text{
            padding: 8px;
            color:#00000077;
        }
        .placeholder-overlay-button{
            margin-top: 8px;
        }
    }

}

.data-card-container{
  gap:8px;
  .data-card{
    border-radius: 4px; 
    min-width: 190px;
    max-width: 245px;
    flex:1;
    background-color: #f5f7fb;
    padding: 16px;
    height: 108px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid #eee;
   
    position: relative;
    // padding-top: 0px;
    overflow: hidden;

    .overview-card-wrapper-header-title{
      
    }
    .sub-value{
      display: none;
    }
    .value{
      
    }
    .foot-title{
      display: none;
    }
  }

}

.choose-card-button{
  position: absolute;
  z-index: 10;
  box-shadow: 0px 0px 40px 4px #00000055,  0px 0px 1000px 1000px #00000055;
  border-radius: 4px;
}




 
 