@keyframes open {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes show-input {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.dropdown {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    user-select: none;
    border-radius: 4px;
    outline: none;
    line-height: 1.2;
    position: relative;
    background-color: #fff;
    width: 100%;
}


.dropdown:not(.no-border) {
    border: 1px solid #ddd;
}

.dropdown.open {
    border-radius: 4px 4px 0 0;
    background-color: #fff;
}

.dropdown .dropdown-overlay{
    width: 100%;
    height: 100%;
    left:0px;
    top:0px;
    position: fixed;
    background-color: #00000011;
    z-index: 100;
    animation: show-input 0.2s;
}

.dropdown .content {
    display: flex;
    overflow: hidden;
}
.dropdown .arrow {
    width: 40px;
    cursor: pointer;
    border-left: 1px dotted #ddd;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // // background-image: url('/star.png');
    // background-size: 50%;
    // background-repeat: no-repeat;
    // background-position: center;
    // background-color: #fff;
    z-index: 1;
}

.dropdown .value {
    flex: 1;
    padding: 10px 12px;
    cursor: pointer;
    width: calc(100% - 44px);
    overflow: hidden;

    // white-space: nowrap;
    // text-overflow: ellipsis;
    // border-radius: 3px 0px 0px 3px;
}

.dropdown:not(.multiselect) .value {
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 3px 0 0 3px;
}

.dropdown.multiselect .value {
    padding: 10px 10px;
}

.dropdown.multiselect .value.empty {
    padding: 10px 12px;
}

.dropdown .value .placeholder {
    opacity: 0.4;
    overflow: hidden;
    width: 100%;
    display: block;

    transition: 350ms;
}

.dropdown .value .items-count {
    padding: 6px 0;
    display: block;
}

.dropdown .value .selected-item {
    display: inline-block;
    padding: 6px 12px;
    padding-right: 34px;
    margin: 2px;
    background-color: #fff;
    vertical-align: middle;
    position: relative;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 100px;
    border: 1px solid #e2e2e2;
}

// Adapt Dropdown + Subcomponents styles
.dropdown {
    .selected-item {
        .tag,
        .sticker {
            padding-right: 40px;
            margin-right: 0;
        }

        &:has(.tag, .sticker) {
            color: #fff;
        }
    }
}

.dropdown .value .selected-item.shape-round {
    border-radius: 100px;
}
.dropdown .value .selected-item.shape-square {
    border-radius: 4px;
}

.dropdown .value .selected-item.shape-none {
    border: 0 none;
    background-color: transparent;
    border-radius: 0;
}

.dropdown .value .selected-item.custom {
    padding: 0;
}

.dropdown .value .selected-item.with-full-width {
    display: block;
}

.dropdown .value.with-less-padding {
    padding: 3px;
}

.dropdown .value .selected-item .close {
    background-color: rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    vertical-align: middle;
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.6;
    color: inherit;
}

.dropdown .value .selected-item .close:hover {
    opacity: 0.9;
}

.dropdown .dropdown-popup {
    min-width: 0;
    position: absolute;
    display: flex;
    z-index: 100;
    border-top: 0 none;
    border-radius: 0 0 4px 4px;
    background: #fff;
    max-height: 300px;
    box-shadow: rgb(0 0 0 / 20%) 0 6px 5px -3px, rgb(0 0 0 / 14%) 0px 9px 8px 1px,
        rgb(0 0 0 / 12%) 0px 10px 10px 2px;
}

.dropdown .dropdown-popup .list {
    flex: 1;
}

.dropdown.open-mode-absolute:not(.no-border) .dropdown-popup {
    width: calc(100% + 2px);
    left: -1px;
    border: inherit;
}

.dropdown.open-mode-absolute.down .dropdown-popup {
    border-top: 1px dashed #eee;
    border-radius: 0 0 4px 4px;
}

.dropdown.open-mode-absolute.up.open {
    border-radius: 0 0 4px 4px;
    background-color: #fff;
}
.dropdown.open-mode-absolute.up .dropdown-popup {
    border-bottom: 1px dashed #eee;
    border-radius: 4px 4px 0 0;
    bottom: 100%;
    box-shadow: rgb(0 0 0 / 20%) 0px -6px 5px -3px, rgb(0 0 0 / 14%) 0px -9px 8px 1px,
        rgb(0 0 0 / 12%) 0px -10px 10px 2px;
}

.dropdown.open-mode-block .dropdown-popup {
    position: static;
    box-shadow: none;
}

.dropdown .value.empty label {
    color: #aaa;
    opacity: 0;
}

.dropdown .value label {
    color: #666;
    background-color: transparent;
    position: absolute;
    top: 1px;
    left: 6px;
    font-size: 100%;
    padding: 0 6px;
    transition: 350ms;
    height: 94%;
    display: flex;
    align-items: center;
}

.dropdown .value label,
.dropdown:focus-within .value label {
    height: auto;
    font-size: 65%;
    background: linear-gradient(0deg, #fff 68%, #fff0 32%);
    background-color: inherit;
    top: -5px;
    left: 6px;
    opacity: 1;
}

.dropdown.up:focus-within .value label {
    opacity: 0;
}

.dropdown:not(.no-border):hover {
    border-color: #666;
}

.dropdown:hover .value label {
    color: #666;
}

.dropdown:not(.no-border):focus-within {
    border-color: #3949ab;
}

.dropdown:focus-within .value label {
    color: #3949ab;
}

.dropdown:focus-within .placeholder {
    opacity: 0.2;
}

.dropdown.error .value:after {
    content: 'Error: ' attr(data-error);
    position: absolute;
    padding: 0 6px;
    transition: 150ms;
    display: flex;
    align-items: center;
    height: auto;
    font-size: 65%;
    background-color: #fff;
    bottom: -5px;
    left: 9px;
    color: #d69d9d;
    z-index: 1;
}

.dropdown.error .value,
.dropdown.error .value label {
    color: #d32f2f;
}

.dropdown.error {
    border: 1px solid #d32f2f;
    border-left: 3px solid #d32f2f;
}

@media (max-width: 768px) {
    .dropdown.open:not(.keepopen) .dropdown-popup {
        min-width: 0;
        position: fixed;
        display: flex;
        z-index: 100;
        border-radius: 4px;
        top: 50% !important;
        left: 50% !important;
        margin-top: -1% !important;
        bottom: auto !important;
        border: 1px solid #ddd;
        width: 98% !important;
        max-height: 96% !important;
        height: auto !important;
        background: #fff;
        transform: translate(-50%, -50%);
        box-shadow: 1px 1px 100px 100px #00000055 !important;
    }
}

.dropdown .value.color-red,
.dropdown .selected-item.color-red {
    background-color: #e90101;
    color: #fff;
}

.dropdown .value.color-green,
.dropdown .selected-item.color-green {
    background-color: green;
    color: #fff;
}

.dropdown .value.color-blue,
.dropdown .selected-item.color-blue {
    background-color: blue;
    color: #fff;
}

.dropdown .value.color-yellow {
    background-color: yellow;
}

.dropdown .value.color-silver {
    background-color: silver;
}

.dropdown .value.color-brown {
    background-color: brown;
    color: #fff;
}

.dropdown .value.color-orange {
    background-color: orange;
    color: #fff;
}

.dropdown .value.color-purple {
    background-color: purple;
    color: #fff;
}

.dropdown .value.color-pink {
    background-color: pink;
}

.dropdown .value.color-lightsalmon {
    background-color: lightsalmon;
}

.dropdown .value.color-darksalmon {
    background-color: darksalmon;
    color: #fff;
}

.dropdown .value.color-salmon {
    background-color: salmon;
    color: #fff;
}

.dropdown .value.color-indianred {
    background-color: indianred;
    color: #fff;
}

.dropdown .value.has-color label,
.dropdown:focus-within .value.has-color label {
    background: #fff;
}


.dropdown.disabled{
    user-select: none;
    pointer-events: none;
    opacity: 0.9;
    filter:grayscale(1);
    color:#000000aa;
    background-color: #eee;
    
}


.dropdown.disabled label,
.dropdown.disabled:focus-within  label {
    background: linear-gradient( #fff 0%, #eee 100%)
}
