
         .edit-referral.edit-page{
          .info{
            display: grid;
            grid-gap: 16px;
            gap:16px; 
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas: "modal-content"
  "form-fields"
  "require-form"
  "owner-customer-id"
  "trigger-api"
  "redirect-to"
  "code"
  "updated-at"
  "api-params"
  "require-login"
  "show-bar"
  "bar-content"
  "code-type"
  "show-modal"; 
          }
          .modal-content{
      grid-area:modal_content;
    }
  .form-fields{
      grid-area:form_fields;
    }
  .require-form{
      grid-area:require_form;
    }
  .owner-customer-id{
      grid-area:owner_customer_id;
    }
  .trigger-api{
      grid-area:trigger_api;
    }
  .redirect-to{
      grid-area:redirect_to;
    }
  .code{
      grid-area:code;
    }
  .updated-at{
      grid-area:updated_at;
    }
  .api-params{
      grid-area:api_params;
    }
  .require-login{
      grid-area:require_login;
    }
  .show-bar{
      grid-area:show_bar;
    }
  .bar-content{
      grid-area:bar_content;
    }
  .code-type{
      grid-area:code_type;
    }
  .show-modal{
      grid-area:show_modal;
    }
         }
      
