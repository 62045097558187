.list-item-switch-group-children{
  padding-left: 16px;
  opacity: 0.3;
  pointer-events: none;
  color:#444;
  font-size: 90%;
  background-color: #fafafa;
  margin-bottom: 16px;
  .list-item{
    border-bottom: 0px none;
  }
}

 

.list-item-switch-group-children.enabled{ 
  opacity: 1;
  pointer-events: all;
}

.group-item-children{

}

.group-item-children[disabled]{
  pointer-events: none; 
  user-select: none;
  cursor: default;
  background-color: #fafafa;

}
.group-item-children[disabled] *{
  pointer-events: none;
  opacity: 0.5;
  user-select: none;
  cursor: default; 
  
}