.popover:has(.customer-info-popover) {
    z-index: 102;
}

.customer-info-popover {
    padding-bottom: 24px;

    .customer-info-popover-rfm {
        display: flex;

        & > div {
            margin-right: 4px;
        }
    }

    .customer-info-popover-goto {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 90%;
        font-weight: 500;
        opacity: 0.8;
        color: inherit;
        text-decoration: inherit;

        .ficon {
            margin-right: 8px;
        }

        &:hover {
            opacity: 1;
        }
    }
}

.customer-info {
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .customer-avatar-wrapper {
        pointer-events: none;
    }
}
