
[type=checkbox],
[type=radio] { 
    color: dodgerblue;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 2px;
    background-color: #FFFFFF;
    transition: background 300ms;
    cursor: pointer;
  }
  
  
  /* Pseudo element for check styling */
  
  [type=checkbox]::before, 
  [type=radio]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: 140%;
    background-position: center;
    box-shadow: inset 0 0 0 2px #aaa;
  }
  
  
  /* Checked */
  
  [type=radio]:checked,
  [type=checkbox]:checked {
    background-color: currentcolor;
  }
  
  [type=radio]:checked::before,
  [type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }
  
  
  /* Disabled */
  
  [type=radio]:disabled,
  [type=checkbox]:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
  }
  
  
  /* IE */
  
  [type=radio]::-ms-check
  [type=checkbox]::-ms-check {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 2px #CCD3D8;
  }
  
  [type=radio]:checked::-ms-check,
  [type=checkbox]:checked::-ms-check {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }

  input[type="radio"],
  input[type="checkbox"]{
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding:0px; 
      vertical-align: center;
      align-self: center; 
  }
  
  input[type="radio"]{
      border-radius: 50%;
  }
  

  .check{
    display: inline-flex;
    align-items: center;
    margin: 4px 0px;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  }

.check input{
    vertical-align: middle;
    margin: 0px;
}
.check .text{
    padding-left: 4px;
    padding-right: 4px;
    display: inline-block;
    vertical-align: middle; 
    user-select: none;
    cursor: pointer;
}