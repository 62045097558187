.ecommerce {
    &:not(.page-root) {
        .tab-header {
            margin: 0 0 8px 0;
        }

        .toolbar-wrapper {
            margin: 0;
            border: none;

            .toolbar {
                margin: 0;
                padding-bottom: 16px;
                border-bottom: 1px solid #ddd;
            }
        }
    }
}

.translated-text {
    display: flex;
    flex-direction: column;

    .translated-text-main {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .translated-text-sub {
        font-size: 80%;
        opacity: 0.7;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.price {
    display: flex;
    flex-direction: column;

    .price-old {
        font-size: 70%;
        opacity: 0.5;
        text-decoration: line-through;
    }

    .price-new {
    }
}

.shipping-address {
    display: flex;
    flex-direction: column;

    .country {
        font-size: 80%;
        opacity: 0.5;
        text-transform: capitalize;
    }

    .address {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-transform: capitalize;
    }
}
