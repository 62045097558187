
.custom-column-select{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  .custom-column-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 80%;
    padding: 8px; 
    border: 1px solid #eee;
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000011;
    }
    cursor: pointer;
    .ficon{
      font-size:200%;
      margin-bottom: 0px;
    }
  }
}


.article-custom-code{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 8px;

  .custom-html-code{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 80%;
    padding: 8px;
    border: 1px solid #eee;
    flex:1;
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000011;
    }
    cursor: pointer;
    .ficon{
      font-size:200%;
      margin-bottom: 0px;
    }
  }
}