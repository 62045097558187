.product-description.translated-text{
    // font-size: small;
    // font-size: 10px !important;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    
    max-height: 150px; 
}

span.product-description-html, span.product-description-html *{
    // font-size: small;
    // font-size: 10px !important;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
     
}
span.product-description-html br{
    content: "";
    margin: 0em;
    display: block;
    font-size: 24%;
    outline: red;
}