.customer-avatar-wrapper {
    position: relative;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0px;
    
    .customer-avatar {
        display: flex;
        align-items: center;
        border-radius: 4px;
        overflow: hidden;
        

        img {
            width: 40px; 
            height: 40px;
            border-radius: 50%;
            background-color: #ddd;
            object-fit:cover;
        }


        img.square { 
            border-radius:4px; 
        }

        .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 6px;
            overflow: hidden;
        }

        .name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-transform: capitalize;
        }

        .location {
            display: block;
            opacity: 0.5;
            font-size: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .sub-description {
            display: block;
            opacity: 0.5;
            font-size: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .customer-avatar-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        bottom: -3px;
        left: 16px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        font-size: 9px;
        color: white;
    }




    .customer-active, .customer-passive, .customer-away{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 30px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        font-size: 9px;
        background-color: rgb(0, 190, 0);
        border: 2px solid #ffffff;
    }
    
    .customer-active.square, .customer-passive.square, .customer-away.square{
        top: 0px;
        left: 30px; 
    }
    

    .customer-active { 
        background-color: rgb(0, 190, 0);
        border: 2px solid #ffffff;
    }
    .customer-passive{
        
        background-color: rgb(197, 197, 197);
        border: 2px solid #fff;
    }

    
    .customer-away{
        
        background-color: rgb(255, 162, 0);
        border: 2px solid #fff;
    }

}

.customer-details-popover {
    padding: 8px 0;
    min-width: 220px;

    .customer-detail-info {
        border-bottom: 1px solid #eee;
        padding: 16px;
        text-transform: capitalize;

        b {
            width: 80px;
            display: inline-block;
        }
    }

    .button-wrapper {
        margin-top: 8px;
    }
}

.customer-avatar-wrapper.xlarge {
    width: 100%;
    font-size: 110%;

    .customer-active {
        top: 4px;
        left: 46px;
        width: 12px;
        height: 12px;
    }

    .customer-avatar {
        width: 100%;
        margin-right: 16px;

        img {
            width: 60px;
            height: 60px;
        }

        .info {
            padding-left: 16px;
        }

        .description {
            font-size: 90%;
        }

        .sub-description {
            font-size: 80%;
        }
    }
}

.customer-avatar-wrapper.large {
    width: 100%;

    .customer-avatar {
        width: 100%;
        margin-right: 16px;

        img {
            width: 40px;
            height: 40px;
            margin-right: 6px;
        }
    }
}

.customer-avatar-wrapper.medium {
    width: 100%;

    .customer-avatar {
        width: 100%;
        margin-right: 16px;

        img {
            width: 32px;
            height: 32px;
            margin-right: 6px;
        }
    }
}

.customer-avatar-wrapper.small {
    width: 100%;

    .customer-avatar {
        width: 100%;
        margin-right: 16px;

        img {
            width: 24px;
            height: 24px;
        }

        .location {
            display: none;
        }
    }
}

div:not(.popover) .customer-avatar[data-popover] *:not(.popover) {
    pointer-events: none;
}
