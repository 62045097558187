.app.wechat.page-article.subpage-preview {
    display: flex;
    background-color: #191919;

    .header {
        display: none;
    }

    .page-footer {
        display: none;
    }

    .app-menu {
        display: none;
    }

    .notifications {
        display: none;
    }

    .page {
        display: flex;
        align-items: center;
        min-height: 100vh;
        max-width: initial;
        max-height: initial;
    }

    .video-container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 677px;
        width: 100%;
        height: 100%;

        .video-player {
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
        }
    }
}
