.grid-stack>.grid-stack-item>.grid-stack-item-content.overview-card-wrapper {
  // overflow: hidden;
}

.overview-page-container .grid-stack>.grid-stack-item>.grid-stack-item-content {
  overflow-x: inherit;
  overflow-y: inherit;
}


@keyframes loading-animation {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.overview-card-wrapper-header-loading {

  display: inline-block;
  vertical-align: middle;

  height: 10px;
  padding: 2px;
  padding-left: 8px;
  margin-top: -10px;

  .ficon {
    animation: loading-animation 400ms linear;
    animation-iteration-count: infinite;
    pointer-events: none;

    font-size: 120%;
    opacity: 0.5;
  }

}


.overview-card-wrapper {
  background-color: #fff;
  border-radius: 4px;
  // flex:1;
  // width: 33%;
  // overflow: hidden;
  position: relative;
  user-select: none;
  // margin: 8px;


  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 4px;
  max-width: 100vw;
  border: 1px solid #fafafa;



  &.realtime {

    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.0196078431), rgba(0, 0, 0, 0.0196078431) 3px, rgba(0, 0, 0, 0.0666666667) 3px, rgba(0, 0, 0, 0.0666666667) 6px) !important;
    padding: 8px !important;
  }

  &.edit-mode:not(.CARD_CONTAINER) {
    box-shadow: 0px 0px 0px 2px #3d54b5;

    .overview-card-wrapper-body {
      user-select: none;
      pointer-events: none;
    }
  }

  &.loading {
    user-select: none;
    pointer-events: none;
  }

  &.no-border {
    .overview-card-wrapper-header {
      border: 0px none;
      padding-bottom: 0px;

      .overview-card-wrapper-header-title {
        text-transform: uppercase;
        font-size: 80%;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.7;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border: 0px none;
        padding-bottom: 0px;
      }

      .overview-card-wrapper-header-icon {

        top: 8px;
        background-color: #fff;

      }
    }

    .overview-card-wrapper-body {
      padding-top: 0px;
    }

  }


  .overview-card-wrapper-header {

    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    position: relative;
    width: 100%;
    height: 44px;

    &.hidden {
      position: absolute;
      top: 0;
      border-bottom: 0px none;
      min-height: 32px;
      z-index: 1;

      .overview-card-wrapper-header-icon {
        position: absolute;
        right: 8px;
        background-color: #fff;
        top: 8px;
        z-index: 1;

      }
    }


    .overview-card-wrapper-header-title {
      flex: 1;
      font-weight: 500;
      margin: 0;
      padding: 16px 16px;

      font-size: 100%;
      text-transform: capitalize;
      font-weight: bold;
      opacity: 1;
      line-height: 1;


      display: flex;
      flex-direction: row;
      align-items: center;
      
      span{
        flex:1;
      }
      .card-header-settings {
        display: flex;
    
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        font-size: 90%;
     
        select {
          opacity: 0.7;
          outline: none;
          padding: 6px 8px;
          padding-right: 28px;
        }
     
      }
    }

    .overview-card-wrapper-header-icon {
      font-size: 120%;
      line-height: 1;
      margin-right: 8px;
      font-weight: bold; 
      visibility: hidden;
      top: 0px;
    }

    &:hover .overview-card-wrapper-header-icon {
      
      visibility: visible;
    }
  }
   
  
  .overview-card-wrapper-body {
    padding: 8px 8px;
    height: calc(100%);
    overflow: hidden;
    align-items: center;
    display: flex;

    width: 100%;

    .funnel-chart {
      width: calc(100% - 16px);
      height: 80%;
      margin: 8px;
    }
  }

  &.has-header.no-border {
    .overview-card-wrapper-body {
      height: calc(100% - 24px);
    }
  }

  &.has-header:not(.no-border) {
    .overview-card-wrapper-body {
      height: calc(100% - 44px);
    }
  }

  &.has-header.has-footer:not(.no-border) {
    .overview-card-wrapper-body {
      height: calc(100% - 96px);
      margin-bottom: 8px;
    }
  }

  &.no-background {

    background-color: transparent;
    border: 0px none;
    box-shadow: none;
  }

  &.no-padding {
    padding: 0px;

    .overview-card-wrapper-body {
      padding: 0px;
    }
  }

  .overview-card-wrapper-footer {
    padding: 8px 16px;
    background-color: #fff;
    border-top: 1px solid #eee;
    z-index: 10;
    height: 44px;
  }

  // .overview-card-resizers > div{
  //   height: 10px !important;
  //   background-color: #ddd;
  // }


  .skeleton-card {
    position: absolute;
    height: calc(100% - 44px);
    left: 0;
    top: 0;
    margin-top: 44px;
    width: calc(100%);
    overflow: hidden;
    background-color: #ddd;
    // margin: -8px;
    // margin-top: -100px; 
    z-index: 10;

    &::before {
      content: '';
      position: absolute;
      background: linear-gradient(90deg, transparent, #e8e8e8, transparent);
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      animation: loading 1s infinite;
    }

    .skeleton-content {
      height: 100%;
      width: 100%;
      background: #ddd;
      border-radius: 4px;
    }
  }

  &:has(.overview-card-wrapper-header.hidden){

  .skeleton-card {
    margin-top: 0px;
    height: calc(100%);
  }
  }
}

.overview-card-wrapper-header-resizer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 1000px 1000px #ffffffee;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #ffffffee;
  font-weight: bold;
  gap: 8px;
  padding: 16px;

  .resize-option {
    flex: none;

    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 1px #00000022;

    &:hover {
      box-shadow: inset 0px 0px 100px 100px #00000022;
    }
  }
}

.overview-card-more-popover {
  width: 100%;
}


.card-footer-link {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ficon {
    font-size: 130%;
  }

  .card-footer-placeholder {
    flex: 1;
  }

  .card-footer-time-selector {
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: none;
    width: auto;

    .ficon {
      margin-right: 8px;
      font-size: 160%;
    }
  }


  .card-header-settings {
    display: flex;

    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 90%;
 
    select {
      opacity: 0.7;
    }
 
  }

  .card-footer-settings {
    display: flex;

    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 90%;
    flex:1;

    // opacity: 0.7;
    select {
      opacity: 0.7;
    }

    .dropdown {
      border-color: #eee;
      width: 220px;

      border: 0px;
      text-align: right;

      .arrow{
        border: 0px none;
        width: auto;
      }
      .value {
        opacity: 0.8;
        padding: 0px 8px;
        line-height: 1;
        display: flex;
        align-items: center;
        text-align: right; 
        justify-content: flex-end;

        .items-count {
          padding: 0px;
        }
      }
    }
  }
  
  .card-footer-max-fields {
    padding: 4px 8px;
    padding-right: 24px;
    outline: none;
    border: 0px none;
    align-self: flex-end;
    justify-self: flex-end;
    flex:1;
    max-width: 120px;
    margin-left: 8px; 
    min-width: 100px;
    cursor: pointer;
  }

  .card-footer-placeholder {
    flex: 1;
  }

  .card-footer-sub-text {
    display: flex;
    align-items: center;
    cursor: pointer;

    .ficon {
      margin-left: 8px;
    }
  }
}












@media (max-width: 768px) {
  .overview-card-wrapper {
   .overview-card-wrapper-header{
    .overview-card-wrapper-header-title{
      padding: 8px;
    }
   } 

    .overview-card-wrapper-footer {
      padding: 8px;

      .card-footer-link {
        .card-footer-settings {

          select {}

          .dropdown {

            width: 160px;
            font-size: 80%;

            .value {
              padding: 0px 4px;
            }
          }
        }
      }
    }
  }
}