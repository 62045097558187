.email-medias{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background-color: #00000066;
  z-index: 20;
  display: flex;
  flex-direction: column; 


  animation: fadeemailmedias 400ms forwards;
  animation-iteration-count: 1;
  .email-medias-header{
      flex:none;
      background-color: #000000dd;
      height: 48px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color:#fff;
      font-weight: bold;
      justify-content: flex-end;
      .email-medias-title{
          flex:1; 
          text-align: left;
          padding-left: 16px;
          font-weight: normal;
          opacity: 0.9;
      }
  }
  .email-medias-content{
      position: relative;
      width: 100%;
      height: calc( 100% - 65px );
      display: flex;
  }
}

@keyframes fadeemailmedias {
  0% {  opacity: 0;}
  50% { opacity: 0; } 
  100% { opacity: 1; } 
}
