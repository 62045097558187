
.view-page-description{
    background-color: #f2f2f2;
    padding:   16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 90%;
    opacity: 0.7;
    position: relative;
    padding-right: 44px;
    border-radius: 4px; 
    line-height: 1.5;
    p{
        padding: 4px 0px;
    }
    .close-description{
        position: absolute;
        right: 8px;
        top:8px;
        font-size: 150%;
        border-radius: 50%;
    }
}
