 
.active-users-header{
    display: flex;
    flex-direction: column;
    .active-users-header-title{
        display: flex;
        align-items: center;
        gap:8px;
            color: #3f51b5; 
        .active-users-header-dot{ 
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #4caf50;
        }
    }
    .active-users-second-header-title{
        color: #3f51b5; 
        display: flex;
        padding: 0px 0px;
        font-size: 90%; 
        opacity: 0.5;
    }

    .active-users-sub-header{ 
        display: flex; 
        opacity: 0.5;
        font-size: 90%;
        font-weight: normal;
        padding-bottom: 0px;
        padding-top: 8px;
    }

    strong{
        flex:1;
    }
    label{
       
    }
}


.active-users-list-container{
    overflow: auto;
    flex: 1;
    padding: 0px 8px;
    .active-customer-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 8px;
        border-bottom: 1px solid #eeeeee;
    }
   }
   

    .top-panel-wrapper {
        display: grid;
        
        width: 100%;
        gap: 16px;
        flex-direction: column;
        grid-template-columns: 1fr 300px;
        grid-template-areas: 'data users';
        align-items: flex-start;
        height: 100%;
        overflow: hidden;

        .top-panel-left {
            height: 100%;
            grid-area: data;
            overflow: hidden;

            .visualizations-wrapper {
                min-height: 462px;
                background: white;
                box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
               
                border-radius: 6px;
                overflow: auto;

                .chart-axis {
                    top: 16px;
                }
            }
        }

        .top-panel-right {
            grid-area: users;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .card{
                flex:1;
            }
            .customer-avatar-wrapper {
                width: 100%;
                max-width: calc(100% - 20px);

                .location > div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    .session-ids {
        font-size: 80%;
    }
 
    
    .overview-card-analytics-active-user{

        position: relative;
        width: 100%;
        height: 100%;

        .custom-options{
            pointer-events: all;
            display: flex;
            align-items: center; 
            padding:   16px;
            margin-bottom: 16px;
            gap:8px;
            background-color: #fff;
            border-radius: 4px;

        }
        .choose-metric-card-action-types{
            width: 300px;
        }
        .choose-metric-card-button{
            
            z-index: 3;
            // left: 50%;
            // transform: translateX(-50%);
            // top:50%;
            // transform: translate(-50%, -50%);
            
            pointer-events: all;
            
            width: 250px;
            // box-shadow: 0px 0px 100px 100px #00000044;
        }

        .overview-card-analytics-active-user-metric-selector-popup{

            user-select: none;
            pointer-events: all;
        }

        .metric-card {
            pointer-events: all;
        }
     

    }




@media (max-width: 768px) {
    
    .grid-stack > .grid-stack-item[gs-h]:has(.overview-card-analytics-active-user){
       height: 1700px;
    }
    .top-panel-wrapper {
        width: 100%;
        display: flex;
        // display: grid;
        
        // width: 100%;
         
        // flex-direction: column;
        // grid-template-columns:  1fr;
        // grid-template-areas: 
        // 'data'
        // 'users'; 

         
        .top-panel-left {
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;
            overflow: unset;
            .card{
                min-height: 0px;
                height: 76px;
                overflow: hidden;
                font-size: 85%;
                padding: 0px;
                .card-content{
                   
                    div+label+label{
                         display: none;
                     }
                }
            }
        }

        .top-panel-right{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            overflow: unset;
        }
    }
        
}

