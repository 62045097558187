.action-handle {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    .card-action {
        border: 0;
        box-shadow: none;
    }

    &:hover {
        cursor: pointer;
        box-shadow: rgb(0, 0, 0, 0.2) 0 3px 6px 0;
        border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    }

    &.react-draggable-dragging {
        cursor: grabbing;
        opacity: 0.7;
        box-shadow: none;
        border-bottom: 0;

        .card-content {
            user-select: initial;
        }
    }
}

.card-action {
    position: relative;
    padding: 24px;
    width: 100%;
    max-width: 832px;
    transition: box-shadow 150ms ease-out 0s, border-color 300ms ease-in-out 0s;
    user-select: none;

    .action-content {
        display: flex;
        margin-top: 24px;
        border-top: 1px solid #e0e0e0;
    }

    &.focused {
        border-radius: 5px;
        border: 2px solid #3d54b5;
        box-shadow: rgba(0, 0, 0, 0.2) 0 10px 30px 0;

        .action-handle {
            &:hover {
                box-shadow: rgb(61, 84, 181, 0.2) 0 6px 10px 0;
                border-bottom: 1px solid rgb(61, 84, 181, 0.6);
            }
        }
    }
}

.action-expand {
    position: absolute !important;
    z-index: 2;
    right: 60px;
    top: 34px;
}

.automation-list {
    .card-action {
        .context-menu-button {
            position: absolute !important;
            z-index: 2;
            right: 92px;
            top: 34px;
        }
    }
}

.action-header-actions {
    position: absolute !important;
    z-index: 2;
    right: 24px;
    top: 37px;
    display: flex;
    align-items: center;
}

.list-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;

    .action-item-wrapper {
        display: flex;
        min-width: 236px;

        &:nth-of-type(odd) .action-item {
            margin-right: 16px;
        }
    }

    .action-item {
        display: flex;
        width: 100%;
        padding: 8px 0;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #eeeeee;

        &:hover {
            cursor: pointer;
            background: #eeeeee;
        }

        .action-name {
            max-width: calc(100% - 44px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .action-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            font-size: 18px;
            border-radius: 3px;
            padding: 16px;
            background-color: white;
            color: #546e7a;
            border: 1px solid #b0bec5;
            margin: 0 8px;
        }
    }
}

.list-operations {
    .operation-item {
        display: flex;
        background: white;
        width: 100%;
        padding: 16px;
        margin-top: 16px;
        align-items: center;
        justify-content: flex-start;

        .operation-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: #3f51b5;
            margin-right: 8px;
        }

        &:hover {
            cursor: pointer;
            background: #eeeeee;
        }
    }
}

.popover-content:has(.context-menu) {
    right: 60px;
}

.action-setup {
    display: flex;
    width: 100%;

    .action-setup-status {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .action-setup-content {
        display: flex;
        width: 100%;
        flex-direction: column;

        .action-event-description {
            display: block;
            margin-bottom: 16px;
        }

        .action-setup-warning-message {
            display: block;
            font-weight: 500;
            line-height: 20px;
            padding: 8px;
            margin-top: 12px;
            border-radius: 4px;

            &.warning {
                color: #ff9800;
                background-color: rgba(230, 126, 34, 0.08);
            }
        }
    }

    .settings-section {
        h3 {
            margin-bottom: 16px;
        }

        .section-title {
            display: block;
            margin-top: 12px;
            margin-bottom: 16px;
        }

        &.divider {
            border-top: 1px solid #e0e0e0;
        }

        .settings-section-heading {
            display: flex;
            align-items: center;
            margin-top: 12px;

            .section-title {
                margin: 0 16px 0 0;
            }
        }
    }

    .timeout-selection {
        display: flex;
        margin-bottom: 24px;

        .timeout-button-wrapper {
            margin-right: 16px;
        }
    }

    .timeout-custom {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .input {
            max-width: 72px;
            margin-left: 32px;
            margin-right: 16px;
        }
    }

    .action-type {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;

        .action-type-data {
            display: flex;
            align-items: center;
            flex: 1;

            .action-type-icon {
                display: flex;
                height: 40px;
                width: 40px;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                padding: 8px;
                background-color: white;
                color: #546e7a;
                margin-right: 16px;
                border-radius: 3px;
                border: 1px solid #b0bec5;
            }
        }
    }

    .action-setup-dropdown {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .action-button-wrapper {
        align-self: center;
    }

    .action-records-list {
        display: flex;
        margin-bottom: 24px;

        .record-selector-list {
            width: 50%;

            &:nth-of-type(odd) {
                margin-right: 16px;
            }
        }
    }
}

.action-change-popup {
    display: flex;
    max-width: 832px;

    .popup-header {
        margin: 8px 8px 0 8px;
    }

    .action-change-content {
        display: flex;
        flex-direction: column;

        .action-description {
            padding-bottom: 16px;
            border-bottom: 1px solid #e0e0e0;
        }

        .action-change-content-wrapper {
            display: flex;
        }
    }

    .popup-content {
        padding: 0 24px;
        background: none;
    }
}

.action-rename-popup {
    display: flex;
    max-width: 832px;
    min-width: 350px;

    .popup-header {
        margin: 8px 8px 0 8px;
    }

    .popup-footer {
        padding: 12px 24px 24px;
    }

    .action-rename-content {
        display: flex;
        flex-direction: column;

        .action-rename-content-wrapper {
            display: flex;
        }
    }

    .popup-content {
        padding: 12px 24px 0 24px;
        background: none;
    }
}

.action-header {
    display: flex;

    .card-content {
        display: flex;
    }

    .action-header-icon {
        display: flex;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        padding: 8px;
        background-color: white;
        color: #546e7a;
        margin-right: 16px;
        border-radius: 3px;
        border: 1px solid #b0bec5;
    }

    .action-header-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        color: #263238;

        span {
            margin-bottom: 6px;
        }
    }
}

.filter-settings {
    margin-top: 16px;
    margin-bottom: 16px;
}

.delay-settings {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;

    .delay-settings-field {
        margin-right: 16px;
        max-width: 180px;

        &:last-child {
            margin-right: 0;
        }

        &.input:has(input[type='number']) {
            max-width: 140px;
        }

        &.input:has(input[type='text']) {
            max-width: 120px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .automation-list {
        .card-action {
            padding: 12px;
            min-height: 70px;

            .action-content {
                margin-top: 12px !important;
            }

            .action-setup-content {
                margin-top: 0;
            }

            .automation-heading {
                margin-top: 8px;
            }

            .automation-description {
                display: block;
                padding-top: 12px;
            }

            .action-content:has(.left-panel) {
                flex-wrap: wrap;
                margin-top: 12px !important;

                .left-panel {
                    width: 100%;
                    margin-bottom: 12px;

                    .action-item-wrapper {
                        width: 100%;
                    }
                }

                .right-panel {
                    width: 100%;
                    margin: 0;
                    height: auto;
                }
            }

            .timeout-selection {
                flex-wrap: wrap;
                margin-bottom: 12px;

                .button-wrapper {
                    margin-bottom: 12px;
                }
            }

            .context-menu-button {
                right: 86px;
                top: 20px;
            }
        }

        .action-header-content {
            h2 {
                max-width: calc(100vw - 230px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .action-item-wrapper {
            &:nth-of-type(odd) .action-item {
                margin-right: 0 !important;
            }
        }

        .action-records-list {
            .record-selector-list {
                width: 100% !important;

                &:nth-of-type(odd) {
                    margin-right: 0 !important;
                }
            }
        }

        .action-expand {
            right: 50px;
            top: 20px;
        }

        .action-header-actions {
            right: 14px;
            top: 24px;
        }

        .action-change-popup {
            max-width: calc(100vw - 32px);

            .action-change-content-wrapper {
                flex-wrap: wrap;

                .left-panel {
                    width: 100%;
                    margin-bottom: 12px;

                    .action-item-wrapper {
                        width: 100%;
                    }
                }

                .right-panel {
                    width: 100%;
                    margin: 0;
                    height: auto;
                }
            }
        }
    }
}
