.assign-popup-content{
  
  display: flex;
  flex-direction: column;
  padding: 8px;
  .assign-popup-buttons{
    display: flex;
    flex-direction: row;

    .button-wrapper{
      margin: 8px;
    }
    
  }
  .assign-note{  
    flex:1;
    // margin: 8px;
    padding: 12px; 
    max-width: 100%; 
    width: 500px;
    min-height: 150px;
    border:1px solid #ddd;
    resize: none;
    outline: none;
    border-radius: 3px;
    margin-top: 8px;
  }
}
