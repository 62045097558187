.product-item-group .card-header{ 
  background-color: #f5f8ff;
}
.property-group-header{
  display: grid;
  grid-gap: 16px;
  gap:16px; 
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
   "text"
   "title"
   "titlesub"; 
  .group-header-text{
    grid-area: text;
  }
  .group-title{
    grid-area: title;
  }
  .group-title-sub{
    grid-area: titlesub;
  }
}
.property-group{   
  display: grid;
  grid-gap: 8px;
  gap:8px; 
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:  
   "items items" 
   "button button";
  // .group-title{
  //   grid-area: title;
  // }
  // .group-title-sub{
  //   grid-area: titlesub;
  // }

  .group-items{
    grid-area: items; 
    padding-left:16px;
  }
  .group-item{
    
    padding: 4px;
    display: grid;
    grid-gap: 8px;
    gap:8px; 
    grid-template-columns: 1fr 1fr 32px;
    grid-template-rows: auto;
    grid-template-areas: 
     "title titlesub remove" ;
    .group-item-title{
      grid-area: title;
    }
    .group-item-title-sub{
      grid-area: titlesub;
    }
    .group-item-remove{
      grid-area: remove;
    }
  }

  .group-item-add{
    grid-area: button;
  }

}



.option-group-header{
  display: grid;
  grid-gap: 16px;
  gap:16px; 
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    "text"
    "title"
    "titlesub"; 

  .group-header-text{
    grid-area: text;
   }
  .group-title{
    grid-area: title;
  }
  .group-title-sub{
    grid-area: titlesub;
  }
} 

.option-groups{
.option-group{   
  
  display: grid;
  grid-gap: 8px;
  gap:8px; 
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
  //  "title title"
  //  "titlesub titlesub"
   "items items" 
   "button button";
  // .option-title{
  //   grid-area: title;
  // }
  // .option-title-sub{
  //   grid-area: titlesub;
  // }
  .option-items{
    grid-area: items; 
    padding-left:16px;

  }
  .option-item{
   
    padding: 4px;
   
    display: grid;
    grid-gap: 8px;
    gap:8px; 
    grid-template-columns: 1.8fr 1.4fr 1fr 32px;
    grid-template-rows: auto;
    grid-template-areas: 
     "title titlesub price remove" ;
    .option-item-title{
      grid-area: title;
    }
    .option-item-title-sub{
      grid-area: titlesub;
    }
    .option-item-price{
      grid-area: price;
    }
    .option-item-remove{
      grid-area: remove;
    }
  }

  .option-item-add{
    grid-area: button;
  }

}
}

.product-data-detail{

    
    padding: 4px;
    
    display: grid;
    grid-gap: 8px;
    gap:8px; 
    grid-template-columns: 2fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "properties options" 
    "skus skus" ;
      

    
    .option-groups{
      grid-area: options;
      margin-left: 0px;
      padding-left: 8px;
      border-left: 1px solid #ddd;
      .product-item-group{
        margin: 8px 0px;
      }

    }
    .property-groups{
      grid-area: properties;
      .product-item-group{
        margin: 8px 0px;
      }
    }

    .sku-groups{
      grid-area: skus;
    }
    
}