.utm-column {
    font-size: 90%;
    *{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.location-alt {
    font-size: 90%;
    opacity: 0.6;
}




.session-actions {
    padding: 8px 16px;
    background-color: #fafafa;
    box-shadow: inset 4px 4px 6px 2px #00000005;
    padding-bottom: 32px;
    border-bottom: 3px solid #eee;
    font-size: 90%;

    .tab-header-wrapper .tab-header {
        margin-bottom: 0px !important;
    }
}

.action-item {
    display: block;
}


.session-user {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .session-user-info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 4px;
    }

    .grid-goto-button {
        font-size: 120%;
    }
}

small {
    font-size: 80%;
}