.overview-card:has(.incoming-events-items) {
    position: relative;

    .card-header {
        margin-bottom: 0;
    }

    .card-content {
        //  overflow-y: auto;
        max-height: 255px;
        padding: 0;
    }

    .card-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0;
        min-height: 51px;

        .card-footer-time-selector {
            display: none !important;
        }
    }

    &:has(.skeleton-card) {
        > .card-content {
            padding: 0;
        }

        .card-header {
            display: none;
        }

        .card-footer {
            display: none;
        }

        .skeleton-card {
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                background: linear-gradient(90deg, transparent, #e8e8e8, transparent);
                width: 50%;
                height: 100%;
                top: 0;
                left: 0;
                animation: loading 1s infinite;
            }

            .skeleton-content {
                height: 100%;
                width: 100%;
                background: #ddd;
                border-radius: 4px;
            }
        }
    }

    &.mock {
        .card-content {
            overflow: hidden;
        }

        .card-footer {
            // TODO: uncomment this out when we implement status empty/needs setup for chart data on API
            // display: none;
            z-index: 2;
        }

        .wizard-link {
            text-decoration: none;
        }
    }
}

.incoming-events-items {
    display: flex;
    flex-direction: column;

    .list-item {
        padding-right: 16px;

        &:last-of-type {
            margin-bottom: 12px;
        }

        .item-image {
            background-size: cover;
            border-radius: 4px;
            width: 30px;
            height: 30px;
        }

        &.customer {
            .item-image {
                border-radius: 50%;
            }
        }
    }
}

.overview-card-show-all-link {
    cursor: pointer;
    opacity: 0.8;
    font-size: 90%;
    padding: 8px 8px;
    margin-top: 8px;
    text-align: center;
    user-select: none;

    &:hover {
        opacity: 1;
        background-color: #00000011;
    }
}

.more-items-popover {
    overflow: auto;
    width: 360px;
    padding: 16px;
}
