

.ai-prompts{
   top:0px;
   left: 0px;
   display: flex; 
   flex-direction: column;
   max-height: 100%; 
   
   padding: 8px; 
   position: relative;
.ai-prompt-search{ 
   display: flex;
   flex-direction: row;
    align-items: center;
   height: 60px;
   padding: 8px;
   select{
      margin-left: 8px;
   }
}
.ai-prompt-list{
   flex:1;
   width: 100%; 
}
}

.ai-prompt-list{
   // position: absolute;
   top:0px;
   left: 0px;
   display: flex;
   flex-wrap: wrap;
   max-height: 100%;
   overflow: auto;
   padding: 8px;
   padding-bottom: 100px;
   .ai-prompt{
      background-color: #fafafa;
      border:1px solid #eee;
      border-radius: 4px;
      min-width: 400px;
      max-width: 600px;
      flex:1;
      margin: 8px;
      padding: 8px;
      height: 200px;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      &:hover{
         box-shadow: inset 0px 0px 1000px 10000px #00000011;
      }
      &>*{
         padding: 8px;
      }
      &.selected{
         background-color: #6272d9;
         color:#fff;
      }
      .ai-prompt-title{
         font-size: 130%; 
         font-weight: bold;
         
      }
      .ai-prompt-category{  
         opacity: 0.6;
         padding: 0px 8px;
         font-size: 90%;
      }
      .ai-prompt-description{ 
         flex:1;
         overflow: hidden;
         font-size: 90%;
      }
      .ai-prompt-info{ 
         display: flex;
         align-items: center;
         font-size: 80%; 
         opacity: 0.6;
         .ai-prompt-icon-placeholder{ flex:1; }
         .ai-prompt-icon-data{
            display: flex;
            align-items: center;
             
            .ficon{
               font-size: 120%;
               font-weight: bold;
               margin-right: 2px;
            }
         }
      }
   }
}





 
@media screen and (max-width: 768px) {
   .ai-prompts{
      padding: 0px;
      .ai-prompt-search{
         padding: 8px 8px;
         height: auto;
         box-shadow: 0px 0px 5px 5px #00000011;
         z-index: 1;
      }
   }
  
   .ai-prompt-list{
      .ai-prompt{
         min-width: 300px;
         max-width: 100%;
      }
   }
   .ai-chat-message-footer{
      box-shadow: 0px 0px 5px 5px #00000008;
      z-index: 1;
   }
 
}