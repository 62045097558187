.conversion-log-item {
    // padding: 16px;
    // gap: 4px 16px;
    // grid-gap: 4px 16px;
    // display: grid;
    // align-items: center;
    // position: relative;
    // grid-template-columns: 60px auto 170px;
    // grid-template-rows: auto;
    // grid-template-areas:
    //     'icon label  expand'
    //     'icon time  expand'
    //     'icon desc  expand'
    //     'content content content';
    border-bottom: 1px solid #eee;

    .conversion-log-item-header{
        user-select: none;
        padding: 16px;
        gap: 4px 16px;
        grid-gap: 4px 16px;
        display: grid;
        align-items: center;
        position: relative;
        grid-template-columns: 60px auto 170px;
        grid-template-rows: auto;
        grid-template-areas:
            'icon label  expand'
            'icon time  expand'
            'icon desc  expand'
            'content content content';
        border-bottom: 1px solid #eee;
        cursor: pointer;
    }

    .list-icon {
        grid-area: icon;
        font-size: 28px;
        width: 60px;
        height: 60px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #fff;
    }

    .title {
        display: flex;
        align-items: center;
        grid-area: label;
        font-size: 120%;
        align-self: flex-end;

        .channel-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 20px;
            margin-left: -3px;
            text-transform: capitalize;

            // &:hover {
            //     cursor: pointer;
            // }

            .ficon {
                pointer-events: none;
                font-size: 15px;
            }
        }
    }

    .time {
        grid-area: time;
        font-size: 80%;
        align-self: flex-end;
        text-transform: uppercase;
        opacity: 1;
        line-height: 1;
    }

    .desc {
        grid-area: desc;
        font-size: 70%;
        align-self: flex-start;
        text-transform: uppercase;
        opacity: 0.7;
        line-height: 1;
    }

    .expand {
        grid-area: expand;
        font-size: 80%;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        padding: 8px 16px;
        user-select: none;
        font-weight: 500;

        // &:hover {
        //     box-shadow: inset 1px 1px 1000px 1000px #00000011;
        // }

        .ficon {
            font-size: 150%;
            font-weight: bold;
        }
    }
}

.conversion-log-item .session-detail-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; 
    padding: 16px;


    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 

    .session-referrer {
        margin-left: 8px;
    }

    .session-icon {
        width: 36px;
        min-width: 36px;
        min-height: 36px;
        height: 36px;
        margin-right: 16px;
        font-size: 20px;
        font-weight: bold;
        background-color: #666;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .session-icon.color-yellow {
        background-color: #ecb40c;
    }

    .session-icon.color-brown {
        background-color: #6a5328;
    }

    .session-icon.color-blue {
        background-color: #0079d9;
    }

    .session-icon.color-gray {
        background-color: #4d6f7b;
    }

    .session-icon.color-green {
        background-color: #1aaf4a;
    }

    .session-icon.color-dgreen {
        background-color: #139f71;
    }

    .time {
        color: #aaa;
        margin-right: 8px;
        min-height: 36px;
        width: 90px;
        flex: none;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        font-weight: 500;
        text-align: left;
        align-self: flex-start;
    }

    .info {
        display: flex;
        flex-direction: column;
        padding-top: 2px;
        min-height: 36px;
        justify-items: center;
        align-items: flex-start;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 

        .item-id {
            font-size: 60%;
            opacity: 0.4;
           
         }   
    }
}

.conversion-log-item .session-detail-item .info .info-line {
    padding: 8px;
    padding-top: 0px;
    flex: 1;
    display: flex;
    align-items: center;


    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 

 
    &.item-type-info {
       border-left: 2px solid #eee;
       margin-left: 12px;
       padding-bottom: 12px;

       .item-info .item {
        color: #000; 
    }
       .key{
        display: none;
       }
    }   


    .action {
        margin-right: 4px;
    }

    .action-sticker {
        line-height: 1;
        margin-left: 8px;
    }

    .detail{
        margin-left: 4px;
        font-style: italic;
         font-size: 90%;
         margin-top: 4px;
         opacity: 0.7;
         b{
            font-weight: 500;
         }
    }
    .key {
        margin-left: 4px;
    }

    a {
        margin-left: 4px;
    }

    .item-info {
        display: flex;
        flex: 1;
        flex-direction: column; 

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 


        .item-wrapper {
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                margin-left: 6px;
                text-decoration: none;

                .ficon {
                    font-size: 16px;
                }
            }
        }

        .url {
            opacity: 0.5;
            margin-top: 4px;
            margin-left: 4px;
            font-size: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
        .key {
            opacity: 0.7;
            margin-top: 4px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }

        .item {
            color: #3a51bc;
            margin-left: 4px;
        }
    }
}

.conversion-log-item .session-detail-start {
    font-size: 90%;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 16px;
    opacity: 0.8;
    margin-bottom: 16px;
}

.conversion-log-item .session-start-info {
    padding: 4px;
    padding-bottom: 8px;
    display: flex;

    width: 100%;
    white-space: wrap;
    line-height: 15px;
    max-height: 48px;
    overflow: hidden;

    &:not(:last-child) {
        border-bottom: 1px dashed #ddd;
        margin-bottom: 8px;
    }
    .session-start-info-label {
        width: 150px;
        min-width: 150px;
    }
}
.conversion-log-item .session-detail-end {
    font-size: 80%;
    opacity: 0.5;
    padding-top: 16px;
    padding: 16px;
}

.conversion-log-item .content {
    grid-area: content;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px 0px;
    padding-top: 0px;
    flex-direction: column;
    overflow: hidden;
}

@media (max-width: 768px) {
    .conversion-log-item {
        grid-gap: 8px;
        gap: 8px;
        grid-template-columns: 44px auto 80px;

        .title {
            font-size: 120%;
        }

        .expand {
            padding: 8px 4px;
            font-size: 70%;

            &:hover {
                box-shadow: none;
            }
        }
    }
}
