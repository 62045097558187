.datagrid .datagrid-tools {
    width: 100%;
    user-select: none;
}

.datagrid .head-wrapper {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px;
}

.datagrid .head-wrapper .search {
    flex: 1;
    align-items: center;
    display: flex;
}


.datagrid .head-wrapper .search .grid-search-cancel{
    opacity: 0.4;
    margin-left: -40px;
    margin-right: 8px;

}
.datagrid.--grid-loading .head-wrapper .search .grid-search-button{
    opacity: 0.1;
}

.datagrid.--grid-loading .head-wrapper .search .grid-search-cancel{
    opacity: 0.1;
}
.datagrid.--grid-loading .head-wrapper .search .search-text{
    background-color: #00000009;
}


.datagrid .head-wrapper .search .search-text {
    max-width: 300px;
    min-width: 180px;
    flex: 1;
    margin-right: 8px;
    padding: 8px 16px;
    user-select: none;
}


.datagrid .head-wrapper .search .search-text.--has-value  {
    box-shadow: inset 0px 0px 1000px 1000px #ff000011;
    font-weight: bold;
}


.datagrid .head-wrapper .actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.datagrid .head-wrapper .action {
    width: 32px;
    height: 32px;
    margin: 0px 2px;
    // background-color: #00000011;
    cursor: pointer;
    user-select: none;
}



@media (min-width: 1100px) {
.datagrid .head-wrapper .actions .action.create {
    position: absolute;
    z-index: 10;
    right: 0px;
    top: -44px;
    background-color: #3949ab;
    color:#fff;
    font-weight: bold;
    border-radius: 4px;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 36px;
    padding: 8px;
    height: 36px; 
    transition: 200ms;
    overflow: hidden;
    font-size: 150%;
    &:after{
        opacity: 0;
        transition: 300ms;
        padding-left: 4px; 
        font-family: Roboto;
        text-transform: capitalize;
         
        color: #fff;
        font-size: 65%;

    }
    &:hover{
        background-color: #293fa0; 
        width: 120px; 
        justify-content: flex-start;
        
        &:after{
            content:attr(data-tooltip);
            opacity: 1;
             
            
        }
    }
}
}



@media (min-width: 1400px) {
    .datagrid .head-wrapper .actions .action.create {
         width: 120px;
         justify-content: flex-start;

        &:after{
            opacity: 1;
            content:attr(data-tooltip);
          }
    }
}

.datagrid .selection-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #00000022;
    display: flex;
    flex-direction: row;
    background-color: #dbdfff;
    user-select: none;
}

.datagrid .selection-info .selection-text {
    flex: 1;
    align-items: center;
    display: flex;
    padding: 0 10px;
    opacity: 0.8;
}

.datagrid .selection-info .selection-actions {
    display: flex;
    flex-wrap: wrap;
}

.datagrid .selection-info .selection-action {
    padding: 8px;
    cursor: pointer;
    user-select: none;
    font-size: 130%;
   
}

.datagrid.disable-delete .selection-info .selection-action.delete{ 
    display: none;
}

.datagrid .selection-info .selection-action:hover {
    background-color: #00000022;
}

//  .datagrid .grouping{
//     flex:1;
//     display: inline-block;
//     height: 56px;
//     padding: 10px 4px;
//     vertical-align: middle;
//     position: relative;

//     user-select: none;
//     border-top:1px solid #eee;

//     .grouping-text{
//         height: 20px;
//         position: absolute;
//         top:15px;
//         left: 20px;
//         z-index: 0;
//         opacity: 0.5;
//     }
//  }
//  .datagrid .grouping .sortable-chosen{
//     background-color: #ffffffdd !important;
//     display: inline-block;
//     width: auto !important;
//  }
//  .datagrid .grouping .datagrid-header-cell{
//     display: inline-block;
//     border:1px solid #ddd;
//     margin: 0px 6px;
//     border-radius: 4px;
//     background-color: #fafafa;
//     line-height: 36px;
//     .cell-extensions, .cell-more, .cell-filter {
//         display: none !important;
//     }
//  }

//  .datagrid .grouping  .datagrid-header-cell-grouped{
//     display: inline-block;
//     border:1px solid #ddd;
//     margin: 0px 6px;
//     border-radius: 4px;
//     background-color: #fff;
//     text-overflow: ellipsis;
//     overflow-x: hidden;
//     white-space:nowrap;
//     vertical-align: middle;
//     max-width: 150px;
//     height: 100%;
//     line-height: 34px;
//     padding: 0px 8px;
//     cursor: pointer;
//     position: relative;
//     padding-right: 32px;

//     .remove-group{
//         display: inline-block;
//         width:22px;
//         height: 22px;
//         line-height: 20px;
//         text-align: center;
//         background-color: #ddd;
//         border-radius: 50%;
//         position: absolute;
//         right:4px;
//         top:6px;
//         font-weight: bold;
//     }
//  }

.sortable-ghost {
    background-color: #00000022 !important;
}

@media (max-width: 768px) {
    .datagrid .head-wrapper {
        flex-direction: column;
    }
    .datagrid .head-wrapper .search {
        flex: 1;
        max-width: 100%;
        padding: 4px 8px;
    }

    .datagrid .head-wrapper .actions {
        padding: 4px 8px;
    }

    .datagrid .head-wrapper .actions .action {
        border: 1px solid #eee;
        margin-right: 4px;
        margin-bottom: 4px;
    }

    //  .datagrid .grouping{
    //      height: 50px;
    //      flex: auto;
    //  }

    //  .datagrid .grouping .grouping-text{
    //          left: 8px;
    //     }

    .datagrid .head-detail-wrapper .selection-info {
        flex-direction: column;
        padding-top: 4px;
        padding-bottom: 4px;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }
    .datagrid .head-detail-wrapper .selection-info .selection-text {
        padding: 8px;
    }

    .datagrid .head-detail-wrapper .selection-info .selection-actions {
        padding: 4px;
    }
    .datagrid .head-detail-wrapper .selection-info .selection-actions .selection-action {
        border: 1px solid #00000022;
        margin: 2px;
        padding: 4px 8px;
        font-weight: 500;
    }
}

.datagrid-grouping {
    display: flex;
    align-items: center;
    padding-right: 12px;
    position: relative;
    user-select: none;
    // min-height: 50px;
    border-top: 1px solid #eee;
    font-size: 90%;
}

.datagrid-grouping.disabled .dropdown {
    opacity: 0.3;
}

.datagrid-grouping .datagrid-grouping-enable {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-color: #ffffffcc;
    z-index: 10;
    font-size: 90%;
    width: 100%;

    gap: 4px 0;
    grid-gap: 4px 0;

    grid-template-columns: 1fr 100px;
    grid-template-rows: auto;
    grid-template-areas: 'text btn';
    padding: 0 12px;
    padding-right: 14px;
}

.datagrid-grouping .datagrid-grouping-enable > .button-wrapper {
    // margin-left: 8px;
    justify-self: flex-end;
    grid-area: btn;
}

.datagrid-grouping.disabled .datagrid-grouping-enable {
    // display: flex;
    display: grid;
}

.datagrid-filter {
    padding: 8px 12px;
    // background-color: #0bb41126;
    display: flex;
    font-size: 90%;
    position: relative;
}

.datagrid-filter-button {
    font-size: 150%;
    padding: 0px 8px;
}

.datagrid-filter.disabled .datagrid-filter-string,
.datagrid-filter.disabled .datagrid-filter-button {
    opacity: 0.3;
}

.datagrid-filter .datagrid-filter-enable {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #ffffffcc;
    z-index: 10;

    gap: 4px 0;
    grid-gap: 4px 0;

    grid-template-columns: 1fr 100px;
    grid-template-rows: auto;
    grid-template-areas: 'text btn';
    padding: 0 12px;
    padding-right: 14px;
}

.datagrid-filter .datagrid-filter-enable > .button-wrapper {
    // margin-left: 8px;
    justify-self: flex-end;
    grid-area: btn;
}

.datagrid-filter.disabled .datagrid-filter-enable {
    display: grid;
}

.datagrid-filter .datagrid-filter-string {
    align-self: center;
    flex: 1;
    line-height: 1.6;
}

.datagrid-filter .datagrid-filter-string a {
    color: #5a83c5;
}

.datagrid-filter .datagrid-filter-string p {
    text-decoration: none; 
    display: inline-block;
    padding: 2px 4px;
    border-radius: 4px;
    color:#c75b5b;
}
.datagrid-filter .datagrid-filter-string u {
    text-decoration: none;
    background-color: #00000011;
    padding: 2px 4px;
    border-radius: 4px;
}
.datagrid-filter .datagrid-filter-string i {
    color: #00000055;
    font-style: normal;
    // font-size: 90%;
}

.datagrid-filter .datagrid-filter-string b {
    color: #000;
}
.datagrid-filter .button-wrapper {
    align-self: center;
    justify-self: flex-end;
}







.datagrid-check-visibility {
    padding: 8px 12px;
    // background-color: #0bb41126;
    display: flex;
    font-size: 90%;
    position: relative;
}

.datagrid-check-visibility-button {
    font-size: 150%;
    padding: 0px 8px;
}

.datagrid-check-visibility.disabled .datagrid-check-visibility-string,
.datagrid-check-visibility.disabled .datagrid-check-visibility-button {
    opacity: 0.3;
}

.datagrid-check-visibility .datagrid-check-visibility-enable {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #ffffffcc;
    z-index: 10;

    gap: 4px 0;
    grid-gap: 4px 0;

    grid-template-columns: 1fr 100px;
    grid-template-rows: auto;
    grid-template-areas: 'text btn';
    padding: 0 12px;
    padding-right: 14px;
}

.datagrid-check-visibility .datagrid-check-visibility-enable > .button-wrapper {
    // margin-left: 8px;
    justify-self: flex-end;
    grid-area: btn;
}

.datagrid-check-visibility.disabled .datagrid-check-visibility-enable {
    display: grid;
}

.datagrid-check-visibility .datagrid-check-visibility-string {
    align-self: center;
    flex: 1;
    line-height: 1.6;
    color:#c05f5f;
}
 
.datagrid-check-visibility .button-wrapper {
    align-self: center;
    justify-self: flex-end;
}




.datagrid-displayset {
    padding: 8px 12px;
    // background-color: #20a3c726;
    border-bottom: 1px solid #eee;
    display: flex;
    font-size: 90%;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1.4;
}
.datagrid-displayset .datagrid-displayset-label {
    width: 100px;
    color: #666;
}

.datagrid-displayset .datagrid-displayset-string {
    flex: 1;
    min-width: 300px;
    // padding: 4px;
}

.datagrid-colorcon {
    padding: 8px 12px;
    // background-color: #ccd51d26;
    border-bottom: 1px solid #eee;
    display: flex;
    font-size: 90%;
    position: relative;
    align-items: center;
}

.datagrid-colorcon.disabled .datagrid-colorcon-string,
.datagrid-colorcon.disabled .datagrid-colorcon-remove {
    opacity: 0.3;
}

.datagrid-colorcon .datagrid-colorcon-enable {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #ffffffcc;
    z-index: 10;

    gap: 4px 0;
    grid-gap: 4px 0;

    grid-template-columns: 1fr 100px;
    grid-template-rows: auto;
    grid-template-areas: 'text btn';
    padding: 0 12px;
    padding-right: 14px;
}

.datagrid-colorcon .datagrid-colorcon-enable > .button-wrapper {
    // margin-left: 8px;
    justify-self: flex-end;
    grid-area: btn;
}

.datagrid-colorcon.disabled .datagrid-colorcon-enable {
    display: grid;
}

.datagrid-colorcon .datagrid-colorcon-string {
    flex: 1;
    line-height: 1.8;
    display: block;
    // br{
    //     display: none;
    // }
}
.datagrid-colorcon .datagrid-colorcon-string .con-color {
    padding: 2px 4px;
    border-radius: 4px;
}
.datagrid-colorcon .datagrid-colorcon-string a {
    color: #5a83c5;
}

.datagrid-colorcon .datagrid-colorcon-string u {
    text-decoration: none;
    background-color: #00000011;
    padding: 2px 4px;
    border-radius: 4px;
}
.datagrid-colorcon .datagrid-colorcon-string i {
    color: #00000055;
    font-style: normal;
    // font-size: 90%;
}

.datagrid-colorcon .datagrid-colorcon-string b {
    color: #000;
}
.datagrid-colorcon .button-wrapper {
    align-self: center;
    justify-self: flex-end;
}

.datagrid-tools-desc-collapser {
    width: 100%;
    // padding: 4px 8px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    line-height: 1;
    font-size: 80%;
    background-color: #fafafa;
}
.datagrid-tools-desc-collapser:before {
    content: ' ';
    width: 100%;
    position: absolute;
    height: 1px;
    background-color: #fff;
    z-index: 0;
}
.datagrid-tools-desc-collapser .label {
    background-color: #fafafa;
    z-index: 1;
    padding: 4px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datagrid-tools-desc-collapser .collapser-icon {
    font-size: 150%;
}
.datagrid-tools-desc-collapser.expanded {
    border-top: 1px solid #eee;
    background-color: #fafafa;
}
.datagrid-tools-desc-collapser.expanded .label {
    background-color: #fafafa;
}

.datagrid-tools-desc {
    display: flex;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    align-items: center;
    font-size: 100%;
    line-height: 1.4;
}

.datagrid-tools-desc i {
    font-size: 100%;
}

.datagrid-tools-desc > div {
    padding: 8px;
}
