$active-color: #00f2ff;
$active-hover-color: #00aeff;
$active-collapsed-color: #07cdff;
$active-ellapsed-color: #00f2ff;

$warn-active-color: #eebb16;
$warn-active-hover-color: #ffc919;
$warn-active-collapsed-color: #ff7207;
$warn-active-ellapsed-color: #d3a320;

$emergency-active-color: #a80000;
$emergency-active-hover-color: #ff0000;
$emergency-active-collapsed-color: #ff0707;
$emergency-active-ellapsed-color: #ff0000;

.ui-hint-wrapper {  


  &.warn{
    background-color: $warn-active-color;
    animation: warn 1.5s infinite;
    &:hover{ 
      background-color: $warn-active-hover-color; 
    }
    img{
      display: none;
    }
  }

  &.emergency{
    background-color: $emergency-active-color;
    animation: emergency 0.4s infinite;
    &:hover{ 
      background-color: $emergency-active-hover-color; 
    }
    img{
      display: none;
    }
  }
 

  border-radius: 50%;
    padding: 0px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    // position: fixed;
    // top: 32px;
    // right: 32px;
    // z-index: 10;  
    animation: glow 1.8s;
    animation-iteration-count: infinite; 
    background-position: center;
    background-size: 200%;
    background-color: $active-color;
    cursor: pointer;
    transition: 200ms;
    margin-right: 8px;

  img{
    transition: 300ms;
    width:60px;
     margin:-20px;
     opacity: 0.5;
    //  animation: rotate 1s;
    //  animation-iteration-count: infinite;
  }
  .ficon{
    font-size: 54px;
    position: absolute;
    top:-7px;
    left:-7px;
    z-index: 1;
    opacity: 0.1; 
    user-select: none;
    color:#fff;
  }

  &:hover{
     
    width: 30px;
    height: 30px; 
    margin-bottom: 0px;
    margin-right: 3px; 
    background-color: $active-hover-color;
    // animation:none;
    img{ 
      width:96px;
       margin:-30px;
    }
  }





@keyframes rotate {
  // scale down and scale up faster in irregular intervals to get the throbbing effect
  0% {
    transform: rotate(0deg)  ; 

  }
  
  100% {
    transform: rotate(359deg);
  }
}
 
@keyframes glow {
  // scale down and scale up faster in irregular intervals to get the throbbing effect
  0% {
    transform:  scale(0.8);
    // box-shadow: 0 0 10px -10px #aef4af;
    box-shadow: 0 0 5px 1px $active-collapsed-color;

  }
  5% {
    transform:  scale(0.9);
    box-shadow: 0 0 5px 1px $active-collapsed-color;
  }
  10% {
    transform:  scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform:  scale(0.8);
    box-shadow: 0 0 120px 1px $active-ellapsed-color;
  }
}
 
@keyframes warn { 
  0% {
    transform:  scale(0.8); 
    box-shadow: 0 0 0px 1px $warn-active-collapsed-color;

  } 
  50% {
    transform:  scale(1);
    box-shadow: 0 0 90px 1px $warn-active-ellapsed-color;
  }
  100% {
    transform:  scale(0.8); 
    box-shadow: 0 0 0px 1px $warn-active-collapsed-color;
  }
}

@keyframes emergency { 
  0% {
    transform:  scale(0.6); 
    box-shadow: 0 0 0px 1px $emergency-active-collapsed-color;

  } 
  50% {
    transform:  scale(1);
    box-shadow: 0 0 90px 1px $emergency-active-ellapsed-color;
  }
  100% {
    transform:  scale(0.6); 
    box-shadow: 0 0 0px 1px $emergency-active-collapsed-color;
  }
}
 
 
 


}

.ui-hint-text{
 
  border-radius: 4px;
    padding: 12px 16px; 
    max-width: 400px; 
    min-height: 36px;
    position: fixed;
    top: 58px;
    // transform: translateY(26%);
    right: 16px;
    z-index: 10;   
    font-size: 80%; 
    background-color: #000000cc;
    color: #fff;
    cursor: pointer;
    transition: 200ms;
    user-select: none;
    opacity: 0;
    pointer-events: none;


    &.show{ 
      opacity: 1;
      pointer-events: all;
    }
    
    .ui-hint-title{
      font-weight: bold;
      margin-bottom: 8px;
      padding-right: 32px;
    }

    .ui-hint-close{
      position: absolute;
      top:0px;
      right: 0px;
      font-size: 130%; 
      color:#aaa;
      border-radius: 50%;
      padding: 10px 12px;
      &:hover{
        color: #fff;
      }
    }

    &:after{
      // content:  ' '; 
      top: -8px;
      right: 170px;
      position: absolute;
      // transform: rotate(45deg);

      border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-bottom: 8px solid #000000cc;


  // border-top: 8px solid transparent;
  // border-bottom: 8px solid transparent; 
  // border-left: 8px solid #000000cc;
  width: 0; 
  height: 0; 
    }
}