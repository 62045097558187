.overview-page .overview-card {
    .overview-scorecard-list {
        .card-data-block {
            .card-data-block-title {
                margin-top: 8px;
            }

            .card-data-block-value {
                font-size: 150%;
                margin: 8px 0;
            }
        }
    }
}
