.notification-popover{
  width: 330px;
  height: 250px;
  overflow: auto;
  .item-image {
    border-radius: 50%;
    width: 40px;
    height: 40px !important;
  }
  .item-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100% !important;
  }
}