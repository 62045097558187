.accordion {
    transition: 500ms;
    width: 100%;

    .accordion-head {
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        user-select: none;
        font-weight: 500;
        font-size: 100%;
        border-bottom: 1px solid #eee;
        text-transform: uppercase;
    }

    &:not(.only-arrow-expand) .accordion-head:hover {
        box-shadow: inset 1px 1px 1000px 1000px #00000011;
    }

    .accordion-head:not(.--only-arrow):active {
        box-shadow: inset 1px 1px 1000px 1000px #00000022;
    }

    .accordion-title {
        padding: 16px;
        flex: 1;
    }

    .arrow {
        &:hover {
            box-shadow: none;
        }
    }
}
