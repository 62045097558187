.card-trigger {
    position: relative;
    padding: 24px;
    width: 100%;
    max-width: 832px;
    transition: box-shadow 150ms ease-out 0s, border-color 300ms ease-in-out 0s;
    user-select: none;

    .trigger-content {
        display: flex;
        margin-top: 24px;
        border-top: 1px solid #e0e0e0;
    }

    &.focused {
        border-radius: 5px;
        border: 2px solid #3d54b5;
        box-shadow: rgba(0, 0, 0, 0.2) 0 10px 30px 0;
    }
}

.list-triggers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;

    .trigger-item-wrapper {
        display: flex;
        min-width: 50%;

        &:nth-of-type(odd) .trigger-item {
            margin-right: 16px;
        }
    }

    .trigger-item {
        display: flex;
        width: 100%;
        padding: 8px 0;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #eeeeee;

        &:hover {
            cursor: pointer;
            background: #eeeeee;
        }

        .trigger-name {
            max-width: calc(100% - 44px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .trigger-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            font-size: 18px;
            border-radius: 3px;
            padding: 16px;
            background-color: white;
            color: #546e7a;
            border: 1px solid #b0bec5;
            margin: 0 8px;
        }
    }
}

.list-operations {
    .operation-item {
        display: flex;
        background: white;
        width: 100%;
        padding: 16px;
        margin-top: 16px;
        align-items: center;
        justify-content: flex-start;

        .operation-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: #3f51b5;
            margin-right: 8px;
        }

        .operation-name {
            color: #263238;
            font-size: 14px;
        }
    }
}

.trigger-header {
    display: flex;

    .trigger-header-icon {
        display: flex;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        padding: 8px;
        background-color: white;
        color: #546e7a;
        margin-right: 16px;
        border-radius: 3px;
        border: 1px solid #b0bec5;
    }

    .trigger-header-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        color: #263238;

        span {
            margin-bottom: 6px;
        }
    }
}

.trigger-expand {
    position: absolute !important;
    z-index: 2;
    right: 60px;
    top: 32px;
}

.trigger-header-actions {
    position: absolute !important;
    z-index: 2;
    right: 24px;
    top: 37px;
    display: flex;
    align-items: center;
}

.trigger-setup {
    display: flex;
    width: 100%;

    .trigger-setup-status {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .trigger-setup-content {
        display: flex;
        width: 100%;
        flex-direction: column;

        .trigger-event-description {
            margin-bottom: 24px;
        }
    }

    .trigger-type {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 8px;

        .trigger-type-data {
            display: flex;
            align-items: center;
            flex: 1;

            .trigger-type-icon {
                display: flex;
                height: 40px;
                width: 40px;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                padding: 8px;
                background-color: white;
                color: #546e7a;
                margin-right: 16px;
                border-radius: 3px;
                border: 1px solid #b0bec5;
            }
        }
    }

    .trigger-setup-dropdown {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .trigger-button-wrapper {
        align-self: center;
    }

    .section-title {
        display: block;
        margin-top: 12px;
        margin-bottom: 0;
    }
}

.trigger-reset-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 470px;
    padding: 16px;

    .trigger-reset-icon {
        font-size: 64px;
        color: #e65100;
        margin-top: 64px;
        margin-bottom: 24px;
    }

    .trigger-reset-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            margin-bottom: 8px;
        }

        p {
            text-align: center;
            margin-bottom: 8px;
        }
    }

    .popup-header {
        display: none;
    }

    .popup-content {
        background: none;
    }

    .popup-footer {
        display: flex;
        flex-direction: column;

        .button-wrapper {
            align-self: center;
            margin-bottom: 24px;

            .primary {
                margin-top: 24px;
            }
        }
    }
}

.trigger-change-popup {
    display: flex;
    max-width: 832px;

    .popup-header {
        margin: 8px 8px 0 8px;
    }

    .trigger-change-content {
        display: flex;
        flex-direction: column;

        .trigger-description {
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid #e0e0e0;
        }

        .trigger-change-content-wrapper {
            display: flex;
        }
    }

    .popup-content {
        padding: 0 24px;
        background: none;
        min-width: 670px;
    }
}

.valid-icon {
    color: #4caf50;
}

@media only screen and (max-width: 768px) {
    .card-trigger {
        padding: 12px;

        .trigger-content {
            margin-top: 12px !important;
        }

        .trigger-setup-content {
            margin-top: 0;
        }

        .trigger-header-content {
            h2 {
                max-width: calc(100vw - 190px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .trigger-item-wrapper {
        &:nth-of-type(odd) .trigger-item {
            margin-right: 0 !important;
        }
    }

    .trigger-expand {
        right: 50px;
        top: 20px;
    }

    .trigger-header-actions {
        right: 14px;
        top: 24px;
    }

    .trigger-reset-popup {
        max-width: calc(100vw - 32px);
    }

    .trigger-change-popup {
        max-width: calc(100vw - 32px);

        .popup-content {
            min-width: initial;
        }

        .trigger-change-content-wrapper {
            flex-wrap: wrap;

            .left-panel {
                width: 100%;
                margin-bottom: 12px;

                .trigger-item-wrapper {
                    width: 100%;
                }
            }
        }
    }
}
