.sticker {
    padding: 4px 16px;
    color: #fff;
    font-size: 80%;
    border-radius: 2px;
    // align-self: center;
    // justify-self: center;
    cursor: pointer;
    display: inline-block;

    background: repeating-linear-gradient(
        45deg,
        #ffffff11,
        #ffffff11 3px,
        #ffffff22 3px,
        #ffffff22 6px
    );
}

.sticker.nofill {
    border: 1px solid #ddd;
    color: #666;
}
.sticker.nofill.blue {
    border-color: var(--color-blue);
    color: var(--color-blue);
    background-color: #fff;
}
.sticker.nofill.red {
    border-color: var(--color-red);
    color: var(--color-red);
    background-color: #fff;
}
.sticker.nofill.orange {
    border-color: var(--color-orange);
    color: var(--color-orange);
    background-color: #fff;
}
.sticker.nofill.green {
    border-color: var(--color-green);
    color: var(--color-green);
    background-color: #fff;
}
.sticker.nofill.darkblue {
    border-color: var(--color-darkblue);
    color: var(--color-darkblue);
    background-color: #fff;
}
.sticker.nofill.yellow {
    border-color: var(--color-yellow);
    color: var(--color-darkblue);
    background-color: var(--color-yellow);
}
.sticker.nofill.purple {
    border-color: var(--color-purple);
    color: var(--color-purple);
    background-color: #fff;
}
.sticker.nofill.lightsalmon {
    border-color: var(--color-lightsalmon);
    color: var(--color-lightsalmon);
    background-color: #fff;
}
.sticker.nofill.salmon {
    border-color: var(--color-salmon);
    color: var(--color-salmon);
    background-color: #fff;
}
.sticker.nofill.darksalmon {
    border-color: var(--color-darksalmon);
    color: var(--color-darksalmon);
    background-color: #fff;
}
.sticker.nofill.indianred {
    border-color: var(--color-indianred);
    color: var(--color-indianred);
    background-color: #fff;
}

.sticker.nofill.pink {
    border-color: var(--color-pink);
    color: var(--color-pink);
    background-color: #fff;
}

.sticker.nofill.brown {
    border-color: var(--color-brown);
    color: var(--color-brown);
    background-color: #fff;
}

.sticker.nofill.white {
    border-color: var(--color-white);
    color: var(--color-white);
    background-color: var(--color-black);
}

.sticker.nofill.black {
    border-color: var(--color-black);
    color: var(--color-black);
    background-color: var(--color-white);
}

.sticker.nofill.silver {
    border-color: var(--color-silver);
    color: var(--color-silver);
    background-color: var(--color-black);
}

.sticker.size-small.nofill {
    font-size: 70%;
    padding: 3px 6px;
}

.sticker.filled.lightblue {
    background-color: var(--color-lightblue);
}
.sticker.filled.blue {
    background-color: var(--color-blue);
}
.sticker.filled.red {
    background-color: var(--color-red);
}
.sticker.filled.orange {
    background-color: var(--color-orange);
}
.sticker.filled.yellow {
    background-color: var(--color-yellow);
}
.sticker.filled.green {
    background-color: var(--color-green);
}
.sticker.filled.purple {
    background-color: var(--color-purple);
}
.sticker.filled.darkblue {
    background-color: var(--color-darkblue);
}
.sticker.filled.pink {
    background-color: var(--color-pink);
}
.sticker.filled.lightsalmon {
    background-color: var(--color-lightsalmon);
}
.sticker.filled.salmon {
    background-color: var(--color-salmon);
}
.sticker.filled.darksalmon {
    background-color: var(--color-darksalmon);
}
.sticker.filled.indianred {
    background-color: var(--color-indianred);
}
.sticker.filled.gray {
    background-color: var(--color-gray);
}
.sticker.filled.silver {
    background-color: var(--color-silver);
}
.sticker.filled.brown {
    background-color: var(--color-brown);
}
.sticker.filled.white {
    background-color: var(--color-white);
    color: var(--color-black);
}
.sticker.filled.black {
    background-color: var(--color-black);
    color: var(--color-white);
}

.sticker.size-smallest {
    font-size: 70%;
    padding: 2px 4px;
}

.sticker.size-small {
    font-size: 80%;
    padding: 4px 8px;
}
.sticker.--bold {
    font-weight: bold;
}

.sticker.size-small.--long {
    padding-left: 16px;
    padding-right: 16px;
}

.sticker.size-normal {
    font-size: 100%;
    padding: 4px 16px;
}
.sticker.size-normal.--long {
    padding-left: 24px;
    padding-right: 24px;
}

.sticker.size-large {
    font-size: 120%;
}

.sticker.--more-padding {
    padding: 8px 16px;
}
