.shorturl-add-popup{

    .shorturl-add-popup-content{
        width: 500px; 
        padding: 8px;

        &.loading { 
            user-select: none;
            pointer-events: none;
            opacity: 0.4;
        }



        .shorturl-add-text{
            display: flex;
            flex-direction: row;
            position: relative;

            
            .shorturl-add-restart{
                position: absolute;
                z-index: 1;
                width:calc(100% + 32px);
                height: calc(100% + 24px);
                top:-8px;
                left:-16px;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: row;
                
                background-color: #00000033;
            }
            &:hover .shorturl-add-restart{
                display: flex;
            }
            .shorturl-add-shorten-button{
                margin-top: 16px;
                margin-left: 8px; 
                border:1px solid #ddd;
                border-radius: 4px;
            }
        }
        .shorturl-add-shortened-url{
            background-color: #ddd;
            padding: 8px 16px;
            user-select: all;
            line-height: 1.6;
            border-radius: 4px;
            padding-right: 32px;
            margin-right: 8px;
        }
        
        .shorturl-add-shortened-results{
            b{
                flex:none;
                display: block;
                width: 100%;
                padding-bottom: 4px;
            }
        }
        .shorturl-add-shortened-result{
            border-top:1px solid #ddd;
            padding-top: 16px;
            margin-top: 16px;
          
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            
        }
        .shorturl-add-redirected-url{
            font-size: 80%;
            opacity: 0.7;
            margin-top: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            a{ color:#333; text-decoration: none; }
        }
    }
}