.attr-manager {
    padding: 8px;
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 8px;
    grid-row-gap: 16px;
}

.attr-manager .attr-manager-row-head {
    align-items: center;
    font-weight: bold;
    display: grid;
    grid-template-columns: repeat(2, 200px) 332px;
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding-bottom: 8px;
}
.attr-manager .attr-manager-row-head .attr-manager-head-name {
    grid-area: 1 / 1 / 2 / 2;
}
.attr-manager .attr-manager-row-head .attr-manager-head-type {
    grid-area: 1 / 2 / 2 / 3;
}
.attr-manager .attr-manager-row-head .attr-manager-head-display {
    grid-area: 1 / 3 / 2 / 4;
    margin-left: 16px;
}

.attr-manager .attr-manager-row {
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 200px) auto 32px;
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 16px;
}

.attr-manager .attr-manager-row:not(:last-child):before {
    content: ' ';
    height: 1px;
    grid-area: 2 / 1 / 3 / 5;
    z-index: 1;
    background-color: #eee;
    color: #bbb;
}

.attr-manager .attr-manager-display {
    display: flex;
    margin-left: 16px;
    grid-area: 1 / 3 / 2 / 4;

    .attr-manager-display-row {
        display: flex;
    }
}

.attr-manager .attr-manager-name {
    grid-area: 1 / 1 / 2 / 2;
}

.attr-manager .attr-manager-type {
    grid-area: 1 / 2 / 2 / 3;
}

.attr-manager .attr-manager-pc {
    width: 100px;
}

.attr-manager .attr-manager-index {
    width: 100px;
}

.attr-manager .attr-manager-mobile {
    margin-left: 24px;
    width: 100px;
}

.attr-manager .attr-manager-mindex {
    width: 100px;
}

.attr-manager .attr-manager-remove {
    grid-area: 1 / 4 / 2 / 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000011;
    width: 24px;
    height: 24px;
    text-align: center;
    vertical-align: middle;
    font-style: normal;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .attr-manager .attr-manager-row {
        background-color: #fff;
        padding: 16px 8px;
        position: relative;
        padding-top: 40px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'name'
            'type'
            'display';
    }

    .attr-manager .attr-manager-row:not(:last-child):before {
        content: '';
        display: none;
    }

    .attr-manager .attr-manager-row-head {
        display: none;
        grid-template-columns: 200px auto;
        grid-template-rows: 1fr;
        padding-bottom: 0;
        padding: 0 8px;
    }

    .attr-manager .attr-manager-name {
        grid-area: name;
    }

    .attr-manager .attr-manager-type {
        grid-area: type;
    }

    .attr-manager .attr-manager-display {
        grid-area: display;
        display: flex;
        flex-direction: row;
        margin-left: 0;
        width: 100%;
        flex-wrap: wrap;
    }

    .attr-manager .attr-manager-pc,
    .attr-manager .attr-manager-index,
    .attr-manager .attr-manager-mobile,
    .attr-manager .attr-manager-mindex {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .attr-manager .attr-manager-row-head .attr-manager-head-name {
        grid-area: 1 / 1 / 2 / 2;
    }

    .attr-manager .attr-manager-row-head .attr-manager-head-type {
        display: none;
    }

    .attr-manager .attr-manager-row-head .attr-manager-head-display {
        grid-area: 1 / 2 / 3 / 3;
        margin-left: 0;
    }

    .attr-manager input.attr-manager-remove {
        position: absolute;
        top: -32px;
        right: 8px;
    }
}
