.select-popup-content{
    margin:   -8px ;
    
    background-color: #fff;
    position: relative;

    .datagrid-row{
        padding-left: 6px;
    }
    .datagrid-header {
        padding-left: 6px;
    }
    .head-wrapper{
        padding-left: 14px;
    }
    .datagrid .selection-info .selection-text{
        padding-left: 16px;
    }
    .datagrid .datagrid-footer .datagrid-paging .page-info{
        padding-left: 16px;
    }
}