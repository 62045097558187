.popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000044;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    animation: openpopoverbg 0.2s;
    animation-iteration-count: 1;
}

.popup-wrapper.blur {
    backdrop-filter: blur(5px);
}

.popup {
    position: relative;
    min-width: 200px;
    min-height: 120px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 20px 10px #00000022;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    max-width: 90vw;
}

.popup .popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 44px;
    max-height: 44px;
    user-select: none;
}

.popup .popup-header .popup-title {
    flex: 1;
    padding: 16px;
    font-size: 100%;
    font-weight: bold;
    text-transform: uppercase;
}

.popup .popup-header .popup-close {
    width: 32px;
    height: 32px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
}

.popup .popup-header .popup-close:hover {
    background-color: #00000022;
}

.popup .popup-content {
    background-color: #fafafa;
    flex: 1;
    padding: 8px;
    overflow-y: auto;
}

.popup.no-padding .popup-content {
    
    padding: 0px; 
}


.popup .popup-footer {
    background-color: #fff;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border-radius: 0 0 4px 4px;
    user-select: none;
}


.popup .popup-footer.disabled {  
    user-select: none;
    pointer-events: none;
    position: relative;
    overflow: hidden;
}
.popup .popup-footer.disabled:after {
    content: ' ';
    width:100%;
    height: 100%;
    left:0px;
    position: absolute;
    background-color: #ffffffdd; 
    
}


.popup .popup-footer .popup-footer-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.popup .popup-footer .popup-footer-left > * {
    width: auto;
}

.popup .popup-footer .popup-footer-right {
    display: flex;
    align-items: center;
}

// .popup .popup-footer .popup-note{
//   flex:1;
// }

.popup .popup-footer .popup-buttons .popup-button {
    padding: 8px 16px;
    background-color: #2ed2ff;
    font-weight: bold;
    margin: 4px;
    cursor: pointer;
    border-radius: 4px;
}

.popup .popup-footer .popup-buttons .popup-button:hover {
    box-shadow: inset 0 0 400px 10px #00000022;
}

@keyframes openpopupbg {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.popup.tabbed .popup-header {
    // box-shadow: 0px 0px 10px 40px #00000022;
    // border-bottom: 1px solid #f2f2f2;
}

.popup.tabbed .popup-subheader {
    box-shadow: 0 10px 10px 5px #00000005;
    z-index: 1;
}

.popup.tabbed .popup-footer {
    box-shadow: 0 0 20px 5px rgb(0 0 0 / 7%);
}

.popup .popup-header .tab-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid transparent;
    margin-bottom: 0;
    padding-bottom: 0;
    align-self: flex-start;

    .tab-title {
        user-select: none;
        padding: 12px 16px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }

        &.selected {
            border-bottom: 2px solid #163ec4;
            margin-bottom: -2px;
        }
    }
}

.popup.grid > .popup-content {
    background-color: #ffff;
    flex: 1;
    padding: 0;
    display: flex;
}

.popup.grid .popup-content .datagrid {
    flex: 1;
    width: 100%;
}

.popup.grid .popup-content .datagrid .grid-body {
    flex: 1;
}

.popup.grid .popup-content .datagrid .grid-body .datagrid-header {
    position: sticky;
    top: 0;
    display: inline-flex;
}

@media (min-width: 900px) {
    .popup.grid > .popup-content {
        border-radius: 4px;
    }

    .popup.grid > .popup-header {
        width: 100%;
        min-height: 0;
        padding-top: 4px;
    }

    .popup.grid > .popup-header .tab-header-wrapper {
        position: absolute;
        z-index: 1;
        left: 0;
        width: auto;
        border-radius: 4px;
        top: 8px;
    }

    .popup.grid > .popup-header .popup-title {
        position: absolute;
        z-index: 1;
        left: 4px;
        top: 4px;
    }

    .popup.grid > .popup-header .popup-close {
        position: absolute;
        z-index: 1;
        right: 4px;
        top: 4px;
    }

    .popup.grid .popup-content .datagrid {
        background-color: transparent;
    }

    .popup.grid .popup-content .datagrid .datagrid-tools {
        display: flex;
        flex-direction: column;
    }

    .popup.grid .popup-content .datagrid .datagrid-tools .head-wrapper {
        margin-right: 40px;
        width: 500px;
        align-self: flex-end;
    }

    .popup.grid .popup-content .datagrid .datagrid-tools .actions {
        margin-left: 20px;
    }

    .popup.grid .popup-content .datagrid .datagrid-footer {
        width: calc(100% - 160px);
        height: 60px;
        padding-top: 12px;
    }

    .popup.grid .popup-content .datagrid .datagrid-footer .page-info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 90%;
    }

    .popup.grid .popup-content .datagrid .datagrid-footer .page-size {
        font-size: 90%;
    }

    .popup.grid .popup-content .datagrid .datagrid-footer .pages {
        font-size: 100%;
    }

    .popup.grid .popup-content .datagrid .datagrid-footer .pages .page-number {
        min-width: 32px;
    }

    .popup.grid > .popup-footer {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        box-shadow: none;
        background-color: transparent;
    }
}
