.media-gallery{

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .swiper {
      width: 100%;
      height: 100%;
    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px; 
    
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    // .swiper-slide img {
    //   display: block;
    //   width: auto;
    //   height: 100%;
    //   object-fit: cover;
    // }
     
    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
    
    .main { 
      // position: relative;
      // width: 80%;
      flex:1;
    }
    
    .main .swiper-slide  {
      // max-width: 90%; 
    }
    
    .main .swiper-slide img,  .main .swiper-slide video {
      // max-width: 90%; 
      //  margin-top:5%;

      display: block;
      // width: auto;
      // height: 100%;
      object-fit: cover;

      max-height: 95%;
      max-width: 100%;
      box-shadow: 0px 0px 20px 3px #00000055;
      background-color: #fff;
    }
    .main .swiper-slide video {
      height: 95%;
    }
    
    .thumbs {
      height: 100px;
      box-sizing: border-box;
      padding: 10px 0;
      // position: absolute;
      // bottom: 0px;

    }
    
    .thumbs .swiper-slide {
      width: 80px;
      height: 80px; 
      opacity: 1;
      border:4px solid #ddd;
      cursor: pointer;
      box-shadow: 0px 0px 10px 2px #00000022;
      background-color: #f2f2f2;
    }
    
    .thumbs .swiper-slide-thumb-active {
      border:4px solid #f5ae08;
    }
    
    .thumbs .swiper-slide img {
      display: block;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      background-color: #fff;
    }

    .swiper-button-next, .swiper-button-prev{
      color: #ffffff99;
    }
  
}