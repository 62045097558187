.statusbar{
  width: 100%; 
   
  overflow: hidden;
  position: relative;


  background: repeating-linear-gradient(
    45deg,
    #ffffff11,
    #ffffff11 3px,
    #ffffff22 3px,
    #ffffff22 6px
  );

  background-color: #ddd;

  .statusbar-bar-label{
    height: 32px;
    text-align: center;
    display: flex; 
    align-self: center;
    justify-content: center;
    color: #000;
    text-transform: uppercase;
    vertical-align: middle;
    line-height: 32px; 
    position: absolute;
    width: 100%;
    font-weight: bold;
    z-index: 1;
    opacity: 0.6;
    text-shadow: 1px 1px 0px #ffffff,-1px -1px 0px #ffffff,0px -1px 0px #ffffff,-1px 0px 0px #ffffff,0px 0px 0px #ffffff,1px -1px 0px #ffffff, -1px 1px 0px #ffffff;
  }
  .statusbar-bar{

  background: repeating-linear-gradient(
    45deg,
    #ffffff11,
    #ffffff11 3px,
    #ffffff22 3px,
    #ffffff22 6px
  );
    
  transition: 1s;

  animation: status-bar-animate 1000ms linear;
  animation-iteration-count: infinite;   

    background-color: #3d54b5;
    height: 32px;
   
  }
}



@keyframes status-bar-animate {
  0% { 
     
      opacity: 1;
  }
  50% { 
         
    opacity: 0.8;
  }  
  100% { 
         
    opacity: 1;
  }  
}






.statusbar-bar.gray{
  background-color: #aaa; 
}


.statusbar-bar.blue{
  background-color: #3a51bc; 
}

.statusbar-bar.red{
  background-color: #f67700; 
}
.statusbar-bar.yellow{
  background-color: #ffc600; 
}
.statusbar-bar.green{
  background-color: #00b241; 
}
.statusbar-bar.purple{
  background-color: #b60bb9; 
}
.statusbar-bar.darkblue{
  background-color: #000449; 
}
.statusbar-bar.lightsalmon{
  background-color: lightsalmon; 
}
.statusbar-bar.salmon{
  background-color: salmon; 
}

.statusbar-bar.darksalmon{
  background-color: darksalmon; 
}

.statusbar-bar.indianred{
  background-color: indianred; 
}


.statusbar.small{
  .statusbar-bar{
    height: 12px;
  }
  .statusbar-bar-label{
    height: 12px;
    line-height: 12px; 
    font-size: 60%;
  }
}
.statusbar.medium{
  .statusbar-bar{
    height: 32px;
  }
  .statusbar-bar-label{
    height: 32px;
    line-height: 32px; 
  }
}
.statusbar.large{
  .statusbar-bar{
    height: 48px;
  }
  .statusbar-bar-label{
    height: 48px;
    line-height: 48px; 
  }
}

.statusbar.fill{
  position: absolute;
  height: 100%;
  display: flex;
  opacity: 0.8;
  align-items: center;
  .statusbar-bar{
    height: 100%;
  }
  .statusbar-bar-label{
     
     
  }
}