.avatar-wrapper {
    display: flex;
    position: relative;

    .avatar-uploader-button {
        position: absolute;
        z-index: 1;
        width: 24px;
        height: 24px;
        bottom: 8px;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #444;
        color: white;
        font-size: 15px;

        .ficon {
            color: inherit;
        }
    }

    .avatar-uploader {
        position: relative;
        grid-area: avatar;
        width: 100px;
        height: 100px;
        background-color: #eee;
        border-radius: 50%;
        user-select: none;
        overflow: hidden;

        &:hover:not(.empty) {
            .avatar-uploader-image:active {
                opacity: 0.9;
            }

            .avatar-uploader-image {
                opacity: 0.7;
            }
        }

        &:hover.empty {
            .avatar-uploader-image {
                opacity: 0.25;
            }
        }

        &:hover.empty {
            .avatar-uploader-image:active {
                opacity: 0.4;
            }
        }

        &.uploading {
            .avatar-uploader-button,
            .avatar-uploader-image {
                display: none;
            }
        }

        &.uploaded {
            .avatar-file-upload {
                display: none;
            }
        }

        &.square {
            border-radius: 8px;
        }

        .avatar-uploader-image {
            cursor: pointer;
            width: 100%;
            height: 100%;
            text-align: center;
            object-fit: cover;
            color: transparent;
            text-indent: 10000px;
            background: transparent !important;
            border: none !important;
        }

        &.empty .avatar-uploader-image {
            transform: scale(0.75);
            border: 1px solid #ddd;
        }

        &.empty .avatar-uploader-image,
        &:hover .avatar-uploader-image {
            display: flex;
        }
    }
}
