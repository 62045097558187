.edit-wechatcoupon.edit-page {
    .card-qr-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            width: 400px;
        }
        a {
        }
    }

    .custom-codes {
        width: 100%;
        min-height: 600px;
    }

    .basic-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > * {
            width: 50%;
            flex: none;
            margin-bottom: 16px;
            margin-top: 16px;
            align-self: flex-start;
        }

        .edit-field-desc {
            position: relative;
        }

        .edit-field-desc:after {
            content: ' ';
            width: 196%;
            height: 1px;
            background-color: #eee;
            top: -16px;
            left: -98%;
            position: absolute;
        }

        .sub-fields {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding-left: 16px;
            border-left: 3px solid #f2f2f2;

            > * {
                width: 50%;
                flex: none;
                margin-bottom: 16px;
                margin-top: 16px;
            }
        }
    }

    textarea {
        width: 100%;
    }

    .edit-field-desc {
        padding: 4px 12px;
        font-size: 80%;
        opacity: 0.6;
        margin-bottom: 20px;

        .edit-field-desc-cn {
            opacity: 0.7;
        }
    }

    .dropdown .value .item,
    .dropdown .list .option .item {
        display: grid;
        grid-gap: 0 4px;
        gap: 0 4px;
        grid-template-columns: 40px 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'icon label'
            'icon desc';

        .icon {
            grid-area: icon;
            font-size: 40px;
        }

        .label {
            grid-area: label;
            align-self: flex-end;
        }

        .desc {
            align-self: flex-start;
            grid-area: desc;
            font-size: 80%;
            opacity: 0.6;
            max-width: 600px;
            white-space: normal;
        }
    }

    .cash-amount-value {
        display: grid;
        grid-gap: 0 8px;
        gap: 0 8px;
        grid-template-columns: 1fr 150px;
        grid-template-rows: auto;
        grid-template-areas: 'mao rmb';
        align-items: center;

        .cash-amount-value-mao {
            grid-area: mao;
        }

        .cash-amount-value-rmb {
            grid-area: rmb;
            min-width: 150px;
        }
    }
}

.time-limits {
    .time-limit-item {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 4px;

        .time-limit-day {
            width: 80px;
            align-items: center;
            display: flex;
            flex-direction: row;
        }

        .time-limit-hour {
            flex: 1;
            align-items: center;
            display: flex;
            flex-direction: row;
            padding: 4px 0;

            .start-from {
                margin-right: 8px;
                padding: 4px;
            }

            .end-to {
                margin-left: 8px;
                padding: 4px;
            }
        }
    }
}

.edit-wechatcoupon.edit-page {
    .coupon-advanced-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 16px 0;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }
    }
}
