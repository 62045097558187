.coupon-detail-list-editor{

  border:1px solid #ddd;
  border-radius: 4px;

  .content-types{
    display: flex;
    flex-direction: row;
    .content-type-button{
      display: flex;
      flex-direction: column;
      cursor: pointer;
      flex: 1;
      align-items: center;
      padding: 8px;
      border-bottom: 2px solid #eee;
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000011;
      }
      .ficon{
        font-size: 200%;
      }
    }
  }
  .page-content{
    display: flex;
    flex-direction: column;

    .content-item{
      padding: 8px;
      position: relative;

      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000022;
        img, video{
          opacity: 0.8;
        }
        .page-content-actions{
          display: flex;
        }
      }
    }
    .content-item.type-image{
        img{
          width:100%; 
        }
    }
  }
  
}

.coupon-detail-list-editor .page-content .page-content-actions{
  display: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  .ficon{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#fff;
    font-weight: bold;
  }

  .page-content-action{
    cursor: pointer;
    padding: 8px;
    background-color: #000000aa;
    border-radius: 50%;
    margin: 8px;
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000011;
    }
  }
}