@keyframes loading {
    0% {
        transform: skewX(-10deg) translateX(-100%);
    }
    100% {
        transform: skewX(-10deg) translateX(200%);
    }
}

.overview-page {
    display: flex;
    flex-direction: row;
    // align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    .overview-card {
        position: relative;

        min-width: 340px;
        // min-width:  calc(32% - 32px);
        max-width: calc(100vw - 32px);
        flex: 1;
        margin: 8px;
        display: flex;
        flex-direction: column;
        user-select: none;

        &.overview-card-shadow {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            // TODO: custom styles here for the element when it is being grabbed
            .card-content {
                &:has(.chart-wrapper) {
                    display: none;
                }
            }

            .card-footer {
                &:has(.chart-wrapper) {
                    display: none;
                }
            }
        }

        &.ghost {
            opacity: 0.7;
            background-image: linear-gradient(180deg, #3d54b5 0%, #3949ab 100%) !important;
            color: white;

            .card-content {
                background-image: linear-gradient(180deg, #3d54b5 0%, #3949ab 100%) !important;
            }
        }

        &.sm {
            width: 100%;
            min-height: 340px;
        }

        &.md {
            width: 100%;
            min-height: 340px;
        }

        &.lg {
            width: 100%;
            min-height: 340px;


         
            &.expanded {
                min-width: calc(100% - 16px);
            }
        }

        &.xl {
            width: 100%;
            min-height: 340px;

            &.expanded {
                min-width: calc(100% - 16px);
            }
        }

        &.full {
            width: 100%;
        }


        // &.expanded-1 {
        //     max-width: calc(20% - 16px);
        //     min-width: calc(20% - 16px);
        // }
 
        &.expanded-2 {
            // max-width: calc(48% - 16px);
            min-width: calc(32% - 16px);
        }
        &.expanded-3 {
            // max-width: calc(64% - 16px);
            min-width: calc(50% - 16px);
        }
        &.expanded-4 {
            // max-width: calc(80% - 16px);
            min-width: calc(66.66% - 16px);
            max-width: calc(66.66% - 16px);
        }
        &.expanded-5 {
            // max-width: calc(100% - 16px);
            min-width: calc(80% - 16px);
            max-width: calc(80% - 16px);
        }
        &.expanded-6 {
            // max-width: calc(100% - 16px);
            min-width: calc(100% - 16px);
        }

        &.block {
            flex: none;
            align-self: flex-start;
            min-width: 340px;
            max-width: 100%;
            width: calc(100% - 16px);
        }

        &.report {
            .card-content {
                padding: 8px 16px;
            }
        }

        &.highlight {
            color: white;
            background-image: linear-gradient(
                180deg,
                rgba(92, 107, 192, 1) 0%,
                rgba(57, 73, 171, 1) 100%
            );

            &:has(.chart-wrapper.dark-bg) {
                background-image: linear-gradient(180deg, #3d54b5 0%, #3949ab 100%);

                .card-footer {
                    border-top-color: white;
                }

                .card-time-range-selector .time-range,
                .card-footer-time-selector .time-range {
                    opacity: 1;
                }
            }
        }

        &:has(.skeleton-card) {
            > .card-content {
                padding: 0;
            }

            .card-footer {
                display: none;
            }

            .skeleton-card {
                position: relative;
                height: 100%;
                width: 100%;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    background: linear-gradient(90deg, transparent, #e8e8e8, transparent);
                    width: 50%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    animation: loading 1s infinite;
                }

                .skeleton-content {
                    height: 100%;
                    width: 100%;
                    background: #ddd;
                    border-radius: 4px;
                }
            }
        }

        .card-content {
            flex: 1;
            padding: 8px;

            &:has(.list-item) {
                padding: 0;
            }
        }

        .small-card-data-line {
            padding: 8px;
            margin-bottom: 8px;
            color: #aaa;

            .data {
                color: #111;
                margin-right: 8px;
            }
        }

        .card-list-items {
            overflow: auto;
            max-height: 245px;
            margin: -8px;
        }

        .card-data-blocks {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
        }

        .card-data-chart {
            display: flex;
            flex-direction: row;

            .card-data-chart-labels {
                margin-right: 16px;

                .card-data-chart-label {
                    padding: 8px;

                    .card-data-chart-label-title {
                        font-weight: 500;
                        opacity: 0.5;
                        font-size: 90%;
                    }

                    .card-data-chart-label-data {
                        font-size: 120%;
                    }
                }
            }
        }

        .card-empty {
            .card-empty-title {
                font-weight: 500;
                padding: 8px;
            }

            .card-empty-desc {
                opacity: 0.5;
                padding: 8px;
            }

            .card-empty-button {
                padding: 16px 8px;

                .card-empty-get-started {
                    width: auto;
                    max-width: 200px;
                    align-self: flex-start;
                }
            }
        }

        .card-data-block {
            min-width: 180px;
            max-width: 220px;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 8px;
            flex: 1;

            .card-data-block-title {
                font-weight: 500;
                opacity: 0.5;
                margin-bottom: 4px;
                font-size: 90%;
            }

            .card-data-block-value {
                font-size: 150%;
                margin-bottom: 4px;
            }

            .card-data-block-rate {
            }
        }

        .card-data-wrapper {
            display: flex;
            flex-direction: column;
        }

        .card-footer-link {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ficon {
                font-size: 130%;
            }

            .card-footer-time-selector {
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                align-items: center;

                .ficon {
                    margin-right: 8px;
                }
            }

            .card-footer-placeholder {
                flex: 1;
            }

            .card-footer-sub-text {
                display: flex;
                align-items: center;
                cursor: pointer;
                .ficon {
                    margin-left: 8px;
                }
            }
        }

        .card-settings {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            position: absolute;
            z-index: 2;
            top: 5px;
            right: 8px;
            width: 30px;
            height: 30px;
            transition: 0.3s ease;
        }

        &.draggable {
            cursor: move;
            border: 2px solid #3d54b5;
        }

        &.highlight.draggable {
            border: 2px solid white;
        }

        &.sortable-chosen {
            .card-list-items {
                overflow: hidden;
            }
        }

        &:hover {
            &:not(:has(.skeleton-card)) {
                .card-settings {
                    opacity: 1;
                }
            }
        }

        &:has(.card-list-items) {
            .card-content {
                padding: 8px 8px 0 8px;
            }

            .card-header {
                margin-bottom: 0;
            }

            .card-footer {
                margin-top: 0;
            }
        }
    }

    .overview-sortable-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &:has(.overview-empty-state) {
        height: 100%;

        .overview-empty-state {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: #f2f2f2;

            .empty-state-description {
                text-align: center;
                padding: 24px 32px;
                font-size: 120%;
            }
        }
    }

    .overview-card:has(.card-data-block-visualization) {
        .card-content {
            overflow-x: auto;
            overflow-y: hidden;
            padding: 0;
        }

        .card-header {
            margin: 0;
        }

        .card-footer {
            margin: 0;
        }
    }

    .card-data-blocks:has(.card-data-block-visualization) {
        flex-wrap: nowrap;
        min-height: 280px;
        height: 100%;
        width: 100%;

        .card-data-wrapper {
            display: flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            padding: 16px 8px 0 8px;
            border-right: 1px solid #eeeeee;

            &:last-child {
                border-right: none;
            }
        }

        .card-data-block {
            min-width: initial;
            max-width: initial;
            margin: 0;
            padding-left: 8px;
        }

        .card-data-block-visualization {
            align-items: center;
            margin-bottom: -10px;
            width: 100%;
            max-width: 144px;

            .chart-labels {
                display: none;
            }

            .chart-wrapper {
                padding: 0;
            }
        }
    }
}

.card-time-range-selector {
    padding: 8px 0;
    overflow: auto;

    .card-time-range-selector-line {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            box-shadow: inset 0 0 1000px 10000px #00000011;
        }

        &.selected {
            color: #3949ab;
            font-weight: 500;
        }
    }
}

.card-time-range-selector,
.card-footer-time-selector {
    .time-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .time-label {
    }
    .time-range {
        display: block;
        font-size: 70%;
        opacity: 0.6;
    }
}

.exit-edit-mode-button {
    display: flex;
    justify-content: center;
    position: fixed;
    background: white;
    padding: 12px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    font-size: 120%;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    .button-wrapper:first-of-type {
        margin-right: 16px;
    }
}

.popup-wrapper:has(.add-card-popup) {
    z-index: 10000;
}

.add-card-popup {
    width: 80%;
    height: 70%;
    max-height: 663px;

    .popup-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 16px 0 16px;
    }

    .section-title {
        display: block;
        font-size: 90%;
        font-weight: 500;
        color: #546e7a;
        margin-top: 24px;
    }

    .card-selection-list {
        margin-top: 16px;
    }

    .card-selection-paging {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 12px 0;

        span {
            font-size: 90%;
        }

        .paging-icon {
            margin-left: 16px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .overview-page .card-data-blocks:has(.card-data-block-visualization) {
        .card-data-block-visualization {
            max-width: initial;
        }
    }

    .overview-card:has(.card-data-block-visualization) {
        overflow-x: auto;
    }

    .overview-card {
        &.sm {
            min-width: calc(25% - 32px);
        }

        &.md {
            min-width: calc(33% - 32px);
        }

        &.lg {
            min-width: calc(50% - 32px);
            max-width: 100%;
        }

        &.xl {
            min-width: calc(66% - 32px);
            max-width: 100%;
        }

        &.full {
            min-width: calc(100% - 32px);
        }

        .card-data-wrapper {
            
        }
    }
}

@media only screen and (min-width: 996px) {
    .add-card-popup {
        width: 786px;
        height: 663px;
    }
}

@media only screen and (min-width: 1600px) {
    .overview-card {
        &.lg {
            max-width: calc(50% - 16px);
        }
    }
}
