.segment-filters {
    position: relative;
    border: 1px solid #eee;
    background-color: #fafafa;
    padding: 16px 8px;
    border-radius: 4px;

    .filter-panel {
        padding: 0;
        justify-content: stretch;

        .filter-row {
            grid-template-columns: 6fr 4fr 6fr 1fr;

            .filter-op {
                min-width: 140px;
            }

            .filter-value {
                min-width: 250px;
            }
        }
    }
    .add-filter-button {
        margin-top: 16px;
    }

    .segment-filters-message {
        padding: 16px 0;
        padding-bottom: 8px;
        font-size: 80%;
        opacity: 0.5;
    }

    .segment-filters-ai-warn {
        padding: 16px 8px;
        font-size: 80%;
        opacity: 0.5;
        background-color: #ffe600;
        margin-bottom: -16px;
        margin-left: -8px;
    }

    &.error:after {
        content: 'Error: ' attr(data-error);
        position: absolute;
        padding: 0 6px;
        transition: 150ms;
        display: flex;
        align-items: center;
        height: auto;
        font-size: 65%;
        background-color: #fff;
        bottom: -5px;
        left: 9px;
        color: #d69d9d;
    }

    &.error {
        border: 1px solid #d32f2f;
        border-left: 3px solid #d32f2f;
    }
}
