.website-tracking{

  .website-tracking-title{
    font-size: 160%;
    padding: 8px 10px;
    padding-bottom: 0px;
  }
  .website-tracking-desc{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

  .website-tracking-footer{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

}

 

.website-info{
  flex:1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}
.website-info .formel{  
  margin-bottom: 16px;
}


.website-info-inner{
  display: flex;
  flex-direction: row;
}