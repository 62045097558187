.textarea{
  position: relative;
  outline: none;
  border:0px none;
  width: 100%;
  display: flex;
  align-items: center;
  
}

.textarea .clear-button{
  margin-left: -30px;
  font-size: 120%;
  opacity: 0.2;
}
.textarea .variable-button{
  margin-left: -50px;
  opacity: 0.8;
  font-size: 120%;
}

.textarea label{ 
    background-color: transparent;
    position: absolute;
    top:1px;
    left: 6px; 
    font-size: 100%;
    padding: 0px 6px;
    color:#aaa; 
    transition: 50ms;
    height: 94%;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.textarea label span{ 
  margin-left: 8px;
  padding: 0px 2px; 
  font-size: 90%;
  opacity: 0.5;
  visibility: hidden;
}

.textarea label span:before{  
  padding-right: 8px;
  content: ' - ';
}




.textarea.disabled {
  opacity: 1; 
  user-select: text;
  cursor:default;
}

.textarea.disabled textarea{ 
  cursor:text; 
  border-color: #eee;
  background-color: #eee;
  color: #888;
}

.textarea.disabled textarea:hover{ 
  border-color: #ddd;
}

.textarea.disabled textarea:focus + label, .textarea.disabled textarea:not(:placeholder-shown) + label, .textarea.disabled textarea:focus:not(:placeholder-shown) + label, .textarea label.with-value
{
  background: linear-gradient(0deg, #eee 50%, rgba(255, 255, 255, 0) 50%)
}


.textarea textarea:focus + label, .textarea label.with-value, .textarea textarea:not(:placeholder-shown) + label, .textarea textarea:focus:not(:placeholder-shown) + label{  
  height: auto;
  font-size: 65%;
  background-color: #fff; 

  background: linear-gradient(0deg, #fff 60%, #fff0 32%);
  top:-5px;
  left: 9px;   
  color:#3949ab; 
}


.textarea textarea:focus + label span, .textarea textarea:not(:placeholder-shown) + label span, .textarea textarea:focus:not(:placeholder-shown) + label span{  
  visibility: visible;
}

.textarea textarea:not(:placeholder-shown) + label,
.textarea label.with-value{   
  color:#666; 
}
 
.textarea.has-value .clear-button{ 
  opacity: 0.7;
}

.textarea:hover textarea{
  border-color: #666;
}

.textarea textarea:focus{
  border-color: #3949ab;
}

.textarea textarea{
  border:1px solid #ddd;
  border-radius: 4px;
  padding: 10px 12px;
  transition: 150ms;
  outline: none;
  padding-right: 30px;
}

.textarea.bindable textarea{ 
  padding-right: 60px;
}
.textarea .inline-variables{
  padding-right: 55px;
}

.textarea.error:after{ 
  content:'Error: ' attr(data-error);;
  background-color: transparent;
  position: absolute; 
   
  padding: 0px 6px; 
  transition: 150ms; 
  display: flex;
  align-items: center; 
  height: auto;
  font-size: 65%;
  background-color: #fff; 
  bottom:-5px;
  left: 9px;   
  color:#d69d9d; 

}


.textarea.error textarea{  
  color:#d32f2f;   
  //box-shadow: inset 0px 0px 10px 10px #00000022;

}


.textarea.error textarea:not(:placeholder-shown)  + label, 
.textarea.error textarea:focus + label {
  color:#d32f2f;
}

.textarea.error textarea,
.textarea.error textarea:focus, .textarea.error textarea:focus-visible{ 
  border: 1px solid #d32f2f;
  border-left: 3px solid #d32f2f;
}


.textarea .variables{
  width: 100%;
  border:1px solid #ddd;
  border-radius: 4px;
  padding: 10px 12px;
  transition: 150ms;
  outline: none;
  padding-right: 30px;
} 
.textarea textarea.inline-variable{
  margin: 0px 2px; 
  display: inline-block;
  width: auto;
  min-width: 0px;
  max-width: 1000px;
  padding: 2px;
  border:0px none;
  text-align: center;
  background-color: #eee;
  user-select: none;
  text-transform: capitalize;
  font-family: monospace;
  pointer-events: none;
  cursor: default;
}