.app.editor .page{ 
  padding: 0px;
  iframe{
    width:100%;
    height:calc( 100% );
  }

  .page-footer{
    display: none;
  }
}

