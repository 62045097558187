.analytics-active-users-card {
    background: none;
    box-shadow: none;
    border: none;

    .card-header {
        padding-left: 0;
        border-radius: 4px;
        margin-bottom: 0;
    }

    &.split {
        position: relative;
        padding-top: 8px;
        padding-bottom: 16px;
        border-radius: 0;

        & > * {
            z-index: 1;
        }

        &:after {
            pointer-events: none;
            content: ' ';
            z-index: 0;
            position: absolute;
            width: calc(100% + 32px);
            height: 100%;
            left: -16px;
            top: 0;
            background: repeating-linear-gradient(
                45deg,
                #00000005,
                #00000005 3px,
                #00000011 3px,
                #00000011 6px
            ) !important;
        }
    }

    .card-settings {
        top: 10px !important;
    }

    .analytics-active-pages-card.draggable {
        border: none !important;
        min-height: 330px;
    }

    > .card-content {
        max-width: calc(100% - 32px);
        padding: 0 !important;
    }

    .analytics-active-pages-card {
        margin: 0 !important;
        border: none;
    }

    .overview-card:has(.skeleton-content) {
        padding: 0 !important;
        margin: 0 !important;

        .skeleton-content {
            min-height: 660px;
        }
    }

    .top-panel-wrapper {
        display: grid;
        width: 100%;
        gap: 16px;
        flex-direction: column;
        grid-template-columns: calc(100% - 300px + 16px) 300px;
        grid-template-areas: 'data users';
        align-items: flex-start;

        .top-panel-left {
            height: 100%;
            grid-area: data;

            .visualizations-wrapper {
                min-height: 462px;
                background: white;
                box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
                padding-top: 16px;

                .chart-axis {
                    top: 16px;
                }
            }
        }

        .top-panel-right {
            grid-area: users;

            .customer-avatar-wrapper {
                width: 100%;
                max-width: calc(100% - 20px);

                .location > div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    .session-ids {
        font-size: 80%;
    }
}

@media screen and (max-width: 968px) {
    .analytics-active-users-card {
        .analytics-active-pages-card {
            min-height: initial;
        }

        > .card-content {
            max-width: initial;
        }

        .top-panel-wrapper {
            grid-template-columns: auto;
            grid-template-areas:
                'users'
                'data';

            .top-panel-left {
            }

            .top-panel-right {
                max-width: initial;

                .card {
                    margin-right: 0;
                }
            }
        }
    }
}
