.wx-automessage-preview-app {
    width: 100%;

    height: 100%;
    display: flex;
    flex-direction: column;

    .wx-automessage-preview-app-head {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 6px 4px;

        .ficon {
            color: inherit;
            font-weight: bold;
            font-size: 150%;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 4px;
            user-select: none;

            &:hover {
                box-shadow: inset 0 0 1000px 1000px #00000011;
            }
        }

        .wx-automessage-preview-app-head-back {
            display: flex;
            align-items: center;
        }

        .wx-automessage-preview-app-head-center {
            display: flex;
            align-items: center;
            flex: 1;
            font-weight: normal;
            text-align: center;
            justify-content: center;
            font-size: 12pt;
        }

        .wx-automessage-preview-app-head-right {
            display: flex;
            align-items: center;
        }
    }

    .wx-automessage-preview-app-body {
        flex: 1;
        height: auto;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 44px);
    }
}

.wx-auto-messages {
    overflow: auto;
    flex: 1;
    padding: 8px;
    padding-bottom: 100px;
}

.wx-auto-message {
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    &.user {
        justify-content: flex-end;

        .wx-auto-message-content {
            &:before {
                left: auto;
                right: -4px;
            }
        }
    }

    .wx-auto-message-sender {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        background-color: #ddd;
        border-radius: 4px;
        width: 40px;
        height: 40px;

        &.company {
            background-image: url(../../assets/shopnow.png);
            background-size: 80%;
            background-color: #1143ad;
        }

        &.user {
            background-image: url(../../assets/noavatar.png);
            opacity: 0.2;
            background-color: #aaa;
        }
    }

    .wx-auto-message-content {
        background-color: #fff;
        max-width: 70%;
        padding: 8px 12px;
        margin: 0 8px;
        border-radius: 6px;
        position: relative;
        margin-left: 12px;

        &:before {
            content: ' ';
            width: 8px;
            height: 8px;
            position: absolute;
            left: -4px;
            top: 10px;
            background-color: #fff;
            transform: rotate(45deg);
        }

        img,
        video {
            display: block;
            width: 100%;
            height: auto;
        }

        audio {
            transform: scale(0.75);
            max-height: 100%;
            max-width: calc(100% + 60px);
            margin-left: -30px;
            object-fit: contain;
        }

        .message-content--link,
        .message-content--article {
            text-decoration: none;
            text-transform: none;
            color: #444;

            .message-content-title {
                font-weight: bold;
                margin-bottom: 4px;
            }

            img {
                border-radius: 4px;
                margin-top: 8px;
            }
        }
    }
}

.wx-message-box {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    width: 100%;
    padding: 0 3%;
    padding-bottom: 24px;
    padding-top: 8px;
    left: 0;
    border-top: 2px solid #ddd;
    z-index: 10;
    background-color: #eee;

    .button-wrapper {
        margin-left: 8px;
        margin-bottom: 1px;
    }

    textarea {
        height: auto;
        min-height: 1px;
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%) !important;
        width: auto;
        flex: 1;
    }
}
