.record-selector-list{
  // min-width: 300px;
  // max-width: 500px;
  border:1px solid #ddd;
  border-radius: 4px;
  // padding-bottom: 16px;
  .record-selector-title{
    font-size:65%;
    background-color: #fff;
    margin-top: -8px;
    width: auto;
    display: inline-block;
    transform: translate(8px, -10px);
    padding: 1px 4px;
  }
  .select-record{
    // margin-top: 16px;
    width: 100%;
    
  }

 .no-record-selected{
  padding: 16px;
  text-align: center;
  opacity: 0.4;
  user-select: none;
 }

}

.record-selector-list .selected-record{
  
  display: flex;
  align-items: center;
  width: 100%;
 .record-item{
   flex:1;
 }
 .remove-item{
   margin-right: 16px;
   font-size: 120%;
   opacity: 0.6;
 }
}

