.choose-application{
  width: 600px; 

  padding: 0px;
  .choose-application-content{
    padding:8px;
  }
  .sub-title{
    padding: 8px 0px;
  }
  .note{
    font-size:80%;
    opacity: 0.5;
    padding: 8px 0px;
  }

  .application-items{
    display: flex;
    flex-direction: row; 
    justify-content: center;
    flex-wrap: wrap;
    .application-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 16px;
      height: 128px;
      background-color: #fff;
      border-radius: 4px;
      margin: 8px;
      min-width: 150px;
      max-width: 250px;
      flex:1;
      text-align: center;
      cursor: pointer;
      &:hover{
        box-shadow: inset 0px 0px 1000px 10000px #00000011;
      }
      &.selected{
        background-color: #3949ab;
        color:#fff;
      }
      .ficon{
        font-size: 240%;
        margin-bottom: 8px;
      } 

      .application-item-type{
        font-size: 80%;
        font-weight: bold;
        opacity: 0.5;
      }
      .application-item-name{
        padding-top: 4px;
        word-break: break-word;
      }
    }
  }
}

