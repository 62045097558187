.edit-profile-page-content {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: minmax(300px, 600px) minmax(300px, 440px);
    justify-content: flex-start;

    .main-profile-content {
        .card-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > *:not(:last-child) {
                margin-bottom: 8px;
            }

            & > .save-button {
                min-width: 300px;
                width: 100%;
            }
        }
    }

    .button-profile-content {
        .card-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;
        }

        .list-item:has(.linked-accounts-item) {
            position: relative;
            padding-bottom: 50px;

            .item-content {
                position: absolute;
                left: 65px;
                top: 46px;
                padding: 0;
            }
        }

        .linked-accounts-item {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 2px;
            margin-top: 16px;
            height: 20px;
            width: 20px;
            border-radius: 2px;

            .ficon {
                font-size: 12px !important;
            }
        }
    }

    .profile-button-top-margin {
        flex: 1;
    }

    .mobile-input {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 8px;
        align-items: flex-start;
        width: 100%;

        &.disabled > * {
            opacity: 0.5;
            background-color: #ddd;
            pointer-events: none;
        }
    }
}

.login-history-popup {
    .datagrid {
        .search {
            justify-content: flex-end;
        }

        .grid-body {
            padding: 0 4px;
        }

        .head-wrapper {
            width: initial !important;
        }
    }
}

.change-email-popup.grid,
.change-email-sent-popup.grid {
    padding: 16px;
    max-width: 423px;

    .popup-footer {
        align-self: flex-end;
    }

    > .popup-content {
        padding: 32px 0;

        strong,
        p {
            display: block;
            margin-bottom: 16px;
        }

        .input {
            margin-bottom: 16px;
        }
    }
}

.linked-accounts-popup.grid {
    padding: 16px;
    max-width: 423px;

    .popup-footer {
        align-self: flex-end;
    }

    > .popup-content {
        padding: 32px 0 24px 0;

        .linked-account-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            .linked-account-item__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                padding: 4px;
                color: white;
                width: 32px;
                height: 32px;
                font-size: 20px;
                margin-right: 16px;
            }

            .linked-account-item__content {
                flex: 1;

                .linked-account-item__title {
                    display: flex;
                    align-items: center;
                }

                .linked-account-status__icon {
                    display: flex;
                    width: 16px;
                    height: 16px;
                    margin-top: 4px;

                    .ficon {
                        color: #5cb945;
                        font-size: 16px;
                        margin-right: 4px;
                    }

                    span {
                        display: block;
                        white-space: nowrap;
                        opacity: 0.7;
                    }
                }
            }
        }

        strong,
        p {
            display: block;
            margin-bottom: 16px;
        }

        .input {
            margin-bottom: 16px;
        }
    }
}

@media (max-width: 768px) {
    .edit-profile-page-content {
        grid-template-columns: minmax(300px, 600px);
    }

    .login-history-popup {
        .datagrid {
            .search {
                justify-content: flex-start;
            }
        }
    }

    .main-profile-content {
        position: relative;
        padding-top: 32px;

        .profile-settings-mobile {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    .linked-accounts-popup.grid,
    .change-email-popup.grid,
    .change-email-sent-popup.grid {
        max-width: calc(100vw - 32px);

        .popup-title {
            padding: 0;
        }

        .popup-footer {
            padding: 0;
        }

        > .popup-content {
            padding: 0;

            strong,
            p {
                display: block;
                margin-bottom: 16px;
            }

            .input {
                margin-bottom: 16px;
            }
        }
    }

    .popup-wrapper:has(.settings-popup) {
        z-index: 90;
    }

    .settings-popup {
        position: absolute;
        z-index: 90;
        bottom: 0;
        left: 0;
        max-width: initial;
        width: 100%;
        border-radius: 8px 8px 0 0;

        .popup-header {
            width: 100%;
            right: 0;
        }

        .popup-content {
            padding: 0;
            border-radius: 8px 8px 0 0;

            .button-profile-content {
                box-shadow: none;
                margin: 0 auto;
                width: calc(100% - 32px);

                .card-content {
                    padding: 0;
                }
            }

            .logout-button {
                margin-top: 80px;
            }
        }
    }
}
