
  .message-box{
    // background-color: #fff;
    border-top: 1px solid #ddd;
    // padding: 8px;
    position: relative;
    height: 120px;

    // user-select: none;
    // touch-action: none;
    

    .file-upload{
        // max-width: 400px;
        position:absolute;
        left:-13px;
        top:-13px;
        width:calc(100% + 26px);
        user-select: none;
    }

    .message-text{ 

    pointer-events:all;

      border:0px none;
      padding:16px;
      
      outline:none;
      background-color: #f2f2f2;
      
      resize: none;
      position: absolute;
      top:8px;
      left:8px;
      right:8px;
      bottom: 48px; 

      width: auto;
      .emoji{
        font-size: 150%;
      }
    }

    .send-button{ 
      user-select: none;
      position: absolute;
      bottom:8px;
      right:8px;
      left:auto;
      z-index: 1;
    }
    .message-box-tools{
      display: flex;
      position: absolute;
      bottom:0px;
      padding: 8px;
      left:0px;

      user-select: none;
      // right:16px;
      // background-color: #f2f2f2;
    }
    .message-tool-divider{
      background-color: #ddd;
      width: 1px;
      
      margin: 4px 12px;
    }
    .message-tool.loading{
      animation: loading-animation 400ms linear;
      animation-iteration-count: infinite;
      pointer-events: none;
    }

    @keyframes loading-animation {
      0% {  transform: rotate(0deg);}
      100% {  transform: rotate(360deg);}  
    }

 
  }


.email-send-button-popover, .email-send-wecom-message-popover,   .email-send-custom-message-popover{
  width: 170px;  
  padding: 8px 0px;
  .line{
    padding: 8px;
    cursor: pointer;
    &:hover{
      background-color: #f2f2f2;
    }
    &.selected{
      color:#3d54b5;
      background-color: #fafafa;
    }
  }
}


.email-emoji-list-popover{
  width: 306px;  
  max-height: 310px;
  padding: 8px; 
  overflow: auto;
  .emoji{
    width:32px;
    height: 32px;
    font-size: 22px; 
    cursor: pointer;
    display: inline-flex; 
    align-items: center;
    justify-content: center;
    &:hover{
      background-color: #00000022;
    }
  }
}
 
 

.message-box-subject{ 
  margin-top: -32px;
  height: 32px; 
  font-size: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  padding: 0px 16px;
  background-color: #00000011;
  opacity: 0.9;
   

.message-box-subject-text{
  background-color: #ffc600aa;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 4px; 
}  
.subject-close{
  font-size: 120%;
  border-radius: 50%;
  margin-right: 4px;
}
}