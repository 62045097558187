.map {
    height: 100%;
    width: 100%;
}

.leaflet-bottom {
    z-index: 10;
}

.overlay {
    display: none;

    &.block-actions {
        position: absolute;
        z-index: 15;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .overlay-background {
            position: absolute;
            z-index: 15;
            width: 100%;
            height: 100%;
            background: gray;
            opacity: 0.9;
        }

        .button-wrapper {
            position: absolute;
            z-index: 15;
            top: initial;
            left: initial;
            opacity: 1;
        }
    }
}

.map-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 610px;
    width: 100%;
    margin: auto;

    .button-wrapper {
        position: absolute;
        z-index: 10;
        top: 5px;
        left: 5px;
        display: flex;
        padding-bottom: 10px;
        flex-direction: row;
        justify-content: flex-start;
    }

    .leaflet-container {
        background: transparent;
    }

    .map-button-back {
        align-self: flex-start;
    }
}
