.gender-select 
{
  display: flex;
  flex-direction: row;
  text-align: center;

  .gender-select-option {
    cursor: pointer;
      margin-right: 8px;
      padding: 8px;
      border:1px solid #eee;
      width: 75px;
      color:#444;
      cursor: pointer;
      border-radius: 4px;
      opacity: 0.8;

      .ficon{
        display: block;
        opacity: 0.7;
      }
      &:hover{
        box-shadow: inset 0px 0px 1000px 10000px #00000011;
      }
      &.selected{
        border:1px solid #3d54b573;
        background-color: #2331ed11;
        opacity: 1;
      }
  }
}