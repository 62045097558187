.image-selection-popup .selected-material-list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    flex: 1;
    padding-top: 0;

    .no-material-item {
        height: 38px;
        margin: 0.5%;
        padding: 0;
        display: flex;
        align-items: center;
        opacity: 0.5;
    }

    .selected-material-item {
        width: 36px;
        margin: 0.5%;
        padding: 0;
        cursor: pointer;
        font-size: 100%;
        text-align: center;
        border-radius: 4px;
        border: 1px solid rgb(55, 117, 223);
        box-sizing: content-box;

        &.selected,
        &.selected .material-item-image {
            box-shadow: inset 0 0 1000px 1000px #1c91ff23;
        }

        &:hover {
            box-shadow: inset 0 0 1000px 10000px #00000011;
        }

        .material-item-image {
            width: 36px;
            height: 36px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f2f2f2;
            border-radius: 4px;
            padding: 4px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.popup-wrapper:has(.image-selection-popup) {
    z-index: 90;
}

.popup-wrapper:has(:not(.image-selection-popup)) {
    .material-item {
        background: white;
        border: 1px solid #eee;

        &:hover {
            cursor: initial;
            box-shadow: none;
        }
    }
}

.material-filter-panel {
    display: flex;
    flex-direction: row;
    padding: 8px;
    border-bottom: 1px solid #eee;

    & > * {
        margin: 0 4px;
    }
}

.image-selection-popup .upload-new-container {
    height: 72vh;
}

// .image-selection-popup-content{
//      width: 1000px;
//     display: flex;
//     flex-direction: column;

//     .tab-content  {
//         display: flex;
//         flex-direction: column;
//         flex: 1;
//         height: calc(100% - 34px);
//     }
// }

// .image-selection-popup.popup .popup-content{
//     overflow: hidden;
//     overflow-y:hidden;
//     display: flex;
// }

// .image-selection-popup.popup  .tab-header{
//      margin-bottom: 0px;
// }

// .image-selection-popup .material-list{

//     display: flex;
//     flex-wrap: wrap;
//     overflow: auto;
//     flex: 1;
//     padding-top: 8px;

//     .material-item{
//         width: 19%;
//         margin:0.5%;
//         padding: 12px;
//         min-width: 160px;
//         cursor: pointer;

//         font-size: 100%;
//         text-align: center;
//         border-radius: 4px;
//         position: relative;
//         border:1px solid transparent;
//         &.selected {
//             border:1px solid #2994ff90;
//         }
//         &.selected,  &.selected .material-item-image{
//             box-shadow: inset 0px 0px 1000px 1000px #1c91ff23;

//         }
//         &:hover{
//             box-shadow: inset 0px 0px 1000px 10000px #00000011;
//         }

//         .material-item-image{
//             width: 100%;
//             min-width: 160px;
//             height: 160px;
//             overflow: hidden;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             background-color: #f2f2f2;
//             border-radius: 4px;
//             padding: 4px;
//             position: relative;
//             img{
//                 max-width: 100%;
//                 max-height: 100%;
//             }

//             .play-icon{
//                 position: absolute;
//                 font-size: 40px;
//                 background-color: #ffffffaa;
//                 opacity: 0.6;
//                 border-radius: 50%;
//                 width:40px;
//                 height: 40px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 left:50%;
//                 top:50%;
//                 transform: translate(-50%, -50%);
//                 display: none;
//             }
//             .file-icon{
//                 position: absolute;
//                 background-color: #000000aa;
//                 opacity: 0.8;
//                 border-radius: 5px;
//                 width:60px;
//                 height: 60px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 left:50%;
//                 top:50%;
//                 transform: translate(-50%, -50%);
//                 padding: 8px;
//                 display: none;
//             }
//         }

//         &.video  .material-item-image .play-icon{
//             display: block;
//         }

//         &:not(.video):not(.image)  .material-item-image .file-icon{
//             display: block;
//         }

//         .material-item-name{
//             padding: 6px 0px;
//         }
//         .material-item-tags{
//              margin-bottom: 2px;
//             .material-item-tag{
//                 display: inline-block;
//                 font-size: 80%;
//                 padding: 2px 8px;
//                 background-color: #00000011;
//                 display: inline-block;
//                 border-radius: 3px;
//                 margin: 2px;
//                 opacity: 0.8;
//             }
//         }
//         .material-item-type{
//         }
//         .material-item-date{
//             font-size: 80%;
//             opacity: 0.5;
//         }
//     }
// }

// .ai-material .generate-image{
//     padding: 0px 4px;
//     .generate-image-input {
//         display:flex;
//         flex-direction:row;
//         align-items: center;
//         margin-top:16px;

//         .generate-prompt{
//             width: 120px;
//             height: 100px;
//             input{
//                 white-space:normal;
//                 word-break:normal;
//             }
//         }
//         .placeholder-text{
//             padding: 8px;
//         }
//         .prompt{
//             height: 100px;
//             margin:0px 8px;
//         }
//         .generate-images{
//             height: 100px;
//             width: 100px;
//             input{
//                 white-space:normal;
//                 word-break:normal;
//             }
//         }

//     }
// }

// .ai-material .divider{
//     width:98%;
//     padding: 2px;
//     height: 1px;
//     background-color: #eee;
//     margin:1%;
// }

// .ai-material .generate-audio{
//     padding: 0px 4px;
//     .generate-audio-input{
//         display:flex;
//         flex-direction:row;
//         align-items: center;

//        .generate-text{
//             width: 120px;
//             height: 100px;
//             input{
//                 white-space:normal;
//                 word-break:normal;
//             }
//        }

//         .placeholder-text{
//             padding: 8px;
//         }

//        .audio-text{
//         height: 100px;
//         margin:0px 8px;
//        }
//        .audio-gender{
//             width: 130px;
//        }
//        .generate-audio{
//             height: 100px;
//             width: 100px;
//             input{
//                 white-space:normal;
//                 word-break:normal;
//             }
//        }
//     }
//    }

// .image-selection-popup.popup.ai-chat .popup-content{
//     display: flex;
//     padding: 0px;
// }

// .image-selection-popup.popup.ai-chat .popup-footer{
//      box-shadow: 0px 0px 20px 5px #00000011;
//      padding-top: 8px;
// }

//     .ai-chat-message-list{

//         flex:1;
//         display: flex;
//         flex-direction: column-reverse;
//         padding: 16px;
//         overflow: auto;

//         .ai-chat-message{
//             padding: 16px;
//             border-radius: 4px;
//             background-color: #307bf3;
//             color:#fff;
//             margin: 8px 0px;
//             align-self:flex-start;
//             position: relative;
//             // border-bottom: 1px solid #ddd;

//             display: grid;
//             grid-gap: 4px 8px;
//             gap: 4px 8px;

//             grid-template-columns: 48px auto 1fr;
//             grid-template-rows: auto 1fr;
//             grid-template-areas:
//             "image name time"
//             "image content content";

//             .sender-image{ grid-area: image; background-color: #ddd; width:48px; height:48px; border-radius: 4px; }
//             .sender-name{ grid-area: name; font-weight: bold;line-height: 12px; opacity: 0.5;align-self: flex-end; margin-top:1px; }
//             .sent-time{ grid-area: time; font-size:80%; line-height: 10px;  opacity: 0.5; align-self: flex-end; }
//             .message-content{ grid-area: content; }
//             .message-more{
//                 // grid-area: more;
//                 display: none;
//                 font-size: 100%;
//                 text-align: center;
//                 position: absolute;
//                 right: 8px;
//                 top:8px;
//                 width:24px;
//                 height: 24px;
//                 box-shadow:   0px 0px 0px 4px #00000011;
//                 border-radius: 50%;

//                 .iconbutton{
//                     font-size: 120%;
//                     border-radius: 50%;
//                     width:24px;
//                     height: 24px;
//                     font-weight: bold;
//                     text-align: center;
//                     align-items: center;
//                     display:flex;
//                     justify-content: center;
//                     padding: 0px;
//                     background-color: #00000011;
//                 }
//             }

//             &:hover{
//                 .message-more{
//                     display: block;
//                 }
//             }

//             &.me{
//                 background-color: #f3a830;
//                 grid-template-columns: 20px 1fr auto 48px;
//                 grid-template-rows: auto 1fr;
//                 grid-template-areas:
//                 "more time name image"
//                 "content content content image";
//                 align-self:flex-end;
//                 .sent-time{  justify-self: flex-end; }

//                 .message-more{
//                     left: 8px;
//                     right:0px;
//                 }

//             }
//         }

//     }

// .ai-extra-button-popover{
//     padding: 8px;
//     .line{
//         padding: 8px 16px;
//         cursor: pointer;
//         &:hover{
//             box-shadow: inset 0px 0px 1000px 1000px #00000022;
//         }
//     }
// }

// .ai-chat-message-box{

//     padding: 0px 8px;
//     // background-color: ;
//     width:100%;

//         .ai-chat-message-text{
//             padding-top: 0px;
//             display: grid;
//             grid-gap: 8px;
//             gap: 8px;

//             grid-template-columns: 1fr 1fr 1fr auto;
//             grid-template-rows: auto;
//             grid-template-areas:
//             "language tone style style"
//             "prompt prompt prompt send";

//             select{
//                 background-color: transparent;
//                 border: 0px none;
//                 outline: none;
//                 border-left:  1px solid #ddd;
//                 border-radius: 0px;
//                 &:first-child{
//                     border: 0px none;
//                 }
//             }
//             .ai-language{ grid-area: language; }
//             .ai-tone{ grid-area: tone; }
//             .ai-style{ grid-area: style; }
//             .ai-extra{ grid-area: extra; }
//             .prompt{ grid-area: prompt; height: 40px; min-height: auto; }
//             .prompt.disabled{ user-select: none; pointer-events: none; opacity: 0.4; overflow: hidden; }
//             .send-prompt{ grid-area: send; align-self: center; }
//         }
// }

// .ai-message-popover{
//     padding: 4px 0px;
//     .ai-message-popover-line{
//         padding: 12px 16px;
//         cursor: pointer;

//         &:hover{
//             box-shadow:  inset 0px 0px 1000px 1000px #00000011;
//         }
//     }
// }

@media screen and (max-width: 768px) {
    .image-selection-popup .material-list {
        .material-item {
            margin: 0.5%;
            padding: 2px;
            width: 49%;

            &:hover {
                box-shadow: none;
                background-color: inherit;
            }
        }
    }
}
