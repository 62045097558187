.company-profile{

  .company-profile-title{
    font-size: 160%;
    padding: 8px 10px;
    padding-bottom: 0px;
  }
  .company-profile-desc{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

  .company-profile-footer{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

}

.app-types{ 
  width: 100%; 
}
 

.company-info{
  flex:1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}
.company-info .formel{  
  margin-bottom: 16px;
}


.company-info-inner{
  display: flex;
  flex-direction: row;
}







.app-types{
  max-width: 1050px;
  display: flex;  
  flex-direction: row;
  flex-wrap: wrap;

  .application-type{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 134px;  
    height: 134px;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    margin: 16px;
    padding: 8px;
    background: #fff;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 0px 10px 0px #00000011;
    &:hover{
      background: #f2f2f2;
    }
    &.selected{
      background-color: #3949ab;
      color:#fff;
      .ficon{ 
        // background-color: #fff;
        // color: #000;

        background-color: #fff;
        color: #000;
      }
    }
    .ficon{ 
      height: 48px;
      width: 48px;
      font-size: 32px;
      margin-top: 16px;
      opacity: 0.8;
      background-color: #ffc003;
      background-color: #000;
      color: #000;
      color: #fff;

      background-color: #eee;
      color: #000;

      padding: 8px;
      border-radius: 4px;
    }
    .parent-logo{
      font-size: 12px;
      height: 24px;
      width: 24px;
      position: absolute;
      top:8px;
      left:8px; 
      border-radius: 8px;
      box-shadow: 0px 0px 2px 1px #00000011;
      padding: 6px;
      margin-top: 0px;
      background-color: #fff;
      color:#000;
    }
    .application-type-name{
      padding: 8px 0px;  
    }
  }
}