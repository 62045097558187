.datagrid-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    position: sticky;
    z-index: 2;
    top: -95px;
    border-top: 1px solid #eee;
    background: #fafafa;

    .head-columns {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        user-select: none;
        width: 100%;
        position: relative;
    }

    .datagrid-header-cell {
        background-color: #fafafa;
        text-overflow: ellipsis;
        overflow-x: hidden;
        display: flex;
        white-space: nowrap;
        vertical-align: middle;
        height: 100%;
        line-height: 46px;
        cursor: pointer;
        position: relative;

        &.disable-sort{
            cursor: default;
        }
        
        .datagrid-header-cell-inner {
            width: 100%;
        }

        .cell-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            justify-content: flex-end;

            &:after {
                content: ' ';
                width: 2px;
                background-color: #eee;
                height: 50%;
                position: absolute;
                right: 0;
                border-radius: 4px;
            }

            .cell-title {
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                vertical-align: middle;
                padding-left: 8px;
                padding-right: 0;
            }

            .cell-extensions {
                height: 28px;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;

                .more {
                    display: none;
                    font-size: 14pt;
                    font-weight: bold;
                    vertical-align: middle;
                    padding: 4px 2px;
                }

                .sort {
                    width: 80%;
                    height: 18px;
                    position: absolute;
                    top: -9px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 150%;
                    color: #10739787;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 3px;
                    z-index: 1;
                }

                .sort.asc,
                .sort.desc {
                    display: block;
                }
            }
        }

        .column-shadow {
            display: none;
            width: calc(100% + 20px);
            height: 100%;
            left: 0;
            top: 0;
            background-color: #00000011;
            position: fixed;
            z-index: -1;
        }
    }

    .datagrid-header-cell.stick-left {
        position: sticky;
        left: 0;
        background-color: #fafafa;
        z-index: 2;
    }

    .datagrid-header-cell.stick-right {
        position: sticky;
        right: 0;
        background-color: #fafafa;
        z-index: 2;
    }

    .datagrid-header-cell.dragging {
        background: #fff;
        user-select: none;
        pointer-events: none;
        opacity: 0.9;
    }

    .datagrid-header-cell.dropping {
        background: #00000022;
    }

    .datagrid-header-cell:hover {
        .cell-resizer {
            display: block;
        }

        .cell-extensions .more {
            display: block;
        }
    }

    .datagrid-header-cell.type-system-check {
        .cell-resizer {
            display: none;
        }

        .cell-extensions .more {
            display: block;
        }
    }

    .datagrid-header-cell:hover .column-shadow {
        display: block;
    }
}

.datagrid-header.column-dragging {
    .datagrid-header-cell:hover {
        background-color: transparent;
    }

    .datagrid-header-cell .cell-extensions {
        visibility: hidden;
    }
}

.datagrid .datagrid-header .datagrid-header-cell.resizeable .cell-content .cell-extensions .more {
    margin-right: 5px;
}

.popover-wrapper:has(.grid-more-popover) {
    z-index: 101;

    .popover {
        z-index: 102;
    }
}

.grid-more-popover {
    overflow: hidden;
    border-radius: 4px;
}

.grid-more-popover .line {
    padding: 8px 16px;
}

.grid-more-popover .line:hover {
    background-color: #00000011;
    cursor: pointer;
}

.grid-more-popover .line:first-child,
.grid-more-popover .line:last-child {
    padding-bottom: 10px;
}
