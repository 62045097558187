 

.simple-table{
    width: 100%;
    background-color: #fff; 

   
    .table-header, .table-cell{
        padding: 8px 12px;
        
    }
    &.compact{
        line-height: 1.2; 
        .table-header{
            padding: 8px 8px;
            
        }
        .table-cell{
            padding: 4px 8px;
            
        }
    }
    .table-headers{
      
        .table-header{
            border-right: 1px solid #eee;
            font-weight: bold;
            color:#888;
            background-color: #fafafa;

            .filter-input{
                padding: 0px;
                border: 0px;
                background-color: transparent;
                margin-top: 12px;
                border-bottom: 1px solid #eee;
                font-size: 90%;
                opacity: 0.8;
                color:#dd0000;
                border-radius: 0;
            }
        }
    }


    .table-footers{
        background-color: #fafafa;
        .table-footer{
            
            font-weight: bold;
            color:#888;
            padding: 8px; 

            .footer-filter{
                font-size: 80%;
                font-style: italic;
                font-weight: normal;

            }
        }
    }
    .table-footer-info{
        background-color: #fafafa; 
        
            td{
                 
        border-top: 1px solid #eee;
            font-weight: bold;
            color:#888;
            padding: 8px; 
 
                font-size: 80%;
                font-style: italic;
                font-weight: normal;
            }
 
        
    }
    .table-rows{
        .table-row{


            

            &:has(input:checked){
                background-color: #00c5f767;
            }

            &[data-hidden="true"]{
                display: none;
            }
            
           
            .table-cell{
                border-bottom: 1px solid #eee;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 300px;
            }
        }
    }
}


.row-child-component{

.simple-table {
    .table-headers{

    }
    .table-header {
        padding-top:16px;
    }
    .table-header ,
    .table-footer,
    .table-footer-info {  
       
        background-color: #f2f2f2;

    }
    .table-footer-info td {  
        
        border-top: 1px solid #e2e2e2;
    }
}
}
 