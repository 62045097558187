 
    .application-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 134px;  
      height: 134px;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin: 8px;
      padding: 8px;
      background: #fff;
      cursor: pointer;
      position: relative;
      user-select: none;
      overflow: hidden;


      &:hover{
        background: #f2f2f2;
      }
      &.selected{
        background-color: #3949ab;
        color:#fff;
        .ficon{ 
          background-color: #fff;
          color: #000;
        }
      }


      .ficon{ 
        height: 48px;
        width: 48px;
        font-size: 32px;
        margin-top: 16px;
        opacity: 0.8;
        background-color: #ffc003;
        background-color: #000;
        color: #000;
        color: #fff;
        padding: 8px;
        border-radius: 4px;
      }
      .parent-logo{
        font-size: 12px;
        height: 24px;
        width: 24px;
        position: absolute;
        top:8px;
        left:8px; 
        border-radius: 8px;
        box-shadow: 0px 0px 2px 1px #00000011;
        padding: 6px;
        margin-top: 0px;
        background-color: #fff;
        color:#000;
      }
      .application-item-name{
        padding: 8px 0px;  
      }


      &.small{
        width: 96px;  
        height: 96px;
        padding: 0px;
        .ficon{ 
          height: 32px;
          width: 32px;
          padding: 4px;
          font-size: 24px;
          margin-top: 0px; 
         }
         .parent-logo{
          height: 20px;
          width: 20px;
          font-size: 12px;
          top:0px;
          left:0px; 
          box-shadow: none;
          background-color:transparent;
          color:#666;
         }
         .application-item-name{ 
          font-size: 90%;
          padding: 4px;
          word-break: break-all;
        }
  

      }

      .application-status{
        position: absolute;
        top:0px;
        right:-4px;
        font-size: 70%;
        background-color: #a30000;
        padding: 2px 8px;
        transform: rotate(45deg) translate(25%);
        opacity: 0.6;
        color: #fff;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-align: center;
        width: 72px;
        
        &[data-type="running"]{
         background-color: #009e03; 
        }
      }

    } 