.settings-page-content{
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: minmax(300px, 300px) minmax(300px, 100%) ; 
    grid-template-rows:1fr auto; 
    justify-content: flex-start;
    align-items: flex-start;

    .settings-sub-content{ 
        width: 100%;
        .card-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            &>*:not(:last-child){
             margin-bottom: 16px;
            }
          
           &> .save-button{
                min-width: 300px;
                width: 100%;
           }
           
        }
    }
    .settings-buttons{  
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        
           
        
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: minmax(100px, 120px); 
        grid-template-rows:1fr auto; 
        justify-content: flex-start;
        align-items: flex-start;
       

    }
    .settings-buttons-content{ 
        .card-content{
            display: flex;
            flex-direction: column; 
            flex:1;
            height: 100%;
        }
    }
    
    .profile-button-top-margin{
        flex:1;
    }
    .logout-button{
        
    }
}



@media (max-width:768px) {
    .settings-page-content{ 
      display: flex;
      width: 100%;
      overflow: hidden;
      flex-direction: column;
    }
    .settings-buttons-content{
        // display: none;
        width: 100%;
        margin-top: 16px;
    }
    .settings-buttons{
      
        
    }
}