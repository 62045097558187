.column-permission{

  display: flex; 
  flex-direction: column; 
  justify-content: center; 
 padding: 8px;
}



.column-permission .permission-row-head { 
  display: flex;
  align-items: center;
  flex-direction: row; 
  font-weight: bold;  

 }
.column-permission .permission-row-head .permission-head-name{
  width: 180px;
}
.column-permission .permission-row-head .permission-head-read{
  width: 400px; 
  padding: 8px;  
} 
.column-permission .permission-row-head .permission-head-write{
  padding: 8px;
  width: 400px; 
} 

.column-permission .permission-row{
  display: flex;
  align-items: center;
  flex-direction: row; 

  align-items: center;
  justify-content: center; 
  border-bottom: 1px solid #eee; 

}
 
 
.column-permission .permission-row>*{
  padding: 8px 8px;
}
.column-permission .column-name{ 
  width:180px; 
}

.column-permission .permission-read{ 
  padding: 8px;
  width: 400px; 
}
 
.column-permission .permission-write{  
  padding: 8px;
  width: 400px; 
} 
 





@media (max-width:768px) {

    .column-permission .permission-row-head {
      display: none;
    }
 
}