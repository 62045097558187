
         .edit-apikey.edit-page{
          .info{
            display: grid;
            grid-gap: 16px;
            gap:16px; 
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas: "cost-per-call"
  "call-limit-per-month"
  "access-rules"
  "call-limit-per-minute"
  "updated-at"
  "valid-until"
  "name"
  "key"
  "secret"
  "cost-formula"
  "scopes"; 
          }
          .cost-per-call{
      grid-area:cost_per_call;
    }
  .call-limit-per-month{
      grid-area:call_limit_per_month;
    }
  .access-rules{
      grid-area:access_rules;
    }
  .call-limit-per-minute{
      grid-area:call_limit_per_minute;
    }
  .updated-at{
      grid-area:updated_at;
    }
  .valid-until{
      grid-area:valid_until;
    }
  .name{
      grid-area:name;
    }
  .key{
      grid-area:key;
    }
  .secret{
      grid-area:secret;
    }
  .cost-formula{
      grid-area:cost_formula;
    }
  .scopes{
      grid-area:scopes;
    }
         }
      
