.recency,
.frequency,
.monetary {
    padding: 4px 16px;
    color: #fff;
    font-size: 80%;
    border-radius: 2px;
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.recency {
    background-color: #3a51bc;
}

.frequency {
    background-color: #d32f2f;
}

.monetary {
    background-color: #00b241;
}

// .social-icons {
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;
// }

// .social-icons i {
//     font-size: 16pt;
//     font-weight: bold;
//     color: #adbec6;

//     &:hover {
//         color: #84969f;
//     }
// }

// .social-icons > span {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     border-style: solid;
//     border-width: 1px;
//     border-color: #00000011;
//     padding: 2px;
//     border-radius: 8px;
//     width: 30px;
//     height: 30px;
//     margin-right: 4px;
//     margin-bottom: 4px;
//     pointer-events: none;
// }

// .social-icons > span:last-child {
//     margin-right: 0;
// }

// .social-icons > span:hover {
//     border: 1px solid #aaa;
// }

// .social-icons > span[data-value]:after {
//     content: attr(data-value);
//     margin-bottom: -12px;
//     transform: translate(-1px, -3px);
//     margin-right: -24px;
//     width: 12px;
//     height: 12px;
//     background-color: #145e9b;
//     border-radius: 50%;
//     font-size: 60%;
//     text-align: center;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .social-icons .wechat[data-value] i {
//     color: #00b241;

//     &:hover {
//         color: #078937;
//     }
// }

// .social-icons .qq[data-value] i {
//     color: #222;

//     &:hover {
//         color: #000000;
//     }
// }

// .social-icons .weibo[data-value] i {
//     color: #9a0707;

//     &:hover {
//         color: #740303;
//     }
// }

// .social-icons .tiktok[data-value] i {
//     color: #222222;

//     &:hover {
//         color: #000;
//     }
// }

// .social-icons .linkedin[data-value] i {
//     color: #145e9b;

//     &:hover {
//         color: #0d426e;
//     }
// }

// .social-icons .web[data-value] i {
//     color: #10638d;

//     &:hover {
//         color: #0a3457;
//     }
// }

.location > span {
    font-size: 75%;
    color: #577682;
    display: block;
    white-space: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid-detail-popoover {
    padding: 16px;
    // color: #577682;
    max-width: 300px;
    overflow: auto;

    .line {
        display: block;
        padding: 2px 0;
        margin-bottom: 4px;
        // color: #577682;

        b {
            font-weight: bold;
            color: #3e55bd;
        }

        span {
            color: #577682;
            font-size: 90%;
        }
    }

    h3 {
        color: #37434a;
        font-size: 120%;
        margin-top: 16px;
        padding-bottom: 8px;
        font-weight: 500;
    }

    p {
        color: #577682;
        padding-bottom: 36px;
    }

    .setting-button {
        position: absolute;
        align-self: flex-end;
        right: 16px;
        bottom: 16px;
    }
}

.grid-open-button,
.grid-edit-button,
.grid-tag-button {
    font-size: 150%;
    font-weight: 500;
}
