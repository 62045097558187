
.code-editor-wrapper{
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .start-edit{
         background-color: #ddd;
         border-radius: 4px; 
    }
    
    .editor-overlay{
     
  background-image: repeating-linear-gradient(to bottom, transparent, transparent 3px, #00000005 3px, #00000005 6px),
  repeating-linear-gradient(to right, #00000011, #00000011 3px, #00000005 3px, #00000005 6px);

        width: 100%;
        height: 100%;
        background-color: #00000066;
        top:0px;
        position: absolute;
        z-index: 1;
        display: flex; 
        align-items: center;
        justify-content: center;
        

    }
}