@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

.funnel-chart {
    width: 100%;
}

.svg-funnel-js {
    display: inline-block;
    position: relative;
}

.svg-funnel-js svg {
    display: block;
    margin-top: 10px;
}

.svg-funnel-js .svg-funnel-js__labels {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
    flex-direction: column;
}

.svg-funnel-js {
    font-family: 'Open Sans', sans-serif;

    display: flex;
    align-items: center;
    height: 100%;
}

.svg-funnel-js .svg-funnel-js__container {
    width: 100%;
    height: 100%;
    

}

.svg-funnel-js .svg-funnel-js__labels {
    width: 100%;
    box-sizing: border-box;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
    flex: 1 1 0;
    position: relative;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
    position: absolute;
    top: 25px;
    font-size: 24px;
    // color: #fff;
    line-height: 18px;
    margin-bottom: 6px;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
    position: absolute;
    top: 0px;
    // font-size: 12px;
    font-size: 80%;
    opacity: 0.8;
    font-weight: bold;
    text-transform: uppercase;
    // color: white;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label:first-of-type .label__percentage {
    display: none;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
    position: absolute;
    top: 60px;
    font-size: 16px;
    font-weight: bold;
    // color: white;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    padding: 8px 24px;
    box-sizing: border-box;
    // background-color: rgba(8, 7, 48, 0.8);
    margin-top: 24px;
    opacity: 0;
    transition: opacity 0.1s ease;
    cursor: default;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li {
    font-size: 13px;
    line-height: 16px;
    // color: #fff;
    margin: 18px 0;
}

.svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label
    .label__segment-percentages
    ul
    li
    .percentage__list-label {
    font-weight: bold;
    color: #05df9d;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label:hover .label__segment-percentages {
    opacity: 1;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) {
  padding-top: 70px;
 
    padding-bottom: 16px;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
    padding-left: 24px;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
    border-left: 1px solid #00000033;
}

.svg-funnel-js.svg-funnel-js--vertical {
    padding-left: 120px;
    padding-right: 16px;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label {
    padding-top: 24px;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label:not(:first-child) {
    border-top: 1px solid #00000033;
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages {
    margin-top: 0;
    margin-left: 106px;
    width: calc(100% - 106px);
}

.svg-funnel-js.svg-funnel-js--vertical
    .svg-funnel-js__label
    .label__segment-percentages
    .segment-percentage__list {
    display: flex;
    justify-content: space-around;
}

.svg-funnel-js .svg-funnel-js__subLabels {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    position: absolute;
    width: 100%;
    left: 0;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
    display: flex;
    // font-size: 12px;
    font-size: 100%;
    line-height: 16px;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel:not(:first-child) {
    margin-left: 16px;
}

.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel .svg-funnel-js__subLabel--color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 2px 8px 2px 0;
}
