.processing-informer{  
    padding: 8px 16px;
    
    border-radius:  100px;
    
    flex: none;
    align-self: center;
    justify-self: center;
    text-align: center;   
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00000077;
    user-select: none;
    outline: none;
    position: relative;
    font-size: 90%;
    .ficon{
      font-size: 150%;
      margin-left:8px;
    }
  
    &:hover{
      background-color: #eee;
    }

  &.loading{
    .ficon {  
        animation:  rotate-loading-button-icon 400ms linear;
        animation-iteration-count: infinite;
     }
  }  

  @keyframes rotate-loading-button-icon {
    0% {  transform: rotate(0deg);} 
    100% {transform: rotate(359deg); } 
  }

  @keyframes fade-nothing-button {
    0% {  opacity: 1;} 
    80% {  opacity: 1;} 
    100% {opacity: 0; } 
  }


}