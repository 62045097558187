.choose-send-method{
  width: 700px; 

  padding: 0px; 
  .choose-send-method-content{
    padding:0px;
  }
  .sub-title{
    padding: 8px 0px;
  }
  .note{
    font-size:80%;
    opacity: 0.5;
    padding: 8px 0px;
  }

  .send-methods{
    display: flex;
    flex-direction: row; 
    justify-content: center;
    flex-wrap: wrap;
    .send-method{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px 16px;
      // height: 110px;
      background-color: #fff;
      border-radius: 4px;
      margin: 8px;
      min-width: 180px;
      max-width: 500px;
      flex:1;
      text-align: center;
      cursor: pointer; 
      &:hover{
        box-shadow: inset 0px 0px 1000px 10000px #00000011;
      }
      &.selected{
        background-color: #3949ab;
        color:#fff;
      }
      .ficon{
        font-size: 240%;
        margin-bottom: 8px;
      } 
    }
  }
}

