.channel-data-item {
    // padding: 16px;
    // gap: 4px 16px;
    // grid-gap: 4px 16px;
    // display: grid;
    // align-items: center;
    position: relative;
    // grid-template-columns: 60px auto auto minmax(min-content, 170px);
    // grid-template-rows: auto;
    // grid-template-areas:
    //     'icon label  label expand'
    //     'icon subt subt  expand'
    //     'icon desc desc  expand'
    //     'content content content content';
    border-bottom: 1px solid #eee;

    .channel-data-item-header { 
        cursor: pointer;
        padding: 16px;
        gap: 4px 16px;
        grid-gap: 4px 16px;
        display: grid;
        align-items: center;
        position: relative;
        grid-template-columns: 60px auto auto minmax(min-content, 170px);
        grid-template-rows: auto;
        grid-template-areas:
            'icon label  label expand'
            'icon subt subt  expand'
            'icon desc desc  expand'
            'content content content content';
        border-bottom: 1px solid #eee;
    
    }

    .list-icon {
        grid-area: icon;
        font-size: 28px;
        width: 60px;
        height: 60px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #fff;
    }

    .title {
        grid-area: label;
        font-size: 130%;
        align-self: flex-end;
    }

    .subtitle {
        grid-area: subt;
        font-size: 80%;
        align-self: flex-end;
        text-transform: uppercase;
    }
    .desc {
        grid-area: desc;
        font-size: 70%;
        align-self: flex-start;
        text-transform: uppercase;
        opacity: 0.7;
    }

    .expand {
        grid-area: expand;
        font-size: 80%;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        padding: 8px 16px;
        user-select: none;
        font-weight: 500;

        // &:hover {
        //     box-shadow: inset 1px 1px 1000px 1000px #00000011;
        // }

        .ficon {
            pointer-events: none;
            font-size: 150%;
            font-weight: bold;
        }
    }
    .content {
        grid-area: content;
        display: flex;
        flex-direction: column;
    }

    .detail {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;

        padding: 16px;
        padding-left: 90px;
        align-items: flex-start;
        justify-items: flex-start;
        position: relative;

        .detail-row {
            padding-bottom: 8px;
            margin-bottom: 8px;
            width: 100%;
            display: flex;
            flex-direction: row;

            &:not(:last-child) {
                border-bottom: 1px dashed #ddd;
            }

            label {
                min-width: 200px;
                display: block;
            }
        }
    }
    .info {
        display: grid;
        flex: 1;
        padding: 16px;
        padding-left: 90px;
        gap: 8px;
        grid-gap: 8px;
        align-items: center;
        justify-items: flex-start;
        position: relative;
        grid-template-columns: min-content min-content min-content auto;
        grid-template-rows: auto;
        grid-template-areas:
            'title title title title'
            'notice notice notice notice'
            'recency frequency monetary monetary'
            'conversion conversion conversion conversion';

        .data-box {
            text-align: left;
            align-items: flex-start;
        }

        .info-title {
            grid-area: title;
        }
        .notice{
            grid-area: notice;
            font-size: 80%;
            font-style: italic;
            background-color: #eee;
            padding: 8px;
            opacity: 0.7;
        }

        .frequency-box {
            grid-area: frequency;
        }

        .recency-box {
            grid-area: recency;
        }

        .monetary-box {
            grid-area: monetary;
        }

        .conversion {
            margin-top: 8px;
            grid-area: conversion;
            span{
                margin-top: 4px;
                background-color: #e2e2e2;
                padding: 8px 8px;
                display: block;
                border-radius: 4px;
            }
        }
    }


   

}

@media (max-width: 768px) {
    .channel-data-item {
        grid-gap: 8px;
        gap: 8px;
        grid-template-columns: 44px auto 80px;

        .title {
            font-size: 120%;
        }

        .expand {
            padding: 8px 4px;
            font-size: 70%;

            &:hover {
                box-shadow: none;
            }
        }
    }
}
