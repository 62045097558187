.chat-message-list{ 
 
    flex:1;
    padding: 0px 8px;
    padding-bottom: 60px;

    display: flex;
flex-direction: column-reverse;

    // animation: fade-chat-message-list 400ms forwards;
    // animation-iteration-count: 1;

    opacity: 1;
    transition: 500ms;
    overflow: hidden;
    overflow-y: scroll;

  &.animate{
    opacity: 0; 
  }
 


    // @keyframes fade-chat-message-list {
    //   0% {  opacity: 0; }  
    //   100% {  opacity: 1;  } 
    // }
    
  .chat-message{
               
    animation: fadewindow 400ms forwards;
    animation-iteration-count: 1;

    display: flex;
    flex-direction: row;
    padding:8px;
    text-align: left;
    padding-bottom: 24px;
    padding-top: 0px;
    align-items: flex-start;
    .message-header{ color:#000;  }
    .image{ 
      width:40px; height: 40px; background-position: center; background-repeat: no-repeat; background-size: auto 100%; 
      margin-top: 16px; 
      background-color: #ccc; border-radius: 50%;
      border:2px solid #ddd;
      user-select: none;
    }
    .image.no-image{
      opacity: 0.16;
      background-size: auto 70%; 
      background-color: #999 !important;
    }
    .image.user{ border-color: #ddd; }
    .image.admin{ border-color: rgb(164, 204, 235); }
    .image.customer{ border-color: rgb(248, 206, 201); }
    .image.watcher{ border-color: rgb(150, 180, 145); }
  }
 
 
  .chat-message.break{
    padding-top: 16px; 
  }

  // f5ac34 

  .chat-message.me{
    opacity: 1;
    color:#fff;

    justify-content: flex-end;
    padding-right: 16px; // added bcs of scroll

    .image{
      // display: none;
    }
    a{
      color: #ffffffcc;
      &:hover{
        color: #ffffffee;
      }
    }
    .chat-message-content{  
      padding-right:16px;  
      padding-left: 15%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .message-header{ color:#000;  }
      .sender{ text-align: right; max-width: 75px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-right:4px ; } 
      .message:before{  right:-5px;   left:auto;   background-color: #2E5266; }
      .message{ background-color: #2E5266; }
      .starred{  
        right: 2px;
        left: auto;
      }

    } 
  }
 

  .chat-message.me.ai-message .chat-message-content .message{
    background-color: #ddd;
    color:#666;
   
    &:before{
      background-color: #eee;
      display: none;
    }
  }

  .chat-message.ai-message{
    padding-bottom: 4px;
    
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    
    &:last-child{ 
    margin-bottom: -50px;
    }
    .message-header{
      display: none;
    }
    &>.image{
      display: none;
    }
    .chat-message-content{
      padding-left: 0px;
      .message{ 
        border-radius: 0px;
        padding: 8px 16px;
        cursor: pointer;
        
        &:hover  {
      
          box-shadow:inset 0px 0px 1000px 10000px #00000011;
        }
      }
      .text-message-content{ 
         white-space:normal; 
        overflow: hidden; 
        text-overflow: ellipsis;  
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical; 
        display: -webkit-box;
      }
    }
    .message-delivery{
      display: none;
    }
   
  }



  .chat-message.me.ai{ 
    opacity: 1;
    .image{  
    }
    .chat-message-content{   
      .message:before{    background-color: #444444; }
      .message{ background-color: #444444; } 
    
    } 
  }

  .chat-message.me.mine{ 
    opacity: 1;
    .chat-message-content{   
      .message:before{    background-color: #27488a; }
      .message{ background-color: #27488a; } 
    } 
  }

  .chat-message.me.mine.internal{ 
    opacity: 1;
    .chat-message-content{   
      .message:before{ background-color: #ff9500; }
      .message{ background-color: #ff9500;  }  
    } 
  }


  
  //  

  .chat-message.system{
    color:#888;

    .image{
      display: none;
    }
    .chat-message-content{  
      padding-right:10px;  
      padding-left:70px;  
      display: flex;
      flex-direction: column;
      align-items: flex-end;
       .message-header{ color:#000;  }
      .sender{ text-align: right; } 
      .message:before{ display: none;}
      .message{ background-color: #ccc; }
      .starred{  
        right: 2px;
        left: auto;
      }

    }

    .message-delivery{
      display: none;
    }
 

  }



  .chat-message.system{

    margin: 24px 0px;
    padding: 0px;
    color:#777; 
    align-items: center;
    justify-content: center;

    .image{
      display: none;
    }
    .chat-message-content{  
      // border-top: 1px solid #00000011;
      // border-bottom: 1px solid #00000011; 
      display: flex;
      align-items: center;
      padding: 32px 16px;
      margin: 0px;
      flex: none;
      width:90%;
       .message-header{ color:#000;   }
      .sender{ text-align: right; } 
      .message:before{ display: none;}
      .message{ 
          background-color: transparent; padding: 0px; margin: 0px; text-align: center; 
          a{
            vertical-align: middle; 
            max-width: 200px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color:#004f6f91;
            text-decoration: none;
            &:hover{
              text-decoration: underline;
            }
          }
      } 

    }
 

  }

  


  .chat-message.internal{
    color:#fff; 
    
    .image{
      margin-top: 24px; 
    }
    .chat-message-content{   
     
      .message:before{ background-color: #f7a92d; }
      .message{ background-color: #f7a92d;  }  
    } 

  }





}




@media (max-width:600px) {

  .chat-message-list{  
    padding: 0px;
       .chat-message{

    .chat-message-content{   
      //  padding-right: 5%;
       padding-right: 44px;
       .sender{
        overflow: hidden;
        max-width: 60px;
        text-overflow: ellipsis;
        white-space: nowrap;
       }
       .role{
          padding: 4px;
       }
    }
        // padding-left:0px;
        // padding-right:0px;
       }
       .chat-message.me{
        padding-right: 8px;
        .chat-message-content{   
          padding-left: 44px;
        }
      }
  }
 
}

.message-sender-popoover{
  padding: 8px 0px;
  min-width: 220px;
  .info-line{
    border-bottom: 1px solid #eee;
    padding: 16px;
    text-transform: capitalize;
    b{
      width: 80px;
      display: inline-block;
    }
  }
  .button-wrapper{
    margin-top: 8px;
  }
}



.chat-message-list{

}



@media (max-width:768px) {
  .chat-message-list{
    .chat-message, .chat-message.me{
      .chat-message-content{   
        .sender{ 
          max-width: 60px; 
        }
    }
  }
}

    .chat-message-list{

      .chat-user-summary{
          
          margin-left: 12px;
          margin-right: 12px;
          .chat-user-image{ width:80px; height:80px;  }
          .chat-user-info{ 
                padding: 0px; padding-left: 16px;  
                .description{
                  font-weight: normal;
                  font-size: 80%;
                }
                .rfm{ padding-top: 0px; }
            }
          .chat-user-score{ } 
          .chat-user-notes{  
              a{ 
              }
          }
      }
    }

}