.urls {
    .tab-header {
        margin: 0 0 8px 0;
    }

    .toolbar-wrapper {
        margin: 0;
        border: none;

        .toolbar {
            margin: 0 0 8px 0;
            padding-bottom: 16px;
            border-bottom: 1px solid #ddd;
        }
    }
}

.loading-overlay {
    width: 100%;
    height: 100%;
}
