 
    .session-status{

      // font-size: 80%;
      // font-weight: bold;
      // padding: 4px 8px;
      // background-color: #eee;
      // border-radius: 2px;
      // margin-left: 32px;
      // cursor: pointer;

        align-self: flex-end;
        grid-area: status; 
        vertical-align: middle;
        padding:4px 8px;
        color:#fff;
        color:#000;
        font-size: 60%;
        border-radius: 2px;
        font-weight: 500;
        cursor: pointer;

    &.in-progress{
      background-color:#4CAF50;
      color:#fff; 
    }
    &.pending{
      background-color:#4c84af;
      color:#fff;
    }
    &.expired{
      background-color:#f2f2f2;
      color:#00000055;
    }
    &.closed{
      background-color:#a1adb2;
      color:#fff;
    }
  }