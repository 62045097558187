.edit-wechatmenu.edit-page {
    .add-sub-button {
        width: 100%;
    }

    .oa-menu-location {
        display: flex;
        flex-direction: column;
        align-items: center;
        .country,
        .province,
        .city {
            margin-bottom: 16px;
        }
    }

    .button-item {
        .button-settings {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            > *:not(.button-item) {
                width: 50%;
                margin-bottom: 16px;
                margin-top: 16px;

                &.wechat-menu-sub-buttons {
                    width: 100%;
                }
            }

            .edit-field-desc {
                position: relative;
            }

            .edit-field-desc:after {
                content: ' ';
                width: 196%;
                height: 1px;
                background-color: #f2f2f2;
                top: -16px;
                left: -98%;
                position: absolute;
            }

            .button-item {
                padding: 8px 0;
                padding-left: 16px;
                margin-left: 32px;
                border: 3px solid #eee;
                border-radius: 4px;
                position: relative;
                margin-bottom: 16px;

                &.sortable-chosen {
                    cursor: grabbing !important;
                }

                .button-item-sequence {
                    background-color: #eee;
                    border-radius: 4px;
                    padding: 8px;
                    position: absolute;
                    left: -32px;
                    top: 0;
                }
            }
            // .edit-field-desc + *{
            //   width:50%;
            // }
            // .edit-field-desc{
            //   width:50%;
            // }
        }
    }

    .basic-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > * {
            width: 50%;
            flex: none;
            margin-bottom: 16px;
            margin-top: 16px;
        }
        .edit-field-desc {
            position: relative;
        }
        .edit-field-desc:after {
            content: ' ';
            width: 196%;
            height: 1px;
            background-color: #eee;
            top: -16px;
            left: -98%;
            position: absolute;
        }
        .cash-amount-value {
        }
        // display: grid;
        // grid-gap: 8px;
        // gap: 8px;
        // grid-template-columns: 1fr 1fr;
        // grid-template-rows: auto;
        // grid-template-areas:
        //   "code-type code-type"
        //   "code-type-desc code-type-desc"
        //   "coupon-name coupon-name"
        //   "coupon-name-desc coupon-name-desc"
        //   "brand-name brand-name"
        //   "brand-name-desc brand-name-desc"
        //   "color color"
        //   "color-desc color-desc"
        //   "notice notice"
        //   "notice-desc notice-desc"
        //   "description description"
        //   "description-desc description-desc"
        //   "sku sku"
        //   "sku-desc sku-desc"
        //   "date-info-type date-info-type"
        //   "date-info-type-desc date-info-type-desc"

        //   "fixed-term fixed-begin-term"
        //   "fixed-term-desc fixed-begin-term-desc"
        //   "fixed-end-timestamp fixed-end-timestamp"
        //   "fixed-end-timestamp-desc fixed-end-timestamp-desc"

        //   "begin-timestamp end-timestamp"
        //   "begin-timestamp-desc end-timestamp-desc";
    }

    // .code-type{
    //   grid-area: code-type;
    // }
    // .code-type-desc{
    //   grid-area: code-type-desc;
    // }

    // .coupon-name{
    //   grid-area: coupon-name;
    // }
    // .coupon-name-desc{
    //   grid-area: coupon-name-desc;
    // }

    // .brand-name{
    //   grid-area: brand-name;
    // }
    // .brand-name-desc{
    //   grid-area: brand-name-desc;
    // }

    // .color{
    //   grid-area: color;
    // }
    // .color-desc{
    //   grid-area: color-desc;
    // }

    // .notice{
    //   grid-area: notice;
    // }
    // .notice-desc{
    //   grid-area: notice-desc;
    // }

    // .description{
    //   grid-area: description;
    // }
    // .description-desc{
    //   grid-area: description-desc;
    // }

    // .sku{
    //   grid-area: sku;
    // }
    // .sku-desc{
    //   grid-area: sku-desc;
    // }

    // .date-info-type{
    //   grid-area: date-info-type;
    // }
    // .date-info-type-desc{
    //   grid-area: date-info-type-desc;
    // }

    // .begin-timestamp{
    //   grid-area: begin-timestamp;
    // }
    // .begin-timestamp-desc{
    //   grid-area: begin-timestamp-desc;
    // }

    // .end-timestamp{
    //   grid-area: end-timestamp;
    // }
    // .end-timestamp-desc{
    //   grid-area: end-timestamp-desc;
    // }

    // .fixed-term{
    //   grid-area: fixed-term;
    // }
    // .fixed-term-desc{
    //   grid-area: fixed-term-desc;
    // }

    // .fixed-begin-term{
    //   grid-area: fixed-begin-term;
    // }
    // .fixed-begin-term-desc{
    //   grid-area: fixed-begin-term-desc;
    // }

    // .fixed-end-timestamp{
    //   grid-area: fixed-end-timestamp;
    // }
    // .fixed-end-timestamp-desc{
    //   grid-area: fixed-end-timestamp-desc;
    // }

    // .updated-at {
    //   grid-area: updated_at;
    // }

    // .valid-for-days {
    //   grid-area: valid_for_days;
    // }

    // .valid-until-date {
    //   grid-area: valid_until_date;
    // }

    // .props {
    //   grid-area: props;
    // }

    // .coupon-data {
    //   grid-area: coupon_data;
    // }

    // .quantity {
    //   grid-area: quantity;
    // }

    // .card-type {
    //   grid-area: card_type;
    // }

    // .title {
    //   grid-area: title;
    // }

    // .color {
    //   grid-area: color;
    // }

    // .notice {
    //   grid-area: notice;
    // }

    // .description {
    //   grid-area: description;
    // }

    // .status {
    //   grid-area: status;
    // }

    // .brand-name {
    //   grid-area: brand_name;
    // }

    textarea {
        width: 100%;
    }

    .edit-field-desc {
        padding: 4px 12px;
        font-size: 80%;
        opacity: 0.5;
        margin-bottom: 20px;
        &:before {
            content: ' * ';
        }
    }

    .dropdown .value .item,
    .dropdown .list .option .item {
        display: grid;
        grid-gap: 0px 4px;
        gap: 0px 4px;
        grid-template-columns: 40px 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'icon label'
            'icon desc';

        .icon {
            grid-area: icon;
            font-size: 40px;
        }
        .label {
            grid-area: label;
            align-self: flex-end;
        }
        .desc {
            align-self: flex-start;
            grid-area: desc;
            font-size: 80%;
            opacity: 0.6;
            max-width: 600px;
            white-space: normal;
        }
    }

    .cash-amount-value {
        display: grid;
        grid-gap: 0px 8px;
        gap: 0px 8px;
        grid-template-columns: 1fr 150px;
        grid-template-rows: auto;
        grid-template-areas: 'mao rmb';
        align-items: center;
        .cash-amount-value-mao {
            grid-area: mao;
        }
        .cash-amount-value-rmb {
            grid-area: rmb;
            min-width: 150px;
        }
    }
}
