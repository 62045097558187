.bound-input{
  position: relative;
  outline: none;
  border:0px none;
  width: 100%;
  display: flex;
  align-items: center;
  
}

.bound-input .clear-button{
  margin-left: -30px;
  font-size: 120%;
  opacity: 0.2;
}
.bound-input .variable-button{
  margin-left: -50px;
  opacity: 0.8;
  font-size: 120%;
}

.bound-input label{ 
    background-color: transparent;
    position: absolute;
    top:1px;
    left: 6px; 
    font-size: 100%;
    padding: 0px 6px;
    color:#aaa; 
    transition: 50ms;
    // height: 94%;
    display: flex;
    align-items: center;
    pointer-events: none;
    height: auto;
    font-size: 65%;
    background-color: #fff; 
  
    background: linear-gradient(0deg, #fff 60%, #fff0 32%);
    top:-5px;
    left: 9px;   
    color:#3949ab; 
}

.bound-input label span{ 
  margin-left: 8px;
  padding: 0px 2px; 
  font-size: 90%;
  opacity: 0.5;
  visibility: hidden;
}

.bound-input label span:before{  
  padding-right: 8px;
  content: ' - ';
}




.bound-input.disabled {
  opacity: 1; 
  user-select: text;
  cursor:default;
}

.bound-input.disabled .inline-variables{ 
  cursor:text; 
  border-color: #eee;
  background-color: #eee;
  color: #888;
}
   
.bound-input label.with-value span{
  visibility: visible;
}
 
.bound-input label.with-value{   
  color:#666; 
}
 
.bound-input.has-value .clear-button{ 
  opacity: 0.7;
} 

.bound-input .inline-variables{
  padding-right: 55px;
}

.bound-input.error:after{ 
  content:'Error: ' attr(data-error);;
  background-color: transparent;
  position: absolute; 
   
  padding: 0px 6px; 
  transition: 150ms; 
  display: flex;
  align-items: center; 
  height: auto;
  font-size: 65%;
  background-color: #fff; 
  bottom:-5px;
  left: 9px;   
  color:#d69d9d; 

}
 

 
.bound-input.error  label {
  color:#d32f2f;
}
 


// .bound-input .variables{
//   width: 100%;
//   border:1px solid #ddd;
//   border-radius: 4px;
//   padding: 10px 12px;
//   transition: 150ms;
//   outline: none;
//   padding-right: 30px; 
// } 
// .bound-input .inline-variable{
//   margin: 0px 2px; 
//   display: inline-block;
//   width: auto;
//   min-width: 0px;
//   max-width: 1000px;
//   padding: 2px;
//   border:1px solid #fff;
//   text-align: center;
//   background-color: #eee;
//   user-select: none;
//   text-transform: capitalize;
//   font-family: monospace;
//   pointer-events: none;
//   cursor: default;
// }