.date-picker {
    position: relative;
    width: 100%;

    .date-picker-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2666666667);
    }

    .date-picker-value {
        cursor: pointer;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 10px 12px;
        transition: 150ms;
        outline: none;

        &:hover {
            border-color: #666;
        }
    }

    label {
        position: absolute;
        padding: 0 6px;
        transition: 50ms;
        display: flex;
        align-items: center;
        pointer-events: none;
        height: auto;
        font-size: 65%;
        background: linear-gradient(0deg, #fff 60%, #fff0 32%);
        top: -5px;
        left: 9px;
        color: #666;
    }

    &.active {
        .date-picker-value {
            border-color: #666;
            box-shadow: 0 0 10px 5px #00000011;
        }

        .date-picker-popover {
            display: block;
            border-color: #666;

            &:before {
                border-color: #666;
            }
        }
    }

    .date-picker-popover {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 8px;
        width: 300px;
        position: absolute;
        top: 52px;
        left: 0;
        background-color: #fff;
        z-index: 10000;
        margin: 0;
        padding-top: 10px;
        box-shadow: 0 0 10px 5px #00000011;
        display: none;
        position: fixed;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);

        &:before {
            content: '';
            display: none;
            width: 10px;
            height: 10px;
            background-color: #fff;
            transform: rotate(45deg);
            position: absolute;
            top: -6px;
            left: 24px;
            border-top: 1px solid #ddd;
            border-left: 1px solid #ddd;
        }

        .selected-date {
            padding: 8px;
            font-size: 90%;
            opacity: 0.8;
        }

        .time-picker {
            margin-top: 8px;
            display: flex;
            align-items: center;
            padding: 8px;

            select {
                outline: none;
                text-align: center;
            }

            .text-divider {
                padding: 8px;
                min-width: 40px;
                text-align: center;
                font-weight: bold;
            }
        }
    }

    .date-picker-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 8px;
        padding-top: 4px;
        border-top: 1px solid #eee;

        .date-picker-cancel-button,
        .date-picker-ok-button {
            padding: 8px 20px;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin: 8px;
            cursor: pointer;
            font-weight: bold;
            user-select: none;

            &:hover {
                box-shadow: inset 0 0 100px 100px #00000011;
            }
        }
        .date-picker-ok-button {
            background-color: #3d54b5;
            color: #fff;
            border: 1px solid #3d54b5;
        }
    }
}
