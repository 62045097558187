.orverview-card-apps{
    
    overflow: hidden;
    height: 100%;
    .added-apps {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        max-height: initial;
        overflow: hidden;

        .application-item {
            max-width: 200px;
            min-width: 140px;
            flex:1;
            height: 160px;
            padding: 16px;
            &.inactive {
                cursor: initial;

                &:hover {
                    background-color: inherit;
                }
            }
        }

        .app-item {
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .sticker {
                margin-top: 8px;
            }

            .ficon {
                margin-bottom: 8px;
                font-size: 200%;
            }
        }

        .application-item-name {
            word-break: break-word;
            width: 100%;
        }
    }
}

.process-log-modal {
    display: flex;
    // min-width: 540px;
    // max-height: 785px;
}




@media (max-width: 768px) {





    .orverview-card-apps{ 
        .added-apps {
          
            .application-item {
                max-width: 50%;
                min-width: 100px;
                flex:1;
                height: 160px;
                padding: 16px;
                
            }
    
            .app-item {
                
            }
    
            .application-item-name {
                
            }
        }
    }
    
    .process-log-modal { 
    }
    
     
}