
.time-chart-wrapper{
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.time-chart-out{
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    user-select: none;
    pointer-events: none;
  }
  
  .time-chart-in{
    border-radius: 50%;
    overflow: hidden;
    width: 80%;
    height: 80%;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 10%;
    background-image: url(../../assets/time-chart.png); 
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    user-select: none;
    pointer-events: none;
  }
