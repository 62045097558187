.color-con{
  
 padding: 8px;

 display: grid; 
 grid-template-rows: auto;
 grid-column-gap: 8px;
 grid-row-gap: 16px; 
 width: 900px;
 max-width: 100%;

}



.color-con .color-con-row-head { 
 
  align-items: center; 
  font-weight: bold; 
 
display: grid;
grid-template-columns: 100px 200px 1fr ;
grid-template-rows: 1fr;
grid-column-gap: 8px;
grid-row-gap: 8px; 
padding-bottom: 8px;

 }
.color-con .color-con-row-head .color-con-head-color{
  grid-area: 1 / 2 / 2 / 3;
   
}
.color-con .color-con-row-head .color-con-head-enable{
  grid-area: 1 / 1 / 2 / 2;
}
.color-con .color-con-row-head .color-con-head-condition{

    grid-area: 1 / 3 / 2 / 4; 
}

.color-con .color-con-row{
  
  align-items: center;
  justify-content: center;  

display: grid;
grid-template-columns: 100px 200px 1fr ;
grid-template-rows: 1fr;
grid-column-gap: 8px;
grid-row-gap: 16px; 

}
 

.color-con .color-con-row:not(:last-child):before{
  content:' ';   
  height: 1px;   
  grid-area: 2 / 1 / 3 / 5;    
  z-index: 1;
  background-color: #eee; 
  color:#bbb;
}


.color-con .color-con-condition{  
  grid-area: 1 / 3 / 2 / 4;
}

 
.color-con .color-con-color{  
  grid-area: 1 / 2 / 2 / 3;
}

.color-con .color-con-enable{  
  grid-area: 1 / 1 / 2 / 2;
}
 

.color-con .color-con-create{  
  width: 100px;
}
.color-con .color-con-update{  
  width: 100px;
} 

.color-con .color-con-remove{
  grid-area: 1 / 4 / 2 / 5;

  display: flex;

  align-items: center;
  justify-content: center;

  background-color: #00000011;
  width:24px;
  height: 24px;   
  text-align: center; 
  vertical-align:middle; 
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
}


.color-con .color-con-addcondition{

}

.color-con .color-con-filter{
  display: flex;
  align-items: center;
  font-size: 90%;
}


 

.color-con .color-con-filter a{
  color:#5a83c5
}

.color-con .color-con-filter u{
   text-decoration: none; 
   background-color: #00000011;
   padding: 2px 4px;
   border-radius: 4px;
}
.color-con .color-con-filter i{
  color:#00000055;
  font-style: normal;
  // font-size: 90%;
}

.color-con .color-con-filter b{
  color:#000; 
}


.color-con .color-con-filter .color-con-editcondition{
  margin:4px;
}





@media (max-width:768px) {
 
  .color-con{ 
  }

 

.color-con .color-con-row{
  background-color: #fff; 
  // padding: 16px; 
  // padding-top: 0px; 
  grid-template-columns: 90px minmax(200px, 100%);
  grid-template-rows: repeat(2, 1fr);

  padding: 16px 8px;
  padding-top: 24px;
  position: relative;

}

.color-con .color-con-row:not(:last-child):before{
  content:'';   
  display: none;
}


.color-con .color-con-row-head {  
  display: none;

 }
 
.color-con .color-con-enable{  
  grid-area: 1 / 1 / 2 / 2;
}

.color-con .color-con-color{  
  grid-area: 1 / 2 / 2 / 3;
}

.color-con .color-con-condition{  
  grid-area: 2 / 1 / 3 / 3;
  display: block;

}
 

.color-con input.color-con-remove{
  position: absolute;
  top:-32px;
  right: 8px;
}
}