.chat-left-panel{ 
  border-left: 1px solid #ddd;
  overflow: auto;
  user-select: none;



  .accordion-head {
    position: sticky;
    top:0px;
    background-color: #f2f2f2;
    opacity: 1;
    z-index: 1;
}

.keyword-filter-input{
    position: relative;

  input{
    padding: 8px 18px;
    background-color: transparent;
    border:0px;
    border-radius: 0px;
    padding-left: 40px;
    transition: 300ms;
    color: #ff0000;
    //  border-bottom:1px solid #ddd;
    //  border-top:1px solid #ddd;
  }
  .keyword-filter-input-search-icon{
    position: absolute;
    left:18px;
    top:8px;
    z-index: 1;
    font-size: 130%;
    opacity: 0.4;
  }

  &.focussed{
    .keyword-filter-input-search-icon{
        display: none;
    }

    input{
        padding-left: 18px;
        background-color: #ffffffaa;
    }
  }
}
  .open-more{
    padding: 8px 18px;
    font-size: 90%;
    cursor: pointer; 
    opacity: 0.8;
    text-decoration: underline;
    height: 44px;
  }
  .open-more:hover{
    text-decoration: underline;
  }
  .close-more{
    padding: 8px 18px;
    font-size: 90%;
    cursor: pointer; 
    opacity: 0.8;
    text-decoration: underline;
    height: 44px;
  }
  .showing-all{
    padding: 8px 18px;
    font-size: 90%;
    cursor: pointer; 
    opacity: 0.4; 
    height: 44px;
  }

  

  .draw-line{
        border-top: 1px solid #ddd;
        width: 100%;
        height: 1px;
        margin: 16px 0px;
    }

    
    .channel-link, .conversation-filter, .label-filter{ 
        padding: 8px 16px; 
        cursor: pointer; 
        border-left: 3px solid transparent;
        display: flex;
        align-items: center;
 
       
        &.selected{
            color:#3d54b5;
            border-left: 3px solid #3d54b5;
        }
        &:hover{
            background-color: #ddd; 
        }
     } 

     .channel-link{
        height: 40px;
        padding: 0px 16px; 
         padding-right: 8px;
        .channel-link-inner{
            flex:1;
            padding: 8px 0px; 
        }
        .channel-link-settings{
            display: none;
            .iconbutton{
                font-size: 130%;
                justify-self: flex-end;
            }
        } 
        .channel-link-notification{
           font-size:8pt;
           background-color: #ff0000;
           color:#fff;
           width:16px;
           height:16px;
           border-radius: 50%;
           display: flex;
           align-items: center;
           justify-content: center;
           font-weight: bold;
        }
        
        &:hover{ 

            // padding-right: 4px;
            .channel-link-settings{
                display: block;
            }

            
        }
     }


@media (max-width:768px) {
    .channel-link .channel-link-settings{
        display: block;
    }
    .channel-link .channel-link-notification{
        display: block;
    }
}


     .label-filter{
        display:  flex;
        align-items: center; 
        .session-count{
            display:  flex;
            align-items: center; 
            justify-content: center;
            margin-left: 8px;

            border-radius: 50%;
            width:16px;
            height: 16px;
            font-size: 60%;
            line-height: 1;
            color:#fff; 
            padding: 0px;
            background-color: #596D79;
            flex: none;
        }
     }
     
}