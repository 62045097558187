.wechat:has(.overview-page) {
    .tab-header {
        margin: 0 8px 8px 8px;
    }

    .page {
        padding: 16px 8px;
    }

    .toolbar-wrapper {
        margin: 0;
        border: none;

        .toolbar {
            margin: 0 8px 0 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid #ddd;
        }
    }
}

.wechat-overview.overview-page {
    .coupon-list-item {
        .item-label {
            width: 100%;
        }

        .item-sticker {
            min-width: 94px;
        }

        .coupon-status {
            display: flex;
            flex-direction: column;
            min-width: 69px;

            .statusbar {
                height: 5px;
                margin-top: 4px;
                border-radius: 4px;
            }
        }
    }

    .live-chats-list-item {
        padding: 16px 0 16px 16px;

        .live-chats-data {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .item-image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .item-status {
            right: 0;

            .live-chat-actions {
                display: flex;
                justify-content: center;
                width: 70px;
                position: relative;
            }
        }
    }

    /* Experimental */
    .overview-card {
        max-width: 100%;
    }
}
