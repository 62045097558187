.edit-role.edit-page {
  .info {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "updated-at"
      "permissions"
      "name";
  }

  .updated-at {
    grid-area: updated_at;
  }

  .permissions {
    grid-area: permissions;
  }

  .name {
    grid-area: name;
  }

  .permission-list {
    max-width: 550px; 
  }
  .permission-row {
    display: flex;
    align-items: center;
    padding: 4px 16px;
    border-bottom: 1px solid #eee; 
    margin-bottom: 0px;
    margin-top: 0px;
    &>div{
    }
    .permission-row-label { 
      min-width: 90px;
      max-width: 200px; 
      flex:1;
    }
    .permission-switch{
      min-width: 80px;
      max-width: 150px;
      flex:1;
    }
  }
}