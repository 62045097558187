.phone-preview { 
  
  width: 410px;
  height: 750px;
  position: relative;
  zoom:0.9;

  &.sticky{ 
    position: sticky;
    top:0px;
  }

  .phone-preview-inner {
    background-image: url(../../assets/iphone.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 100%;
    height: 750px; 
    position: absolute;
    left:0px;
    top:0px;
    
    box-shadow: inset 0px -7px 7px 5px #f2f2f2;
  }
  .phone-preview-content{
    position: absolute;
    top:22px;
    left:45px;
    width: 323px;
    height: 700px; 
    background-color: #fff;
    border-radius: 32px;
    padding: 0px 0px;
  }
}



.phone-preview-content .phone-preview-head{
  // background-color: #000;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color:#fff;
  height: 28px;
  padding: 2px 16px;
  user-select: none;

  .ficon{
    font-size: 120%;
  }

  .phone-preview-head-time{
    padding: 0px 5px;
    font-size: 90%;
  }
  .phone-preview-head-signal{
    padding: 0px 6px;
  }
  .phone-preview-head-battery{
    padding: 0px 6px;
    transform: scaleX(1.2) rotate(180deg) translateY(2px);
  }
  .phone-preview-head-center{
    flex:1;
  }
}

 .phone-preview-bottom{
    width: 40%;
    height: 5px;
    border-radius: 10px;
    background-color: #000000aa;
    position: absolute;
    bottom:34px;
    left:30%;
    z-index: 10;
}

.phone-preview-app-content{
  position: absolute;
  top:50px;
  left:46px;
  width: 321px;
  height: 669px; 
  // background-color: #ddd;
  border-radius: 2px 2px 32px 32px;
  padding: 0px 0px;
  overflow: auto;
}



@media only screen and (max-width: 768px) {
  .phone-preview { 
  
    width: 100%; 
    height: 100%;
    min-height: 80vh;
    zoom:1;

      .phone-preview-content{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        border-radius: 0;
      }


      .phone-preview-inner {
        background-image: none; 
        width: 100%;
        height: 100%; 
        position: absolute;
        left:0px;
        top:0px;
        
        box-shadow: none;
      }


      .phone-preview-app-content{
        position: relative;
        width: 100%;
        height: calc( 100% - 30px);
        left:0; 
        top:30px;
        border-radius: 0; 
      }
      .phone-preview-bottom{
        display: none;
      }
      
  }  
}