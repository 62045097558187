.campaign-view {
    .tab-header {
        margin: 0 8px 16px 8px;
    }

    .view-page-header {
        margin: 0 8px 0 8px;
    }

    .toolbar-wrapper {
        margin: 0;
        border: none;

        .toolbar {
            margin: 0 8px 0 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid #ddd;
        }
    }

    .article-data-cards-charts {
        .card-header {
            margin-bottom: 0;
        }

        .tab-header-wrapper {
            padding-left: 16px;
        }

        .tab-title {
            padding: 12px 16px 12px 0;
            border-bottom: 0;
            text-transform: capitalize;

            &:not(.selected) {
                opacity: 0.5;
            }

            &:hover {
                box-shadow: none;
            }
        }

        .tab-header {
            margin: 0 8px 8px 8px;
            border-bottom: 0;
        }

        .card-content {
            padding: 0 !important;
        }
    }

    .chart-overview-card {
        .funnel-chart {
            height: 100%;
            overflow: hidden;
        }
    }

    .customer-source-charts {
        .chart-results-wrapper {
            display: flex;
            height: 100%;
            justify-content: space-evenly;
            align-items: center;

            .chart-result {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    opacity: 0.7;
                    font-weight: 600;
                }
            }
        }

        .card-content {
            position: relative;
            overflow: initial;
        }

        .chart-wrapper {
            padding: 16px !important;
        }

        .chart-filter-dropdown {
            position: absolute;
            max-width: 150px;
            top: 4px;
            left: 16px;
            z-index: 11;
        }

        &.mock {
            .chart-filter-dropdown {
                display: none;
            }
        }
    }
}

.view-page.campaign-view{
    width: 100%;
    max-width: 1500px;
}
.campaign-view:has(.campaign-overview) {
    width: 100%;
    max-width: 1500px;

    .view-page-header {
        margin-bottom: 0;
    }

    .campaign-overview {
        width: 100%;
        max-width: 1500px;
    }
}

.campaign-overview.overview-page {
    .campaign-report-summary-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .article-comparison {
            display: grid;
            grid-gap: 16px;
            gap: 16px;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            grid-auto-rows: max-content;
            grid-auto-flow: row dense;
        }

        .article-data-cards,
        .article-user-data-cards {
            padding: 16px 0;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            grid-auto-rows: max-content;
            grid-auto-flow: row dense;
        }
    }

    .datagrid {
        height: 100%;

        .grid-body {
            height: 100%;
        }
    }

    .list-item {
        padding-right: 16px;
    }

    .card-settings {
        display: none;
    }

    .card-data-blocks:has(.card-data-block-visualization) {
        padding-bottom: 16px;
    }

    .campaign-history {
        margin: 8px;
        min-width: 100%;

        .overview-card {
            max-width: 100%;
        }

        .datagrid {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .campaign-view {
        .article-comment-view-page {
            width: calc(100vw - var(--side-menu-width) - 48px);
        }

        .toolbar-wrapper {
            .toolbar {
                padding-top: 16px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .campaign-overview {
        width: calc(100vw - var(--side-menu-width) - 48px);
    }
}

@media only screen and (max-width: 600px) {
    .campaign-overview {
        width: calc(100vw - 32px);
    }

    .customer-source-charts {
        .chart-results-wrapper {
            padding-top: 48px;
            flex-direction: column;
        }
    }
}

@media only screen and (min-width: 1600px) {
    .campaign-overview {
        width: calc(100vw - var(--side-menu-large-width) - 64px);
    }
}


.empty-report{
    padding: 32px;
    width: 100%;
    background-color: #eee;
    text-align: center;
}