.chart-wrapper-el{
    position: relative;
    width: 100%;
    height: 100%;

    .placeholder-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        left:0px;
        top:0;
        z-index: 1;
        background: rgb(255,255,255, 0.5);
        background: linear-gradient(1deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 100%);
       
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-shadow: 1px 1px #00000022;

        .placeholder-overlay-title{
            font-size: 160%;
            font-weight: 500;
            padding: 8px;
            color:#00000099;
           
        }
        .placeholder-overlay-text{
            padding: 8px;
            color:#00000077;
        }
        .placeholder-overlay-button{
            margin-top: 8px;
        }
    }

}
