.page-custom_script .edit-page {

  .name-active {
    display: flex;
    flex-wrap: wrap;

    .name {
      max-width: 500px;
      margin-right: 8px;
    }
  }

  .page-filters {
    display: flex;
    flex-direction: column;
    width: 100%;

    .page-filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 0px;
      border-bottom: 1px solid #eee;

      .page-filter-check {
        min-width: 260px;
      }

      .page-filter-type {
        display: flex;
        flex-direction: row;
        min-width: 200px;
      }

      .page-filter-value {

        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1;

        .textarea {
          min-width: 300px;
          max-width: 500px;
        }
      }
    }
  }

  .script-section {
    padding: 16px 0px;
    display: grid;
    gap: 8px;
    grid-gap: 8px;
    grid-template-columns: 1fr minmax(300px, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "switch switch"
      // "label label"
      "editor helper"
    ;


    height: 470px;
    overflow: hidden;

    .script-section-switch {
      grid-area: switch;
      font-weight: bold;
    }

    .label {
      grid-area: label;
      font-weight: 500;
    }

    .editor {
      grid-area: editor;
      font-family: Menlo, Monaco, "Courier New", monospace;
    }

    .script-helpers {
      grid-area: helper;
    }

    &.disabled {
      height: 40px;
    }

    &.disabled .label,
    &.disabled .editor,
    &.disabled .script-helpers {

      filter: grayscale(1);
      opacity: 0.2;
      pointer-events: none;
      user-select: none;
      visibility: hidden;
    }
  }


  .custom-commands {
    padding: 16px 0px;
    display: grid;
    gap: 8px;
    grid-gap: 8px;
    grid-template-columns: 2fr minmax(300px, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "switch switch"
      "editor helper"
    ;



    &.disabled {
      height: 40px;
      overflow: hidden;
    }


    .custom-commands-switch {
      grid-area: switch;
      font-weight: bold;
    }

    .analytics-commands {
      grid-area: editor;
    }

    .custom-commands-description {
      grid-area: helper;

      code {
        margin-top: 4px;
        margin-bottom: 4px;
        font-family: Menlo, Monaco, "Courier New", monospace !important;
        background: #eee;
        padding: 4px 8px;
        line-height: 16px;
        white-space: pre-wrap;
        display: block;
        font-size: 12px;
      }

      .editor * {
        font-family: Menlo, Monaco, "Courier New", monospace !important;
      }

      .readonly-editor {
        user-select: none;
        pointer-events: none;
      }
    }
  }

}





.custom-scripts-faq .custom-scripts-faq-row{ 
  &:first-child{
      padding-top: 24px;
  }
  padding: 8px;  
  display: flex;
  a{
      text-decoration: none;
      display: flex;
      align-items: center;
      color:#000;
      &:hover{
          text-decoration: underline;
          
      } 
  }
  .ficon{
      margin-right: 8px;
      font-size: 140%;
      opacity: 0.8;
  }
}