
.app.page-list.print-mode{

  display: block;
  .view-page-header{
    display: none;
  }
  .header{
    display: none;
  }
  .app-menu{
    display: none;
  }
   .page{
    width: auto;
    position: absolute;
    left:0px;
    top:0px;
    display: block;
    padding: 0px 16px;
    margin-top: 0px;
    max-width: 100%;
    
    .tab-header-wrapper{
      display: none;
    }

    .grid-body{
      overflow: hidden !important;
      width: auto !important;
    }
    .datagrid-tools {
      display: none;
    }
    .datagrid-footer {
      display: none;
    }

    .datagrid-cell[data-type="action"] {
      display: none;
    }
    .datagrid-cell[data-type="system-check"] {
      display: none;
    }
    .datagrid-header-cell { 
      border-bottom: 1px solid #eee;
    }
    .datagrid-header-cell, .datagrid-cell{
      background-color: inherit; 
    }
    .datagrid-header-cell.type-action{
      display: none;
    }
    .datagrid-header-cell.type-system-check{
      display: none;
    }

    .datagrid-cell.stick-left ,
    .datagrid-cell.stick-right,
    .datagrid-header-cell.stick-left ,
    .datagrid-header-cell.stick-right {
      position: static;
      box-shadow: inherit;
      background-color: inherit;
    }

  }
}
