.wechat-article-preview-app {
    width: 100%;

    height: 100%;
    display: flex;
    flex-direction: column;

    .wechat-article-preview-app-head {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 6px 4px;
        .ficon {
            color: inherit;
            font-weight: bold;
            font-size: 150%;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 4px;
            user-select: none;

            &:hover {
                box-shadow: inset 0 0 1000px 1000px #00000011;
            }
        }

        .wechat-article-preview-app-head-back {
            display: flex;
            align-items: center;
        }

        .wechat-article-preview-app-head-center {
            display: flex;
            align-items: center;
            flex: 1;
            font-weight: normal;
            text-align: center;
            justify-content: center;
            font-size: 12pt;
        }

        .wechat-article-preview-app-head-right {
            display: flex;
            align-items: center;
        }
    }

    .wechat-article-preview-app-body {
        flex: 1;
        padding: 8px;
    }
}

.wechat-article-preview-coupon {
    user-select: none;
    position: relative;

    .coupon-logo {
        width: 48px;
        height: 48px;
        position: absolute;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #fff;
        top: -24px;
        left: 50%;
        transform: translate(-50%, 0);
        border: 1px solid #f2f2f2;
    }

    .coupon-brand {
        font-size: 10pt;
        text-align: center;
        margin-top: 32px;
    }

    .coupon-title {
        font-size: 16pt;
        text-align: center;
        margin-bottom: 16px;
    }

    .coupon-description {
        font-size: 10pt;
        display: none;
        text-align: center;
    }

    .coupon-button {
        font-size: 12pt;
        padding: 10px 16px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 16px;
        color: #fff;
        width: 50%;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }
    }

    .coupon-subtitle {
        font-size: 10pt;
        text-align: center;
        margin-bottom: 4px;
    }

    .coupon-date {
        font-size: 8pt;
        text-align: center;
        margin-bottom: 16px;
    }

    .coupon-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #eee;
        min-height: 120px;
        position: relative;

        .coupon-abstract {
            position: absolute;
            bottom: 0;
            background-color: #00000055;
            color: #fff;
        }
    }

    .coupon-list-bottom {
        background-color: #f2f2f2;
        border-radius: 0 0 4px 4px;
    }

    .coupon-list-item {
        padding: 16px 16px;
        margin: 0 0;
        border-bottom: 1px solid #e7e7e7;
        display: grid;
        grid-gap: 0;
        gap: 0;
        grid-template-columns: 1fr 16px;
        grid-template-rows: auto;
        grid-template-areas:
            'text icon'
            'desc icon';
        cursor: pointer;

        &:last-child {
            border-bottom: 0 none;
        }

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }

        .coupon-list-item-text {
            text-align: left;
            grid-area: text;
        }

        .coupon-list-item-desc {
            grid-area: desc;
        }

        .coupon-list-item-arrow {
            grid-area: icon;
            font-size: 16px;
            display: flex;
            align-items: center;
            align-self: flex-start;
        }
    }
}

.wechat-article-preview-app.coupon-details .wechat-article-preview-app-body {
    padding: 16px 12px;

    .coupon-detail-title {
        font-weight: bold;
        padding: 4px 2px;
        border-bottom: 1px solid #eee;
        margin-bottom: 8px;
    }

    .coupon-detail-item {
        padding: 6px 0;
        font-size: 9pt;
        display: flex;

        .coupon-detail-label {
            opacity: 0.5;
            width: 25%;
        }

        .coupon-detail-value {
        }
    }
}

.wx-article-title {
    font-size: 150%;
    padding: 8px 0;
    word-wrap: break-word;
}

.wx-article-tag {
    background-color: #00000022;
    display: inline-block;
    padding: 3px 6px;
    font-size: 80%;
    border-radius: 3px;
    opacity: 0.5;
}

.wx-article-company {
    display: inline-block;
    // padding: 0 4px;
    opacity: 0.5;
}

.wx-article-brand {
    display: inline-block;
    color: #0059ff88;
    padding: 0 4px;
}

.wx-article-date-location {
    opacity: 0.5;
}

#article-preview-mobile {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    word-break: break-all;
    text-align: left;
    width: 100%;
    font-size: 110%;

    * {
        max-width: 100% !important;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        letter-spacing: 0.1px;
    }

    table {
    }

    table {
        th {
            background-color: #eee;
            padding: 8px;
        }

        td {
            padding: 8px;
            margin: 0;
            border: 1px solid #ddd;
        }
    }

    h2 {
        font-size: 100%;
        font-weight: normal;
        margin: 4px 0;
    }

    a {
        text-decoration: none;
    }

    code {
        background-color: #fff;
        font-family: 'Courier New', Courier, monospace;
    }

    .editor-column-cell {
        border: none !important;
    }
}
.wx-article-blur {
    height: 500px;
    overflow: hidden;
    position: relative;
}

.wx-article-custom-read-more-blur {
    content: ' ';
    height: 500px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 84%);
    background: -webkit-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 84%
    );
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 84%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.wx-article-custom-read-more {
    color: #fff;
    border-radius: 50px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    display: block;
    width: 70%;
    margin-left: 15%;
    margin-top: -100px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.4px;
    position: relative;
    z-index: 1;
    margin-bottom: 100px;
    user-select: none;
    text-decoration: none;

    &.white,
    &.yellow,
    &.silver {
        color: #444;
    }
}

.wx-article-mentions {
    margin: 16px 0;
    padding: 8px;
    background-color: #00000011;
    border-radius: 8px;
    opacity: 0.7;

    .wx-article-mention-title {
        padding: 4px;
    }

    .wx-article-mention-items {
        .wx-article-mention-item {
            padding: 4px;
            display: flex;
            flex-direction: row;

            a {
                flex: 1;
                color: #0059ff88;
            }

            .wx-article-mention-count {
            }
        }
    }
}
.wx-article-footer {
    .footer-box {
        margin: 16px 0;
        padding: 8px;
        background-color: #00000011;
        border-radius: 8px;
        opacity: 0.8;
    }

    .footer-mentions {
        padding: 8px;
        display: flex;
        .footer-mention {
            a {
                color: #0059ff88;
            }
        }
    }
    .footer-prev-next {
        display: flex;

        .footer-prev {
            cursor: pointer;
            padding: 8px;
            display: flex;
            flex: 1;

            .ficon {
                font-size: 140%;
            }
        }

        .footer-next {
            cursor: pointer;
            padding: 8px;
            display: flex;
            flex: 1;
            text-align: right;

            .ficon {
                font-size: 140%;
            }
        }
    }

    .footer-read-more {
        padding: 8px;
        color: #0059ff88;
        display: inline-block;
    }

    .footer-read-info {
        display: inline-block;
        padding: 0 8px;
    }

    .footer-buttons {
        display: flex;

        .footer-share,
        .footer-fav,
        .footer-like,
        .footer-addtop {
            display: flex;
            flex-direction: column;
            font-size: 80%;
            align-items: center;
            justify-content: center;
            padding: 8px;
            .ficon {
                font-size: 200%;
            }
        }
        .footer-placeholder {
            flex: 1;
        }
    }

    .footer-advertisement {
        user-select: none;
        pointer-events: none;
        opacity: 0.4;
        width: 100%;
        height: 250px;
        background-color: #ddd;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        color: #888;
        text-align: center;
        font-size: 160%;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 16px 0;

        img {
            width: 80%;
            margin-bottom: 20px;
        }

        .footer-advertisement-watermark {
            position: absolute;
            width: 100%;
            height: 100%;
            color: #666;
            opacity: 0.5;
            top: 5%;
            // text-shadow: 0 0 4px #000;
        }
    }
}
.wx-article-comments {
}
