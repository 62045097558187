.popover-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000005;
    z-index: 102;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    animation: openpopoverbg 0.2s;
    animation-iteration-count: 1;
}

.popover-wrapper.blur {
    backdrop-filter: blur(5px);
}

.popover-wrapper.centered .popover:before {
    display: none;
    content: '';
}

.popover {
    background-color: transparent;
    position: relative;
    z-index: 101;
    padding: 16px 0;
    visibility: hidden;
}

.popover .popover-content {
    min-width: 100px;
    min-height: 30px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px,
        rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #00000011;
    z-index: 1;
}

.popover .popover-content .arrow {
    // content: ' ';
    position: absolute;
    top: -6px;
    left: 25px;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: rotate(45deg);
    border-top: 1px solid #00000011;
    border-left: 1px solid #00000011;
    z-index: -1;
}

.popover .popover-content.direction-up .arrow {
    top: auto;
    bottom: -6px;
    border-top: 0 none;
    border-left: 0 none;
    border-bottom: 1px solid #00000011;
    border-right: 1px solid #00000011;
}

@keyframes openpopoverbg {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes openpopover {
    0% {
        opacity: 0;
        transform: translateY(-100px) scale(0);
    }
    20% {
        opacity: 0;
        transform: translateY(-100px) scale(0);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}


@keyframes openpopovercontent {
   
    95% {
       visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

.popover-shower {
    display: none;
}

.popover.opened {
    visibility: visible; 
    animation: openpopover 0.2s; 
    animation-iteration-count: 1;
    display: flex;
}

.popover.opened .popover-content{ 
    animation: openpopovercontent 0.3s; 
    animation-iteration-count: 1;
    
}
