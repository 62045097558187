.view-page.has-leftbar.has-rightbar {
    grid-template-columns: 280px 1fr auto;
    grid-template-areas:
        'mssg   mssg mssg'
        'header header header'
        'leftbar info  rightbar'
        'footer  footer footer';
}

.view-page.has-leftbar:not(.has-rightbar) {
    grid-template-columns: 280px 1fr auto;
    grid-template-areas:
        'mssg   mssg'
        'header header'
        'leftbar info'
        'footer  footer';
}

.view-page:not(.has-leftbar).has-rightbar {
    grid-template-columns: 1fr auto;
    grid-template-areas:
        ' mssg mssg'
        ' header header'
        ' info  rightbar'
        ' footer footer';
}

.view-page:not(.has-leftbar):not(.has-rightbar) {
    grid-template-columns: 1fr;
    grid-template-areas:
        ' mssg'
        ' header'
        ' info'
        ' footer';
}

.view-page {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
        ' mssg mssg'
        ' header header'
        ' info  rightbar'
        ' footer footer';

    .deleted-message {
        grid-area: mssg;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0;
        color: #bf0a0a;

        .button-wrapper {
            margin: 0 16px;
        }
    }

    .view-page-header {
        grid-area: header;
        align-items: center;
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: 3fr 8fr;
        grid-template-rows: auto;
        grid-template-areas: 'title right';
        border-bottom: 1px solid #ddd;
        margin-bottom: 8px;
        padding-bottom: 8px;

        .title {
            font-size: 180%;
            grid-area: title;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            .iconbutton {
                margin-right: 8px;
            }

            span {
                white-space: nowrap;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            }
        }

        .right-side {
            grid-area: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > * {
                margin-left: 8px;
            }
        }
    }

    .info {
        grid-area: info;
    }

    .leftbar {
        grid-area: leftbar;
    }

    .rightbar {
        grid-area: rightbar;
    }

    .main-content {
        display: grid;
        padding-bottom: 32px;
        grid-gap: 16px;
        gap: 16px;

        align-items: center;

        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'name'
            'desc';
    }




    .loading-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 300px;
        left: 0px;
        top:100px;
        position: absolute;
        .ficon {
            margin-bottom: 32px;
            font-size: 500%;
            animation: rotate-loading-icon 400ms linear;
            animation-iteration-count: infinite;
        }

        .loading-content-title {
            font-size: 250%;
            margin-bottom: 32px;
        }

        .loading-content-desc {
            text-align: center;
        }

        @keyframes rotate-loading-icon {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(359deg);
            }
        }
    }

}

.view-page {
    width: 100%;
    max-width: 1200px;
}

.view-page-header {
    display: grid;
    grid: 1fr / 1fr 1fr 1fr;

    .title {
        width: 100%;
        font-size: 180%;
    }

    .right-side {
    }

    .actions {
    }
}

.view-page-footer {
    grid-area: footer;
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 'fmain fmain fmain fright';

    .view-page-footer-main {
        grid-area: fmain;
        display: flex;
        align-items: center;
        gap: 8px;
        grid-gap: 8px;
        padding: 8px;
    }

    .view-page-footer-right {
        grid-area: fright;
    }
}

.view-page.has-leftbar .view-page-footer {
    grid-template-columns: 280px 1fr 1fr 2fr;
    grid-template-areas: 'fleft fmain fmain fright';
}

.view-page:not(.has-leftbar) .view-page-footer {
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-template-areas: 'fmain fmain fmain fright';
}

.view-page-panel-openers {
    display: none;
}

.view-page-panel-openers-bg {
    display: none;
}

@media only screen and (max-width: 768px) {
    .view-page.has-leftbar:not(.has-rightbar),
    .view-page.has-rightbar:not(.has-leftbar),
    .view-page.has-rightbar.has-leftbar {
        grid-template-columns: 1fr;
        grid-gap: 8px;
        gap: 8px;
        grid-template-areas:
            'mssg'
            'header'
            'mobiletabs'
            'leftbar'
            'info'
            'rightbar'
            'footer';
    }

    .view-page.has-leftbar .view-page-footer,
    .view-page:not(.has-leftbar) .view-page-footer {
        grid-template-columns: 1fr;
        grid-template-areas:
            'fleft'
            'fmain'
            'fright';
    }

    .view-page-panel-opener-left,
    .view-page-panel-opener-right {
        display: none;
    }

    .view-page.has-leftbar {
        .view-page-panel-opener-left {
            display: flex;
            align-items: center;
        }
    }

    .view-page.has-rightbar {
        .view-page-panel-opener-right {
            display: flex;
            align-items: center;
        }
    }

    .view-page-panel-openers {
        display: flex;
        grid-area: mobiletabs;
        width: 100%;

        .view-page-panel-opener-placeholder {
            flex: 1;
        }

        .view-page-tab {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .view-page-panel-openers-bg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10;
        position: fixed;
        background-color: #00000011;
        pointer-events: all;
        display: none;
    }

    .view-page.leftbar-open,
    .view-page.rightbar-open {
        .view-page-panel-openers-bg {
            display: block;
        }
    }

    .view-page {
        grid-gap: 0 !important;
        gap: 0 !important;

        .view-page-header {
            grid-area: header;
            align-items: center;
            display: flex;
            grid-gap: 0;
            gap: 0;
            border-bottom: 1px solid #ddd;
            margin-bottom: 8px;
            padding-bottom: 8px;
        }

        .title {
            width: 100%;
            font-size: 140% !important;
            max-width: calc(100% - 135px);

            span {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .right-side {
            .button {
                min-width: initial;
                width: 32px;
                height: 32px;
                margin-left: 4px;
                border-radius: 0;

                .ficon {
                    margin-right: 0;
                }

                span {
                    display: none;
                }
            }

            .button-wrapper {
                margin-right: 0;
            }
        }
    }
}
