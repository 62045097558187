.wizard-page{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  background-color: #fff; 
  min-height: calc(100vh - 55px);
box-sizing: border-box;
  justify-content: flex-start; 
}
.wizard-wrapper{
  display: flex;
  flex-direction: column;  
  //  width: 100%;

   width: 1090px; 
   padding-bottom: 70px;
}
.wizard-content{
  width: 100%;
  display: flex;
  flex-direction: column; 
  background-color: #fff;
  min-height: 300px; 
  padding: 16px;
  border-radius: 0px 0px 4px 4px;
}
.wizard-steps{
  width: 100%; 
  border-bottom: 1px solid #eee;
  // border-radius: 4px 4px 0px 0px;
  background-color: #fff;
}

.wizard-buttons-inner{
   
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
  width: 1090px;
}
.wizard-buttons{ 
  position: fixed;
  bottom:0px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  padding-bottom: 8px;
  background-color: #fff;
  border-top: 1px solid #eee;
  box-shadow: 0px 0px 30px 0px #00000011;
  .next-button{ 
    input{
      padding: 8px 32px;
    }
  }
  .back-button{ 
    input{
      padding: 8px 32px;
    }
  }
  .skip-button{
    margin-right: 16px;
    input{
      padding: 8px 32px;
    }
  }
}

.wizard-buttons-placeholder{
  flex:1;
}


.register-title{
  font-size:120%;
  padding: 24px 32px;
  padding-bottom: 8px;
  font-weight: bold;
}

.wizard-steps-inner{

  display: flex;
  align-items: center; 
  padding-top: 16px;
  width: 1090px;
}

.wizard-steps{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px; 
  .wizard-step{
    display: flex;
    align-items: center;
    font-size: 90%;
    flex-direction: column;
    position: relative; 
    flex:1;
    text-align: center;
    user-select: none;
    padding: 8px;

    .wizard-step-point{ 
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      display: block; 
      width: 20px;
      height: 20px;
      background-color: #fff;
      border:3px solid #666; 
      border-radius: 4px;

        &:after{ 
          content: ' ';
          width: calc( 100% - 15px);
          height: 0px;
          left: 0px;
          transform: translateX(50%);
          margin-left: 15px;
          top:17px;
          position: absolute; 
          border-top:3px dashed #666;
          box-sizing: border-box; 
        }


        &:before{ 
          content: '✔'; 
          font-weight: bold;
          font-size: 80%;
          color: #fff;  
          line-height: 8px;  
          text-align: center;
          margin-top: 3px;
          width: 100%;
          display: none;
        }
        
 
    }
    &:last-child .wizard-step-point:after{
      display: none;
    }

    &.active .wizard-step-point{
      border-style: solid;
      background-color: #fff;
      border-color: #1b51c6;
      border-radius: 50%;
      &:after{
        border-top-style: dashed;
      }
    }

    &.end  {
      .wizard-step-text{ 
        color: #103ea2;
        font-size: 150%;
      } 
      .wizard-step-point{
        border-style: solid;
        border-radius: 50%;
        background-color: #1b51c6;
        border-color: #1b51c6;
        box-shadow: 0px 0px 0px 4px #184bb7;
        &:after{
          border-top-style: solid;
          border-color: #1b51c6;
        }
        &:before{ 
          display: block;
          font-size: 120%;
        }
      } 
    }

    &.done {

        .wizard-step-text{ 
          color: #103ea2;
        }

          .wizard-step-point{
            border-style: solid;
            border-radius: 50%;
            background-color: #1b51c6;
            border-color: #1b51c6;
            &:after{
              border-top-style: solid;
              border-color: #1b51c6;
            }
            &:before{ 
              display: block;
            }
          } 
    }




    &.skipped { 
      .wizard-step-text{
        opacity: 0.5;
      }
        .wizard-step-point{
          border-style:solid;
          border-radius: 36%; 
          background-color: #bbb;
          border-color: #1b51c6; 
          &:after{
            border-top-style: solid;
            border-color: #1b51c6;
          } 
        } 
    }

    .wizard-step-text{
      height: 26px; 
    }




  }

 
}
 


.wizard-steps.sub-steps{
   background-color: #e2e2e2;
   border-radius: 0px;
   border-top: 0px none;
   padding: 8px;
   padding-bottom: 0px;
   opacity: 0.8;
   .wizard-step-text{ 
   }
   :before{}
}