.edit-customer {
    .info > .main-content {
        padding-bottom: 0;
        grid-gap: 0;
        gap: 0;
    }

    .heading-wrapper {
        display: flex;
        align-items: center;

        .iconbutton {
            font-size: 130%;
            margin-left: 4px;
        }
    }

    .customer-form {
        .main-content {
            display: grid;
            grid-gap: 16px;
            gap: 16px;
            align-items: center;
            grid-template-columns: 1fr 1fr 1fr 1fr 140px;
            grid-template-rows: auto;
            grid-template-areas:
                'name name name name photo'
                'desc desc desc desc photo'
                'mobi mobi mobi mobi photo'
                'mail mail mail mail photo'
                'gend gend gend gend gend'
                'location location location location location'
                'lang lang lang lang lang';

            .name {
                grid-area: name;
            }

            .profile-photo {
                align-self: flex-start;
                grid-area: photo;
                width: 140px;
                height: 140px;
                background-color: #eee;
                border-radius: 50%;
                position: relative;
                user-select: none;

                img {
                    width: 100%;
                    height: 100%;
                }

                .profile-photo-upload {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    cursor: pointer;
                    border-radius: 50%;

                    .ficon {
                        color: inherit;
                    }
                }

                .profile-photo-upload:active {
                    box-shadow: inset 0 0 1000px 1000px #00000022;
                }

                &.empty .profile-photo-upload {
                    border: 1px solid #ddd;
                }

                &.empty .profile-photo-upload,
                &:hover .profile-photo-upload {
                    display: flex;
                }

                &:not(.empty):hover .profile-photo-upload {
                    color: #fff;
                    background-color: #00000055;
                }
            }

            .mobile-number {
                grid-area: mobi;
                width: 100%;

                .mobile-wrapper {
                    display: flex;
                    flex-direction: row;
                    width: 100%;

                    .area-select {
                        min-width: 50%;
                    }
                }
            }

            .email {
                grid-area: mail;
            }

            .location {
                grid-area: location;

                .multi-field-item {
                    flex-direction: row;
                }

                .country {
                    grid-area: coun;
                }

                .city {
                    grid-area: city;
                }

                .province {
                    margin: 0 16px;
                    grid-area: prov;
                }
            }

            .language {
                grid-area: lang;
            }

            .description {
                grid-area: desc;
            }

            .gender {
                width: 100%;
                grid-area: gend;

                > .label {
                    color: #546e7a;
                    font-weight: 500;
                    padding: 0;
                    margin-right: 16px;
                    display: block;
                }

                > .check {
                    padding: 8px 8px 8px 0;
                }
            }

            .multi-field-list {
                display: flex;
                width: 100%;
                flex-direction: column;

                .multi-field-item {
                    display: flex;
                    width: 100%;
                    margin-bottom: 16px;

                    .remove-field {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        min-width: 40px;
                        min-height: 40px;
                        margin-left: 8px;
                        font-size: 140%;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                .button-wrapper {
                    align-self: flex-start;
                    margin-bottom: 16px;
                }
            }
        }

        .social-media-permission {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: flex-start;
            padding: 8px 0;
            color: #546e7a;

            .desc {
                color: #546e7ac3;
                padding: 0;
                font-size: 90%;
            }
        }

        .tag-select {
            .value {
                padding: 0 0 8px 0;
            }

            .search-input {
                width: 100%;
                margin: 6px 0;
            }
        }
    }

    .rightbar.sidebar {
        width: 420px;

        .card-header {
            margin-bottom: 0;
        }

        .segments {
            margin-top: 16px;
        }
    }
}

.attribute-component {
    margin-bottom: 8px;
}

@media (max-width: 768px) {
    .edit-customer.edit-page .main-content {
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'photo'
            'name'
            'description'
            'mobi'
            'mail'
            'gend'
            'location'
            'lang'
            'desc';

        .profile-photo {
            width: 200px;
            height: 200px;
            justify-self: center;
        }

        .location {
            .multi-field-item {
                flex-direction: column;
            }

            .province {
                margin: 16px 0;
            }

            .city {
                margin-bottom: 8px;
            }

            .remove-field {
                width: 100%;
                margin: 0 !important;
            }
        }

        .mobile-number {
            flex-direction: column;

            .multi-field-item {
                flex-direction: column;

                .mobile-wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .area-select {
                        width: 100%;
                        margin-bottom: 16px;
                    }

                    .input {
                        width: 100%;
                        margin-bottom: 8px;
                    }
                }

                .remove-field {
                    width: 100%;
                    margin: 0 !important;
                }
            }
        }
    }

    .edit-customer {
        .edit-page-panel-openers {
            display: flex;
        }
    }

    .edit-customer .social-media-permission {
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile-number {
        display: flex;
        flex-direction: row;
        align-items: center;

        .dropdown {
            flex: 1;
            margin-right: 8px;
        }

        .input {
            flex: 2;
        }
    }

    .edit-customer {
        &:not(.has-leftbar) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas:
                ' mssg'
                ' header'
                ' mobiletabs'
                ' info'
                ' selector'
                ' footer';

            .edit-page-panel-openers {
                display: flex;

                .edit-page-panel-opener-left {
                    display: none;
                }

                .edit-page-panel-opener-right {
                    display: flex;
                    align-items: center;
                }
            }

            &.rightbar-open {
                .rightbar {
                    display: block;
                }

                .edit-page-panel-openers-bg {
                    display: block !important;
                }
            }
        }
    }
}

@media (max-width: 968px) {
    .rightbar.sidebar {
        max-width: 300px;
    }

    .page-footer {
        display: none;
    }
}
