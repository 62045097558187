 .learn-page {
   display: grid;
   grid-gap: 16px;
   gap: 16px;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto;
   grid-template-areas:
     ' mssg mssg'
     ' header header'
     ' content content'
     ' footer footer';

   .learn-page-header {
     grid-area: header;
     align-items: center;
     display: grid;
     grid-gap: 16px;
     gap: 16px;
     grid-template-columns: 3fr 8fr;
     grid-template-rows: auto;
     grid-template-areas: 'title right';
     border-bottom: 1px solid #ddd;
     margin-bottom: 8px;
     padding-bottom: 8px;

     .title {
       font-size: 100%;
       grid-area: title;
       display: flex;
       align-items: center;
       opacity: 0.9;

       .iconbutton {
         margin-right: 4px;
         font-size: 130%;
       }

       span {
         white-space: nowrap;
       }
     }

     .right-side {
       grid-area: right;
       display: flex;
       justify-content: flex-end;
       align-items: center;

       >* {
         margin-left: 8px;
       }
     }
   }

   .learn-page-content {
     grid-area: content;
   }

   code {
     margin-top: 4px;
     margin-bottom: 4px;
     font-family: Menlo, Monaco, "Courier New", monospace !important;
     background: #eee;
     padding: 8px 8px;
     line-height: 16px;
     white-space: pre-wrap;
     display: block;
     font-size: 12px;
   }

   .editor   {
   border-radius: 4px;
   overflow: hidden;
   background-color: #111;
  }
   .editor * {
     font-family: Menlo, Monaco, "Courier New", monospace !important;
   }

   .readonly-editor {
     user-select: none;
     pointer-events: none;
   }
 }