.simple-page-editor{

  width: 100%;
  
  .content-types{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(5, 1fr);
    // flex-direction: row;
    // flex-wrap: wrap;
    width: 100%;

    .content-type-button{
     
      display: flex;
      flex-direction: column;
      cursor: pointer;
      flex: 1;
      align-items: center;
      padding: 8px 0px;
      border: 1px solid #eee;
      flex:1;
      font-size: 80%;
      text-align: center;
      justify-content: flex-start;
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000011;
      }
      .ficon{
        font-size: 150%;
        margin-bottom: 4px;
      }
    }
  }
  .page-content{
    display: flex;
    flex-direction: column;

    .content-item{
      padding: 8px;
      position: relative;
      border:1px dotted #bbb;
      margin-top: 10px;
      margin-bottom: 10px; 
      .content-item-placeholder{
        position: absolute;
        width:100px; 
        top:-16px;
        left:16px; 
        font-weight: bold;
        // background-color: #ddd;
        color:#00000066;
        // text-shadow: 0px 0px 10px #000;
        // box-shadow: inset 0px 0px 200px 10px #00000022;
        font-size: 120%;
        z-index: 1;
        display: none;
      }
      img, video{ 
        width: 100%;
      }
      .desc{
        position: absolute;
        bottom:0px;
        left:0px;
        background-color: #00000099;
        color:#fff;
        padding: 8px;
        width: 100%;
        height: auto;
      }
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000022;
        img, video{
          opacity: 0.8; 
        }
        .page-content-actions{
          display: flex;
        }
        .content-item-placeholder{
          display: flex;
        }
        
      }
    }
    .content-item.type-image{
        img{
          width:100%; 
        }
    }
  }
  
}


.simple-page-editor .page-content .content-item .content-types{
  width: 100%;
  flex-wrap: wrap;
  grid-template-columns:auto;
  display: flex;
  .content-type-button{
     display: flex;
     align-items: center;
     justify-content: center;
    padding: 2px 0px;  
    flex:none;
    width: 28px;
    height: 28px; 
    border:none;
    border-radius: 50%;
    background-color: #eee;
    margin: 2px;
    .content-item-label{
      display: none;
    }
    .ficon{
      font-size: 150%; 
      margin:0px;
    }
  }
}

.simple-page-editor .page-content .page-content-actions{
  display: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: absolute;
  top:0%;
  left:50%;
  transform: translate(-50%, -70%);
  // width: auto;
   background-color: #ddd;
   border: 1px solid #ddd;
   box-sizing: content-box;
   padding-top: 0px;
  .ficon{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#fff;
    font-weight: bold;
    font-size: 120%;
  }

  .page-content-action{
    cursor: pointer;
    padding: 6px;
    background-color: #00000088;
    border-radius: 50%;
    margin: 6px 4px;
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000011;
    }
  }
}


.edit-page-editor-textarea{
  width: 100%;
  min-height: 200px;
}


.column-item{
  position: relative;
}
.column-item-remove{
  width:24px;
  height: 24px;
  font-weight: bold;
  cursor: pointer;
  background-color: #00000099;
  color:#fff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:50%;
  left:50%;
  margin-top: -12px;
  margin-left: -12px;
}

.simple-page-editor.disabled{
  user-select: none;
  pointer-events: none; 
  background-color: #eee;
  padding: 8px; 
  .content-type-button{
    opacity: 0.5;
  }

}