 


.materials .material-list{
    
    display: flex;
    flex-wrap: wrap;
    overflow: auto; 
    flex: 1;
    padding-top: 8px;

    .material-item{
        width: 19%;
        margin:0.5%;
        padding: 12px;
        min-width: 160px;
        cursor: pointer;

        font-size: 100%;
        text-align: center;
        border-radius: 4px;
        position: relative;
        border:1px solid transparent;
        &.selected {
            border:1px solid #2994ff90;
        }
        &.selected,  &.selected .material-item-image{
            box-shadow: inset 0px 0px 1000px 1000px #1c91ff23;

        }
        &:hover{
            box-shadow: inset 0px 0px 1000px 10000px #00000011;
        }
       
        .material-item-image{
            width: 100%;
            min-width: 160px;
            height: 160px; 
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f2f2f2;
            border-radius: 4px;
            padding: 4px;
            position: relative;
            img{
                max-width: 100%;
                max-height: 100%;
            }

            .play-icon{
                position: absolute;
                font-size: 40px;
                background-color: #ffffffaa;
                opacity: 0.6;
                border-radius: 50%;
                width:40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                left:50%;
                top:50%;
                transform: translate(-50%, -50%);
                display: none;
            }
            .file-icon{
                position: absolute; 
                background-color: #000000aa;
                opacity: 0.8;
                border-radius: 5px;
                width:60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                left:50%;
                top:50%;
                transform: translate(-50%, -50%);
                padding: 8px;
                display: none;
            }
        }

        &.video  .material-item-image .play-icon{
            display: block;
        }

        &:not(.video):not(.image)  .material-item-image .file-icon{
            display: block;
        }

        .material-item-name{
            padding: 6px 0px;
        }
        .material-item-tags{
             margin-bottom: 2px;
            .material-item-tag{
                display: inline-block;
                font-size: 80%;
                padding: 2px 8px; 
                background-color: #00000011;
                display: inline-block;
                border-radius: 3px;
                margin: 2px;
                opacity: 0.8;
            }
        }
        .material-item-type{
        }
        .material-item-date{
            font-size: 80%; 
            opacity: 0.5;
        }
    }


 

 


}




 .material-filter-panel{
    display:flex;
    flex-direction:row;
    padding:8px;
    border-bottom:1px solid #eee;
    &>*{
        margin:0px 4px;
    }
    
    .filter-material-public{
        min-width: 150px; 
    }
  } 

  .mobile-session-opener{
    display: none;
  }
 
@media screen and (max-width: 768px) {

    .mobile-session-opener{
        position: absolute;
        top:8px;
        left:8px;
        z-index: 1;
        display: block;
    }

    .app.ai {
       
    }
    .app.ai .page {
        position: relative;
        overflow: hidden;
    }
    .app.ai .page .tab-header{
        margin-left: 44px;
    }

        .materials .material-list{
           

            .material-item{ 
                margin:0.5%;
                padding: 2px;
                width: 49%; 
                &:hover{
                    box-shadow: none;
                    background-color: inherit;
                }
            }
        }

}