.orverview-card-world-map{
    width: 100%;
    height: 100%; 
    .placeholder-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        left:0px;
        top:0;
        z-index: 11;
        background: rgb(255,255,255, 0.5);
        background: linear-gradient(1deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 100%);
       
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-shadow: 1px 1px #00000022;


        .placeholder-overlay-title{
            font-size: 160%;
            font-weight: 500;
            padding: 8px;
            color:#00000099;
           
        }
        .placeholder-overlay-text{
            padding: 8px;
            color:#00000077;
        }
        .placeholder-overlay-button{
            margin-top: 8px;
        }
    }


}
.world-map-overview-card {
    .card-content:has(.empty-state) {
        .empty-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 300px;

            .empty-state-icon {
                font-size: 40px;
                opacity: 0.3;
                margin-bottom: 8px;
            }
        }
    }

    &:has(.wizard__overlay) {
        .wizard__mocked-overlay {
            z-index: 11;
        }

        .wizard__overlay {
            z-index: 12;
        }
    }
}


