.wechat-mcard-preview-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .wechat-mcard-preview-app-head {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 6px 4px;

        .ficon {
            color: inherit;
            font-weight: bold;
            font-size: 150%;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 4px;
            user-select: none;

            &:hover {
                box-shadow: inset 0 0 1000px 1000px #00000011;
            }
        }

        .wechat-mcard-preview-app-head-back {
            display: flex;
            align-items: center;
        }

        .wechat-mcard-preview-app-head-center {
            display: flex;
            align-items: center;
            flex: 1;
            font-weight: normal;
            text-align: center;
            justify-content: center;
            font-size: 12pt;
        }

        .wechat-mcard-preview-app-head-right {
            display: flex;
            align-items: center;
        }
    }

    .wechat-mcard-preview-app-body {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.mcard-card {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 30%, rgba(255, 255, 255, 0) 90%);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 12px;
    border-radius: 12px;
    padding: 16px;
    position: relative;
    display: grid;
    grid-gap: 4px 8px;
    gap: 4px 8px;
    grid-template-columns: 36px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'logo brand'
        'logo title'
        'placeholder placeholder'
        'cardno cardno';

    .mcard-logo {
        grid-area: logo;
    }

    .mcard-brand {
        grid-area: brand;
    }

    .mcard-title {
        grid-area: title;
    }

    .mcard-placeholder {
        grid-area: placeholder;
    }

    .mcard-no {
        grid-area: cardno;
    }
}

.mcard-logo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: transparent;
    border: 1px solid #fff;
}

.mcard-brand {
    font-size: 11pt;
    align-self: flex-end;
    color: #fff;
}

.mcard-title {
    font-size: 8pt;
    align-self: flex-start;
    color: #fff;
}

.mcard-placeholder {
    min-height: 94px;
}

.mcard-no {
    font-size: 12pt;
    color: #fff;
}

.mcard-loyalty-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 8px;
}
.mcard-cfield {
}

.mcard-cfield {
    flex: 1;
    font-size: 10pt;
    padding: 10px 0;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 16px;
    color: #fff;
    width: 50%;
    border-right: 1px solid #eee;
    border-radius: 0;
    cursor: pointer;

    &:last-child {
        border: 0 none;
    }

    &:hover {
        box-shadow: inset 0 0 1000px 1000px #00000011;
    }

    .mcard-cfield-title {
        font-size: 80%;
        color: #00000099;
        margin-bottom: 8px;
    }

    .mcard-cfield-value {
        font-size: 100%;
        color: green;
    }
}
.mcard-loyalty {
    font-size: 12pt;
    padding: 10px 16px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 16px;
    color: #fff;
    width: 50%;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        box-shadow: inset 0 0 1000px 1000px #00000011;
    }
}

.mcard-subtitle {
    font-size: 10pt;
    text-align: center;
    margin-bottom: 4px;
}

.mcard-date {
    font-size: 8pt;
    text-align: center;
    margin-bottom: 16px;
}

.mcard-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #eee;
    min-height: 120px;
    position: relative;

    .mcard-abstract {
        position: absolute;
        bottom: 0;
        background-color: #00000055;
        color: #fff;
    }
}

.mcard-list-bottom {
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    margin-top: 16px;
}

.mcard-list-item {
    padding: 16px 16px;
    margin: 0 0;
    border-bottom: 1px solid #e7e7e7;
    display: grid;
    grid-gap: 0;
    gap: 0;
    grid-template-columns: 1fr auto 16px;
    grid-template-rows: auto;
    grid-template-areas:
        'text tips icon'
        'desc tips icon';
    align-items: center;
    cursor: pointer;

    &:last-child {
        border-bottom: 0 none;
    }

    &:hover {
        box-shadow: inset 0 0 1000px 1000px #00000011;
    }

    .mcard-list-item-text {
        text-align: left;
        grid-area: text;
    }

    .mcard-list-item-desc {
        grid-area: desc;
    }

    .mcard-list-item-tips {
        grid-area: tips;
        justify-self: flex-end;
        text-align: right;
        font-size: 80%;
    }

    .mcard-list-item-arrow {
        grid-area: icon;
        font-size: 16px;
        display: flex;
        align-items: center;
        align-self: flex-start;
    }
}

.center-button {
    font-size: 12pt;
    padding: 8px 16px;
    margin: 16px auto;
    text-align: center;
    color: green;
    max-width: 70%;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid green;
    align-self: center;
    justify-self: center;

    &:hover {
        box-shadow: inset 0 0 1000px 1000px #00000011;
    }
}

.center-subtitle {
    text-align: center;
    padding: 8px;
}

.wechat-mcard-preview-app.mcard-details .wechat-mcard-preview-app-body {
    padding: 16px 12px;

    .mcard-detail-title {
        font-weight: bold;
        padding: 4px 2px;
        border-bottom: 1px solid #eee;
        margin-bottom: 8px;
    }

    .mcard-detail-images {
        .mcard-detail-image {
            img {
                width: 100%;
            }
        }
    }

    .mcard-detail-item {
        padding: 6px 2px;
        font-size: 9pt;
        display: flex;

        .mcard-detail-label {
            opacity: 0.5;
            width: 25%;
        }

        .mcard-detail-value {
        }
    }
}

.mcard-qrcode {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 10;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 24px;
    height: 24px;
    width: 24px;

    .ficon {
        font-size: 24px;
    }
}
