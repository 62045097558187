.column-visibility{

  display: flex; 
  flex-direction: column; 
  justify-content: center; 
 padding: 8px;
}

.column-visibility > .tab-header-wrapper .tab-header{
  margin: 0px !important;
  margin-bottom: 16px !important;
}
.column-visibility-empty{
  min-height: 400px;
}

.column-visibility .column-visibility-row-head { 
  display: flex;
  align-items: center;
  flex-direction: row; 
  font-weight: bold;  

 }
.column-visibility .column-visibility-row-head .column-visibility-head-name{
  width: 180px;
}
.column-visibility .column-visibility-row-head .column-visibility-head-show{
  width: 120px;
  padding: 8px; 
} 
.column-visibility .column-visibility-row-head .column-visibility-head-priority{
  padding: 8px;
  width: 200px;  
} 

.column-visibility .column-visibility-row{
  display: flex;
  align-items: center;
  flex-direction: row; 

  align-items: center;
  justify-content: center; 
  border-bottom: 1px solid #eee; 

}
 
 
.column-visibility .column-visibility-row>*{
  padding: 8px 8px;
}
.column-visibility .column-visibility-name{ 
  width:180px; 
}

.column-visibility .column-visibility-show{ 
  width:120px; 
}

.column-visibility .column-visibility-priority{   
  width: 200px;  
}

// .column-visibility .column-visibility-mobile{  
//   width:70px; 
// }
// .column-visibility .column-visibility-mindex{  
//   width: 130px;
//   padding: 0px 8px;
// } 
 





@media (max-width:768px) {

    .column-visibility .column-visibility-row-head {
      display: none;
    }

    .column-visibility .column-visibility-pc, .column-visibility .column-visibility-index{   
      display: none;
    }

}