.file-upload{
    
    // [data-filepond-item-state*=error] .filepond--item-panel, [data-filepond-item-state*=invalid] .filepond--item-panel

    background-color: #fff;
    background-color: transparent;
    padding: 0px;
    border-radius: 0px !important;
    *{
        border-radius: 0px !important;
    }
    
    color:#000000dd;
    .filepond--file{
        color:#000000dd;
    }
    .filepond--file-info{ 
    }
    .filepond--panel{
        opacity: 0.1;
    }
    svg{
        color:#fff !important;
    }
    .filepond--image-preview-wrapper{
        left: 8px;
        top: 36px;
        right: 10px;
        // height: 80px;
        max-width: 90px; 
        max-height: 60px;
        //   border-radius: 4px 0px 0px 4px;  
        overflow: hidden; 
        opacity: 0.7;
          background-color: #fff; 
          border:8px solid #fff;
          box-sizing: content-box;
          border-radius: 4px !important;
    }
    .filepond--file-action-button{
        background-color:#3d54b5;
        border-radius: 4px !important;
    }
    .filepond--panel-center{
        // height: 100% !important;
    }
    .filepond--progress-indicator{
        color:#000000dd;
    }
    .filepond--image-preview{
        background-color: transparent;
    }
    .filepond--image-preview-overlay {
        height: 100%;
        background-color: #00000000;
        >*{
            display: none;
        }
    }

    .filepond--image-preview-overlay.filepond--image-preview-overlay-failure {
        background-color: #ff000000;
    }
    .filepond--image-preview-overlay.filepond--image-preview-overlay-success {
        background-color: #20ff0c00;
    }

    .fileupload-placeholder{
        font-size: 120%;
        color: #666;
    }

    .filepond--drop-label{
        color: #aaa;
    }
}


.file-upload.hidden{
    visibility: hidden;
    position: absolute;
    width: 0px;
    height: 0px;
    opacity: 0;
}