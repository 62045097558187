.mp-select-item {
    display: flex;
    position: relative;
    align-items: center;

    .mp-select-item-image {
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        width: 48px;
        height: 48px;
        font-size: 30px;
    }

    .mp-select-item-data {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .mp-select-item-desc {
            font-size: 80%;
            opacity: 0.6;
        }
    }
}
