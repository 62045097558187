.customer:has(.overview-page) {
    .tab-header {
        margin: 0 8px 0 8px;
    }

    .page {
        padding: 16px 8px;
    }
}

@media (max-width: 768px) {
}
