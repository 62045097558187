.popup-wrapper:has(.select-custom-component-popup) {
    z-index: 90;
}

.custom-components {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 750px;

    .custom-component {
        padding: 16px;
        width: 218px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 16px;

        cursor: pointer;

        &:hover {
            background-color: #ddd;
        }
        &.selected {
            background-color: #71c9ff;
        }

        .ficon {
            font-size: 300%;
        }
        image {
            width: 100%;
            height: 200px;
        }

        .custom-component-image {
            width: 100%;
            max-height: 200px;
            background-color: #eee;
        }
        .custom-component-name {
            font-weight: bold;
            font-size: 110%;
            margin-top: 8px;
            height: 40px;
        }
    }
}
