.article-report-overview.overview-page {
    width: 100%;
}

.article-options {
    .option-line {
        display: flex;
        align-items: center;
        font-size: 90%;
        padding: 4px 0;
        opacity: 0.8;

        .ficon {
            font-size: 160%;
        }
    }
}

.view-page:has(.article-comment-view-page) {
    max-width: 1500px;

    .view-page-header {
        margin-bottom: 0;
    }

    .article-comment-view-page {
        max-width: 1500px;
    }
}

// .article-thumb-image {
//     width: 100%;
//     height: auto;

//     &.sub-article {
//         padding: 0;
//     }
// }

.article-comment-view-page {
    width: 100%;

    .article-comparison {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }

    .article-data .card-content,
    .article-user-data .card-content {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }
}

.article-comment-view-page .data-card .card-content,
.article-comment-view-page .article-data .article-data-card,
.article-comment-view-page .article-user-data .article-user-data-card {
    display: grid;
    grid: auto / auto;
    gap: 8px;
    grid-gap: 8px;
    align-items: center;
    align-content: center;
    justify-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    border-right: 1px solid #eee;

    &:last-child {
        border: 0 none;
    }

    .sub-title {
        font-size: 80%;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.7;
    }

    .value {
        font-size: 140%;
    }

    .sticker {
        width: auto;
        margin-bottom: 8px;
    }

    .sub-value {
    }

    .foot-title {
        color: #aaa;
    }
}

.article-comment-view-page .article-data .article-data-card,
.article-comment-view-page .article-user-data .article-user-data-card {
    justify-items: center;
    justify-content: center;
    padding: 8px 0;
}

.article-data-cards-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .article-data-cards {
        min-height: 210px;
        width: calc(50% - 8px);
        border-radius: 4px;

        .card {
            border-radius: 0;
        }
    }

    .article-user-data-cards {
        min-height: 210px;
        padding-top: 16px;
        width: calc(50% - 8px);
        border-radius: 4px;

        .card {
            border-radius: 0;
        }
    }
}

.article-comment-view-page .article-data-cards,
.article-comment-view-page .article-user-data-cards {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    padding-bottom: 16px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;

    &:first-of-type {
        padding: 16px 0;
    }

    .article-data,
    .article-user-data {
        flex: 1;
    }
}

.article-data-charts {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;
    margin-bottom: 16px;

    .card-content {
        min-height: 350px;
    }
}

.article-data-cards-charts {
    display: flex;
    width: 100%;
    margin: 16px 0;

    .article-data-card-chart {
        display: flex;
    }

    .card {
        width: 100%;

        .card-header {
            margin-bottom: 0;
        }

        .card-content {
            display: flex;
            flex-direction: column;
            height: calc(100% - 42px);
            min-height: 300px;
            padding-top: 0;
            width: 100%;

            .datagrid {
                height: 100%;

                .grid-body {
                    height: 100%;
                }
            }

            .tab-header {
                border: none;
            }
        }
    }

    .card-data-chart-labels {
        margin-right: 16px;
        min-width: 120px;

        .card-data-chart-label {
            padding: 8px 0;

            .card-data-chart-label-title {
                font-weight: 500;
                opacity: 0.5;
                font-size: 90%;
            }

            .card-data-chart-label-data {
                font-size: 120%;
            }
        }
    }

    .chart-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .card-content:has(.article-data-card-chart-wrapper) {
        padding: 0 16px;
    }

    .article-data-card-chart-wrapper {
        display: flex;

        .chart-wrapper {
            max-width: 140px;
        }

        .article-data-card-chart {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-right: 1px solid #eeeeee;
            margin: 8px 0 24px 0;
        }

        :last-child {
            border: none;
        }

        .card-data-chart-labels {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 16px 0 0 0;
        }

        .card-data-chart-label {
            display: flex;
            width: 100%;
            // max-width: 180px;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            padding: 8px 0;

            .card-data-chart-label-data {
                text-align: right;
                margin-right: 4px;
                font-size: 110%;
                width: 50%;
            }

            .card-data-chart-label-title {
                font-weight: 500;
                opacity: 0.5;
                font-size: 110%;
                width: 50%;
                margin-left: 4px;
            }
        }
    }
}

.summary-item-popover {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .summary-item-title {
        font-size: 120%;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .summary-item-description {
        max-width: 200px;
    }
}

.article-report-lists {
    display: flex;
    flex-direction: column;

    > :first-child {
        margin-bottom: 16px;
    }

    .article-report-list {
        overflow: auto;

        .datagrid-rows {
            max-height: 280px;
        }

        &.col3 {
            width: 66%;
        }

        .card-header {
            margin-bottom: 0;
        }

        .list-item {
            padding-right: 16px;

            &:last-of-type {
                // margin-bottom: 12px;
            }

            .item-image {
                background-size: cover;
                border-radius: 4px;
                margin-left: 16px;
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .article-data-cards-charts {
        .chart-wrapper {
            max-width: 150px;
        }
    }

    .article-data-card-chart-wrapper {
        flex-direction: column;
    }

    .article-report-lists {
        .article-report-list {
            .button {
                align-self: flex-start;
                padding: 8px;
                min-width: initial;

                .ficon {
                    margin-right: 0;
                }

                span {
                    display: none;
                }
            }

            &.col3 {
                width: 100%;
            }
        }
    }
}

.article-comment-view-page {
    width: calc(100vw - 32px);

    .article-report-map {
        margin-bottom: 16px;

        .map-chart {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            flex: 1;
            background: white;
            width: 100%;
            margin-top: 0;
        }
    }
}

@media only screen and (min-width: 768px) {
    .article-comment-view-page {
        width: calc(100vw - var(--side-menu-width) - 48px);
    }
}

@media only screen and (min-width: 1600px) {
    .article-comment-view-page {
        width: calc(100vw - var(--side-menu-large-width) - 64px);
    }
}

@media only screen and (min-width: 968px) {
    .article-data-cards-charts:not(.full-width) {
        .card {
            width: calc(50% - 8px);
        }
    }

    .article-report-lists {
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 16px;
        flex-wrap: wrap;

        > :first-child {
            margin-bottom: 0;
            margin-right: 16px;
        }

        .article-report-list {
            width: calc(50% - 8px);
            flex: 1;
        }
    }

    .article-data-card-chart-wrapper {
        flex-direction: row;

        .article-data-card-chart {
            width: 33%;
        }
    }

    .article-comment-view-page .article-data-cards,
    .article-comment-view-page .article-user-data-cards {
        &:has(.metric-card) {
            grid-gap: 0;
        }
    }
}
