.application-edit-popup-content {
    width: 750px;
    display: flex;

    .application-edit-popup-content-left {
        width: 450px;
        padding-right: 8px;
        border-right: 1px solid #eee;
    }

    .application-edit-popup-content-right {
        padding-left: 8px;
    }
}

.page-accounts {
    .popup-wrapper:has(.application-edit-popup) {
        z-index: 90;
    }

    .popup-wrapper {
        z-index: 91 !important;
    }
}
