.invite-popup-content{
  
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  width: 600px;
  max-width: 100%;
  
  .invite-link{
    flex:1; 
    padding: 12px; 
    max-width: 100%; 
    width: 100%;
    height: 240px;

    border:1px solid #ddd;
    resize: none;
    outline: none;
    border-radius: 3px;
    margin-top: 8px;
  }
  .invite-qr-wrapper{
    height: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    .invite-qr-code{
      width:200px;height: 200px;
      background-color: #fff;
      padding: 10px;
    } 
  }
}
