.analytics-active-pages-card {
    .last-updated-at{
        font-size: 70%;
        opacity: 0.6;
        padding: 4px 8px;
    }
    .datagrid-tools {
        display: none;
    }

    > .card-content {
        padding: 0 !important;
    }

    .status-icons {
        display: flex;

        .status-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0 4px;

            .status-icon {
                pointer-events: none;
                font-size: 16px;
            }
        }
    }
}
