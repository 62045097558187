.edit-customerrfm.edit-page {
    .main-content {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: 1fr auto auto;
        grid-template-rows: auto;
        grid-template-areas:
            'name enable enable'
            'advanced advanced advanced'
            'rfmformula rfmformula rfmformula';
    }

    .formula-types{
        display: flex; 
        gap:16px;

        &>*{
            flex:1;
        }
    }
    .name {
        grid-area: name;
    }
    .enable-rfm {
        grid-area: enable;
    }
       

    .advanced-settings-section {
        display: flex;
        flex-direction: column;
        grid-area: advanced;

        .advanced-settings-switch {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .advanced-settings-switch__title {
                margin-right: 8px;
            }
        }

        .formula-types{
            grid-area: swithces;
        }
        .more-advanced{
            margin-top: 16px;
            grid-area: moreadv;
        }
        .formula-inputs{
            padding-left: 22px;
            display: flex;
            flex-direction: column;
            gap:16px;
        }
        .advanced-settings-fields {
            display: grid;
            grid-gap: 16px;
            gap: 16px;
            grid-template-columns: 1fr auto auto;
            grid-template-rows: auto;
            grid-template-areas:
                'periodlen periodlen periodlen'
                'swithces swithces swithces'
                'moreadv moreadv moreadv'
                'recformula recformula recformula'
                'freformula freformula freformula'
                'monformula monformula monformula' 
                'rfmformula rfmformula rfmformula';

            .period-length {
                grid-area: periodlen;
                display: flex;
                align-items: center;

                .period-length-days {
                    flex: 1;
                }

                .period-start {
                    flex: 1.5;
                }

                & > .period-length-label {
                    flex: none;
                    padding: 0 8px;

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }

            .recency-formula {
                grid-area: recformula;
            }

            .frequency-formula {
                grid-area: freformula;
            }

            .monetary-formula {
                grid-area: monformula;
            }
 
        }
    }

    .rfm-formula {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #eee;
        grid-area: rfmformula;

        .rfm-item-headers {
            display: flex;
            flex-direction: row;
            padding-left: 160px;
            padding-right: 32px;

            .rfm-item-header {
                padding: 8px;
                flex: 1;
            }
        }

        .rfm-item {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .rfm-item-add {
            margin-top: 16px;
        }
    }

    .rfm-formula .rfm-item-headers .rfm-item-header {
        .rfm-item-header-text {
            font-size: 160%;
        }

        .rfm-item-header-sub-text {
            font-size: 80%;
            opacity: 0.7;
            margin-bottom: 8px;
        }
    }

    .rfm-locked-value {
        font-size: 130%;
    }

    .rfm-formula .rfm-item {
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px dashed #eee;

        .rfm-item-value {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            padding: 8px;
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 120%;
            border: 1px solid #3d54b5;
            color: #3d54b5;
        }

        .rfm-item-valuable {
            width: 100px;
            padding: 8px;
        }

        .recency-item-value {
            flex: 1;
            margin: 8px;
        }

        .frequency-item-value {
            flex: 1;
            margin: 8px;
        }

        .monetary-item-value {
            flex: 1;
            margin: 8px;
        }

        .rfm-item-remove {
            width: 32px;
            padding: 8px;

            &.hide-remove {
                visibility: hidden;
                pointer-events: none;
            }
        }
    }
}

.rfm-record-list {
    .rfm-record-list-item {
        background-color: #fff;
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
        padding: 16px;
        cursor: pointer;
        display: grid;
        grid-gap: 4px;
        gap: 4px;
        grid-template-columns: 0 1fr 1fr 24px;
        grid-template-rows: auto;
        grid-template-areas:
            'icon title title remove'
            'icon desc desc remove'
            'icon status status remove';
        align-items: center;
        position: relative;

        &:first-child {
            border-radius: 4px 4px 0 0;
        }

        &:last-child {
            border-radius: 0 0 4px 4px;
        }

        &.selected {
            background-color: #3d54b5;
            color: #fff;
        }

        &:after {
            content: ' ';
            width: 12px;
            height: 12px;
            background-color: #fff;
            position: absolute;
            right: -5px;
            top: 20px;
            transform: rotate(45deg);
            border-radius: 2px;
            display: none;
        }

        &.selected:after {
            background-color: #3d54b5;
            display: block;
        }

        .rfm-record-list-item-icon {
            align-self: flex-start;
            grid-area: icon;
            display: none;
        }

        .rfm-record-list-item-title {
            grid-area: title;
            font-size: 100%;
        }

        .rfm-record-list-item-desc {
            grid-area: desc;
            opacity: 0.6;
            font-size: 90%;
        }

        .rfm-record-list-item-enable {
            grid-area: switch;
        }

        .rfm-record-list-item-status {
            grid-area: status;
        }

        .rfm-record-list-item-remove {
            grid-area: remove;
            font-size: 120%;
            align-self: flex-start;
            border-radius: 50%;
            pointer-events: all;
        }
    }
}

@media (max-width: 1200px) {
    .rfm-formula {
        max-width: calc(100vw - 64px);
        overflow: auto;

        .rfm-item-value {
            min-width: 36px;
        }

        .rfm-item-valuable {
            min-width: 100px;
        }

        .input,
        .rfm-item-header-color {
            min-width: 200px;
        }
    }

    .edit-customerrfm.edit-page {
        .advanced-settings-section {
            .advanced-settings-fields {
                display: grid;
                grid-gap: 16px;
                grid-area: advanced;
                gap: 16px;
                grid-template-columns: auto;
                grid-template-rows: auto;
                grid-template-areas:
                    'periodlen'
                    'recformula'
                    'freformula'
                    'monformula'
                    'rfmformula';

                textarea {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .edit-customerrfm.edit-page {
        .main-content {
            display: grid;
            grid-gap: 16px;
            gap: 16px;
            grid-template-columns: 1fr auto auto;
            grid-template-rows: auto;
            grid-template-areas:
                'name'
                'enable'
                'advanced'
                'rfmformula';
        }

        .advanced-settings-section {
            width: 100%;

            .advanced-settings-fields {
                .period-length {
                    flex-direction: column;
                    align-items: center;

                    > div {
                        margin-bottom: 16px;
                    }

                    .period-start {
                        width: 100%;
                    }
                }
            }
        }
    }
}
