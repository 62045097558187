
.best-record-sub-label{
  opacity: 0.6;
  font-size: 80%;
  // margin-left: 16px;
    margin-right: 4px;
}
.best-record-sub-label:first-child{ 
  margin-left: 0px; 
}
.best-record-sub-value{
  font-size: 90%;
  
}

.best-record-item{
  // max-height:59px;
  overflow: hidden;
}

.best-records .best-record-item.list-item.has-children.list-item.has-image:not(.has-desc) {
    padding-top: 7px;
  // padding-bottom: 8px;
}



.best-records .best-record-item.list-item.has-children.list-item.has-image:not(.has-desc):first-child{
  // padding-top: 0px;
}

.best-records .best-record-item.list-item.has-children.list-item.has-image:not(.has-desc):last-child{
 border-bottom: 0px none;
}




.best-record-sub-data{
   white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap:8px;
    height: 16px;
    
}
.best-record-sub-data-item{
  
}
// .best-record-item .{
//   max-height: 55px;
// }


.best-records 
.overview-card-wrapper-header-title{
  
}



.best-records{
  padding: 16px 0px;
  width: 100%;

 position: relative;

    .placeholder-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        left:0px;
        top:0;
        z-index: 1;
        background: rgb(255,255,255, 0.5);
        background: linear-gradient(1deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 100%);
       
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-shadow: 1px 1px #00000022;

        .placeholder-overlay-title{
            font-size: 160%;
            font-weight: 500;
            padding: 8px;
            color:#00000099;
           
        }
        .placeholder-overlay-text{
            padding: 8px;
            color:#00000077;
        }
        .placeholder-overlay-button{
            margin-top: 8px;
        }
    }
 

  .best-record-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap:4px 8px;
    border-bottom: 1px solid #eee;
     height: 60px;
    &:last-child{
      border-bottom: 0px none;
    }
    .best-record-item-image{
      width: 40px;
      
      overflow: hidden;
      img{
        width: 40px;
        height: 40px;
        border-radius: 50%; 
      }
    }
  
    .best-record-item-content{
       flex:1;
       white-space: nowrap;
       text-overflow: ellipsis;
       overflow: hidden;
       align-items: center;
    }

    .best-record-item-sticker{
       align-items: center;
       display: flex;
    }
    
    
    .best-record-item-label{  
      align-self: flex-end;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
     
      .best-record-item-name{ 
        display: inline;
      }
      .best-record-item-description{ 
        display: inline;
        font-size: 80%;
        opacity: 0.7;
        margin-left: 16px;
      }
    }
  

    .best-record-sub-data{

    }

    .item-label { 
        align-self: flex-end;
    }
    .item-sticker{
        grid-area: sticker;
        align-self: center;
    }
    .item-content {
        grid-area: content;
        padding: 0px;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: flex-start;

    }
    .item-image, .item-logo, .item-icon {
        width: 44px;
        height: 44px;
    }

    .item-image {
        grid-area: icn;
        align-self: flex-start;
        background-position: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-color: #ddd;
        margin-right: 4px;
        border-radius: 4px;
    }

  }
}