.actiontype-edit-popup {

    .actiontype-edit-popup-content {
        width: 500px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &.loading {
            user-select: none;
            pointer-events: none;
            opacity: 0.4;
        }



        .actiontype-edit-text {
            display: flex;
            flex-direction: row;
            position: relative;


            .actiontype-edit-restart {
                position: absolute;
                z-index: 1;
                width: calc(100% + 32px);
                height: calc(100% + 24px);
                top: -8px;
                left: -16px;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: row;

                background-color: #00000033;
            }

            &:hover .actiontype-edit-restart {
                display: flex;
            }

            .actiontype-edit-shorten-button {
                margin-top: 16px;
                margin-left: 8px;
                border: 1px solid #ddd;
                border-radius: 4px;
            }
        }

        .actiontype-edit-shortened-url {
            background-color: #ddd;
            padding: 8px 16px;
            user-select: all;
            line-height: 1.6;
            border-radius: 4px;
            padding-right: 32px;
            margin-right: 8px;
        }

        .actiontype-edit-shortened-results {
            b {
                flex: none;
                display: block;
                width: 100%;
                padding-bottom: 4px;
            }
        }

        .actiontype-edit-shortened-result {
            border-top: 1px solid #ddd;
            padding-top: 16px;
            margin-top: 16px;

            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

        }

        .actiontype-edit-redirected-url {
            font-size: 80%;
            opacity: 0.7;
            margin-top: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            a {
                color: #333;
                text-decoration: none;
            }
        }
    }
}