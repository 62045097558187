.tab-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 8px;
    align-self: flex-start;

    .tab-title {
        user-select: none;
        padding: 12px 16px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }

        &.selected {
            border-bottom: 2px solid #163ec4;
            margin-bottom: -2px;
        }
    }
}

.lang-zh .tab-header {
    .tab-title {
        font-weight: bold;
    }
}

.tab-content {
    width: 100%;
    height: 100%;
}

.tab-header-wrapper {
    width: 100%;
}

.tab-header-wrapper.sub {
    font-size: 80%;
    background-color: #f2f2f2;

    .tab-header {
        margin-top: 8px;
        margin-bottom: 8px;

        .tab-title {
            padding-top: 12px;
            padding-bottom: 12px;

            &.selected {
                background-color: #163394;
                color: #fff;
                border-bottom: 2px solid #fff;
            }
        }
    }
}

@media (max-width: 768px) {
    .tab-header-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        display: block;
    }

    .tab-header {
        white-space: nowrap;
        min-width: 0;
        display: block;
        margin-bottom: 2px;

        .tab-title {
            min-width: 0;
            padding: 12px 16px;
            white-space: nowrap;
            border-bottom: 2px solid transparent;
            display: inline-block;

            &:hover {
                box-shadow: none;
            }
        }

        .tab-title.selected {
            margin-bottom: 0;
        }
    }
}