.app.print-mode {
    display: block;

    .view-page-header {
        display: none;
    }

    .header {
        display: none;
    }

    .app-menu {
        display: none;
    }

    .page {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        padding: 0 16px;

    margin-top: 0px;
    max-width: 100%;
    overflow: auto;

    .article-comment-view-page{
        width: 100%;
        max-width:100%;
      }
  
    }
}
