.email-right-panel-wrapper{  
 
    position: absolute;
    right:0px;
    top:0px;
    z-index: 10;
    height: 100%;
    left:0px;
    bottom: 0px;

    &.right-panel-disabled *{
        pointer-events: none;
        user-select: none;
        opacity: 0.7;
        background-color: transparent;
        .user-avatar{
            display: none;
        }
    }
  }
   
.email-right-panel{  
 
    user-select: none;
    animation: fade-email-right-panel 300ms forwards;
    animation-iteration-count: 1;

    position: absolute;
    right:0px;
    top:0px;
    z-index: 10;
    background-color: #fff;
    height: 100%; 
    width: 300px;
    box-shadow: 0px 0px 20px 2px #00000022;

    @keyframes fade-email-right-panel {
        0% { transform:translateX(100%); opacity: 0; } 
        50% {  transform:translateX(100%); opacity: 0; } 
        100% {  transform:translateX(0%); opacity: 1;  } 
    }


}
 

.email-right-panel .email-detail{
    display: flex;
    flex-direction: column; 
    height: 100%;

    .email-name{
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
    }
    .email-detail-content{
        overflow: auto;
        flex:1; 
    }
    .user-avatar{
        padding: 8px 16px; 
        cursor: pointer;
        img{
            width:48px; height: 48px;
        }
        .ficon {  
        }
    }

    .edit-email{
        // margin: 0px 16px;
    }

    .email-assigned-member{
        
        border-bottom: 1px solid #eee;
        padding: 8px;
        padding-top: 0px;
        &.show-top-border{
            border-top: 1px solid #eee;
            margin-top: 8px;;
            padding-top: 8px;
        }
        .user-avatar{
            padding-left: 8px;
        
            img{
                border: 3px solid #ddd;
                width: 36px;
                height: 36px;
            }
        }
        .email-assigned-member-head{
            padding: 8px;
            font-weight: 500;
        }
    }

    .email-members{
        display: flex;
        flex-direction: column;
        padding: 8px;
        border-bottom: 1px solid #eee;
        .email-members-head{
            padding: 8px;
            font-weight: 500;
        }
        .email-members-list{
            // display: flex;
            // flex-direction: row;
            padding: 4px 8px;
        }
        .email-member-avatar{
            width:36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 4px;
            margin-top: 4px;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            border:3px solid #ddd;
        }

        .email-member-avatar.user{ border-color: #ddd; }
        .email-member-avatar.admin{ border-color: rgb(164, 204, 235); }
        .email-member-avatar.customer{ border-color: rgb(248, 206, 201); }
        .email-member-avatar.watcher{ border-color: rgb(150, 180, 145); }

        .email-members-count{
            width:36px;
            height: 36px;
            border-radius: 50%;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            border:1px solid #ddd;
            margin-right: 4px;
            margin-top: 4px;
            cursor: pointer;
        }
        .iconbutton{
            margin-right: 4px;
            margin-top: 4px;
            width:36px;
            height: 36px;
            border-radius: 50%;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            border:1px solid #ddd;
        }
    }


    .email-labels{
        display: flex;
        flex-direction: column;
        padding: 8px;
        border-bottom: 1px solid #eee;
        .email-labels-head{
            padding: 8px;
            font-weight: 500;
        }
        .email-labels-list{ 
            padding: 0px;
            .no-subjects-note{
                padding: 8px 16px; 
                opacity: 0.4;
            }
        }
        .email-label-text{
            display: inline-block;
            background-color: #eee;
            border-radius: 6px;
            margin: 4px;
            cursor: pointer;  
            padding: 4px 8px;
            font-size: 90%;
        }
        .email-labels-count{
            width:36px; 
            border-radius: 40px;
            display: inline-block;
            padding: 4px 12px;
            border:1px solid #ddd;
            margin-right: 4px;
            margin-top: 4px;
            cursor: pointer;
        }
        .iconbutton{
            width:36px; 
            border-radius: 40px;
            display: inline-block;
            justify-content: center;
            border:1px solid #ddd;
            font-size: 100%;
            padding: 4px 12px;

            margin-top: 4px;
        }
    }
  }

  .email-right-panel>div{ 
    animation: fade-details 300ms forwards;
    animation-iteration-count: 1;
 


    @keyframes fade-details {
      0% { transform:translateX(0%); opacity: 0; }
    //   50% {  transform:translateX(100%); } 
      50% {  transform:translateX(100%); } 
      100% {  transform:translateX(0%); opacity: 1;  } 
    }

     
  }




  .email-right-panel .customer-detail{

    display: flex;
    flex-direction: column; 
    height: 100%; 
    .customer-detail-header{
        display: flex;
        flex-direction: row;  
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
        line-height: 1;
        font-size: 100%; 
        cursor: pointer;
        .ficon{
            font-size: 120%;
            line-height: 1;
            min-height: 0;  
        }
    }
    .customer-detail-content{ 
        overflow: auto;
        flex:1; 
    }
  }




  .email-right-panel .email-notes{

    display: flex;
    flex-direction: column; 
    height: 100%; 
    .email-notes-header{
        display: flex;
        flex-direction: row;  
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
        line-height: 1;
        font-size: 100%; 
        cursor: pointer;
        .ficon{
            font-size: 120%;
            line-height: 1;
            min-height: 0;  
        }
    }
    .email-note-list{ 
        overflow: auto;
        flex:1; 
        padding: 16px;
        background-color: #fafafa;

    display: flex;
    flex-direction: column-reverse;
        .email-note-item{
            border-radius: 4px;
            border:1px solid #ddd;
            background-color: #fff;
            padding: 16px;
            margin-bottom: 16px;
            .email-note-title{
                font-size: 80%;
                opacity: 0.7;
                margin-bottom: 8px;
            }
        }
    }
    .email-note-text{  
        padding: 16px;  
        width: 100%;
        height: 100px; 
        min-height: 80px;
        border:0px none;
        border-top:1px solid #ddd;
        // border-bottom:1px solid #ddd;
        resize: none;
        outline: none;
        border-radius: 0px;
        margin-top: 8px; 
        flex:none;
    }
    .button-wrapper{
        margin:16px;
    }
  }
  


  .email-right-panel .email-history{

    display: flex;
    flex-direction: column; 
    height: 100%; 
    .email-history-header{
        display: flex;
        flex-direction: row;  
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
        line-height: 1;
        font-size: 100%; 
        cursor: pointer;
        .ficon{
            font-size: 120%;
            line-height: 1;
            min-height: 0;  
        }
    }
    .email-history-list{ 
        overflow: auto;
        flex:1; 
        background-color: #eee;
        .email-history-item{
            padding: 16px;
            background-color: #fff;
            border-bottom: 2px solid #eee;
            .email-message-content{
                padding-right: 0px;
                padding-left: 0px;
                max-width: 100%;

                 .video-message video{
                    max-width: 250px;
                 }

                .message-header{
                    display: block;
                    opacity: 0.8;
                    .sender{
                        opacity: 0.8;
                    }
                    .ficon.channel { 
                        float: left;
                    }
                    .time{  
                        opacity: 0.6;
                    }
                    .internal{
                        padding: 0px;
                        margin: 0px;
                        opacity: 0.7;
                    }
                }
                .message{
                    padding: 0px;
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }
    .email-history-type{
        border: 0px none;
        outline: none;
        padding: 16px;
        border-bottom: 1px solid #ddd;
        border-radius: 0px;
        opacity: 0.6;
    }
  }

  .edit-email-detail-popup{ 
    width: 250px;
    max-width: 100%;
    padding: 8px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .input{
        margin-top: 8px;
    }
     .avatar{
        width:80px;
        height: 80px;
        background-color: #eee;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat; 
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        cursor: pointer;
        margin-bottom: 8px;

        .ficon{
            display: none;
        }

        &:hover{
            .ficon{
                display: block;
            }
        }
        &:hover:after{
            content: ' ';
            background-color: #00000022;
            border-radius: 50%;
            width:100%;
            height: 100%;
            position: absolute;
            left:0px;
            top:0px;

        }
     }
  }