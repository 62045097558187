.grid-action-button {
    font-size: 150%;
    font-weight: 500;
}

.customer-merge {
    .customer-merge-section {
        margin-bottom: 24px;
    }

    .datagrid .datagrid-row .datagrid-cell > span {
        
    }

    .source-dropdown {
        min-width: 220px;

        .value {
            padding: 0;
        }

        &:not(.source-applications-dropdown) {
            .selected-item {
                padding: 6px 34px 6px 12px !important;

                .customer-avatar {
                    img {
                        display: none;
                    }
                }
            }
        }
    }

    .mergelog-desc{
        padding: 0px 8px;
        margin-bottom: 8px;
        opacity: 0.6;
        font-size: 80%;
    } 
}
