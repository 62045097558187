.page-automessage .edit-page {
}

.personalized-content-types {
    min-width: 280px;
}

.login-buttons {
    position: relative;
    display: grid;
    grid-gap: 16px;
    gap: 16px;

    &.error {
        border: 1px solid #d32f2f;
        border-left: 3px solid #d32f2f;
    }

    &.error:after {
        content: 'Error: ' attr(data-error);
        position: absolute;
        padding: 0 6px;
        transition: 150ms;
        display: flex;
        align-items: center;
        height: auto;
        font-size: 65%;
        background-color: #fff;
        bottom: -5px;
        left: 9px;
        color: #d69d9d;
    }
}
