.countdown-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
}

.countdown-container > form {
    padding: 0.5rem;
}

.countdown-container > form > label {
    margin-right: 1rem;
}

.expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
    background-color: #ececec;
    box-shadow: 10px 10px #cfc9c9;
}

.expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
}

.expired-notice > p {
    font-size: 1.5rem;
}

.counter-container {
    display: flex;
    align-items: center;
}

.countdown-description {
    font-size: 90%;
    color: #444;
    opacity: 0.7;
}

.show-counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 80%;
    padding: 0.5rem;
    text-decoration: none;
    color: #444;
}

.show-counter .countdown {
    padding: 4px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.show-counter .countdown.complete {
    opacity: 0.2;
}

.show-counter .countdown > p {
    opacity: 0.9;
    margin: 0;
}

.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 75%;
    font-weight: 400;
    opacity: 0.7;
}
