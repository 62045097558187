.save-tag-popup-content {
    width: 300px;
    padding: 16px;
}

.card:has(.edit-tag-props) {
    .card-content {
        .main-content {
            padding-bottom: 0;
        }
    }
}

.edit-wechattag {
    .record-select {
        overflow: hidden;
    }

    .main-content {
        display: flex;
        flex-direction: column;
    }

    .edit-tag-props {
        width: 100%;

        .tag-subtitle {
            padding: 0;
            padding-bottom: 8px;
        }
    }
    .name-edit {
        max-width: 380px;
    }

    .tag-subtitle {
        font-size: 110%;
        text-align: left;
        width: 100%;
        padding: 8px;
        padding-bottom: 0;
    }
}

.edit-tag-select-followers {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .card:has(.edit-tag-props) {
        margin-bottom: 16px;

        .card-content {
            .main-content {
                padding-bottom: 0;
            }
        }
    }
}
