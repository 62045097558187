.coupon-color-tag {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    background-color: #63b359;
    min-width: 30px;
    color: #fff;
    font-size: 80%;
    font-weight: 500;
}

.coupon-qr-popoover {
    img {
        width: 200px;
        height: 200px;
    }
}

.member-card-image-preview {
    position: relative;
    box-shadow: none;

    .bg {
        width: 170px;
        height: 112px;
        border-radius: 8px;
        background-color: #000;
        border: 2px solid #eee;
        box-shadow: none;
    }

    .logo {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #fff;
    }

    .brand {
        position: absolute;
        top: 12px;
        left: 40px;
        font-size: 80%;
        color: #fff;
    }

    .title {
        position: absolute;
        top: 24px;
        left: 40px;
        font-size: 60%;
        color: #fff;
    }

    .card-no {
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-size: 80%;
        color: #fff;
    }
}
