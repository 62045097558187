.overview-card-container{
  // display: inline-flex;
  // flex-wrap: wrap;

  // grid-gap: 10px;
  // grid-template-columns: repeat( min-content);

  // vertical-align: top;
  // white-space: break-all;
  // align-items: flex-start;
  // flex-direction: row;
  // gap:8px;
  width:100%;
  height: 300px;
  // display: inline;
  // word-break: break-all;
  // white-space: normal;

  display: flex;

flex-wrap: wrap;
  // padding: 8px;

  // display: grid;
  // grid-template-columns: repeat(auto-fill, 200px);
  // grid-gap: 1rem;
  // justify-content: space-between;
}


 
// #masonry .brick .inner {
//   margin-bottom: 40px;
//   border: 1px solid lightblue;
//   padding: 20px;
// }

 .overview-card-wrapper{
    inset: 8px !important ;
  // display: inline;
  // vertical-align: top;
}

.grid-stack-item-content{
    
}


.grid-stack-item-content .data-card { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid #eee;
    padding: 8px;
    position: relative;
    padding-top: 0px;

    &:last-child {
        border: 0 none;
    }

    .sub-title {
        font-size: 80%;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.7;

  
        width: 100%;
        white-space: nowrap; 
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .value {
        font-size: 140%;
        margin: 8px 0;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap; 
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        
        width: 100%;

        .value-label {
            
            font-size: 70%;
            opacity: 0.5;
            text-transform: lowercase;
           
        }
    }

    .sub-value {
        margin: 8px 0;
    }

    .sticker {
        width: auto;
        margin-bottom: 8px;
    }

    .foot-title {
        color: #aaa;
    }
    .more-data-button{
        display: inline-block; 
        font-size: 140%;
        vertical-align: middle;
        font-weight: bold;
        margin-left:8px;
    }

    .card-main-part{
        
        width: 100%;
        white-space: nowrap; 
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap; 
        display: flex;
        flex-wrap: wrap;
    }

    .values{

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex:1.2;
        min-width: 60px;
    }


        .card-data-block-visualization {
            align-items: center; 
            width: 100%;
            min-width: 120px;
            max-width: 180px;
            flex: 1;
            max-height: 60px;
            .chart-labels {
                display: none;
            }
        
            .chart-wrapper {
                background-color: transparent;
                padding: 0;
            }
        }
}




.gs-3 > .grid-stack-item[gs-x="1"]  { left: 33.33% }
.gs-3 > .grid-stack-item[gs-x="2"]  { left: 66.66% }
.gs-3 > .grid-stack-item[gs-x="3"]  { left: 99.99% } 

.gs-3 > .grid-stack-item { width: 33.33% }
.gs-3 > .grid-stack-item[gs-w="1"]  { width: 33.33% }
.gs-3 > .grid-stack-item[gs-w="2"]  { width: 66.66% }
.gs-3 > .grid-stack-item[gs-w="3"]  { width: 99.99% } 




.gs-6 > .grid-stack-item[gs-x="1"]  { left: 16.5% }
.gs-6 > .grid-stack-item[gs-x="2"]  { left: 33% }
.gs-6 > .grid-stack-item[gs-x="3"]  { left: 49.5% }
.gs-6 > .grid-stack-item[gs-x="4"]  { left: 66% }
.gs-6 > .grid-stack-item[gs-x="5"]  { left: 82.5% } 

.gs-6 > .grid-stack-item { width: 16.5% }
.gs-6 > .grid-stack-item[gs-w="1"]  { width: 16.5% }
.gs-6 > .grid-stack-item[gs-w="2"]  { width: 33% }
.gs-6 > .grid-stack-item[gs-w="3"]  { width: 49.5% }
.gs-6 > .grid-stack-item[gs-w="4"]  { width: 66% }
.gs-6 > .grid-stack-item[gs-w="5"]  { width: 99%; }
     
.gs-6 > .grid-stack-item[gs-w="6"]  { width: 99% } 



.gs-25 > .grid-stack-item[gs-x="1"]  { left: 4% }
.gs-25 > .grid-stack-item[gs-x="2"]  { left: 8% }
.gs-25 > .grid-stack-item[gs-x="3"]  { left: 12% }
.gs-25 > .grid-stack-item[gs-x="4"]  { left: 16% }
.gs-25 > .grid-stack-item[gs-x="5"]  { left: 20% }
.gs-25 > .grid-stack-item[gs-x="6"]  { left: 24% }
.gs-25 > .grid-stack-item[gs-x="7"]  { left: 28% }
.gs-25 > .grid-stack-item[gs-x="8"]  { left: 32% }
.gs-25 > .grid-stack-item[gs-x="9"]  { left: 36% }
.gs-25 > .grid-stack-item[gs-x="10"]  { left: 40% }
.gs-25 > .grid-stack-item[gs-x="11"]  { left: 44% } 
.gs-25 > .grid-stack-item[gs-x="12"]  { left: 48% }
.gs-25 > .grid-stack-item[gs-x="13"]  { left: 52% }
.gs-25 > .grid-stack-item[gs-x="14"]  { left: 56% }
.gs-25 > .grid-stack-item[gs-x="15"]  { left: 60% }
.gs-25 > .grid-stack-item[gs-x="16"]  { left: 64% }
.gs-25 > .grid-stack-item[gs-x="17"]  { left: 68% }
.gs-25 > .grid-stack-item[gs-x="18"]  { left: 72% }
.gs-25 > .grid-stack-item[gs-x="19"]  { left: 76% }
.gs-25 > .grid-stack-item[gs-x="20"]  { left: 80% }
.gs-25 > .grid-stack-item[gs-x="21"]  { left: 84% }
.gs-25 > .grid-stack-item[gs-x="22"]  { left: 88% }
.gs-25 > .grid-stack-item[gs-x="23"]  { left: 92% }
.gs-25 > .grid-stack-item[gs-x="24"]  { left: 96% } 

.gs-25 > .grid-stack-item { width: 4% }
.gs-25 > .grid-stack-item[gs-w="2"]  { width: 8% }
.gs-25 > .grid-stack-item[gs-w="3"]  { width: 12% }
.gs-25 > .grid-stack-item[gs-w="4"]  { width: 16% }
.gs-25 > .grid-stack-item[gs-w="5"]  { width: 20% }
.gs-25 > .grid-stack-item[gs-w="6"]  { width: 24% }
.gs-25 > .grid-stack-item[gs-w="7"]  { width: 28% }
.gs-25 > .grid-stack-item[gs-w="8"]  { width: 32% }
.gs-25 > .grid-stack-item[gs-w="9"]  { width: 36% }
.gs-25 > .grid-stack-item[gs-w="10"]  { width: 40% }
.gs-25 > .grid-stack-item[gs-w="11"]  { width: 44% }
.gs-25 > .grid-stack-item[gs-w="12"]  { width: 48% }
.gs-25 > .grid-stack-item[gs-w="13"]  { width: 52% }
.gs-25 > .grid-stack-item[gs-w="14"]  { width: 56% }
.gs-25 > .grid-stack-item[gs-w="15"]  { width: 60% }
.gs-25 > .grid-stack-item[gs-w="16"]  { width: 64% }
.gs-25 > .grid-stack-item[gs-w="17"]  { width: 68% }
.gs-25 > .grid-stack-item[gs-w="18"]  { width: 72% }
.gs-25 > .grid-stack-item[gs-w="19"]  { width: 76% }
.gs-25 > .grid-stack-item[gs-w="20"]  { width: 80% }
.gs-25 > .grid-stack-item[gs-w="21"]  { width: 84% }
.gs-25 > .grid-stack-item[gs-w="22"]  { width: 88% }
.gs-25 > .grid-stack-item[gs-w="23"]  { width: 92% }
.gs-25 > .grid-stack-item[gs-w="24"]  { width: 96% }
.gs-25 > .grid-stack-item[gs-w="25"]  { width: 100% }
 
.edit-mode .overview-card-wrapper-header-title{
    cursor: move;
}

.card-edit-footer{
    position: fixed;
    bottom:0px;
    width:100%;
    z-index: 90;
    left:0px;
    padding: 8px;
    background: #3d54b5;
    box-shadow: 1px 1px 10px 10px #00000011;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:8px;
    .button-wrapper .button{
        font-weight: bold;
        color:  #3d54b5;
    }
}

.exit-edit{
    border:1px solid #fff;
    border-radius: 4px;
    overflow: hidden;
}




.overview-card-simple-table{
    height:100%;
    width:100%;
    }

    .overview-card-data-grid{
        height:100%;
        width:100%;
    }
     

@media (max-width: 768px) {


        .overview-card-wrapper{
            inset: 8px 0px !important ; 
        }


    div.grid-stack.gs-3 > .grid-stack-item{
        width: 100%;
        position: relative;
        left:0;
        top:0; 
    }


    .overview-card-simple-table{
        height:100%;
        width:100%;  
        overflow-x: auto;
        overflow-y: hidden;
        }

    .overview-card-data-grid{
        // height:100%; 
        // display: flex;
        .head-wrapper{
            padding: 0px;
        }
    }
        
}

















.overview-card-funnel{ 
    width: 100%; 
    height: 100%;
   position: relative;
  
      .placeholder-overlay{
          width: 100%;
          height: 100%;
          position: absolute;
          left:0px;
          top:0;
          z-index: 1;
          background: rgb(255,255,255, 0.5);
          background: linear-gradient(1deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 100%);
         
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-shadow: 1px 1px #00000022;
  
          .placeholder-overlay-title{
              font-size: 160%;
              font-weight: 500;
              padding: 8px;
              color:#00000099;
             
          }
          .placeholder-overlay-text{
              padding: 8px;
              color:#00000077;
          }
          .placeholder-overlay-button{
              margin-top: 8px;
          }
      }
}