.edit-wechatarticle.edit-page {
    grid-template-columns: 280px 1fr 380px;

    .article-content {
        min-width: 0;
    }

    .material-item {
        max-width: initial;
    }

    .info {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: '';
    }

    .page-editor {
        // max-width: 380px;
    }

    .add-article {
        margin-top: 16px;
        .line-button {
            font-weight: bold;
            font-size: 90%;
        }
    }
}

.group-name-input {
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
    border-radius: 4px 4px 0 0;
}
.group-name-input input {
    border-radius: 4px 4px 0 0;
    border: 0 none;
    padding-left: 16px;
}

.article-group-shadow {
    background: none;
}

.article-group-items {
    .article-group {
        width: 100%;
        display: grid;

        grid-gap: 2px 8px;
        gap: 2px 8px;

        grid-template-columns: 56px 1fr auto;
        grid-template-rows: auto;
        grid-template-areas:
            'image title'
            'image desc'
            'image status';
        min-width: 280px;
        position: relative;
        background-color: #fff;
        padding: 16px;
        box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);

        cursor: pointer;
        user-select: none;

        // &:first-child{
        //   border-radius: 4px 4px 0px 0px;
        // }
        & + .button-wrapper {
            border-radius: 0 0 4px 4px;
        }

        &:hover,
        &:hover:after {
            box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%),
                inset 0 0 100px 1000px #00000011;
        }

        &.selected,
        &.selected:after {
            background-color: #3d54b5;
            color: #fff;
        }

        &.selected:after {
            display: block;
        }

        &.article-group-ghost {
            opacity: 0.7;
        }

        &:after {
            content: ' ';
            width: 12px;
            height: 12px;
            background-color: #fff;
            position: absolute;
            right: -5px;
            top: 20px;
            transform: rotate(45deg);
            border-radius: 2px;
            display: none;
        }

        .article-image {
            grid-area: image;
            height: 56px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            background-color: #eee;
            border-radius: 2px;
        }

        .article-title {
            grid-area: title;
            align-self: flex-end;
            font-weight: bold;
        }

        .article-desc {
            grid-area: desc;
            align-self: flex-start;
            font-size: 80%;
            opacity: 0.8;
        }

        .article-index {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 11px;
            bottom: 8%;
            width: 20px;
            height: 20px;
            transform: translateY(-50%);
            font-size: 80%;
            border-radius: 3px;
            text-align: center;
            background-color: #eee;
            color: #000;
        }

        .article-status {
            grid-area: status;
            align-self: flex-start;
        }

        .article-status-bar {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .article-remove {
            position: absolute;
            right: 8px;
            top: 32%;
            transform: translateY(-50%);
            font-size: 130%;
            border-radius: 50%;
            display: none;
        }

        &:hover .article-remove {
            display: block;
        }
    }
}

.article-editors {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;

    .article-editor-placeholder {
        flex: 1;
    }

    .paste-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 80%;
        padding: 8px;
        border-radius: 4px;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }

        cursor: pointer;

        .ficon {
            font-size: 250%;
            margin-bottom: 8px;
        }
    }

    .article-editor {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 8px 12px;
        color: #000;
        text-decoration: none;
        font-size: 90%;

        img {
            width: 48px;
            filter: drop-shadow(0px 0px 4px #00000022);
            margin-bottom: 4px;
        }
    }
}

.sub-inputs {
    padding-left: 22px;
    border-left: 1px solid #eee;
    margin-left: 14px;
    margin-right: -40px;
    transition: 500ms;
    width: 90%;
    box-sizing: border-box;

    @keyframes slowlyopen {
        0% {
            opacity: 0;
            margin-top: -300px;
            height: 0;
            overflow: hidden;
            background-color: #fff;
        }
        40% {
            opacity: 0;
            background-color: #fff;
        }
        100% {
            opacity: 1;
            margin-top: 0;
            height: auto;
            overflow: auto;
        }
    }
}

@media (max-width: 768px) {
    .edit-wechatarticle.edit-page {
        grid-template-columns: initial;

        .article-content {
            min-width: initial;
        }
    }

    .article-group-items {
        .article-group {
            width: 100%;
            &:after {
                display: none;
            }
        }

        .group-name-input {
            margin: 16px;
            width: calc(100% - 32px);
        }
    }
}
