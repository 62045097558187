.automation-edit-page {
    user-select: none;
}

.automation-list {
    width: 100%;
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    overflow: hidden;
    padding-bottom: 150px;

    .left-panel {
        align-self: flex-start;
        width: 100%;
        margin-top: 12px;
    }

    .right-panel {
        padding: 16px;
        min-width: 280px;
        height: 285px;
        background: #f4f6f8;
        margin-left: 24px;
        margin-top: 12px;

        .panel-title {
            color: #263238;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .section-title {
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        color: #546e7a;
    }

    .search-box {
        margin-bottom: 16px;
    }
}

.automation-heading {
    color: #263238;
    margin-bottom: 8px;
}

.automation-edit-page {
    max-width: initial !important;
    width: 100%;

    .info > .main-content {
        padding-bottom: 0;
    }

    .edit-page-header {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
    }

    .edit-page-footer {
        position: fixed;
        z-index: 99;
        display: flex;
        width: 100%;
        bottom: 0;
        right: 0;
        justify-content: center;
        background: #ffffff;
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);

        .main {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            max-width: 1200px;
            padding: 16px;

            .button-wrapper {
                align-self: center;
                margin-bottom: 0;
            }

            .switch-wrapper-toggle-automation {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .automation-edit-page {
        gap: 0;
        padding: 0 12px;
    }
}
