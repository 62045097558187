.wecom-chat-setting{
  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
    width: 1000px;
  max-width: 100%;


  .chatbot-upload-file{
    font-size: 90%;
    font-weight: bold;
    padding: 16px;
     
    
    background-color: #eee;
    border-radius: 4px;
    margin-top: 4px;

    position: relative;
   .remove-selected-file{
    position: absolute;
    right: 0px;
    top:0px;  
   }

   }

 
    b{
      font-size: 90%;
      opacity: 0.6;
      display: block;
    } 

  .wecom-chat-setting-sub-items{
    padding-left: 16px;
    opacity: 0.3;
    pointer-events: none;
    color:#444;
    font-size: 90%;
    background-color: #fafafa;
    margin-bottom: 16px;
    .list-item{
      border-bottom: 0px none;
    }
  }

  .wecom-chat-setting-sub-items.enabled{ 
    opacity: 1;
    pointer-events: all;
  }
  .wecom-chat-setting-bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // padding: 16px;
    padding-bottom: 100px;
    padding-top: 40px;
    .save-button, .go-button{
      width: 200px;
      margin: 16px;
    }
  }
  
  .wecom-chat-setting-title{ 
    padding: 16px; 
    font-size: 180%; 
  } 
  .customer-service-max{

  }
  .setting-label{
    font-weight: 500;
    padding-top: 8px; 
  }
  .setting-description{ 
    font-size:80%;
    padding: 8px;
    opacity: 0.7;
  }
  .setting-values{ 
    padding: 8px 0px;
  }
  
  
  .chat-setting-popup-users{ 
    padding: 8px; 
}
.setting-messages-inner{
  padding: 0px 8px;
  textarea{
    width: 100%;
  }
}
  .wecom-chat-setting-section{
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      width: 100%;

      display: grid;
      flex-wrap: wrap;
      grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

      gap:8px;
      grid-gap: 8px;
      margin-bottom: 8px;
      // padding: 8px 0px; 
      
 
    .wecom-chat-setting-section-title{
      font-weight: bold;
      padding: 16px;
      background-color: #fff;
      width: 100%;
    } 
  }
  .wecom-chat-setting-section-left{
    border-right: 1px solid #eee; 
    // padding-right: 16px;
    min-width: 300px;  
  } 
  .wecom-chat-setting-section-right{
    min-width: 300px; 
  }
  .wecom-chat-setting-section-left,
  .wecom-chat-setting-section-right{ 
       flex:1;
      // width: 48%;
      background-color: #fff;
      border-radius: 4px;
      // margin: 1%;
      padding: 8px  0px; 
      padding-bottom: 16px;
  }

  .working-hours{
    .working-hour-item{
      align-items: center;
      display: flex;
      flex-direction: row;
      .working-day{ 
        width: 80px;
        align-items: center;
        display: flex;
        flex-direction: row;
       }
      .working-hour{
        flex:1;
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 4px 0px;
        .start-from{
          margin-right: 8px;
          padding:4px
        }
        .end-to{
          margin-left: 8px;
          padding:4px
        }
      }
    }
  }

  .customer-service-max{
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.4;
    padding: 8px 0px;
    .setting-label{
      flex:1;
      width: auto;
      padding: 0px;
    }
    .setting-values{
       width: 70px;
    }
    select{
      padding: 8px 16px;
    }
  }




  .welcome-message, .waiting-list-message, .out-office-message { 
    padding:16px;
  }
  .watchers{
    padding:16px;
  }

  .customer-service-max , .working-hours {
    padding:16px;
  }
}
