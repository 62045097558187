.input {
    position: relative;
    outline: none;
    border: 0 none;
    width: 100%;
    display: flex;
    align-items: center;
}

.input .clear-button {
    margin-left: -30px;
    font-size: 120%;
    opacity: 0.2;
}
.input .variable-button {
    margin-left: -50px;
    opacity: 0.8;
    font-size: 120%;
}

.input label {
    background-color: transparent;
    position: absolute;
    top: 1px;
    left: 6px;
    font-size: 100%;
    padding: 0 6px;
    color: #aaa;
    transition: 50ms;
    height: 94%;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.input label span {
    margin-left: 8px;
    padding: 0 2px;
    font-size: 90%;
    opacity: 0.5;
    visibility: hidden;
}

.input label span:before {
    padding-right: 8px;
    content: ' - ';
}

.input.disabled {
    opacity: 1;
    user-select: text;
    cursor: default;
}

.input.disabled input {
    cursor: text;
    border-color: #eee;
    background-color: #eee;
    color: #888;
}

.input.disabled input:hover {
    border-color: #ddd;
}

.input.disabled input:focus + label,
.input.disabled input:not(:placeholder-shown) + label,
.input.disabled input:focus:not(:placeholder-shown) + label,
.input label.with-value {
    background: linear-gradient(0deg, #eee 50%, rgba(255, 255, 255, 0) 50%);
}

.input.disabled .clear-button {
    display: none;
}


.input input:focus + label,
.input label.with-value,
.input input:not(:placeholder-shown) + label,
.input input:focus:not(:placeholder-shown) + label {
    height: auto;
    font-size: 65%;
    background: linear-gradient(0deg, #fff 60%, #fff0 32%);
    top: -5px;
    left: 9px;
    color: #3949ab;
}

.input input:focus + label span,
.input input:not(:placeholder-shown) + label span,
.input input:focus:not(:placeholder-shown) + label span {
    visibility: visible;
}

.input input:not(:placeholder-shown) + label,
.input label.with-value {
    color: #666;
}

.input.has-value .clear-button {
    opacity: 0.7;
}

.input:hover input {
    border-color: #666;
}

.input input:focus {
    border-color: #3949ab;
}

.input input {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 12px;
    transition: 150ms;
    outline: none;
}

.input:has(.clear-button) {
    input {
        padding-right: 30px;
    }
}

.input.bindable input {
    padding-right: 60px;
}
.input .inline-variables {
    padding-right: 55px;
}

.input.error:after {
    content: 'Error: ' attr(data-error);
    position: absolute;
    padding: 0 6px;
    transition: 150ms;
    display: flex;
    align-items: center;
    height: auto;
    font-size: 65%;
    background-color: #fff;
    bottom: -5px;
    left: 9px;
    color: #d69d9d;
}

.input.error input {
    color: #d32f2f;
    //box-shadow: inset 0px 0px 10px 10px #00000022;
}

.input.error input:not(:placeholder-shown) + label,
.input.error input:focus + label {
    color: #d32f2f;
}

.input.error input,
.input.error input:focus,
.input.error input:focus-visible {
    border: 1px solid #d32f2f;
    border-left: 3px solid #d32f2f;
}

.input .variables {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 12px;
    transition: 150ms;
    outline: none;
    padding-right: 30px;
}
.input input.inline-variable {
    margin: 0 2px;
    display: inline-block;
    width: auto;
    min-width: 0;
    max-width: 1000px;
    padding: 2px;
    border: 0 none;
    text-align: center;
    background-color: #eee;
    user-select: none;
    text-transform: capitalize;
    font-family: monospace;
    pointer-events: none;
    cursor: default;
}
