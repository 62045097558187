.email-message-content{ 
  flex:1;
  padding-left:16px;
  padding-right:50px;
  position: relative;
  max-width: calc(100% - 50px);

  .message-header{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px; 
    // height: 16px;
    .channel{ font-size:90%; padding-bottom: 2px; opacity: 0.6; user-select: none; margin-right: 4px; }
    .channel.wecom{color:#2462a5;}
    .channel.wechat{color:#1bbe21;} 
    .time {   font-size:80%; padding-bottom: 2px; opacity: 0.3; user-select: none;   }
    .sender{  font-size:80%; padding-bottom: 2px; color:#777; user-select: none; max-width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; } 
    .sender:after{ content: ' - '; font-size:80%; padding-bottom: 2px; color:#777; user-select: none;  margin:0px 8px; } 
    // .role{  font-size:80%; color:#fff; opacity: 1; user-select: none;  border-color: #2e81da; padding: 4px 8px; border-radius: 2px; margin-left:8px;   }
    // .role.user{  background-color: #bbb;  }
    // .role.admin{  background-color: #a7afb8;  }
    // .role.watcher{  background-color: rgb(216, 199, 44);  }
    // .role.customer{  background-color: rgb(216, 44, 44);  }
    .internal{  font-size:80%; color:rgb(218, 152, 46); font-weight: bold; user-select: none;  background-color: #fff; padding: 4px 8px; border-radius: 2px; margin-left:8px;  } 
    .system{  font-size:80%; color:rgb(218, 152, 46); font-weight: bold; user-select: none;  background-color: #fff; padding: 4px 8px; border-radius: 2px; margin-left:8px;   } 
  }
  .message{ 
    background-color: #fff; padding: 12px 16px; position: relative; font-size: 100%; border-radius: 4px; display: inline-block;
    min-width: 80px;
    // text-align: center;
    word-break: break-all;
    line-height: 1.5;
    
    a{
      color: rgb(18, 44, 123);
    }
    a:hover{
      color: rgb(18, 14, 86);
    }
    .subject{
      background-color: #00000015;
      padding: 2px 8px;
      font-weight: 500;
      border-radius: 4px;
      text-decoration: none;
    }
    .internal-event{
      display: block;
      &.--with-broder{ 
        border-bottom: 1px solid #00000011;
        padding-bottom:8px;
        margin-bottom:8px;
      }
    }

    .internal-event:has(+.empty){
      padding-bottom:0px;
      margin-bottom:0px;
      border-bottom: 0px none;
    }

    &.type-video,  &.type-image {
      padding: 0px; 
      background-color: transparent !important;
      &:before{
        display: none;
      }
    }

  }
  .message:before{ content:' '; width:10px; height: 10px;  background-color: #fff; transform: rotate(45deg); position: absolute; left:-5px;
    z-index: 0;
  }
  
  .message-translate{ border-top:1px solid #00000011; padding-top: 10px; margin-top: 10px; text-align: left; border-radius: 0px 0px 4px 4px; font-style:italic;  font-size:80%; color:#222; }
  .message.--translated{   border-radius: 4px 4px 0px 0px;  }
  .message-replied{ user-select: none; border-top:1px solid #eeee; background: #e2e2e2; color:#777; padding-left: 20px; font-size: 70%; margin-top: 2px; margin-left: 0px; font-style:italic; cursor: pointer;}
  .message-translate:before{ content:''; display: none;    }
  .message-replied:before{ content:'➡'; display: inline-block; position: relative; transform: rotate(0deg); font-style: normal; background-color: transparent; padding-right: 10px;    }
  .starred{ 
    width:16px; height: 16px; background-position: center; background-repeat: no-repeat; background-size: auto 100%;
    position: absolute;  
    bottom: 13px;
    left: 2px;
  }

  .image-message{
      max-width: 200px;
      max-height: 200px;
      cursor: pointer;
  }

  .audio-message{
    width: 100%;
    min-width: 200px;
    max-width: 300px;
  }
  .video-message{

      cursor: pointer;
      video{
          max-width: 300px;
          max-height: 200px; 
          width: 100%;
      }
      .play-icon{ 
        font-size: 50px;
        width:50px;
        height: 45px;
        border-radius: 4px;
        background-color: #00000099;
        color:#fff;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }

      .link-message{

        cursor: pointer;
          gap: 8px;
          grid-gap:8px;
          display: grid; 
          position: relative;
 
          grid-template-columns: 60px 1fr;
          grid-template-rows: auto;
          grid-template-areas: 
            "title title"
            "image descr"
            "url url"
            ;
              .message-title{ grid-area: title; font-weight: bold; }
              .message-desc{grid-area: descr; font-size: 90%; padding-top: 4px; }
              .message-image{

                grid-area: image; width: 60px; height: 60px;  
                background-position: center;
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-color: #f2f2f2;
                border-radius: 4px;
              }
              .message-url{
                grid-area: url;  
                text-decoration: none; font-size: 80%; 
              
                padding-top: 8px;
                border-top:1px solid #00000011;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
        }


      // .miniprogram-message{

      //   cursor: pointer;
      //   gap: 8px;
      //   grid-gap:8px;
      //   display: grid; 
      //   position: relative;

      //   grid-template-columns:  1fr; 

      //   grid-template-rows: auto;
      //   grid-template-areas: 
      //     "title"
      //     "image"
      //     "info" 
      //     ;
      //     align-items: center ;
      //       .message-title{ grid-area: title; font-weight: bold; } 
      //       .message-image{
      //         grid-area: image; width: 200px; height: 200px;  
      //         background-position: center;
      //         background-size: 100%;
      //         background-repeat: no-repeat;
      //         background-color: #f2f2f2;
      //         border-radius: 4px;
      //       }
      //       .message-info{
      //         grid-area: info;   
      //         text-decoration: none; font-size: 80%; opacity: 0.5; 
            
      //         padding-top: 8px;
      //         border-top:1px solid #00000011;
      //       }
      // }


      .miniprogram-message{

        gap: 8px;
        grid-gap:8px;
        display: grid; 
        position: relative;

        grid-template-columns:  1fr; 

        grid-template-rows: auto;
        grid-template-areas: 
          "title"
          "image"
          "info" 
          ;
          align-items: center ;
            .message-title{ grid-area: title; font-weight: bold; } 
            .message-image{

              cursor: pointer;
              grid-area: image; width: 200px; height: 200px;  
              background-position: center;
              background-size: 100%;
              background-repeat: no-repeat;
              background-color: #f2f2f2;
              border-radius: 4px;
            }
            .message-info{
              grid-area: info;   
              text-decoration: none; font-size: 80%; opacity: 0.5;
              padding-top: 8px;
              border-top:1px solid #00000011;
             }
      }



      .location-message{

        gap: 8px;
        grid-gap:8px;
        display: grid; 
        position: relative;
        max-width: 250px;
        grid-template-columns:  1fr; 

        grid-template-rows: auto;
        grid-template-areas: 
          "name"
          "address"
          "map"
          "info" 
          ;
          align-items: center ;
            .message-name{ grid-area: name; font-weight: bold; } 
            .message-address{ grid-area: address;   } 
            .message-map{

              cursor: pointer;
              grid-area: map; 
              width: 100%; 
              height: 200px;  
              max-width: 250px; 
              background-position: center;
              background-size: 100%;
              background-repeat: no-repeat;
              background-color: #f2f2f2;
              border-radius: 4px;
            }
            .message-info{
              grid-area: info;   
              text-decoration: none; font-size: 80%; opacity: 0.5; 
              padding-top: 8px;
              border-top:1px solid #00000011;
            }
      }


      .file-message{

        gap: 0px 8px;
        grid-gap:0px 8px;
        display: grid; 
        position: relative;
        max-width: 300px;
        grid-template-columns: 44px 1fr; 

        grid-template-rows: auto;
        grid-template-areas: 
          "icon name" 
          "icon desc" 
          "info info" 
          ;
          align-items: center ;
            .message-name{ grid-area: name; font-weight: bold; align-self: flex-end; white-space: nowrap  } 
            .message-desc { grid-area: desc; align-self: flex-start; font-size: 90%; }
            .message-image{ grid-area: icon; width: 44px; height: 60px;  
              background-position: center;
              background-size: 100%;
              background-repeat: no-repeat; 
            }  

            .message-download{  
                position: absolute; width: 106%; height: 70px; top:-5px; left:-3%; background-color: #00000066; z-index: 1; color:#fff !important; font-weight: bold; font-size: 150%; display: flex;align-items: center; justify-content: center; text-decoration: none; 
                border-radius: 4px; display: none;
                .ficon{
                  font-size: 150%;
                }
             }  
             &:hover .message-download{
              display: flex;
             }
            .message-info{
              grid-area: info;   font-size: 80%; opacity: 0.5;
              padding-top: 6px;
              border-top:1px solid #00000011;
            }
            
      }


 
       
}




.email-message-content .message .message-delivery.message-sent{
  color:#00000044;
  cursor: default;
  .ficon{
    font-size: 20px;
    font-weight: bold;
  }
}

.email-message-content .message .message-delivery.message-synced{
  color:#0084ff8b;
  cursor: default;
  .ficon{
    font-size: 20px;
    font-weight: bold;
  }
  
}

.email-message-content .message .message-failed{
  background-color: #ff0000;
  color:#fff;
  cursor: pointer; 
  
  &:hover{
    box-shadow: inset 0px 0px 100px 100px #00000022;
  }
}
.email-message-content .message .message-delivery {
  position: absolute;
   right:-30px; 
  left: auto;
  user-select: none;
  opacity: 0.7;

  top:12px;
  // background-color: #ff0000;
  // color:#fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

 
  .ficon{
    font-size: 12px;
  }
  
}

.me .email-message-content .message .message-delivery{
  position: absolute;
  right:auto; 
  left:-30px;
  
}



.email-message-content .message .message-edit {
  position: absolute;
   right:auto; 
  left: -30px;
  user-select: none;
  opacity: 0.7;

  top:8px; 
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center; 
  color:#000;
  .ficon{
    font-size: 24px;
  }
  
}
 


.email-message-content  .extras{
  color: #666;
  .possible-response{
    a{
      
    }
  }
  .possible-tags{
    .possible-tag{}
  }
  .possible-reason{}
}




@media (max-width:768px) {

  
  .file-message{
 
        .message-name{  letter-spacing: 0px;  } 
        
        
  }
}

