
.variable-editor{
  width: 800px;
}
.variable-editor-content{
  display:flex;
  max-height: 72vh;
  overflow: hidden;
}
.variable-list{

  width:280px;
  display: flex;
  flex-direction: column; 
 .variable-search{
  width:88%;
  margin:8px;
 }
 
 
}
.variable-list-items{
  overflow: auto; 
  flex:1;
 min-height: 0;
//  max-height: 100%;
}

.variable-edit{
  padding: 8px;
  margin-left: 8px;
  flex:1;
  .text-content{
    background-color: #fff;
    margin-bottom: 8px; 
    max-height: 30vh;
    min-height: 100px;
    overflow: auto;
    font-size: 110%;
    word-break: break-all;
    &.preview-mode{
      max-height: 30vh;
    }
  }
}