.short-url {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.disabled{
        user-select: none;
        pointer-events: none;
        opacity: 0.5;
        textarea{
            background-color: #ddd;
        }
        label{
            background-color: transparent;
            color: #888;
        }
    }

    &.error:after {
        content: 'Error: ' attr(data-error);
        position: absolute;
        padding: 0 6px;
        transition: 150ms;
        display: flex;
        align-items: center;
        height: auto;
        font-size: 65%;
        background-color: #fff;
        bottom: -5px;
        left: 9px;
        color: #d69d9d;
    }

    &.error textarea {
        color: #d32f2f;
        border: 1px solid #d32f2f;
        border-left: 3px solid #d32f2f;
    }

    &.error label {
        color: #d32f2f;
    }

    textarea {
        width: 100%;
    }

    .long-url-label {
        font-size: 70%;
        margin-left: 10px;
        position: relative;
        bottom: -8px;
        background-color: #fff;
        padding: 2px 4px;
        color: #184c84;
        margin-bottom: 0;
        display: inline-block;
        flex: none;
        width: 120px;
    }

    .long-url {
        padding-top: 16px;
        border-radius: 4px 4px 0 0;
        border: 1px solid #ddd;
    }

    .shorten-url {
        padding: 4px 12px;
        color: #0c249b;
        font-size: 90%;
        background-color: #eee;
        border: 1px solid #ddd;
        border-radius: 0 0 4px 4px;
        margin-top: 0;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
    .shortened-url {
        padding: 4px 12px;
        color: #999;
        font-size: 90%;
        background-color: #ddd;
        border: 1px solid #ddd;
        border-radius: 0 0 4px 4px;
        margin-top: 0;

        a {
            color: #999;
            font-size: 90%;
        }
    }

    .shortened-error {
        font-size: 80%;
        padding: 6px;
        background-color: #ffc7c7;
        border: 1px solid #ffcdcd;
        border-radius: 0 0 3px 3px;
    }

    .url-whitelist {
        font-size: 80%;
        padding: 6px;
        background-color: #eee;
        border: 1px solid #ddd;
        color: #004cff;
        cursor: pointer;
        user-select: none;
    }
}

.short-url-whitelist-popover {
    padding: 8px;
    padding-bottom: 16px;
    max-width: 320px;
    font-size: 90%;

    .short-url-whitelist-item {
        border-bottom: 1px solid #eee;
        width: 100%;
        padding: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
