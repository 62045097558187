
  .audio-player { 
    width:100%;
    display: flex;
    flex-direction: row; 
    position: relative; 
  }
  .audio-player audio{
      width:1px;
      height: 1px;
  }
  .audio-player .control-button{
      font-size: 200%;
  }
  .audio-player .progress{ 
     flex:1; 
   position: absolute;
   left:0px;
   top:0px;
   width:0%;
   height: 100%;  
    // background-image: url(/progress.png);
    // background-position: 0px;
    // background-repeat: no-repeat;
    // background-size: auto 100%; 
    // overflow:hidden;
    background-color: #00000011;

  }
  .audio-player .current-time{ 
      font-size: 80%;
      align-self: center;
  }
  .audio-player  .freq {
    margin-left: 10px;
    margin-right: 10px;
     flex:1;  
    background-image: 'url(/progress.png)';
    background-position: 0px;
    background-repeat: no-repeat;
    background-size: auto 100%; 
    position: relative; 
     
  }