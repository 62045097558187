.wechat.page-followers {
    .wechatid {
        font-size: 80%;
    }
}

.location > span {
    font-size: 75%;
    color: #577682;
    display: block;
    white-space: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid-detail-popoover {
    padding: 16px;
    // color: #577682;
    max-width: 300px;

    .line {
        display: block;
        padding: 2px 0;
        // color: #577682;

        b {
            font-weight: bold;
            color: #3e55bd;
        }

        span {
            color: #577682;
            font-size: 90%;
        }
    }

    h3 {
        color: #37434a;
        font-size: 120%;
        margin-top: 16px;
        padding-bottom: 8px;
        font-weight: 500;
    }

    p {
        color: #577682;
        padding-bottom: 36px;
    }

    .setting-button {
        position: absolute;
        align-self: flex-end;
        right: 16px;
        bottom: 16px;
    }
}

.grid-open-button,
.grid-edit-button,
.grid-tag-button,
.grid-customer-button,
.grid-filler-button {
    font-size: 150%;
    font-weight: 500;
}
