.chat-right-panel-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    left: 0;
    bottom: 0;

    &.right-panel-disabled * {
        pointer-events: none;
        user-select: none;
        opacity: 0.7;
        background-color: transparent;

        .user-avatar {
            display: none;
        }
    }
}

.chat-right-panel {
    user-select: none;
    animation: fade-chat-right-panel 300ms forwards;
    animation-iteration-count: 1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    background-color: #fff;
    height: 100%;
    width: 300px;
    box-shadow: 0 0 20px 2px #00000022;

    @keyframes fade-chat-right-panel {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        50% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            opacity: 1;
        }
    }
}

.chat-right-panel .chat-detail {
    display: flex;
    flex-direction: column;
    height: 100%;

    .chat-name {
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
    }

    .chat-detail-content {
        overflow: auto;
        flex: 1;
    }

    .user-avatar {
        padding: 8px 16px; 

        img {
            width: 48px;
            height: 48px;
        }
    }

    .chat-assigned-member {
        border-bottom: 1px solid #eee;
        padding: 8px;
        padding-top: 0;

        &.show-top-border {
            border-top: 1px solid #eee;
            margin-top: 8px;
            padding-top: 8px;
        }
        .user-avatar {
            padding-left: 8px;

            img {
                border: 3px solid #ddd;
                width: 36px;
                height: 36px;
                object-fit: contain;
            }
        }
        .chat-assigned-member-head {
            padding: 8px;
            font-weight: 500;
        }
    }

    .chat-members {
        display: flex;
        flex-direction: column;
        padding: 8px;
        border-bottom: 1px solid #eee;

        .chat-members-head {
            padding: 8px;
            font-weight: 500;
        }

        .chat-members-list {
            padding: 4px 8px;
        }

        .chat-member-avatar {
            object-fit: contain;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 4px;
            margin-top: 4px;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            border: 3px solid #ddd;

            &.inactive {
                // opacity: 0.3;
                filter: grayscale(100%);
            }
        }

        .chat-member-avatar.user {
            border-color: #ddd;
        }

        .chat-member-avatar.admin {
            border-color: rgb(164, 204, 235);
        }

        .chat-member-avatar.customer {
            border-color: rgb(248, 206, 201);
        }

        .chat-member-avatar.watcher {
            border-color: rgb(150, 180, 145);
        }

        .chat-members-count {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            border: 1px solid #ddd;
            margin-right: 4px;
            margin-top: 4px;
            cursor: pointer;
        }

        .iconbutton {
            margin-right: 4px;
            margin-top: 4px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            border: 1px solid #ddd;
        }
    }

    .chat-labels {
        display: flex;
        flex-direction: column;
        padding: 8px;
        border-bottom: 1px solid #eee;

        .chat-labels-head {
            padding: 8px;
            font-weight: 500;
        }
        .chat-labels-list {
            padding: 0;

            .no-subjects-note {
                padding: 8px 16px;
                opacity: 0.4;
            }
        }

        .chat-label-text {
            display: inline-block;
            background-color: #eee;
            border-radius: 6px;
            margin: 4px;
            cursor: pointer;
            padding: 4px 8px;
            font-size: 90%;
        }

        .chat-labels-count {
            width: 36px;
            border-radius: 40px;
            display: inline-block;
            padding: 4px 12px;
            border: 1px solid #ddd;
            margin-right: 4px;
            margin-top: 4px;
            cursor: pointer;
        }

        .iconbutton {
            width: 36px;
            border-radius: 40px;
            display: inline-block;
            justify-content: center;
            border: 1px solid #ddd;
            font-size: 100%;
            padding: 4px 12px;
            margin-top: 4px;
        }
    }
}

.chat-right-panel > div {
    animation: fade-details 300ms forwards;
    animation-iteration-count: 1;

    @keyframes fade-details {
        0% {
            transform: translateX(0%);
            opacity: 0;
        }

        50% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0%);
            opacity: 1;
        }
    }
}

.chat-right-panel .customer-detail {
    display: flex;
    flex-direction: column;
    height: 100%;

    .customer-detail-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
        line-height: 1;
        font-size: 100%;
        cursor: pointer;

        .ficon {
            font-size: 120%;
            line-height: 1;
            min-height: 0;
        }
    }
    .customer-detail-content {
        overflow: auto;
        flex: 1;
    }
}

.chat-right-panel .chat-notes {
    display: flex;
    flex-direction: column;
    height: 100%;

    .chat-notes-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
        line-height: 1;
        font-size: 100%;
        cursor: pointer;

        .ficon {
            font-size: 120%;
            line-height: 1;
            min-height: 0;
        }
    }

    .chat-note-list {
        overflow: auto;
        flex: 1;
        padding: 16px;
        background-color: #fafafa;
        display: flex;
        flex-direction: column-reverse;

        .chat-note-item {
            border-radius: 4px;
            border: 1px solid #ddd;
            background-color: #fff;
            padding: 16px;
            margin-bottom: 16px;

            .chat-note-title {
                font-size: 80%;
                opacity: 0.7;
                margin-bottom: 8px;
            }
        }
    }

    .chat-note-text {
        padding: 16px;
        width: 100%;
        height: 100px;
        min-height: 80px;
        border: 0 none;
        border-top: 1px solid #ddd;
        resize: none;
        outline: none;
        border-radius: 0;
        margin-top: 8px;
        flex: none;
    }

    .button-wrapper {
        margin: 16px;
    }
}

.chat-right-panel .chat-history {
    display: flex;
    flex-direction: column;
    height: 100%;

    .chat-history-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #ddd;
        line-height: 1;
        font-size: 100%;
        cursor: pointer;

        .ficon {
            font-size: 120%;
            line-height: 1;
            min-height: 0;
        }
    }
    .chat-history-list {
        overflow: auto;
        flex: 1;
        background-color: #eee;

        .chat-history-item {
            padding: 16px;
            background-color: #fff;
            border-bottom: 2px solid #eee;

            .chat-message-content {
                padding-right: 0;
                padding-left: 0;
                max-width: 100%;

                .video-message video {
                    max-width: 250px;
                }

                .message-header {
                    display: block;
                    opacity: 0.8;

                    .sender {
                        opacity: 0.8;
                    }

                    .ficon.channel {
                        float: left;
                    }

                    .time {
                        opacity: 0.6;
                    }

                    .internal {
                        padding: 0;
                        margin: 0;
                        opacity: 0.7;
                    }
                }
                .message {
                    padding: 0;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .chat-history-type {
        border: 0 none;
        outline: none;
        padding: 16px;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        opacity: 0.6;
    }
}

.edit-chat-detail-popup {
    width: 250px;
    max-width: 100%;
    padding: 8px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input {
        margin-top: 8px;
    }

    .avatar {
        width: 80px;
        height: 80px;
        background-color: #eee;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        margin-bottom: 8px;

        .ficon {
            display: none;
        }

        &:hover {
            .ficon {
                display: block;
            }
        }

        &:hover:after {
            content: ' ';
            background-color: #00000022;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
