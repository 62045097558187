.chart-wrapper {
    border-radius: 4px;
    padding: 16px;
    position: relative;

    .chart-axis {
        position: absolute;
    }

    .chart-time-ranges{
        z-index: 1;
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 8px;
        .chart-time-range{ 
           .button{
            padding: 4px;
           }
        }
    }
}

.chart-wrapper.no-padding {
    padding: 0;
}

.chart-wrapper.dark-bg {
    background: #3949ab;
    background: linear-gradient(180deg, #3d54b5 0%, #3949ab 100%);
    color: #fff;

    select {
        color: #fff;
    }

    .dropdown {
        color: #fff;
    }
}

.chart-wrapper.light-bg {
    background: #fafafa;
    background: linear-gradient(180deg, #fff 0%, #fafafa 100%);
    color: #000;

    select {
        color: #000;
    }

    .dropdown {
        color: #000;
    }
}

.chart-labels {
    display: flex;
    margin: 16px;
    flex-wrap: wrap;

    .chart-label {
        padding: 8px;
        display: grid;
        gap:2px 8px;
        grid-gap:2px 8px;
        grid-template-columns: 16px auto auto;
        grid-template-rows: auto;
        grid-template-areas: 
        "color label label"
        "color value value";
        align-items: center;
        font-size: 80%;
        font-weight: 500;
        max-width: 150px;

        .chart-label-text {
            grid-area: label;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            user-select: none;
        }

        .chart-label-value {
            grid-area: value;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            user-select: none; 
            font-weight: normal;
            font-size: 90%;
        }

        .chart-label-color {
            grid-area: color;
            width: 16px;
            height: 16px;
            min-width: 16px;
            border-radius: 3px;
            background-color: #ff0000;
            margin-right: 4px;
        }
    }

    .button-wrapper {
        margin-top: 8px;
    }
}

.chart-data-options {
    font-weight: 500;
    margin-bottom: 16px;

    select {
        display: inline-block;
        border: 0 none;
        padding: 4px;
        padding-left: 12px;
        margin: 4px;
        padding-right: 32px;
        outline: none;
        width: auto;
        font-weight: 500;
        border-radius: 4px;
        background-color: #00000011;
    }

    span {
        display: inline-block;
        margin: 0 4px 12px 4px;
        min-height: 14px;
    }

    .dropdown {
        display: inline-block;
        width: auto;
        max-width: 200px;
        padding: 0 2px;
        margin: 0 4px;
        background-color: #00000011;

        .arrow {
            border: none;
        }

        .value {
            padding: 4px;
        }

        .options {
            .option {
                color: #444;
                padding: 5px 6px;
            }
        }
    }
}

@media (max-width: 768px) {
    .chart-labels {
        margin: 0px;
        margin-left: 16px;
        .chart-label {
            // max-width: 100px;
        }
    }
}
