.page-customscripts .datagrid-rows .datagrid-cell.multiline span {
    font-size: 90%;
    opacity: 0.8;

    u {
        text-decoration: none;
        color: #008d00;
    }

    a {
        color: #00299a;

        &:before {
            content: '"';
        }

        &:after {
            content: '"';
        }
    }
}


.page-customscripts .datagrid-rows .message-type {
    display: flex;
    align-items: center;

    .ficon {
        font-size: 130%;
        margin-right: 4px;
    }
}