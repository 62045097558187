
.data-history-items{
  max-width: 100%;
  width: 700px;
}

.data-history-items .history-item{
    display:flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    padding:8px 0px ;
}

.data-history-items .history-item.insert{
  background-color: #00ff2622;
}
.data-history-items .history-item.update{
  background-color: #ffd50038;
}

.data-history-items .history-item.delete{
  background-color: #ff000022;
}

.data-history-items .history-item.sync{
  background-color: #00000011;
}

.data-history-items .history-item>*{
  padding: 8px;
}

.data-history-items .history-item .action{
    width:80px;
    text-transform: uppercase;
}

.data-history-items .history-item .user{
  width:120px;
}

.data-history-items .history-item .date{
  width:170px;
}

.data-history-items .history-item .label{
  flex:1;
}
 