.tags-popover {
    padding: 0;
    max-width: 300px;

    .tags-desc > span {
        padding-right: 10px;
        padding-top: 8px;
        display: inline-block;
    }
}

.popover .tags-popover {
    padding: 16px;
    overflow: auto;
}

.tags-popover .tag {
    margin: 4px;
    // font-size: 100%;
    box-shadow: inset 1px 1px 100px 100px #00000011;
    letter-spacing: 0.1px;
}

.tags-popover .tag .ficon {
    line-height: 0.84;
    border: 1px solid #ffffff44;
    background-color: #00000022;
}

.tags-desc {
    color: #577682;
    line-height: 1.4;
}

.tags-desc b {
    display: block;
    color: inherit;
    margin-bottom: 4px;
}

.tags-desc .ficon {
    padding-left: 4px;
    color: inherit;
    
}

.tag {
    background-color: #767c92;
    border-radius: 40px;
    padding: 8px 12px;
    margin-right: 8px;
    font-size: 80%;
    font-weight: normal;
    display: inline-block;
    color: #ffffffee;
    vertical-align: middle;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
    position: relative;
    min-width: 32px;
}
.tag.--size-small {
    padding: 6px 10px;
    font-size: 80%;
    line-height: 1;
}

.tag.square {
    border-radius: 4px;
}

.tag span {
    color: inherit;
    vertical-align: middle;
}

.tag .highlight {
    color: #fff !important;
    font-weight: bold;
}
.tag.bordered:after {
    content: ' ';
    border: 3px dashed #00000022;
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    left: 0;
    //  box-shadow: inset 0px 0px 0px 2px #ffffff88;
    // filter:saturate(0.8)
    //     background: linear-gradient(135deg, #00000011 25%, transparent 25%) -50px 0,
    //     linear-gradient(-135deg, #00000011 25%, transparent 25%) -50px 0,
    //     linear-gradient(45deg, #00000011 25%, transparent 25%),
    //     linear-gradient(-45deg, #00000011 25%, transparent 25%);
    // background-size: 10px 10px;
}

.tag.minimized {
    width: 8px;
    min-width: 0px;
    padding: 6px 0;
    margin-right: 4px;
    color: transparent;
    display: inline-block;

    height: 20px;
}

.tag.more {
    background-color: transparent;
    color: #444;
    font-size: 100%;
    padding: 8px 0;
    font-size: 80%;
}

.tag .ficon {
    padding: 4px;
    vertical-align: middle;
    color: inherit;
    border: 1px solid #ffffff55;
    margin-left: -10px;
    margin-right: 4px;
    border-radius: 50%;
    font-size: 140%;
    background-color: #00000011;
    line-height: 0.6;
}


.tag.--size-small .ficon.tag-icon, 
    .tag.--size-small.square .ficon.tag-icon {
    
    font-size: 130%; 
    padding: 4px;
     margin-left: -8px;
    background-color: #00000011;
    line-height: 0.6;
    line-height: 1;
}

// .tag.tag.square .ficon {
//     border-radius: 0;
//     padding: 6px;
//     margin-right: 6px;
//     border: 1px solid transparent;
//     background-color: #00000022;
//     margin-left: -12px;
// }

/** Safari fix*/
@media not all and (min-resolution: 0.001dpcm) {
    .tag {
        height: 28px;
    }

    .tag.minimized {
        height: 20px;
    }
}

.tag.red {
    background-color: var(--color-red);
}
.tag.green {
    background-color: var(--color-green);
}
.tag.lightblue {
    background-color: var(--color-lightblue);
}
.tag.blue {
    background-color: var(--color-blue);
}
.tag.darkblue {
    background-color: var(--color-darkblue);
}
.tag.brown {
    background-color: var(--color-brown);
}
.tag.gray {
    background-color: var(--color-gray);
}
.tag.white {
    background-color: var(--color-white);
    color: #444;
}
.tag.black {
    background-color: var(--color-black);
    color: #fff;
}
.tag.yellow {
    background-color: var(--color-yellow);
    color: #444;
}
.tag.silver {
    background-color: var(--color-silver);
    color: #444;
}
.tag.orange {
    background-color: var(--color-orange);
}
.tag.purple {
    background-color: var(--color-purple);
}
.tag.pink {
    background-color: var(--color-pink);
}
.tag.lightsalmon {
    background-color: lightsalmon;
}
.tag.darksalmon {
    background-color: darksalmon;
}
.tag.salmon {
    background-color: salmon;
}
.tag.indianred {
    background-color: indianred;
}

.tag.square .ficon {
    margin-left: -12px;
    margin-top: -8px;
    margin-bottom: -8px;
    line-height: 0.7;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-radius: 0;
    padding: 8px 5px;
    font-size: 190%;
}

.tag.square .ficon.tag-icon {
    margin-left: -12px;
}
