.anonym-header{
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#fff;
    background-color:#3d54b5;
    width: 100%;
    user-select: none;
    .logo{
        height: 30px;
       padding-right: 16px;
       border-right: 1px solid #ffffff22;
       margin-right: 16px;

        background-image: url('../../assets/shopnow.png');
        background-size: auto 100%;
        background-position: left center;
        background-repeat: no-repeat;
        width: 200px;
        cursor: pointer;
        
        &:hover{ 
          filter:invert(0.1);
        }
    }
    
    .middle{
      flex:1;
    }
    .account-select{
      cursor: pointer;
        margin-left: 16px;
       
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        
        &:hover{
          box-shadow: inset 0px 0px 1000px 1000px #00000022;
        }
      .account-logo {
        width:24px;
        height: 24px;
        background-color: #fff;
        margin-right: 8px;
        border-radius: 4px;
      }
      .account-name{
        font-weight: 500;
      }
      .arrow{}
    }
    .right-menu{
        display: flex;
        flex-direction: row;
        align-items: center;
      .chat{ margin:0px 8px; }
      .notification{margin:0px 8px; }
      .refresh{margin:0px 8px; }
      .profile{
         
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 4px;
        // font-weight: 500;
         text-transform: uppercase;
        &:hover{
          box-shadow: inset 0px 0px 1000px 1000px #00000022;
        }
        .ficon{
          margin:0px 4px;
        }
        .arrow{
          font-size: 100%;
        }
      }
    }

}





@media (max-width:768px) {
   
  .anonym-header{
    height: 48px;
    border:0px none;
    .logo{ 
     padding-right: 0px; 
     margin: 0px 0px;
     width: 90px; 
     background-size: auto 90%;
     background-position: left center; 
  }
    .account-select{ 
        margin-left: 2px; 
        &:hover{
          box-shadow: none;
        }
      .account-logo { 
        margin-right: 4px;
      }
      .account-name{
        display: none;
      }
      .arrow{}
    }

    .right-menu{ 
      .chat{ margin:0px 0px; }
      .notification{margin:0px 0px; }
      .refresh{margin:0px 0px; }
      .profile{ 
        padding: 2px; 
        text-transform: uppercase;
        &:hover{
          box-shadow: inset 0px 0px 1000px 1000px #00000022;
        }
        span{
          display: none;
        }
        .ficon{
          margin:0px 0px;
        }
        .arrow{
          font-size: 130%;
        }
      }
    }

    
  }


}
  


.account-select-popover{
  padding: 8px;
  .account-item   {
    padding: 8px 12px;
    padding-right: 16px;
    cursor: pointer; 
     
      display: flex;
      flex-direction: row;
      align-items: center; 
      
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000022;
      }
      .account-logo {
        width:24px;
        height: 24px;
        background-color: #fff; 
        border-radius: 4px;
        margin-right: 8px;
      }
      .account-name{
        font-weight: 500;
      } 
  }
}