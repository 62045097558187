.marketing:has(.overview-page) {
    .tab-header {
        margin: 0 8px 0 8px;
    }

    .page {
        padding: 16px 8px;
    }
}

.marketing-overview.overview-page {
    .campaign-history {
        margin: 8px;
        min-width: 100%;

        .overview-card {
            max-width: 100%;
        }

        .datagrid {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .marketing-overview.overview-page {
        .campaign-report {
            min-width: 100%;
        }
    }
}
