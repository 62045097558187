.color-input{
    width: 100%;
    position: relative;
}

.color-input .color-input-slider{
    position: absolute;
    left: 0px;
    top:0px;
    background-color: transparent;
    width: 20%;
    margin-left: 50%; 

z-index: 2;
}
.color-input-color-cover{
    width: 100%;
        height: 4px;
        position: absolute;
        background-color: #eee;
        top:0%; 
        width: 19%;
        margin-left: 75%; 
        z-index: 1; 
        height: 100%;
        pointer-events: none;
        user-select: none;
}

// .color-input-slider-track{
//     width: 100%;
//     height: 4px;
//     position: absolute;
//     top:50%;
//     margin-top: -2px;
//     width: 20%;
//     margin-left: 50%; 
//     z-index: 1;
//     filter: invert(1);

// }

input[type="color"]{
    min-height: 36px;
    padding: 0px; 
    appearance: none;
    --webkit-appearance:none;

	border: none;
}




input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
    width: 20%;
    margin-left:80%;
    border:3px solid #eee;
    border-radius: 6px;
    overflow: hidden;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

.input:has(input[type="color"]) input:not(:placeholder-shown) + label,
.input:has(input[type="color"]) input:focus + label,
.input:has(input[type="color"]) input:active + label,

    .input:has(input[type="color"]) label{
    background: transparent;
    // color:#ffffff;
    // background-color: #ffffff88;
    padding: 1px 4px;
    // text-shadow: 1px 1px 0px #00000088, -1px -1px 0px #00000088, 1px -1px 0px #00000088, -1px 1px 0px #00000088;
    top:auto;
    font-size: 100%;
}


.input:has(input[type="color"]) label:after{
    content:attr(data-value);
    padding-left: 8px;
 
}






 
input[type="range"]{
    min-height: 36px;
    padding: 0px; 
    appearance: none;
    --webkit-appearance:none; 
	border: none;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
     background: #00000044;


    // filter: invert(1);

    box-sizing: content-box;
    height: 3px;
  }
  
  /******** Firefox ********/
  input[type="range"]::-moz-range-track {
    background: inherit;
    border: 2px solid #ffffff66;
    box-sizing: content-box;
    height: 2px;
  }

  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -12px; /* Centers thumb on the track */
    background: #dddddd;  
    height: 26px;
    width: 10px;  
    border-radius: 4px;
    cursor: pointer;  
 }
 input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
}
 


// input[type="color"]::-webkit-color-swatch-wrapper {
// 	padding: 0;
//     width: 50%;
//     margin-left:50%;
//     border:3px solid #eee;
//     border-radius: 6px;
//     overflow: hidden;
// }
// input[type="color"]::-webkit-color-swatch {
// 	border: none;
// }

// .input:has(input[type="color"]) input:not(:placeholder-shown) + label,
// .input:has(input[type="color"]) input:focus + label,
// .input:has(input[type="color"]) input:active + label,

//     .input:has(input[type="color"]) label{
//     background: transparent;
//     // color:#ffffff;
//     // background-color: #ffffff88;
//     padding: 1px 4px;
//     // text-shadow: 1px 1px 0px #00000088, -1px -1px 0px #00000088, 1px -1px 0px #00000088, -1px 1px 0px #00000088;
//     top:auto;
//     font-size: 100%;
// }


// .input:has(input[type="color"]) label:after{
//     content:attr(data-value);
//     padding-left: 8px;
 
// }
