
.no-app-wrapper{
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .no-app-title{
    font-size: 200%;
  }
  .no-app-text{
    width: 100%;
    max-width: 500px;
    padding: 16px;
    text-align: center;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
  }
  .no-app-no-admin{
    padding: 16px;
    background-color: #ffc9c9a6; 
  }
  .ficon{
    font-size: 50px;
    opacity: 0.7;
    margin-bottom: 16px;
  }
}