.verify-page{

  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #eee; 
  height: 100vh;



    .logo{
      align-self: flex-start;
      justify-self: flex-start;
      background-color: #3d54b5;
       height: 80px;
       align-items: center;
       justify-content: flex-start;
       display: flex;
       width: 100%;
       padding-left: 16px;
      img{
        width: 200px;
        padding: 16px;
      }
    }

    .verify-title{ 
      width: 100%;
      font-size:120%;
      padding: 32px;
      padding-bottom: 8px;
 
    }
    .verify-desc{ 
      width: 100%;
      font-size:90%; 
      padding: 0px 32px;
      padding-bottom: 8px;
      a{
        text-decoration: underline;
        cursor: pointer;
      }
    }


    .verify-content{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex:1;
      width: 100%;
    }



}


.verify-wrapper{
  display: flex;
  flex-direction: row;
   
  width: 400px;
} 

.verify{
  flex:1;
    background-color: #fff;
    padding: 0px;
    min-width: 300px;
    width: 40%; 
    max-width: 440px;  
    overflow: hidden;
    border-radius: 4px ; 
     
    .verify-content{
      padding: 16px 32px;
       

      .email{
         margin-bottom: 16px;
      }
      .password{
        margin-bottom: 16px;
      }
      .verify-button{ 
        flex:1;
        width: 100%;
      }
     
      .register-button{ 
       
        margin-top: 32px;
        margin-bottom: 16px;
      }
      .forgot-link{ 
        margin-bottom: 16px;
        display: block;
        color:#000;
        opacity: 0.5;
        text-decoration: none;
        align-self: flex-end;
        text-align: right;
        cursor: pointer;
      }
    }
}