.task-popover{
  display:flex;
  flex-direction: column;
  // position: fixed;
  // top:0px;
  // right:16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 4px #00000011;
  width: 316px;
  height: 220px;
  z-index: 10;
  padding: 8px;

  .task-popover-head{
    display:flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding: 8px 16px;
    border-bottom: 1px solid #eee;
    .task-popover-title{
      flex:1;
    }
    .minimize-button{
      cursor: pointer;
    }
  }
  .task-popover-list{
    padding-bottom: 16px;
    flex:1;
    overflow: auto;

    display:flex;
    flex-direction: column-reverse;
    justify-items: center;
    max-height: 200px;
  }
  
}



.task-popover-list-item{
 
  padding: 8px 16px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: grid;
    
  grid-gap: 2px 8px;
  gap: 2px 8px;

  grid-template-columns:  1fr 24px;  
  grid-template-rows: auto;
  grid-template-areas: 
    "name progress"
    "time progress" 
    "detail progress" 
    ; 


  &:hover{
    box-shadow: inset 0px 0px 1000px 10000px #00000011;
  }
  .task-name{
    grid-area: name;
    font-size: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .task-start-time{
    grid-area: time;
    font-size: 70%;
    opacity: 0.5;
  }
  .task-detail{
    grid-area: detail;
    font-size: 70%;
    opacity: 0.7;
    a{
      color:#0061a7;
      padding: 0px 8px;
      font-weight: bold;
    }
  }
    .task-start-progress{
      grid-area: progress;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      margin: 0px;
      width: 24px;
      height: 24px;
      position: relative;
      .running, .done, .warning, .error, .waiting {
        display:none;
      }

      &.waiting .waiting{ 
        width: 20px;
        height: 20px;
        opacity: 0.7; 
        font-size: 20px; 
        text-align: center;
      }

      &.running .running{
        color:#333;
        display: block;
        position: absolute;
        top:0px;
        width: 24px;
        height: 24px;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 24px;
        font-size: 24px;
        padding-top: 4px;
         animation: rotate-task-progress 1000ms linear;
        animation-iteration-count: infinite;
          
        
      }
      &.done .done{
        display:flex;
        color:#3bb91b;
      }
      &.warning .warning{
        display:flex;
        color:#cc651b;
      }
      &.error .error{
        display:flex;
        color:#e92e09;
      }
      &.waiting .waiting{
        display:flex;
        color:#3d9de2;
      }
    }

}


@keyframes rotate-task-progress {
  0% {  transform: rotate(0deg); }  
  100% { transform: rotate(359deg);} 
}