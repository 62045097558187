.edit-application.edit-page {
  .info {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "identifier"
      "name";
  }

  .identifier {
    grid-area: identifier;
  }

  .name {
    grid-area: name;
  }
}