.email-connection{

  .email-connection-title{
    font-size: 160%;
    padding: 8px 10px;
    padding-bottom: 0px;
  }
  .email-connection-desc{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

  .email-connection-footer{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

}



.email-providers{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
  flex:1;
  padding-left: 0px;
  padding-right: 16px;

  .email-provider{

   align-items: center;
    padding: 16px;
    border:1px solid #ddd;
    border-radius: 4px;
    margin: 10px;
    cursor: pointer; 
    flex:1;
    max-width: 800px;
    min-width: 250px;

    display: grid;
    grid-template-columns: 48px 1fr;
    grid-template-areas: 
    "icon title"
    "icon desc"
    ;
    grid-gap:0px 16px;
    gap:0px 16px;

    &:hover{
      box-shadow: inset 0px 0px 1000px 10000px #00000011;
    }
    
    &.selected{
      background-color: #1147bb;
      color: #fff;
      .ficon{
        color:#fff;
      }
    }

    
    .email-provider-icon{
       grid-area: icon; 
       font-size: 20px;
       align-items: center;
       display: flex;
       font-weight: bold;
       border-right: 1px solid #00000011;
       background-color: #ffffff22;
       justify-content: center;
       text-align: center;
       border-radius: 8px;
       width: 48px;
       height: 48px;
       .ficon{
        font-size: 32px;
       }
    }
    .email-provider-title{
      grid-area: title;
      font-weight: 500;
    }
    .email-provider-desc{
      grid-area: desc;
      font-size: 80%;
      opacity: 0.7;
    }
  }
}

.email-info{
  max-width: 350px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 400px; 
}
.email-info .formel{  
  margin-bottom: 16px; 
}

.email-info-inner{
  display: flex;
  flex-direction: row;
}

.needhelp{

}


.formel.passive{   
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
  user-select: none;
}