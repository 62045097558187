.filter-panel{

  display: grid; 
  flex-direction: column;
  gap:16px;
  grid-gap: 16px;
  justify-content: center;
  align-items: flex-start;
 padding: 8px;
 grid: auto / auto;
}

.filter-panel .filter-row{
  display: grid;
  
  // grid-template-rows: auto / auto;
  align-items: baseline;
  flex-direction: row;
  gap:8px;
  grid-gap: 8px;
 
  justify-content: center; 
  // border-bottom: 1px solid #eee;
  // padding-bottom: 16px;
  flex-wrap: wrap;


 
grid-template-columns: 4fr 5fr 6fr 1fr;
grid-template-rows: repeat(1fr,1px);
grid-column-gap: 8px;
grid-row-gap: 16px;
  position: relative;
}


.filter-panel .filter-row:not(:last-child):before{
  content:'AND'; 
  width: 22px;
  text-align: center;
  height: 1px;  
  box-shadow: 0px 0px 0px 2px #fafafa;
  grid-area: 2 / 1 / 3 / 5;
  // position: absolute;
  left:0;
  margin-top:-10px;
  transform: translate(14px, 2px);
  font-size: 80%;
  z-index: 1;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#bbb;
}

.filter-panel .filter-row:not(:last-child):after{
  content:' ';
  width: 100%;
  height: 1px;
  background-color: #eee;
  // grid-column:4;  
  grid-area: 2 / 1 / 3 / 5;
}

.filter-panel .filter-row .placeholder{ 
  opacity: 0.3;
  flex: 1;  
}
 
.filter-panel .filter-field{ 
  grid-area: 1 / 1 / 2 / 2;
  min-width:200px; 
  flex:1;
}
.filter-panel .filter-op{ 
  grid-area: 1 / 2 / 2 / 3;
  min-width:250px; 
  flex:1;
}
.filter-panel .filter-value{ 
  grid-area: 1 / 3 / 2 / 4;
  min-width:300px; 
  

  display: grid;
  grid: auto auto  / auto ;
  grid-gap: 8px;
  grid-gap: 8px;
 

}

.filter-panel .filter-and{ 
  width:34px;
  text-align: center;
}


.filter-panel .filter-value-el{ 
  display: flex; 
  align-items: center;  
  flex:1;
}
.filter-panel .filter-value-el.has-or{  
  display: block; 

  display: grid;
  grid: auto /  auto min-content;
  grid-gap: 0px 4px;
  gap: 0px 4px;

  align-items: stretch;
 
}
.filter-panel .filter-value-el.has-or:before{ 
    // content: ' OR '; 
    align-self: center;
    display: flex;
    align-items: center; 
}
.filter-value-remove{
  // width: 80px; 
  // font-size: 80%;
}

.filter-panel .filter-value-el.half{ 
  min-width:150px;
}

.filter-panel .filter-condition, .filter-panel .filter-add{ 
  align-self: flex-end; 
  width: auto; 
  min-width: 0; 
  // width: 200px; 

  justify-self: end;
} 


.filter-panel .filter-condition{
  opacity: 0.7;
} 

.filter-panel .filter-condition.selected{
  opacity: 1;
  background-color: rgb(7, 193, 255); 
} 

// .filter-panel .filter-remove{

//   display: flex;

//   align-items: center;
//   justify-content: center;

//   background-color: #00000011;
//   width:24px;
//   height: 24px;   
//   text-align: center; 
//   vertical-align:middle; 
//   font-style: normal;
//   font-weight: bold;
//   display: flex;
//   align-items: flex-start; 
//   cursor: pointer;
//   border-radius: 50%;
//   line-height: 24px;
//   overflow: hidden;
// }




@media (max-width:768px) {
 
  .filter-panel{
    padding:0px;
  }
.filter-panel .filter-row{
  background-color: #fff;
  display: block;
  padding: 16px; 
  padding-top: 0px;
  position: relative;
}

.filter-panel .filter-row:first-child:before{
  content: 'IF' !important;   
}

.filter-panel .filter-row:before, .filter-panel .filter-row:not(:last-child):before{
  content: 'AND';  
  display: block;
  padding:  16px 0px;
  font-weight: bold;

  
  content:' AND '; 
  width: auto;
  text-align: initial;
  height: auto;  
  box-shadow: none;  
  left:auto;
  margin-top:auto;
  transform: none;
  font-size:100%; 
  background-color: transparent;  
  color:inherit;
  

}

.filter-panel .filter-row>*{ 
  display: block; 
  margin-bottom: 8px;
}
.filter-panel .filter-row .filter-value-el>*{ 
  display: block; 
  margin-bottom: 8px;
}


.filter-panel input.filter-remove{
  position: absolute;
  top:8px;
  right: 8px;
}
}