.toolbar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 8px;
}

.toolbar {
    display: flex;
    width: 100%;

    .dropdown.multiselect .value.empty {
        min-height: 40px;

        .placeholder {
            line-height: 1.4;
        }
    }

    .toolbar-filters {
        display: flex;
        flex: 1;
    }

    .button-wrapper {
        align-self: flex-start;
    }

    .toolbar-applications-filter {
        display: flex;
        align-self: flex-start;
        min-width: 260px;
        max-width: 800px;
        min-height: 40px;
        margin-right: 8px;
        align-items: center;
        width: initial;

        &:has(.dropdown-popup) {
            align-items: initial;
        }

        .content {
            width: 100%;

            .value {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .toolbar-selection-filter {
        display: flex;
        align-self: flex-start;
        min-width: 300px;
        max-width: 500px;
        min-height: 40px;
        margin-right: 8px;
        align-items: center;
        width: initial;

        &:has(.dropdown-popup) {
            align-items: initial;
        }

        .content {
            width: 100%;

            .value {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .toolbar-timeframe-filter {
        align-self: flex-start;
        height: 40px;

        &.mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;

            padding: 4px;
            margin: 0;

            .ficon {
                margin-right: 0;
            }
        }
    }

    .toolbar-filter-switch {
        margin-left: 16px;
    }

    .toolbar-filter-icon {
        display: flex;
        align-items: center;
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        justify-content: center;
        background-color: white;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-left: 8px;
    }

    .dropdown .value:not(.empty) {
        padding: 0 4px;
    }

    .toolbar-sync-button,
    .toolbar-clear-button {
        align-self: flex-end;
        margin-left: 16px;
        min-height: 40px;
        white-space: nowrap;
    }
}

.bot-filter-popover {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;

    .toolbar-filter-switch {
        padding: 8px 0;
        border-bottom: 1px solid #ddd;
        min-width: 150px;
        justify-content: space-between;

        .switch-wrapper {
            order: 1;
            margin-left: 16px;
        }

        .text {
            white-space: nowrap;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}

@media only screen and (max-width: 968px) {
    .toolbar {
        display: flex;
        padding: 12px 8px 0 8px;

        .toolbar-filters {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            > div {
                margin-bottom: 4px;
            }
        }

        .placeholder {
            // font-size: 11px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .toolbar-applications-filter {
            flex: 1;
            min-width: 140px;
            max-width: 260px;
            margin-right: 8px;
            width: calc(100vw / 3 - 16px);
            align-items: center !important;
        }

        .toolbar-selection-filter {
            flex: 1;
            min-width: 140px;
            max-width: 260px;
            margin-right: 8px;
            width: calc(100vw / 3 - 16px);
        }

        .toolbar-timeframe-filter {
            font-size: 80%;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .content {
            .tag {
                padding-right: 0 !important;

                span {
                    font-size: 0;
                }
            }
        }
    }
}
