.forgot-page{

  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #eee; 
  height: 100vh;



    .logo{
      align-self: flex-start;
      justify-self: flex-start;
      background-color: #3d54b5;
       height: 80px;
       align-items: center;
       justify-content: flex-start;
       display: flex;
       width: 100%;
       padding-left: 16px;
      img{
        width: 200px;
        padding: 16px;
      }
    }

    .forgot-title{ 
      width: 100%;
      font-size:120%;
      padding: 24px 16px;
      padding-bottom: 16px;
    }

    .forgot-content{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex:1;
      width: 100%;
    }



}


.forgot-wrapper{
  display: flex;
  flex-direction: row;
   
  width: 680px;
}
.forgot-qr{ 
  width: 280px; 
  background-color: #fff; 
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 0px 4px 4px 0px;  

  overflow: hidden; 

  .forgot-title{ 
    border-left: 1px solid #eee; 
    text-align: center;
  }
  .forgot-content{ 
    border-left: 1px solid #eee; 
    padding-bottom: 32px; 
  }

  .forgot-qr-text{
    width: 200px; 
    font-size: 80%;
    text-align: center;
    opacity: 0.6;

  }
  .qr-code{
    opacity: 0.6;
    flex:1;
  }
}

.forgot{
  flex:1;
    background-color: #fff;
    padding: 0px;
    min-width: 300px;
    width: 40%; 
    max-width: 440px;  
    overflow: hidden;
    border-radius: 4px 0px 0px 4px; 
    

    .forgot-title{
      font-size:120%;
      padding: 32px;
      padding-bottom: 16px;
    }
    .forgot-content{
      padding: 32px;
       

      .email{
         margin-bottom: 16px;
      }
      .password{
        margin-bottom: 16px;
      }
      .forgot-button{ 
        flex:1;
        width: 100%;
      }
     
      .register-button{ 
       
        margin-top: 32px;
        margin-bottom: 16px;
      }
      .forgot-link{ 
        margin-bottom: 16px;
        display: block;
        color:#000;
        opacity: 0.5;
        text-decoration: none;
        align-self: flex-end;
        text-align: right;
      }
    }
}