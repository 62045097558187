
         .edit-landingpage.edit-page{
          .info{
            display: grid;
            grid-gap: 16px;
            gap:16px; 
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas: "author"
  "require-login"
  "health-status"
  "updated-at"
  "enable-comments"
  "custom-read-more-text"
  "sub-landing-page-ids"
  "custom-read-more-height"
  "image"
  "publish-status"
  "show-custom-read-more"
  "status"
  "custom-read-more-need-login"
  "title"
  "description"
  "custom-read-more-url"
  "content"; 
          }
          .author{
      grid-area:author;
    }
  .require-login{
      grid-area:require_login;
    }
  .health-status{
      grid-area:health_status;
    }
  .updated-at{
      grid-area:updated_at;
    }
  .enable-comments{
      grid-area:enable_comments;
    }
  .custom-read-more-text{
      grid-area:custom_read_more_text;
    }
  .sub-landing-page-ids{
      grid-area:sub_landing_page_ids;
    }
  .custom-read-more-height{
      grid-area:custom_read_more_height;
    }
  .image{
      grid-area:image;
    }
  .publish-status{
      grid-area:publish_status;
    }
  .show-custom-read-more{
      grid-area:show_custom_read_more;
    }
  .status{
      grid-area:status;
    }
  .custom-read-more-need-login{
      grid-area:custom_read_more_need_login;
    }
  .title{
      grid-area:title;
    }
  .description{
      grid-area:description;
    }
  .custom-read-more-url{
      grid-area:custom_read_more_url;
    }
  .content{
      grid-area:content;
    }
         }
      
