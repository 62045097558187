.article-options {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    .option-line {
        display: flex;
        align-items: center;
        font-size: 90%;
        padding: 4px 0;
        opacity: 0.8;
        overflow: hidden;
        text-overflow: ellipsis;

        .ficon {
            font-size: 160%;
            margin-right: 4px;
        }
    }
}

.child-row .article-options {
    .option-line {
        padding: 2px 0;
        opacity: 0.7;

        .ficon {
            font-size: 160%;
        }
    }
}

.article-thumb-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 150px;
    height: 100px;
    overflow: hidden;
    background-color: #eee;
}
.article-thumb-image {
    width: 100%;
    height: 100px;
    object-fit: contain;

    &.sub-article {
        padding: 0;
    }
}

.sub-image,
.main-image {
    margin: 4px 0;
    width: calc(100% + 16px);
    display: flex;
    border: 1px solid #eee;
}

.sub-image {
    background-color: #eee;
    // padding: 8px;
}

.statuses .sticker {
    margin-bottom: 4px;
}

.page-articles {
    .datagrid-row {
        &:has(.datagrid-row-group) {
            .datagrid-cell[data-type='system-check'] input {
                margin-top: 44px;
                align-self: flex-start;
            }
        }
    }
}
