.member-contact {
    display: flex;
    flex-direction: column;
    font-size: 90%;

    & > * {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ficon {
            margin-right: 8px;
            font-size: 100%;
        }
    }
}

.member-actions {
    flex: none;
    width: 100px;
    .iconbutton, .ficon{
        font-size: 140%;
    }
    .ficon{
        
    }
}

.popup-wrapper:has(.invite-member-popup) {
    z-index: 90;
}

.invite-member-popup {
    max-width: 440px;

    .invite-form {
        margin: 8px;
        display: grid;
        gap: 16px;
        grid-auto-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'long-name long-name'
            'email email'
            'password password'
            'department department'
            'mobile-number mobile-number'
            'accounts accounts';

        .long-name {
            grid-area: long-name;
        }

        .email {
            grid-area: email;
        }

        .pwd {
            grid-area: password;
        }

        .department {
            grid-area: department;
        }

        .mobile-number {
            grid-area: mobile-number;

            .area-select {
                min-width: 200px;
            }
        }

        .accounts-dropdown {
            grid-area: accounts;
        }
    }
}

.remove-member-popup {
    .remove-member-form {
        .remove-member-desc {
            display: block;
            padding: 8px;
        }

        .remove-single-account {
            color: #3949ab;
        }

        .accounts-dropdown {
            margin-top: 16px;
        }
    }
}

.account-list {
    .members-table-wrapper {
        overflow-x: auto;

        .member-row-header {
            display: flex;
            padding: 12px;
            border-bottom: 1px solid #eee;

            span {
                display: block;
                font-weight: 500;
                flex: 1;

                &.long-header {
                    min-width: 260px;
                }

                &.medium-header {
                    min-width: 140px;
                }

                &.short-header {
                    min-width: 100px;
                }

                &:first-of-type {
                    padding-left: 8px;
                }

                &:last-of-type {
                    max-width: 100px;
                }
            }
        }
    }
}

.member-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .member-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #eee;
        min-height: 60px;

        &:first-of-type {
            padding-left: 12px;
        }

        &:last-of-type {
            min-width: 112px;
            max-width: 112px;
        }
    }

    & > * {
        flex: 1;
    }

    .member-profile {
        display: flex;
        align-items: center;
        padding-left: 8px;
        min-width: 272px;
        
    }

    .member-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 260px;

        & > * {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 0;

            .ficon {
                font-size: 14px;
                margin-right: 8px;
            }
        }
    }

    .member-department {
        min-width: 140px;
    }

    .member-password {
        min-width: 140px;
    }

    .member-login-date {
        min-width: 140px;
    }

    .member-status {
        min-width: 100px;

        .sticker {
            align-self: flex-start;
        }
    }

    .member-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        flex: none;
        max-width: 100px;
    }
}

.member-popover {
    padding: 8px 0;
    min-width: 220px;
    width: 100%;
    max-width: 350px;
    padding: 8px;
    & > div {
        display: block;
        padding: 8px;
        border-bottom: 1px solid #eee;
        opacity: 0.8;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
