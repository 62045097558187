.overview-page {
    .overview-card.chart-overview-card {
        min-height: 340px;

        &:not(.sortable-chosen) {
            transition: 0.3s ease-in-out;

            .card-content {
                transition: 0.3s ease-in-out;
            }

            .chart-wrapper {
                transition: 0.3s ease-in-out;

                canvas {
                    transition: 0.3s ease-in-out;
                }
            }
        }

        &.mock {
            transition: none;

            .card-content {
                transition: none;
            }

            .chart-wrapper {
                transition: none;

                canvas {
                    transition: none;
                }
            }
        }

        .card-header {
            margin-bottom: 0;
        }

        .card-footer {
            margin-top: 0;
        }

        .card-content {
            padding: 0;

            &:has(.funnel-chart) {
                padding: 16px;
            }
        }

        .chart-wrapper {
            overflow: hidden;
            height: 100%;
            // padding: 24px;

            &.scrollable {
                overflow: auto;
            }
        }

        .chart-filter-dropdown {
            position: absolute;
            z-index: 10;
            max-width: 200px;
            margin: 16px;
        }

        &:has(.chart-filter-dropdown) {
            .chart-wrapper {
                padding: 62px 16px 24px 16px;
            }

            .chart-axis {
                top: 62px;
            }
        }

        .chart-filter-dropdown__skeleton {
            position: absolute;
            z-index: 10;
            margin: 16px;
            border-radius: 4px;
        }

        .card-content:has(.empty-state) {
            .chart-filter-dropdown {
                position: absolute;
                top: 42px;
                left: 0;
                max-width: 200px;
                margin: 16px;
            }

            .empty-state {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                min-height: 300px;

                .empty-state-icon {
                    font-size: 40px;
                    opacity: 0.3;
                    margin-bottom: 8px;
                }
            }
        }

        &.large-chart {
            .card-content {
                min-height: 490px;
            }
        }

        &.mock {
            .card-footer {
                // TODO: uncomment this out when we implement status empty/needs setup for chart data on API
                // display: none;
                z-index: 2;
            }

            .wizard-link {
                text-decoration: none;
            }
        }
    }
}
