.page-automessage .edit-page {
    .reply-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 0;
        padding-bottom: 16px;
        border-bottom: 1px solid #eee;

        .reply-inner {
            flex: 1;
        }
    }

    .name,
    .priority,
    .amessage-type,
    .reply-type {
        max-width: 400px;
    }

    .action-title {
        font-weight: bold;
        margin-top: 48px;
        margin-bottom: 16px;
    }

    .form-warning {
        background-color: #ff000022;
        padding: 16px;
        text-align: center;
    }
}
