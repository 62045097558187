.automation-report {
    .view-page-header {
        margin-bottom: 0;
    }

    .report-top-visualizations {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;

        .report-visualization {
            width: calc(50% - 12px);
            margin: 0;
            padding: 16px;
            color: white;
            background: linear-gradient(180deg, #3d54b5 0%, #3949ab 100%);

            .report-visualization-title {
                font-weight: 500;
                margin-bottom: 40px;
            }

            .tab-header {
                border-bottom: 0;

                .tab-title {
                    padding: 12px 16px 12px 0;
                    border-bottom: 0;
                    text-transform: capitalize;

                    &:hover {
                        box-shadow: none;
                    }
                }

                .tab-title:not(.selected) {
                    opacity: 0.5;
                }
            }

            .chart-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-height: 260px;
            }

            .funnel-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: 100%;
                width: 100%;
                overflow: hidden;
            }
        }
    }

    .report-sessions {
        display: flex;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(170, 170, 170, 0.15);

        .report-sessions-left-panel {
            min-width: 300px;

            .list-item {
                border-radius: 4px;
                background: white;
                align-items: flex-start;
                margin-bottom: 32px;
                box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);

                &.selected {
                    background-color: #3d54b5;
                    color: #fff;
                }

                &:hover {
                    box-shadow: inset 10px 1000px rgba(0, 0, 0, 0.15);
                }

                .list-item-status {
                    margin-top: 8px;
                    align-self: flex-start;
                }

                .item-label {
                    width: calc(100% - 80px);
                }

                .item-desc {
                    opacity: 1;
                }

                .ficon:not(.item-arrow) {
                    display: flex;
                    height: 24px;
                    width: 24px;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    padding: 8px;
                    background-color: white;
                    color: #546e7a;
                    margin-right: 16px;
                    border-radius: 3px;
                    border: 1px solid #b0bec5;
                }

                .automation-list-item-desc {
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-size: 120%;
                        opacity: 1;
                        margin: 4px 0;
                    }

                    span {
                        font-size: 80%;
                    }
                }

                .automation-item-warning {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    margin-right: 16px;

                    .ficon {
                        pointer-events: none;
                        margin: 0;
                    }
                }

                .item-status .ficon {
                    border-radius: 0;
                    border: 0;
                    background-color: transparent;
                    font-size: 24px;
                    color: #d32f2f;
                }

                &:not(.selected) {
                    strong {
                        color: black;
                    }
                }
            }
        }

        .report-sessions-right-panel {
            display: flex;
            margin-left: 24px;

            .grid-body {
                max-width: 870px;
            }

            .datagrid-cell:has(.session-data-popover-trigger) {
                :hover {
                    cursor: pointer;
                }
            }

            .session-data-popover-trigger {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;

                span {
                    pointer-events: none;
                }

                .ficon {
                    pointer-events: none;
                    margin-right: 8px;
                    font-size: 14px;
                }
            }
        }
    }

    .report-automation-logs {
        display: flex;

        .grid-body {
            max-width: 1200px;
        }
    }

    .report-admin-logs {
        display: flex;
        padding: 16px 0;
        border-top: 1px solid rgba(170, 170, 170, 0.15);

        .card {
            width: 100%;
        }

        .datagrid {
            width: 100%;
        }

        .grid-body {
            max-width: 1200px;

            .user-avatar img {
                border-radius: 4px;
            }
        }
    }
}

.session-data-popover {
    display: flex;
    flex-direction: column;
    padding: 16px;

    strong {
        margin-bottom: 8px;
    }

    .session-data-row {
        margin-bottom: 4px;
    }
}

.automation-item-warning-popover {
    display: flex;
    flex-direction: column;
    max-width: 417px;
    padding: 16px;
    font-size: 80%;
    line-height: 1.5;
    text-align: left;
    color: #546e7a;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);

    .automation-item-warning-issue {
        display: flex;
        margin-bottom: 8px;

        span:first-of-type {
            white-space: nowrap;
            margin-right: 16px;
        }
    }

    .button-wrapper {
        align-self: center;
    }

    :last-child {
        margin-bottom: 0;
    }
}

.automation-item-issues-modal {
    display: flex;
    min-width: 540px;
    max-height: 785px;
}

@media only screen and (max-width: 600px) {
    .automation-report {
        .report-top-visualizations {
            .report-visualization {
                width: calc(100% - 100px);
            }
        }
    }

    .automation-item-warning-popover {
        max-width: calc(100vw - 30px);
    }
}

@media only screen and (max-width: 768px) {
    .automation-report {
        .toolbar {
            padding: 12px 8px 0 8px;
        }

        .view-page-header {
            gap: 0;
            padding: 0 12px;
        }

        .report-top-visualizations {
            display: flex;
            flex-direction: column;
            width: 100%;

            .report-visualization {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 8px auto;
                width: calc(100% - 16px);

                .funnel-wrapper {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    max-height: 300px;
                }

                .chart-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    max-height: 230px;
                }
            }
        }

        .report-sessions {
            display: flex;
            flex-direction: column;

            .report-sessions-left-panel {
                min-width: initial;
            }

            .report-sessions-right-panel {
                margin-left: 0;

                .card {
                    max-width: calc(100vw - 16px);
                    margin: 0 auto;
                }
            }
        }

        .report-automation-logs {
            .card {
                max-width: calc(100vw - 16px);
                margin: 0 auto;
            }
        }

        .report-published-logs {
            .card {
                width: 100%;
            }
        }

        .report-admin-logs {
            max-width: calc(100% - 16px);
            margin: 0 auto;
        }
    }
}
