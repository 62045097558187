.segment-edit.edit-page {
    .main-content {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'name  name active active'
            'color color icon icon '
            'score score category category'
            'filter filter filter filter'
            'notes notes notes notes'
            'grid grid grid grid';
    }

    .name {
        grid-area: name;
    }
    .active {
        grid-area: active;
    }

    .color {
        grid-area: color;
    }

    .icon {
        grid-area: icon;
    }

    .score {
        grid-area: score;
    }

    .category {
        grid-area: category;
    }
    .segment-filter {
        grid-area: filter;
    }
    .segment-notes {
        grid-area: notes;
    }

    .segment-grid {
        grid-area: grid;
        border-top: 2px solid #eee;
        margin-top: 16px;
        padding-top: 16px;
    }

    .segment-grid-nodata {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        font-size: 150%;
        opacity: 0.4;
        padding-top: 64px;
    }
}
