.membercard-report-overview.overview-page {
    width: 100%;
}

.membercard-options {
    .option-line {
        display: flex;
        align-items: center;
        font-size: 90%;
        padding: 4px 0;
        opacity: 0.8;

        .ficon {
            font-size: 160%;
        }
    }
}

.view-page:has(.membercard-comment-view-page) {
    max-width: 1500px;

    .view-page-header {
        margin-bottom: 0;
    }

    .membercard-comment-view-page {
        max-width: 1500px;
    }
}

// .membercard-thumb-image {
//     width: 100%;
//     height: auto;

//     &.sub-membercard {
//         padding: 0;
//     }
// }

.membercard-comment-view-page {
    width: 100%;

    .membercard-comparison {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }

    .membercard-data .card-content,
    .membercard-user-data .card-content {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }
}

.membercard-comment-view-page .data-card .card-content,
.membercard-comment-view-page .membercard-data .membercard-data-card,
.membercard-comment-view-page .membercard-user-data .membercard-user-data-card {
    display: grid;
    grid: auto / auto;
    gap: 8px;
    grid-gap: 8px;
    align-items: center;
    align-content: center;
    justify-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    border-right: 1px solid #eee;

    &:last-child {
        border: 0 none;
    }

    .sub-title {
        font-size: 80%;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.7;
    }

    .value {
        font-size: 140%;
    }

    .sticker {
        width: auto;
        margin-bottom: 8px;
    }

    .sub-value {
    }

    .foot-title {
        color: #aaa;
    }
}

.membercard-comment-view-page .membercard-data .membercard-data-card,
.membercard-comment-view-page .membercard-user-data .membercard-user-data-card {
    justify-items: center;
    justify-content: center;
    padding: 8px 0;
}

.membercard-data-cards-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .membercard-data-cards {
        min-height: 210px;
        width: calc(50% - 8px);
        border-radius: 4px;

        .card {
            border-radius: 0;
        }
    }

    .membercard-user-data-cards {
        min-height: 210px;
        padding-top: 16px;
        width: calc(50% - 8px);
        border-radius: 4px;

        .card {
            border-radius: 0;
        }
    }
}

.membercard-comment-view-page .membercard-data-cards,
.membercard-comment-view-page .membercard-user-data-cards {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    padding-bottom: 16px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;

    &:first-of-type {
        padding: 16px 0;
    }

    .membercard-data,
    .membercard-user-data {
        flex: 1;
    }
}

.membercard-data-charts {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;
    margin-bottom: 16px;

    .card-content {
        min-height: 350px;
    }
}

.membercard-data-cards-charts {
    display: flex;
    width: 100%;
    margin: 16px 0;

    .membercard-data-card-chart {
        display: flex;
    }

    .card {
        width: 100%;

        .card-header {
            margin-bottom: 0;
        }

        .card-content {
            display: flex;
            flex-direction: column;
            height: calc(100% - 42px);
            min-height: 300px;
            padding-top: 0;
            width: 100%;

            .datagrid {
                height: 100%;

                .grid-body {
                    height: 100%;
                }
            }

            .tab-header {
                border: none;
            }
        }
    }

    .card-data-chart-labels {
        margin-right: 16px;
        min-width: 120px;

        .card-data-chart-label {
            padding: 8px 0;

            .card-data-chart-label-title {
                font-weight: 500;
                opacity: 0.5;
                font-size: 90%;
            }

            .card-data-chart-label-data {
                font-size: 120%;
            }
        }
    }

    .chart-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .card-content:has(.membercard-data-card-chart-wrapper) {
        padding: 0 16px;
    }

    .membercard-data-card-chart-wrapper {
        display: flex;

        .chart-wrapper {
            max-width: 140px;
        }

        .membercard-data-card-chart {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-right: 1px solid #eeeeee;
            margin: 8px 0 24px 0;
        }

        :last-child {
            border: none;
        }

        .card-data-chart-labels {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 16px 0 0 0;
        }

        .card-data-chart-label {
            display: flex;
            width: 100%;
            // max-width: 180px;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            padding: 8px 0;

            .card-data-chart-label-data {
                text-align: right;
                margin-right: 4px;
                font-size: 110%;
                width: 50%;
            }

            .card-data-chart-label-title {
                font-weight: 500;
                opacity: 0.5;
                font-size: 110%;
                width: 50%;
                margin-left: 4px;
            }
        }
    }
}

.summary-item-popover {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .summary-item-title {
        font-size: 120%;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .summary-item-description {
        max-width: 200px;
    }
}

.membercard-report-lists {
    display: flex;
    flex-direction: column;

    > :first-child {
        margin-bottom: 16px;
    }

    .membercard-report-list {
        overflow: auto;

        .datagrid-rows {
            max-height: 280px;
        }

        &.col3 {
            width: 66%;
        }

        .card-header {
            margin-bottom: 0;
        }

        .list-item {
            padding-right: 16px;

            &:last-of-type {
                // margin-bottom: 12px;
            }

            .item-image {
                background-size: cover;
                border-radius: 4px;
                margin-left: 16px;
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .membercard-data-cards-charts {
        .chart-wrapper {
            max-width: 150px;
        }
    }

    .membercard-data-card-chart-wrapper {
        flex-direction: column;
    }

    .membercard-report-lists {
        .membercard-report-list {
            .button {
                align-self: flex-start;
                padding: 8px;
                min-width: initial;

                .ficon {
                    margin-right: 0;
                }

                span {
                    display: none;
                }
            }

            &.col3 {
                width: 100%;
            }
        }
    }
}

.membercard-comment-view-page {
    width: calc(100vw - 32px);

    .membercard-report-map {
        margin-bottom: 16px;

        .map-chart {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            flex: 1;
            background: white;
            width: 100%;
            margin-top: 0;
        }
    }
}

@media only screen and (min-width: 768px) {
    .membercard-comment-view-page {
        width: calc(100vw - var(--side-menu-width) - 48px);
    }
}

@media only screen and (min-width: 1600px) {
    .membercard-comment-view-page {
        width: calc(100vw - var(--side-menu-large-width) - 64px);
    }
}

@media only screen and (min-width: 968px) {
    .membercard-data-cards-charts:not(.full-width) {
        .card {
            width: calc(50% - 8px);
        }
    }

    .membercard-report-lists {
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 16px;
        flex-wrap: wrap;

        > :first-child {
            margin-bottom: 0;
            margin-right: 16px;
        }

        .membercard-report-list {
            width: calc(50% - 8px);
            flex: 1;
        }
    }

    .membercard-data-card-chart-wrapper {
        flex-direction: row;

        .membercard-data-card-chart {
            width: 33%;
        }
    }

    .membercard-comment-view-page .membercard-data-cards,
    .membercard-comment-view-page .membercard-user-data-cards {
        &:has(.metric-card) {
            grid-gap: 0;
        }
    }
}


















  

    .card-usage-info {
        grid-area: info;
        overflow: hidden;

        .card-usage-details {
            margin-bottom: 16px;

            .card-header {
                margin-bottom: 0;
            }

            .card-details-info {
                display: flex;

                .card-details-summary {
                    margin-left: 16px;
                }

                .card-preview {
                    position: relative;
                    box-shadow: none;

                    .bg {
                        width: 300px;
                        height: 190px;
                        object-fit: cover;
                        border-radius: 8px;
                        background-color: #000;
                        border: 2px solid #eee;
                        box-shadow: none;
                    }

                    .logo {
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        border: 1px solid #fff;
                    }

                    .brand {
                        position: absolute;
                        top: 12px;
                        left: 50px;
                        font-size: 80%;
                        color: #fff;
                    }

                    .title {
                        position: absolute;
                        top: 24px;
                        left: 50px;
                        font-size: 70%;
                        color: #fff;
                    }

                    .card-no {
                        position: absolute;
                        left: 20px;
                        bottom: 20px;
                        font-size: 120%;
                        color: #fff;
                    }
                }
            }
        }
    }

    .card-usage-list-wrapper {
        .card-header {
            margin-bottom: 0;
        }

        .card-usage__search-bar {
            max-width: 300px;
            margin-bottom: 16px;
        }

        .card-usage-list {
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
            background: white;
            overflow: auto;
            align-items: flex-start;

            .item__column {
                text-overflow: ellipsis;
                overflow-x: hidden;
                white-space: nowrap;
                // min-width: 100px;
                max-width: 300px; 

                &.small {
                    // min-width: 80px;
                    // max-width: 80px;
                    font-size: 90%;
                }

                &.cw-s{
                    width: 60px;
                }
                &.cw-1{
                    width: 80px;
                }
                &.cw-2{
                    width: 120px;
                }
                &.cw-3{
                    width: 160px;
                }
                &.cw-4{
                    width: 200px;
                }
            }

            .list-headers {
                display: flex;
                padding: 16px;
                font-weight: bold;
                background: #fafafa;
            }

            .card-usage-list-item {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f0f0f0;
                padding: 8px 16px;

                .empty-transactions-text {
                    padding-left: 16px;
                }

                .card-usage-list-item__cell {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &:last-child {
                        padding: 8px 0 8px 16px;
                    }
                }
            }

            .transaction-list {
                display: flex;
                list-style: none;
                flex-direction: column;

                .list-headers {
                    font-weight: 400;
                    color: #999;
                    padding: 8px 0 8px 32px;

                    .list-header {
                        &:first-child {
                            min-width: 260px;
                            max-width: initial;
                        }
                    }
                }

                .transaction-list-item {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    border-bottom: 1px solid #f0f0f0;
                    padding: 8px 0 8px 32px;

                    .transaction-list-item__cell {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 1;

                        &:first-child {
                            min-width: 260px;
                            max-width: initial;
                        }
                    }
                }
            }
        }
    }
 

@media only screen and (max-width: 968px) {
    .card-usage-list {
        max-width: 1200px;
    }

    .card-details-info {
        flex-direction: column;
        align-items: center;

        .card-preview {
            margin-bottom: 16px;
        }
    }
}
