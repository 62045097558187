.select{
  position: relative;
  outline: none;
  border:0px none;
  width: 100%;
  display: flex;
  align-items: center;
  
}

.select .clear-button{
  margin-left: -30px;
  font-size: 120%;
  opacity: 0.2;
}
.select .variable-button{
  margin-left: -50px;
  opacity: 0.8;
  font-size: 120%;
}

.select label{ 
    background-color: transparent;
    position: absolute;
    top:1px;
    left: 6px; 
    font-size: 100%;
    padding: 0px 6px;
    color:#aaa; 
    transition: 50ms;
    height: 94%;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.select label span{ 
  margin-left: 8px;
  padding: 0px 2px; 
  font-size: 90%;
  opacity: 0.5;
  visibility: hidden;
}

.select label span:before{  
  padding-right: 8px;
  content: ' - ';
}




.select.disabled {
  opacity: 1; 
  user-select: text;
  cursor:default;
}

.select.disabled select{ 
  cursor:text; 
  border-color: #eee;
  background-color: #eee;
  color: #888;
}

.select.disabled select:hover{ 
  border-color: #ddd;
}

.select.disabled select:focus + label, .select.disabled select:not(:placeholder-shown) + label, .select.disabled select:focus:not(:placeholder-shown) + label, .select label.with-value
{
  background: linear-gradient(0deg, #eee 50%, rgba(255, 255, 255, 0) 50%)
}


.select select:focus + label, .select label.with-value, .select select:not(:placeholder-shown) + label, .select select:focus:not(:placeholder-shown) + label{  
  height: auto;
  font-size: 65%;
  background-color: #fff; 

  background: linear-gradient(0deg, #fff 60%, #fff0 32%);
  top:-5px;
  left: 9px;   
  color:#3949ab; 
}


.select select:focus + label span, .select select:not(:placeholder-shown) + label span, .select select:focus:not(:placeholder-shown) + label span{  
  visibility: visible;
}

.select select:not(:placeholder-shown) + label,
.select label.with-value{   
  color:#666; 
}
 
.select.has-value .clear-button{ 
  opacity: 0.7;
}

.select:hover select{
  border-color: #666;
}

.select select:focus{
  border-color: #3949ab;
}

.select select{
  border:1px solid #ddd;
  border-radius: 4px;
  padding: 10px 12px;
  transition: 150ms;
  outline: none;
  padding-right: 30px;
}

.select.bindable select{ 
  padding-right: 60px;
}
.select .inline-variables{
  padding-right: 55px;
}

.select.error:after{ 
  content:'Error: ' attr(data-error);;
  background-color: transparent;
  position: absolute; 
   
  padding: 0px 6px; 
  transition: 150ms; 
  display: flex;
  align-items: center; 
  height: auto;
  font-size: 65%;
  background-color: #fff; 
  bottom:-5px;
  left: 9px;   
  color:#d69d9d; 

}


.select.error select{  
  color:#d32f2f;   
  //box-shadow: inset 0px 0px 10px 10px #00000022;

}


.select.error select:not(:placeholder-shown)  + label, 
.select.error select:focus + label {
  color:#d32f2f;
}

.select.error select,
.select.error select:focus, .select.error select:focus-visible{ 
  border: 1px solid #d32f2f;
  border-left: 3px solid #d32f2f;
}


.select .variables{
  width: 100%;
  border:1px solid #ddd;
  border-radius: 4px;
  padding: 10px 12px;
  transition: 150ms;
  outline: none;
  padding-right: 30px;
} 
.select select.inline-variable{
  margin: 0px 2px; 
  display: inline-block;
  width: auto;
  min-width: 0px;
  max-width: 1000px;
  padding: 2px;
  border:0px none;
  text-align: center;
  background-color: #eee;
  user-select: none;
  text-transform: capitalize;
  font-family: monospace;
  pointer-events: none;
  cursor: default;
}