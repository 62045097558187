.context-menu {
    padding: 8px 0;
    border-radius: 3px;

    .context-menu-item {
        display: flex;
        align-items: center;
        height: 40px;
        width: 200px;
        padding: 10px 16px;

        &:hover {
            cursor: pointer;
            background: #f4f6f8;
        }

        .menu-item-label {
            margin-left: 10px;
        }
    }
}

.popover-content:has(.context-menu) {
    .arrow {
        display: none;
    }
}
