.popup-wrapper:has(.add-html-link-popup) {
    z-index: 90;
}

.add-html-link-popup {
    width: 500px;
    padding: 0;

    .add-html-link-popup-content {
        padding: 8px;
    }

    .sub-title {
        padding: 8px 0;

        .go-back-to {
            cursor: pointer;
            text-decoration: underline;
            margin-right: 16px;
            opacity: 0.5;
        }
    }

    .note {
        font-size: 80%;
        opacity: 0.5;
        padding: 8px 0;
    }

    .application-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        .application-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px 16px;
            height: 110px;
            background-color: #fff;
            border-radius: 4px;
            margin: 8px;
            min-width: 140px;
            max-width: 200px;
            flex: 1;
            text-align: center;
            cursor: pointer;

            &:hover {
                box-shadow: inset 0 0 1000px 10000px #00000011;
            }

            &.selected {
                background-color: #3949ab;
                color: #fff;
            }

            .ficon {
                font-size: 240%;
                margin-bottom: 8px;
            }
        }
    }
}
