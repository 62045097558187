.customer-profile-kpi {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 'life last sess enga';
}

.customer-profile-kpi .life-time-value {
    grid-area: life;
    color: #fff;
    background-color: #3f51b5;
}

.customer-profile-kpi .last-time-value {
    grid-area: last;
}

.customer-profile-kpi .session-time {
    grid-area: sess;
}

.customer-profile-kpi .last-time-engagement {
    grid-area: enga;
}

.customer-profile-page .activities {
    align-self: flex-start;
    overflow: auto;
    .empty-logs {
        padding: 16px;
    }
}

.customer-profile-kpi .data-card {
    align-items: center;
    display: flex;
}

.customer-profile-kpi .data-card .card-content {
    display: grid;
    grid: auto / auto;
    gap: 8px;
    grid-gap: 8px;
    align-items: center;
    align-content: center;
    justify-items: flex-start;
    justify-content: flex-start;

    .large-icon {
        font-size: 32pt;
        margin-bottom: 16px;
    }

    .sub-title {
        font-size: 80%;
        text-transform: uppercase;
    }

    .value {
        font-size: 140%;
    }

    .sticker {
        width: auto;
        margin-bottom: 16px;
    }

    .foot-title {
        color: #aaa;
    }
}

@media (max-width: 768px) {
    .customer-profile-kpi {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'head'
            'info'
            'life'
            'last'
            'sess'
            'enga'
            'acti';
    }
}

.suggestions{
    padding: 8px;
    padding-bottom: 32px;
   
    .suggestion-row{
        display: flex;
        flex-direction: row; 
        align-items: flex-start;
        padding: 16px;
        border-bottom: 1px solid #eee;
        label{
            width: 136px;
            font-weight: bold;
        }
        span{
          flex:1;
        }
    }
}


.product-log-list {
    overflow-x: auto;

    .product-log-list-item {
        display: flex;
        flex-direction: row;
        background: white;
        min-width: 120px;

        &.headers {
            font-weight: 500;
            background: #fafafa;
        }

        .product-log-list-cell {
            padding: 16px;
            min-width: 180px;
            max-width: 180px;
            border: 1px solid #eee;
            word-break: break-all;

            &.channel-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                min-width: 60px;
                max-width: 60px;
            }
            i{
                font-size: 90%;
            }

            &.product-name {
                min-width: 250px;
                max-width: 100%; 
                width: auto;
                flex: 1;
                
                
                .product-url{
                    font-size: 80%;
                    text-decoration: none;
                    color:#000;
                    opacity: 0.6;
                }
            }
        }
    }
}




.customer-kpi-orders-popoover{
    padding: 16px 0px;
    width: 400px;
    overflow: auto;
}
