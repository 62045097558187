.datagrid.--with-cell-borders-web .datagrid-row .datagrid-cell {
    border: 1px solid #00000005;
}

.datagrid {
    --highlight-color: rgba(61, 84, 181, 0.05);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: baseline;
    border-radius: 4px;
    background-color: #fff;

    .datagrid-rows {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .datagrid-row-skeleton-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            padding: 16px;
        }
    }



    .datagrid-rows:has(.data-grid-empty-state) {
        position: relative;
    }

    .grid-body:has(.data-grid-empty-state) {
        overflow: hidden !important;
    }
}

.cell-resizer {
    display: block;
    transform: translate(0px, 0px);
    width: 14px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: col-resize;
    z-index: 10;

    &:after {
        content: ' ';
        width: 4px;
        background-color: rgb(206, 206, 206);
        position: absolute;
        top: 0;
        left: 6px;
        height: 100%;
    }
}

.datagrid {
    .datagrid-tools {
        width: 100%;
    }

    .head-detail-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #00000022;

        .selection-info {
            display: flex;
            flex-direction: row;
            background-color: #dbdfff;
            user-select: none;

            .selection-text {
                flex: 1;
                align-items: center;
                display: flex;
                padding: 0 10px;
                opacity: 0.8;
            }

            .selection-actions {
                display: flex;
                flex-wrap: wrap;

                .selection-action {
                    padding: 10px;
                    cursor: pointer;
                    user-select: none;
                }

 

                .selection-action:hover {
                    background-color: #00000022;
                }
            }
        }
    }
}

.datagrid {
    .group-header {
        font-weight: 500;
        color: #00000066;
        font-size: 90%;
        height: 32px;
    }

    .group-header:first-child {
        border-top: 1px solid #eee;
    }

    .group-header .content {
        width: 100%;
        background: #fafafa;
        padding: 8px;
        left: 0;
        border-bottom: 1px solid #eee;
        position: absolute;
    }

    .group-header .content span {
        padding: 0 8px;
        border-right: 1px solid #eee;
    }

    .group-header .content span:last-child {
        border-right: 0 none;
    }

    .datagrid-cell.stick-left {
        position: sticky;
        left: 0;
        z-index: 1;

        border-right: 1px solid #f0f0f0;
        background-color: inherit;
    }

    .datagrid-row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border-bottom: 1px solid #f0f0f0;

        &:not(.conditional) {
            background-color: #fff;
        }

        &.disabled{
            filter: grayscale(0.9);
            opacity: 0.7;
            background-color: #d3d2ff;
            text-decoration: line-through;
            // box-shadow:inset 0px  0px 1000px 1000px #00000022;
            // text-decoration: line-through;
        }

        &.tune-down{
            filter: grayscale(0.9);
            opacity: 0.7;
            background-color: #f1f0ff;
            
            // box-shadow:inset 0px  0px 1000px 1000px #00000022;
            // text-decoration: line-through;
        }

        &:not(:has(.datagrid-row-group)) {
            &:hover {
                box-shadow: inset 1px 1px 200px 200px var(--highlight-color);

                .datagrid-cell.stick-left,
                .datagrid-cell.stick-right {
                    box-shadow: inset 1px 1px 200px 200px var(--highlight-color);
                }
            }
        }

        &:has(.datagrid-row-group) {
            &:not(.conditional) {
                background: white;

                .datagrid-row-group {
                    .datagrid-row-group-children {
                        background: #f9fafb;
                    }
                }
            }

            &:hover {
                .datagrid-cell.stick-left {
                    // box-shadow: inset 1px 1px 200px 200px var(--highlight-color);
                }
            }

            .datagrid-cell[data-type='system-check'] input {
                align-self: flex-start;
            }
        }

        .datagrid-row-group {
            background-color: inherit;

            .datagrid-row-group-children-item {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                width: 100%;
                background-color: inherit;

                .stick-right {
                    background: inherit;
                    border-left: 1px solid #f0f0f0;
                }
            }

            .datagrid-row-group-parent {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                width: 100%;
                background-color: inherit;

                &:hover {
                    box-shadow: inset 1px 1px 200px 200px var(--highlight-color);

                    .stick-left {
                        background-color: var(--highlight-color);
                    }

                    .datagrid-cell.stick-left,
                    .datagrid-cell.stick-right {
                        box-shadow: inset 1px 1px 200px 200px var(--highlight-color);
                    }
                }
            }

            .datagrid-row-group-children-item {
                border-top: 1px solid #f0f0f0;
                background-color: inherit;

                &:hover {
                    box-shadow: inset 1px 1px 200px 200px var(--highlight-color);

                    .datagrid-cell.stick-left,
                    .datagrid-cell.stick-right {
                        box-shadow: inset 1px 1px 200px 200px var(--highlight-color);
                    }
                }
            }
        }

        &.checked {
            .datagrid-row-group-parent {
                background: #e5f6ff;
            }

            &.disabled {
                filter: grayscale(0.5);
                opacity: 1;
                // background: #d5e5ed;
                // .datagrid-cell.stick-left,
                //     .datagrid-cell.stick-right{
                //     background: #d5e5ed;
                // }
                
            }

            .datagrid-row-group {
                background: #e5f6ff;
            }
        }

        .datagrid-row-group-children {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            background-color: inherit;
        }

      

        .datagrid-cell {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            vertical-align: middle;
            padding: 8px 8px;
            display: flex;
            align-items: center;
            // 
          
            > span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              
            }
            // &:not([data-tooltip]):not([data-popover]){
            //     > span {
            //         text-overflow: ellipsis;
            //         overflow: hidden;
            //         white-space: nowrap;
            //         position: relative;
            //         &:hover::before{ 
            //             position: absolute;
            //             transition: 300ms;
            //             text-overflow: clip;
            //               white-space: normal;
            //              word-break: break-word; 
            //             z-index: 1; 
            //             min-width: 20px;
            //             min-height: 20px;
            //             content: '';
            //             // transform: scale(2) translate(0%, 0%);
                        
            //             background-color: inherit;
            //             box-shadow: inset 0px 0px 1000px 10000px #fff, 0px 0px 0px 10px #fff;
            //             // font-size: 50%;
            //             //   padding: 8px;  
                          
            //         }
            //     }
            // }

           
        }

        .datagrid-cell.stick-right {
            position: sticky;
            right: 0;
            z-index: 1;
            border-left: 1px solid #f0f0f0;
            border-right: 1px solid #f0f0f0;
            background-color: inherit;
        }

        .datagrid-cell.centered {
            justify-content: center;
        }

        .datagrid-cell.multiline {
            span {
                white-space: normal;
                line-height: inherit;
                word-break: break-word;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                display: -webkit-box;
            }
        }

        .datagrid-cell[data-type='image'] {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
            }
        }

        .datagrid-cell[data-type='dropdown'] {
            overflow: unset;
            display: flex;
            align-items: center;
        }

        &.new-record {
            position: relative;
        }
        &.new-record:after {
            //  background-color: #7ac3fea7;
            content: ' ';
            background-color: #adadad18;
            pointer-events: none;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            //  background-color: #7ac3fea7;
        }

        .datagrid-cell[data-type='parent'] {
            overflow: unset;
            display: flex;
            align-items: center;
        }

        .datagrid-cell[data-type='tag'] {
            overflow: unset;
            display: flex;
            align-items: center;
        }

        .datagrid-cell[data-type='user'] {
            &.users-list {
                .users-list-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .customer-avatar {
                    img {
                        width: 24px;
                        height: 24px;
                    }

                    .location {
                        display: none;
                    }
                }
            }

            .customer-avatar {
                // margin-right: 16px;
            }
        }

        .datagrid-cell[data-type='datetime'] {
            font-size: 90%;
        }

        .datagrid-cell:hover {
            input,
            select,
            textarea {
                box-shadow: inset 1px 1px 20px 20px #00000011;
            }
        }

        &.child-row {
            background-color: #fafafa;

            & .stick-left,
            & .stick-right {
                background-color: #fafafa;
                box-shadow: none;
            }

            .datagrid-cell[data-type='system-check'] * {
                visibility: hidden;
            }
            .datagrid-cell {
                padding: 4px 8px;
            }
        }
    }


    .row-child-component{
        display: block;
       min-width: 100%;
    }

    &.--with-child-rows {
        .datagrid-row.child-row + .datagrid-row:not(.child-row) {
            border-top: 10px solid #f2f2f2;
        }

        .datagrid-row:not(.child-row) + .datagrid-row:not(.child-row) {
            border-top: 10px solid #f2f2f2;
        }

        .datagrid-row-group {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    .datagrid-row.checked + .child-row,
    .datagrid-row.checked + .child-row + .child-row,
    .datagrid-row.checked + .child-row + .child-row + .child-row,
    .datagrid-row.checked + .child-row + .child-row + .child-row + .child-row,
    .datagrid-row.checked + .child-row + .child-row + .child-row + .child-row + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row,
    .datagrid-row.checked
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row
        + .child-row {
        background-color: #e5f6ff;
        & .stick-left,
        & .stick-right {
            background-color: #e5f6ff;
        }
    }

    .datagrid-row .show-more {
        display: none;
    }

    .datagrid-row.checked {
        background-color: #e5f6ff;
        input,
        select,
        textarea {
            box-shadow: inset 1px 1px 20px 20px #00bfff26;
        }
        .datagrid-cell.stick-left,
        .datagrid-cell.stick-right {
            background-color: #e5f6ff;
            box-shadow: none;
        }
    }
}

.users-popover {
    padding: 16px;

    .customer-avatar {
        margin: 12px 0;
    }
}

.author-cell {
    width: 200px;
}

.datagrid {
    .datagrid-footer {
        width: 100%;
        .datagrid-paging {
            user-select: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 4px 0;

            .page-info {
                flex: 1;
                padding: 8px;
            }

            .page-size {
                display: flex;
                align-items: center;

                .page-size-label {
                    flex: 1;
                    padding: 0 10px;
                }

                .page-size-select {
                    width: 75px;
                    padding: 8px 12px;
                    outline: none;
                    color: #444;
                    border: 0 none;
                    background-color: #fafafa;
                    cursor: pointer;
                }
            }

            .pages {
                display: flex;
                align-items: center;
                padding: 0 10px;
                text-transform: uppercase;
                font-weight: 600;

                .page-number {
                    padding: 4px;
                    cursor: pointer;
                    min-width: 36px;
                    line-height: 1.6;
                    text-align: center;
                }

                .page-number.next,
                .page-number.back {
                    opacity: 0.5;
                    font-size: 90%;
                    text-align: center;
                    padding: 4px 8px;
                }

                .page-number:hover {
                    text-decoration: underline;
                }

                .page-number.selected {
                    color: #ff0000;
                    cursor: default;
                }

                .page-number.loading {
                    color: #ff9100;
                    cursor: default;
                    height: auto;
                }

                .page-number.loading.selected {
                    color: #ff0000;
                }
            }
        }
    }
}

.datagrid .grouping {
    flex: 1;
    display: inline-block;
    height: 56px;
    padding: 10px 4px;
    vertical-align: middle;
    position: relative;
    user-select: none;

    .grouping-text {
        height: 20px;
        position: absolute;
        top: 15px;
        left: 20px;
        z-index: 0;
        opacity: 0.5;
    }
}
.datagrid .grouping .sortable-chosen {
    background-color: #ffffffdd !important;
    display: inline-block;
    width: auto !important;
}
.datagrid .grouping .datagrid-header-cell {
    display: inline-block;
    border: 1px solid #ddd;
    margin: 0 6px;
    border-radius: 4px;
    background-color: #fafafa;
    line-height: 36px;

    .cell-extensions,
    .cell-more,
    .cell-filter {
        display: none !important;
    }
}


.datagrid .grouping .datagrid-header-cell-grouped {
    display: inline-block;
    border: 1px solid #ddd;
    margin: 0 6px;
    border-radius: 4px;
    background-color: #fff;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    vertical-align: middle;
    max-width: 150px;
    height: 100%;
    line-height: 34px;
    padding: 0 8px;
    cursor: pointer;
    position: relative;
    padding-right: 32px;

    .remove-group {
        display: inline-block;
        width: 22px;
        height: 22px;
        line-height: 20px;
        text-align: center;
        background-color: #ddd;
        border-radius: 50%;
        position: absolute;
        right: 4px;
        top: 6px;
        font-weight: bold;
    }
}

.sortable-ghost {
    background-color: #00000022 !important;
}

.datagrid-header-cell.sortable-chosen {
    border: 0 none !important;

    .cell-content:after {
        display: none;
    }
    .cell-extensions,
    .cell-more,
    .cell-filter {
        display: none !important;
    }
}

.datagrid .datagrid-row.red,
.datagrid .datagrid-row.red .datagrid-cell.stick-left,
.datagrid .datagrid-row.red .datagrid-cell.stick-right {
    background-color: #ff7f7f;
}

.datagrid .datagrid-row.green,
.datagrid .datagrid-row.green .datagrid-cell.stick-left,
.datagrid .datagrid-row.green .datagrid-cell.stick-right {
    background-color: #a8fe7a;
}

.datagrid .datagrid-row.blue,
.datagrid .datagrid-row.blue .datagrid-cell.stick-left,
.datagrid .datagrid-row.blue .datagrid-cell.stick-right {
    background-color: #7ac3fe;
}

.datagrid .datagrid-row.yellow,
.datagrid .datagrid-row.yellow .datagrid-cell.stick-left,
.datagrid .datagrid-row.yellow .datagrid-cell.stick-right {
    background-color: #fefa7a;
}

.datagrid .datagrid-row.brown,
.datagrid .datagrid-row.brown .datagrid-cell.stick-left,
.datagrid .datagrid-row.brown .datagrid-cell.stick-right {
    background-color: #a9925c;
}

.datagrid .datagrid-row.orange,
.datagrid .datagrid-row.orange .datagrid-cell.stick-left,
.datagrid .datagrid-row.orange .datagrid-cell.stick-right {
    background-color: #ffbf5f;
}

.datagrid .datagrid-row.purple,
.datagrid .datagrid-row.purple .datagrid-cell.stick-left,
.datagrid .datagrid-row.purple .datagrid-cell.stick-right {
    background-color: #ed85ff;
}

.datagrid .datagrid-row.green {
    background-color: #a8fe7a;
}

.datagrid .datagrid-row.green {
    background-color: #a8fe7a;
}

.datagrid .datagrid-row.green {
    background-color: #a8fe7a;
}

.datagrid .datagrid-row .datagrid-cell > .column-header {
    display: none;
}
.datagrid.--grid-loading {
    .datagrid-row {
        opacity: 0.1;
        filter: grayscale(1);
    }
    .datagrid-footer {
        opacity: 0.5;
        cursor: default;
        user-select: none;
        pointer-events: none;
    }
}

.datagrid.--grid-loading {
}


.datagrid .datagrid-row .show-detail{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 4px;
    text-align: left;
    opacity: 0.8;
    font-size: 90%;
    font-weight: bold;
    padding-left: 16px;
    min-width: 80px;
} 
.datagrid .datagrid-row .show-detail .ficon {
    font-size: 160%;
    margin-left: 2px;
}

.datagrid .datagrid-header-cell.show-details-header {
    min-width: 80px;
}




@media (max-width: 768px) {
    .datagrid.--cardview-mobile .datagrid-row {
        flex-wrap: wrap;
        flex-direction: column;
        width: 98%;
        background-color: #fff;
        max-width: 100vw;
        border-width: 8px;
        padding: 4px;
        margin: 4px 1%;
    }

    .datagrid.--cardview-mobile .datagrid-row:hover {
        box-shadow: none;
    }

    .datagrid.--cardview-mobile .datagrid-header .datagrid-header-cell {
        display: none;
    }

    .datagrid.--cardview-mobile .datagrid-row .datagrid-cell[data-type='system-check'] {
        display: none;
    }

    .datagrid.--cardview-mobile.mobile-show-top-3-columns
        .datagrid-row.collapsed
        .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell {
        display: none;
    }
    .datagrid.--cardview-mobile.mobile-show-top-4-columns
        .datagrid-row.collapsed
        .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell {
        display: none;
    }

    .datagrid.--cardview-mobile.mobile-show-top-5-columns
        .datagrid-row.collapsed
        .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell {
        display: none;
    }

    .datagrid.--cardview-mobile.mobile-show-top-6-columns
        .datagrid-row.collapsed
        .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell {
        display: none;
    }

    .datagrid.--cardview-mobile.mobile-show-top-7-columns
        .datagrid-row.collapsed
        .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell {
        display: none;
    }

    .datagrid.--cardview-mobile.mobile-show-top-8-columns
        .datagrid-row.collapsed
        .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell
        + .datagrid-cell {
        display: none;
    }

    .datagrid.--cardview-mobile .datagrid-row .show-more {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 4px;
        text-align: left;
        opacity: 0.6;
        font-size: 90%;
        
    }
 
    .datagrid.--cardview-mobile .datagrid-row .show-more .ficon {
        font-size: 100%;
        margin-left: 4px;
    }

    .datagrid.--cardview-mobile .datagrid-row .datagrid-cell {
        width: 100%;
        max-width: 100% !important;
        align-items: flex-start !important;
        flex-direction: column;
        padding: 4px 4px;
    }

    .datagrid .datagrid-row .datagrid-cell:hover {
        box-shadow: none;
    }

    .datagrid .datagrid-row:hover .datagrid-cell.stick-left,
    .datagrid .datagrid-row:hover .datagrid-cell.stick-right {
        box-shadow: none;
    }

    .datagrid .datagrid-row .datagrid-cell.stick-left,
    .datagrid .datagrid-row .datagrid-cell.stick-right {
        box-shadow: none;
    }

    .datagrid.--cardview-mobile .datagrid-row .datagrid-cell > span {
        flex: 1;
    }

    .datagrid.--cardview-mobile .datagrid-row .datagrid-cell > .column-header {
        width: 100%;
        display: block;
        max-width: 100% !important;
        font-size: 70%;
        color: #00000044;
    }

    .datagrid .head-wrapper {
        flex-direction: column;
    }

    .datagrid .head-wrapper .search {
        flex: 1;
        max-width: 100%;
        padding: 4px 8px;
    }

    .datagrid .head-wrapper .actions {
        padding: 4px 8px;
    }

    .datagrid .head-wrapper .actions .action {
        border: 1px solid #eee;
        margin-right: 4px;
        margin-bottom: 4px;
    }

    .datagrid .grouping {
        height: 50px;
        flex: auto;
    }

    .datagrid .grouping .grouping-text {
        left: 8px;
    }

    .datagrid .head-detail-wrapper .selection-info {
        flex-direction: column;
        padding-top: 4px;
        padding-bottom: 4px;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .datagrid .head-detail-wrapper .selection-info .selection-text {
        padding: 8px;
    }

    .datagrid .head-detail-wrapper .selection-info .selection-actions {
        padding: 4px;
    }

    .datagrid .head-detail-wrapper .selection-info .selection-actions .selection-action {
        border: 1px solid #00000022;
        margin: 2px;
        padding: 4px 8px;
        font-weight: 500;
       
    }

    .datagrid .datagrid-footer .datagrid-paging .page-info {
        font-size: 80%;
        padding: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .datagrid .datagrid-footer .datagrid-paging .page-size {
        padding: 8px;
    }

    .datagrid .datagrid-footer .datagrid-paging .page-size .page-size-label {
        font-size: 80%;
        padding: 0 4px;
    }

    .datagrid .datagrid-footer .datagrid-paging .page-size .page-size-select {
        padding: 4px 6px;
    }

    .datagrid .datagrid-footer .datagrid-paging .pages {
        width: 100%;
        font-size: 120%;
        padding: 12px 8px;
        padding-top: 0;
        overflow: auto;
    }

    .datagrid .datagrid-footer .datagrid-paging .pages .page-number {
        box-sizing: content-box;
    }

    .datagrid .datagrid-footer .datagrid-paging .pages .page-number.first,
    .datagrid .datagrid-footer .datagrid-paging .pages .page-number.next,
    .datagrid .datagrid-footer .datagrid-paging .pages .page-number.back,
    .datagrid .datagrid-footer .datagrid-paging .pages .page-number.last {
        min-width: auto;
    }

    .datagrid.full-screen .datagrid-footer .datagrid-paging {
        box-shadow: 0px -5px 5px 1px #00000009;
    }

    .datagrid .group-header {
        width: 99% !important;
    }

    .datagrid .datagrid-row .datagrid-cell > .column-header {
        display: none;
    }

    .datagrid.--cardview-mobile.--with-column-headers-mobile
        .datagrid-row
        .datagrid-cell
        > .column-header {
        display: block;
    }

    .datagrid.--tableview-mobile .datagrid-row .datagrid-cell.stick-left,
    .datagrid.--tableview-mobile .datagrid-row .datagrid-cell.stick-right {
        position: static;
    }

    .datagrid.--tableview-mobile .datagrid-header .datagrid-header-cell.stick-left,
    .datagrid.--tableview-mobile .datagrid-header .datagrid-header-cell.stick-right {
        position: static;
    }

    .datagrid .datagrid-row.red,
    .datagrid .datagrid-row.red .datagrid-cell.stick-left,
    .datagrid .datagrid-row.red .datagrid-cell.stick-right {
        background-color: #ff7f7f;
        box-shadow: none;
    }

    .datagrid .datagrid-row.green,
    .datagrid .datagrid-row.green .datagrid-cell.stick-left,
    .datagrid .datagrid-row.green .datagrid-cell.stick-right {
        background-color: #a8fe7a;
        box-shadow: none;
    }

    .datagrid .datagrid-row.blue,
    .datagrid .datagrid-row.blue .datagrid-cell.stick-left,
    .datagrid .datagrid-row.blue .datagrid-cell.stick-right {
        background-color: #7ac3fe;
        box-shadow: none;
    }

    .datagrid .datagrid-row.yellow,
    .datagrid .datagrid-row.yellow .datagrid-cell.stick-left,
    .datagrid .datagrid-row.yellow .datagrid-cell.stick-right {
        background-color: #fefa7a;
        box-shadow: none;
    }

    .datagrid .datagrid-row.brown,
    .datagrid .datagrid-row.brown .datagrid-cell.stick-left,
    .datagrid .datagrid-row.brown .datagrid-cell.stick-right {
        background-color: #a9925c;
        box-shadow: none;
    }

    .datagrid .datagrid-row.orange,
    .datagrid .datagrid-row.orange .datagrid-cell.stick-left,
    .datagrid .datagrid-row.orange .datagrid-cell.stick-right {
        background-color: #ffbf5f;
        box-shadow: none;
    }

    .datagrid .datagrid-row.purple,
    .datagrid .datagrid-row.purple .datagrid-cell.stick-left,
    .datagrid .datagrid-row.purple .datagrid-cell.stick-right {
        background-color: #ed85ff;
        box-shadow: none;
    }

    .datagrid .datagrid-row.green {
        background-color: #a8fe7a;
    }

    .datagrid .datagrid-row.green {
        background-color: #a8fe7a;
    }

    .datagrid .datagrid-row.green {
        background-color: #a8fe7a;
    }
}

.datagrid.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

@keyframes show-grid-scroller {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.grid-scroller {
    width: 100%;
    overflow: auto;
    position: sticky;
    bottom: 0;
    height: 20px;
    background: transparent;
    z-index: 11;
    margin-top: -20px;
    animation: show-grid-scroller 0.5s;
    animation-iteration-count: 1;
}

.grid-scroller::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
}

.grid-scroller::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.datagrid img.noimage-img {
    opacity: 0.5;
}

.grid-loading {
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 0px 100px 20px #00000022;
    border-radius: 100px;
    width: 220px;
    flex: none;
    align-self: center;
    justify-self: center;
    text-align: center;
    margin: 16px auto;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000aa;
    user-select: none;
    outline: none;
    position: absolute;
    top: 40px;
    z-index: 10;
    font-size: 120%;

    .ficon {
        font-size: 150%;
        margin-left: 8px;
    }

    &.loading {
        .ficon {
            animation: rotate-loading-button-icon 400ms linear;
            animation-iteration-count: infinite;
        }
    }
    @keyframes rotate-loading-button-icon {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }

    @keyframes fade-nothing-button {
        0% {
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}


.grid-loading.row-loading {
    position: static;
    top: auto;  

    margin: 16px 32px;
}