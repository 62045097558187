.wizard__link {
    text-decoration: none;
}

.overview-card {
    &.mock {
        .card-content {
            overflow: hidden;
        }

        .card-footer {
            // TODO: uncomment this out when we implement status empty/needs setup for chart data on API
            // display: none;
            z-index: 2;
        }
    }

    &:has(.tab-content) {
        .wizard__mocked-overlay {
            top: 90px;
            height: calc(100% - 90px);
        }

        .wizard__overlay {
            top: 90px;
            height: calc(100% - 140px);
        }
    }

    &:not(:has(.card-header)) {
        .wizard__mocked-overlay {
            top: 0;
            height: 100%;
        }

        .wizard__overlay {
            top: 0;
            height: 100%;
        }
    }
}
