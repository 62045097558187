.edit-wechatcoupon.edit-page {
    .app-name {
        padding: 12px 16px;
        background-color: #eee;
        opacity: 0.6;
    }
    .card-qr-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            width: 400px;
        }
        a {
        }

        span {
            margin: 16px 0;
        }
    }

    .custom-codes {
        width: 100%;
        min-height: 600px;
    }

    .basic-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > * {
            width: 50%;
            flex: none;
            margin-bottom: 16px;
            margin-top: 16px;
            align-self: flex-start;
        }
        .edit-field-desc {
            position: relative;
        }
        .edit-field-desc:after {
            content: ' ';
            width: 196%;
            height: 1px;
            background-color: #eee;
            top: -16px;
            left: -98%;
            position: absolute;
        }

        .sub-fields {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding-left: 16px;
            border-left: 3px solid #f2f2f2;
            > * {
                width: 50%;
                flex: none;
                margin-bottom: 16px;
                margin-top: 16px;
            }
        }
        .cash-amount-value {
        }

        // display: grid;
        // grid-gap: 8px;
        // gap: 8px;
        // grid-template-columns: 1fr 1fr;
        // grid-template-rows: auto;
        // grid-template-areas:
        //   "code-type code-type"
        //   "code-type-desc code-type-desc"
        //   "coupon-name coupon-name"
        //   "coupon-name-desc coupon-name-desc"
        //   "brand-name brand-name"
        //   "brand-name-desc brand-name-desc"
        //   "color color"
        //   "color-desc color-desc"
        //   "notice notice"
        //   "notice-desc notice-desc"
        //   "description description"
        //   "description-desc description-desc"
        //   "sku sku"
        //   "sku-desc sku-desc"
        //   "date-info-type date-info-type"
        //   "date-info-type-desc date-info-type-desc"

        //   "fixed-term fixed-begin-term"
        //   "fixed-term-desc fixed-begin-term-desc"
        //   "fixed-end-timestamp fixed-end-timestamp"
        //   "fixed-end-timestamp-desc fixed-end-timestamp-desc"

        //   "begin-timestamp end-timestamp"
        //   "begin-timestamp-desc end-timestamp-desc";
    }

    // .code-type{
    //   grid-area: code-type;
    // }
    // .code-type-desc{
    //   grid-area: code-type-desc;
    // }

    // .coupon-name{
    //   grid-area: coupon-name;
    // }
    // .coupon-name-desc{
    //   grid-area: coupon-name-desc;
    // }

    // .brand-name{
    //   grid-area: brand-name;
    // }
    // .brand-name-desc{
    //   grid-area: brand-name-desc;
    // }

    // .color{
    //   grid-area: color;
    // }
    // .color-desc{
    //   grid-area: color-desc;
    // }

    // .notice{
    //   grid-area: notice;
    // }
    // .notice-desc{
    //   grid-area: notice-desc;
    // }

    // .description{
    //   grid-area: description;
    // }
    // .description-desc{
    //   grid-area: description-desc;
    // }

    // .sku{
    //   grid-area: sku;
    // }
    // .sku-desc{
    //   grid-area: sku-desc;
    // }

    // .date-info-type{
    //   grid-area: date-info-type;
    // }
    // .date-info-type-desc{
    //   grid-area: date-info-type-desc;
    // }

    // .begin-timestamp{
    //   grid-area: begin-timestamp;
    // }
    // .begin-timestamp-desc{
    //   grid-area: begin-timestamp-desc;
    // }

    // .end-timestamp{
    //   grid-area: end-timestamp;
    // }
    // .end-timestamp-desc{
    //   grid-area: end-timestamp-desc;
    // }

    // .fixed-term{
    //   grid-area: fixed-term;
    // }
    // .fixed-term-desc{
    //   grid-area: fixed-term-desc;
    // }

    // .fixed-begin-term{
    //   grid-area: fixed-begin-term;
    // }
    // .fixed-begin-term-desc{
    //   grid-area: fixed-begin-term-desc;
    // }

    // .fixed-end-timestamp{
    //   grid-area: fixed-end-timestamp;
    // }
    // .fixed-end-timestamp-desc{
    //   grid-area: fixed-end-timestamp-desc;
    // }

    // .updated-at {
    //   grid-area: updated_at;
    // }

    // .valid-for-days {
    //   grid-area: valid_for_days;
    // }

    // .valid-until-date {
    //   grid-area: valid_until_date;
    // }

    // .props {
    //   grid-area: props;
    // }

    // .coupon-data {
    //   grid-area: coupon_data;
    // }

    // .quantity {
    //   grid-area: quantity;
    // }

    // .card-type {
    //   grid-area: card_type;
    // }

    // .title {
    //   grid-area: title;
    // }

    // .color {
    //   grid-area: color;
    // }

    // .notice {
    //   grid-area: notice;
    // }

    // .description {
    //   grid-area: description;
    // }

    // .status {
    //   grid-area: status;
    // }

    // .brand-name {
    //   grid-area: brand_name;
    // }

    textarea {
        width: 100%;
    }

    .edit-field-desc {
        padding: 4px 12px;
        font-size: 80%;
        opacity: 0.6;
        margin-bottom: 20px;
        .edit-field-desc-cn {
            opacity: 0.7;
        }
    }

    .dropdown .value .item,
    .dropdown .list .option .item {
        display: grid;
        grid-gap: 0px 4px;
        gap: 0px 4px;
        grid-template-columns: 40px 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'icon label'
            'icon desc';

        .icon {
            grid-area: icon;
            font-size: 40px;
        }
        .label {
            grid-area: label;
            align-self: flex-end;
        }
        .desc {
            align-self: flex-start;
            grid-area: desc;
            font-size: 80%;
            opacity: 0.6;
            max-width: 600px;
            white-space: normal;
        }
    }

    .cash-amount-value {
        display: grid;
        grid-gap: 0px 8px;
        gap: 0px 8px;
        grid-template-columns: 1fr 150px;
        grid-template-rows: auto;
        grid-template-areas: 'mao rmb';
        align-items: center;
        .cash-amount-value-mao {
            grid-area: mao;
        }
        .cash-amount-value-rmb {
            grid-area: rmb;
            min-width: 150px;
        }
    }
}

// .wechat-coupon-preview-app{

//   width: 100%;

//   height: 100%;
//   display: flex;
//   flex-direction: column;

//   .wechat-coupon-preview-app-head{
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//     padding: 6px 4px;
//     .ficon{
//       color:inherit;
//       font-weight: bold;
//       font-size: 150%;
//       cursor: pointer;
//       display: flex;
//       align-items: center;
//       padding: 4px;
//       user-select: none;
//       &:hover{
//         box-shadow: inset 0px 0px 1000px 1000px #00000011;
//       }
//     }
//     .wechat-coupon-preview-app-head-back{
//       display: flex;
//       align-items: center;
//     }
//     .wechat-coupon-preview-app-head-center{
//       display: flex;
//       align-items: center;
//         flex:1;
//         font-weight: normal;
//         text-align: center;
//         justify-content: center;
//         font-size: 12pt;
//     }
//     .wechat-coupon-preview-app-head-right{
//       display: flex;
//       align-items: center;
//     }
//   }

//   .wechat-coupon-preview-app-body{
//       flex:1;
//       .wechat-coupon-preview-coupon{
//         background-color: #fff;
//         padding: 0px 0px;
//         width:94%;
//         margin: 0px auto;
//         margin-top: 40px;
//         padding-top: 12px;
//         border-radius: 4px;

//       }

//   }

// }

// .wechat-coupon-preview-coupon{
//   user-select: none;
//   position: relative;

//   .coupon-logo{
//     width:48px;
//     height: 48px;
//     position: absolute;
//     border-radius: 50%;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: 100%;
//     background-color: #fff;
//     top:-24px;
//     left:50%;
//     transform: translate(-50%,0);
//     border:1px solid #f2f2f2;
//   }

//   .coupon-brand{
//     font-size: 10pt;
//     text-align: center;
//     margin-top: 32px;
//   }

//   .coupon-title{
//     font-size: 16pt;
//     text-align: center;
//     margin-bottom: 16px;
//   }

//   .coupon-description{
//     font-size: 10pt;
//     display: none;
//     text-align: center;
//   }

//   .coupon-button{
//     font-size: 12pt;
//     padding: 10px 16px;
//     margin: 0px auto;
//     width: auto;
//     text-align: center;
//     margin-bottom: 16px;
//     color:#fff;
//     width: 50%;
//     border-radius: 3px;
//     cursor: pointer;
//     &:hover{
//       box-shadow: inset 0px 0px 1000px 1000px #00000011;
//     }
//   }

//   .coupon-subtitle{
//     font-size: 10pt;
//     text-align: center;
//     margin-bottom: 4px;
//   }

//   .coupon-date{
//     font-size: 8pt;
//     text-align: center;
//     margin-bottom: 16px;
//   }

//   .coupon-image{
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: 100%;
//     background-color: #eee;
//     min-height: 120px;
//     position: relative;
//     .coupon-abstract{
//       position: absolute;
//       bottom: 0px;
//       background-color: #00000055;
//       color:#fff;
//     }
//   }

//   .coupon-list-bottom{
//     background-color: #f2f2f2;
//     border-radius: 0px 0px 4px 4px;
//   }

//   .coupon-list-item{
//     padding: 16px 16px;
//     margin: 0px 0px;
//     border-bottom: 1px solid #e7e7e7;

//     display: grid;
//     grid-gap: 0px;
//     gap: 0px;
//     grid-template-columns: 1fr 16px;
//     grid-template-rows: auto;
//     grid-template-areas:
//       "text icon"
//       "desc icon";

//       &:last-child{
//         border-bottom: 0px none;
//       }
//       cursor: pointer;
//       &:hover{
//         box-shadow: inset 0px 0px 1000px 1000px #00000011;
//         // , 24px 0px 0px 0px #00000011, -24px 0px 0px 0px #00000011;
//       }

//     .coupon-list-item-text{
//       text-align: left;
//       grid-area: text;
//     }
//     .coupon-list-item-desc{
//       grid-area: desc;
//     }
//     .coupon-list-item-arrow{
//       grid-area: icon;
//       font-size: 16px;
//       display: flex;
//       align-items: center;
//       align-self: flex-start;

//     }
//   }

// }

// .wechat-coupon-preview-app.coupon-details .wechat-coupon-preview-app-body{
//     padding: 16px 12px;
//     .coupon-detail-title{
//         font-weight: bold;
//         padding: 4px 2px;
//         border-bottom: 1px solid #eee;
//         margin-bottom: 8px;
//     }
//     .coupon-detail-item {
//       padding: 6px 0px;
//       font-size: 9pt;
//       display: flex;
//       .coupon-detail-label{
//         opacity: 0.5;
//         width: 25%;
//       }
//       .coupon-detail-value{
//       }
//     }
// }

.time-limits {
    .time-limit-item {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 4px;
        .time-limit-day {
            width: 80px;
            align-items: center;
            display: flex;
            flex-direction: row;
        }
        .time-limit-hour {
            flex: 1;
            align-items: center;
            display: flex;
            flex-direction: row;
            padding: 4px 0px;
            .start-from {
                margin-right: 8px;
                padding: 4px;
            }
            .end-to {
                margin-left: 8px;
                padding: 4px;
            }
        }
    }
}

.edit-wechatcoupon.edit-page {
    .coupon-advanced-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 16px 0;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }
    }
}

.coupon-options {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 84px;
    max-width: 1200px;

    padding: 0 16px;
    display: flex;
    justify-content: flex-end;

    .toggle-coupon-mode {
        display: flex;
        align-self: flex-end;
    }

    &.edit {
        margin-right: 420px;
    }
}

.card-activation-form {
    .card-activation-field {
        display: flex;
        padding-top: 16px;
        border-top: 1px solid #eee;

        strong {
            display: block;
            width: 50%;
        }

        .activation-form-description {
            width: 50%;

            .edit-field-desc {
                margin-bottom: 4px !important;
            }
        }
    }

    .activation-form-checkbox {
        margin-right: 24px;
    }

    .card-activation-description {
        p {
            margin-bottom: 8px !important;
        }
    }

    .card-activation-form-fields {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 16px;

        .activation-form-field {
            display: flex;
            margin: 8px 0;
            min-width: 150px;
        }
    }
}




.card-web-view-settings { 
    padding-top: 16px;
    strong{
        padding: 16px 0px;
        display: block;
    }
 
}


@media (min-width: 1300px) {
    .coupon-options {
        &.edit {
            margin-right: 320px;
        }
    }
}

@media (max-width: 768px) {
    .coupon-options {
        top: 68px;

        &.edit {
            margin-right: 160px;
        }
    }
}
