
.wechat-coupon-preview-app{
  
  width: 100%;
 
  height: 100%; 
  display: flex;
  flex-direction: column;

  .wechat-coupon-preview-app-head{
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 6px 4px;
    .ficon{
      color:inherit;
      font-weight: bold;
      font-size: 150%;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 4px;
      user-select: none;
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000011;
      }
    }
    .wechat-coupon-preview-app-head-back{
      display: flex;
      align-items: center;
    }
    .wechat-coupon-preview-app-head-center{
      display: flex;
      align-items: center;
        flex:1;
        font-weight: normal;
        text-align: center;
        justify-content: center;
        font-size: 12pt;
    }
    .wechat-coupon-preview-app-head-right{
      display: flex;
      align-items: center;
    }
  }

  .wechat-coupon-preview-app-body{
      flex:1;
      .wechat-coupon-preview-coupon{
        background-color: #fff;
        padding: 0px 0px;
        width:94%;
        margin: 0px auto;
        margin-top: 40px;
        padding-top: 12px;
        border-radius: 4px;
        
      }

  }
 
}




.wechat-coupon-preview-coupon{
  user-select: none;
  position: relative; 
  
  .coupon-logo{
    width:48px;
    height: 48px;
    position: absolute;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #fff;
    top:-24px;
    left:50%;
    transform: translate(-50%,0);
    border:1px solid #f2f2f2;
  }

  .coupon-brand{
    font-size: 10pt;
    text-align: center;
    margin-top: 32px;
  }

  .coupon-title{
    font-size: 16pt;
    text-align: center;
    margin-bottom: 16px;
  }

  .coupon-description{
    font-size: 10pt;
    display: none;
    text-align: center;
  }

  .coupon-button{
    font-size: 12pt;
    padding: 10px 16px;
    margin: 0px auto;
    width: auto;
    text-align: center;
    margin-bottom: 16px;
    color:#fff; 
    width: 50%;
    border-radius: 3px;
    cursor: pointer;
    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000011;
    }
  }

  .coupon-subtitle{ 
    font-size: 10pt; 
    text-align: center;
    margin-bottom: 4px;
  }

  .coupon-date{ 
    font-size: 8pt; 
    text-align: center;
    margin-bottom: 16px;
  }

  .coupon-image{  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #eee;
    min-height: 120px; 
    position: relative;
    .coupon-abstract{
      position: absolute;
      bottom: 0px;
      background-color: #00000055;
      color:#fff;
    }
  }

  .coupon-list-bottom{
    background-color: #f2f2f2;
    border-radius: 0px 0px 4px 4px;
  }

  .coupon-list-item{ 
    padding: 16px 16px; 
    margin: 0px 0px; 
    border-bottom: 1px solid #e7e7e7;


    display: grid;
    grid-gap: 0px;
    gap: 0px;
    grid-template-columns: 1fr 16px;
    grid-template-rows: auto;
    grid-template-areas: 
      "text icon"
      "desc icon";

      &:last-child{
        border-bottom: 0px none;
      }
      cursor: pointer;
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000011;
        // , 24px 0px 0px 0px #00000011, -24px 0px 0px 0px #00000011;
      }

    .coupon-list-item-text{
      text-align: left;
      grid-area: text;
    }
    .coupon-list-item-desc{
      grid-area: desc;
    }
    .coupon-list-item-arrow{
      grid-area: icon; 
      font-size: 16px;
      display: flex;
      align-items: center;
      align-self: flex-start;
      
    }
  }
  
  

}




.wechat-coupon-preview-app.coupon-details .wechat-coupon-preview-app-body{
    padding: 16px 12px;
    .coupon-detail-title{
        font-weight: bold;
        padding: 4px 2px;
        border-bottom: 1px solid #eee;
        margin-bottom: 8px;
    }
    .coupon-detail-item {
      padding: 6px 0px;
      font-size: 9pt;
      display: flex;
      .coupon-detail-label{
        opacity: 0.5;
        width: 25%;
      }
      .coupon-detail-value{ 
      }
    }
}



.wx-oamenu-app-body{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wx-oamenu-body{
  flex:1;
}
.wx-oamenu-bottom{

  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  padding-bottom: 8px;
  
  .wx-oamenu-item{
      flex:1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      position: relative;
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000005;
      }
      .ficon{
        font-size: 100%;
        margin:0px 4px;
      }

      .wx-oamenu-item-name{
        font-size: 85%;
        overflow: hidden; 
        white-space:nowrap; 
      }

      .wx-oasubmenu-item-name{
        font-size: 85%;
        overflow: hidden; 
        white-space:nowrap; 
      }

      &.keyboard{
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        // border:2px solid #888; 
        width: 40px;
        height: 40px;
        text-align: center;
        overflow: hidden;

        .ficon{
          font-size: 160%;
          flex: 1;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;
          z-index: 0; 

            border-right: 1px solid #ddd;
        }

      }
  }

}

.wx-oamenu-body-time{
  font-size: 80%;
  width: 100%;
  opacity: 0.5;
  text-align: center;
  padding: 16px;
}
.wx-oamenu-body-first-text{
  font-size: 90%;
  width: 100%;
  opacity: 0.5;
  text-align: center;
  padding: 16px;
  a{
    color: #0256c3;
  }
}

.wx-oamenu-bottom .wx-oamenu-item{
  &:hover{
    .wx-oasubmenu{
      display: block;
    }
  }
  .wx-oasubmenu{
    display: none;
    position: absolute;
    background-color: #f2f2f2;
    bottom: 100%;
    left:0px;
    min-width: 100%;
    box-shadow:  0px -3px 5px 2px #00000011;
    border-radius: 3px;
    overflow: hidden;
    .wx-oasubmenu-item{
      padding: 16px 16px;
      &:hover{
        box-shadow: inset 0px 0px 1000px 1000px #00000005;
      }
    }
  }
}