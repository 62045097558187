.material-list-page{
    display: flex;
    flex-wrap: wrap; 
    width: 100%;
    .material-list-filter{
        min-width: 120px;
        max-width: 190px;
        flex:1;
    }
    .material-list-items{
        flex:2.5;
        padding: 8px;
    }
    .material-list-info{
        padding: 8px;
        background-color: #ddd;
        opacity: 0.6;
        font-size: 80%;
    }
    .material-preview{
        flex:1; 
        min-width: 300px;
       border-left: 4px solid #ddd;
       padding: 0px 8px;
       .close-preview{
        display: none;
        }
        .material-preview-inner{ 
            width: 100%;
            height: 90vh;
            top:0px;
            position: sticky; 
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: auto;

            img, video, audio, iframe{
                width: 100%;
            }
            iframe{ 
                height: 100%;
                border:0px none;
            }
        }
    }

    .material-list-items-upload{
        background-color: #e2e2e2;
    }
}









 
@media screen and (max-width: 768px) {

    .material-list-page{
        .material-list-items{

        min-width: 100px;
        max-width: 400px;
        }
        .material-preview{
            position: fixed;
            left:0px;
            top:0px;
            right:0px;
            width: 100%;
            height: 100%;
            background-color: #000000aa;
            z-index: 100;
            margin: 0px;
            max-width: 100%;
            border:0px none;
            &.no-item{
                display: none;
            }
            .close-preview{
                display: block;
                color:#fff;
                font-weight: bold;
                background-color: #00000055;
                position: fixed;
                top:10px;
                right: 10px;
                z-index: 1;
            }
        }
    }

}