.timeframe-selector-popover {
    padding: 8px 0;
    overflow: auto;

    .timeframe-selector-popover-line {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            box-shadow: inset 0 0 1000px 10000px #00000011;
        }

        &.selected {
            color: #3949ab;
            font-weight: 500;
        }
    }
}

.timeframe-selector {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin: 0;

    .ficon {
        margin-right: 8px;
        font-size: 150%;
    }

    &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 1000px 1000px rgba(0, 0, 0, 0.0666666667);
    }

    .time-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        white-space: pre;
    }

    .time-label {
        text-transform: uppercase;
        white-space: pre;
    }

    .time-range {
        display: block;
        font-size: 70%;
        opacity: 0.6;
    }
}

.custom-date-timeframe-popup {
    .popup-content {
        width: 250px;
        padding: 8px 16px 0 16px;
        overflow: initial;
    }

    .custom-date-input-start {
        margin-bottom: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .timeframe-selector {
        font-size: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
