:root {
    --header-menu-height: 48px;
    --side-menu-width: 44px;
    --side-menu-large-width: 210px;
}

.app {
    gap: 0;
    grid-gap: 0;
    display: grid;
    position: relative;
    grid-template-columns: 44px auto;
    grid-template-rows: auto;
    grid-template-areas:
        'header header'
        'menu content'
        'footer footer';
    background-color: #f2f2f2;

    .header {
        grid-area: header;
    }

    .app-menu {
        grid-area: menu;
    }

    .page {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        grid-area: content;
        padding: 16px;
        max-width: calc(100vw - var(--side-menu-width));
        min-height: calc(100vh - var(--header-menu-height));
        background-color: #f2f2f2;
         

        .page-footer {
            width: 100%;
            height: 50px;
        }
    }

    .footer {
        grid-area: footer;
    }
}

.custom-list-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .custom-list-header-menu {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
            margin-right: 32px;
            text-decoration: none;
            color: #666;
            font-weight: 500;
        }
    }

    .add-new-button {
        min-width: 250px;
        max-width: 350px;
    }
}

.inline-variables {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 12px;
    transition: 150ms;
    outline: none;
    padding-right: 30px;
}

.inline-variables input.inline-variable {
    margin: 1px 2px;
    display: inline-block;
    width: auto;
    min-width: 0;
    max-width: 1000px;
    padding: 2px;
    border: 0 none;
    text-align: center;
    background-color: #eee;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    text-transform: capitalize;
    font-family: monospace;
    pointer-events: none;
    cursor: default;
    position: relative;

    &:after {
        content: ' dsd';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.inline-variables.preview-mode input.inline-variable {
    background-color: #fffb81;
    border-radius: 0;
    font-family: inherit;
}

// COLORS
.red {
    background-color: #cc0909;
}

.green {
    background-color: #00b241;
}

.blue {
    background-color: #0098fa;
}

.darkblue {
    background-color: #3d54b5;
}

.brown {
    background-color: #ff9200;
}

.gray {
    background-color: #4d6f7b;
}

.white {
    background-color: #fff;
    color: #444;
}

.yellow {
    background-color: #d4bf00;
    color: #444;
}

.silver {
    background-color: #cecece;
    color: #444;
}

.orange {
    background-color: #f2a711;
}

.purple {
    background-color: #b23ac0;
}

.pink {
    background-color: #d13482;
}

.lightsalmon {
    background-color: lightsalmon;
}

.darksalmon {
    background-color: darksalmon;
}

.salmon {
    background-color: salmon;
}

.indianred {
    background-color: indianred;
}

@media (max-width: 768px) {
    .app {
        gap: 0;
        grid-gap: 0;
        display: grid;
        position: relative;
        grid-template-columns: 40px auto;
        grid-template-rows: auto;
        grid-template-areas:
            'menu header '
            'content content'
            'footer footer';

        .page {
            padding: 16px;
            width: 100vw;
            background-color: #f2f2f2;
            display: flex;
            flex-direction: column;
            justify-items: center;
            max-width: 100vw;
        }
    }
}

@media (min-width: 1600px) {
    .app {
        grid-template-columns: 210px auto;

        .page {
            max-width: calc(100vw - var(--side-menu-large-width));
        }
    }
}
