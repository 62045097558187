.overview-page {
    .overview-card:has(.kpi-totals-overview-card) {
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;

        > .card-content {
            width: 100%;
            padding: 0;
        }
    }
}

.kpi-totals-overview-card {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;

    .kpi-data-card { 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-right: 1px solid #eee;
        padding: 8px;
        position: relative;

        &:last-child {
            border: 0 none;
        }

        .sub-title {
            font-size: 80%;
            text-transform: uppercase;
            font-weight: bold;
            opacity: 0.7;
        }

        .value {
            font-size: 140%;
            margin: 8px 0;
            .value-label {
                font-size: 70%;
                opacity: 0.5;
                text-transform: lowercase;
            }
        }

        .sub-value {
            margin: 8px 0;
        }

        .sticker {
            width: auto;
            margin-bottom: 8px;
        }

        .foot-title {
            color: #aaa;
        }
        .more-data-button{
            display: inline-block; 
            font-size: 140%;
            vertical-align: middle;
            font-weight: bold;
            margin-left:8px;
        }
    }
}


.more-data-popover{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 16px;
        border-bottom: 1px solid #eee;
        width: 300px;
        &:last-child{
            border: none;
        }
        .more-data-item{
            padding: 8px;
            .more-data-item-label{  
                font-size: 80%;
                text-transform: uppercase;
                font-weight: bold;
                opacity: 0.7;
            }
            .more-data-item-value{
                font-size: 140%;
            }
        }
}


@media only screen and (max-width: 1200px) {
    .kpi-totals-overview-card {
        grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .kpi-totals-overview-card {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
}




.overview-card:has(.empty-state) {
    .card-content {
        .empty-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 125px;

            .empty-state-icon {
                font-size: 40px;
                opacity: 0.3;
                margin-bottom: 8px;
            }
        }
    }
    .card-header {
        margin-bottom: 0;
    }
}
