.dashboard {
    .page {
        padding: 16px 8px;
    }
}

.dashboard-title {
    width: 100%;
    padding: 8px;
    font-size: 180%;

    .dashboard-desc {
        font-size: 50%;
        opacity: 0.5;
    }
}

.dashboard-overview.overview-page {
    width: 100%;
}
