.popup-wrapper:has(.create-custom-article-component) {
    z-index: 90;
}

.create-custom-article-component {
    max-width: 700px;
}

#article-component-preview {
    width: 500px;
    height: 500px;
    padding: 16px;
}
.saving-component {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #ffffffdd;
    text-align: center;
    padding-top: 100px;
    font-size: 150%;
}
