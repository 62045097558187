.app.wechat.page-article.subpage-preview {
    grid-template-columns: auto;
    grid-template-areas: 'content';
    background-color: transparent;
    overflow: hidden;
    font-size: 150%;

    .header {
        display: none;
    }

    .app-menu {
        display: none;
    }

    .page {
        margin: 0;
        padding: 8px;
        width: 100%;
        overflow: hidden;

        background-color: transparent;
    }
}
