.email-session-list {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .email-session-list-header {
        background-color: #fff;
        border-bottom: 1px solid #eee;
        padding-top: 8px;
        user-select: none;

        .menu-open {
            margin-top: 12px 0px;
        }

        .status-filter-combo,
        .sort-combo {
            display: inline-block;
            width: auto;
            border: 0 none;
            background-color: transparent;
            outline: none;
            padding: 8px 8px;
            padding-right: 32px;
            margin-left: 0;
            opacity: 0.6;
            cursor: pointer;
            border-radius: 0;
            color: #444;

            &:hover {
                background-color: #eeee;
            }
        }

        .status-filter-combo {
            margin-left: 8px;
            border-right: 1px solid #eeee;
        }

        .sort-combo {
            padding-left: 10px;
        }

        .search-box {
            margin-top: 8px;
            background-color: transparent;
            outline: none;
            border: 0 none;
            padding: 10px 16px;
            border-top: 1px solid #eee;
        }
    }

    .email-session-list-items {
        overflow: auto;
    }

    .session-list-item {
        cursor: pointer;
        position: relative;
        padding: 12px 12px;
        padding-left: 10px;
        padding-right: 20px;
        user-select: none;
        border-left: 3px solid transparent;
        gap: 2px 8px;
        grid-gap: 2px 8px;
        display: grid;
        grid-template-columns: 48px 1fr auto;
        grid-template-rows: auto;
        grid-template-areas:
            'image title status'
            'image desc time';
        align-items: center;

        .email-notification {
            position: absolute;
            left: 40px;
            top: 10px;
            background-color: #ff0000;
            color: #fff;
            font-weight: bold;
            font-size: 70%;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .user-is-online {
            position: absolute;
            left: 45px;
            bottom: 16px;
            background-color: #05b834;
            color: #fff;
            font-weight: bold;
            font-size: 70%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            background: #f2f2f2;
        }

        &.selected {
            background: #f2f2f2;
            border-left: 3px solid #4c84af;
        }

        &.pinned {
            box-shadow: inset 0 0 1000px 1000px #0011ff08;
            border-left: 3px solid #d29898;
        }
        &.pinned.selected {
            box-shadow: inset 0 0 1000px 1000px #0011ff08;
            border-left: 3px solid #386c93;
        }

        .mute-icon {
            display: none;
        }

        &.muted {
            .mute-icon {
                display: block;
                position: absolute;
                right: 55px;
                bottom: 14px;
                font-size: 100%;
                background-color: #eeeeeeee;
                border-radius: 50%;
                padding: 2px;
                font-weight: bold;
                opacity: 0.6;
            }
        }

        .avatar {
            grid-area: image;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: #ddd;
            border: 1px solid #ddd;
        }

        .email-title {
            color: #263238;
            align-self: flex-end;
            grid-area: title;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .last-message {
            align-self: flex-start;
            grid-area: desc;
            font-size: 90%;
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .last-message-sender {
                max-width: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                opacity: 0.6;
            }

            .last-message-text {
                opacity: 0.8;
                padding-left: 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex: 1;
            }
        }

        // .session-status{

        //     align-self: flex-end;
        //     grid-area: status;
        // //   position:absolute;
        // //   top:8px;
        // //   right:8px;
        //   padding:4px 8px;
        //   color:#fff;
        //   color:#000;
        //   font-size: 60%;
        //   border-radius: 2px;
        //   font-weight: 500;

        //   &.in-progress{
        //     background-color:#4CAF50;
        //     color:#fff;
        //   }
        //   &.pending{
        //     background-color:#4c84af;
        //     color:#fff;
        //   }
        //   &.expired{
        //     background-color:#f2f2f2;
        //     color:#00000055;
        //   }
        //   &.closed{
        //     background-color:#a1adb2;
        //     color:#fff;
        //   }
        // }
        .last-time {
            align-self: flex-start;
            grid-area: time;
            //   position:absolute;
            //   top:8px;
            //   right:8px;
            font-size: 70%;
            padding: 2px 8px;
            font-weight: 500;
            color: #000;
            border-radius: 2px;
            justify-self: flex-end;
            text-transform: uppercase;
            background: #f2f2f2;

            &.active {
                background: #ffc600;
                color: #fff;
            }
        }
    }
}

.session-assigned-popoover {
    padding: 16px;

    .member-detail {
        padding: 8px 0;
        font-size: 90%;
    }
}
.session-time-popoover {
    padding: 16px;
}

@media (max-width: 768px) {
    .email-session-list {
        .email-session-list-header {
            .status-filter-combo {
                max-width: 100px;
            }
            .sort-combo {
                max-width: 140px;
            }
        }
    }
}
