.application-info-popup-content {
    width: 750px;
    display: flex;  

    .application-info-popup-content-left {
        width: 350px;
        padding-right: 8px;
        padding-left: 8px;
        border-right: 1px solid #eee;
    }

    .application-info-popup-content-right {
        padding-left: 8px;
        padding-right: 8px;
        flex: 1;
    }

    .application-info-name{
        font-size: 140%;
        margin-bottom: 8px;
    }
  .application-info-subtitle{
    display: flex;
    flex-direction: row;
    gap:4px;
    margin-bottom: 16px;
  }
}
 