.urlwhitelist-add-popup{

    .urlwhitelist-add-popup-content{
        width: 500px; 
        padding: 8px;
        gap:16px;
        display: flex;
        flex-direction: column;

        &.loading { 
            user-select: none;
            pointer-events: none;
            opacity: 0.4;
        }
        .urlwhitelist-error{
            padding: 8px;
            background-color: #ff00000c;
            color:#9a0000;
            font-size: 90%;
        }   
    }
}