.account-edit-popup-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .account-setting-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-left: 16px;
    }

    .account-setting-row {
        display: flex;
        position: relative;
        padding: 8px;

        &.file-upload-row {
            padding-top: 24px;
        }

        .profile-photo {
            max-width: 120px;
            border-radius: initial;
        }
    }

    .account-setting-label {
        font-size: 75%;
        width: 100%;
        height: 16px;
        padding-left: 12px;
        padding-top: 4px;
    }

    .account-setting-desc {
        font-size: 75%;
        opacity: 0.4;
        width: 100%;
        height: 16px;
        padding-left: 12px;
        padding-top: 4px;
    }

    .account-setting-value {
        margin: 8px 0;

        input {
            padding-right: 36px;
        }
    }

    .file-upload-input {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px !important;

        .filepond--root {
            margin-bottom: 0;
        }

        .filepond--drop-label.filepond--drop-label label {
            opacity: 0.6;
            font-size: 80%;
        }
    }

    .account-setting-info {
        position: absolute;
        right: 16px;
        bottom: 16px;
        width: 22px;
        height: 22px;
        font-size: 22px;
        padding: 0;
        border-radius: 50%;
        opacity: 0.3;

        &:hover {
            opacity: 0.8;
        }
    }
}

@media (min-width: 768px) {
    .account-edit-popup-content {
        width: 500px;
    }
}
