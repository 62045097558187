.card-usage-page {
    .view-page-header {
        grid-area: header;
        width: 100%;
        justify-content: space-between;
    }

    .card-usage-info {
        grid-area: info;
        overflow: hidden;

        .card-usage-details {
            margin-bottom: 16px;

            .card-header {
                margin-bottom: 0;
            }

            .card-details-info {
                display: flex;

                .card-details-summary {
                    margin-left: 16px;
                }

                .card-preview {
                    position: relative;
                    box-shadow: none;

                    .bg {
                        width: 300px;
                        height: 190px;
                        object-fit: cover;
                        border-radius: 8px;
                        background-color: #000;
                        border: 2px solid #eee;
                        box-shadow: none;
                    }

                    .logo {
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        border: 1px solid #fff;
                    }

                    .brand {
                        position: absolute;
                        top: 12px;
                        left: 50px;
                        font-size: 80%;
                        color: #fff;
                    }

                    .title {
                        position: absolute;
                        top: 24px;
                        left: 50px;
                        font-size: 70%;
                        color: #fff;
                    }

                    .card-no {
                        position: absolute;
                        left: 20px;
                        bottom: 20px;
                        font-size: 120%;
                        color: #fff;
                    }
                }
            }
        }
    }

    .card-usage-list-wrapper {
        .card-header {
            margin-bottom: 0;
        }

        .card-usage__search-bar {
            max-width: 300px;
            margin-bottom: 16px;
        }

        .card-usage-list {
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
            background: white;
            overflow: auto;
            align-items: flex-start;

            .item__column {
                text-overflow: ellipsis;
                overflow-x: hidden;
                white-space: nowrap;
                // min-width: 100px;
                max-width: 300px; 

                &.small {
                    // min-width: 80px;
                    // max-width: 80px;
                    font-size: 90%;
                }

                &.cw-s{
                    width: 60px;
                }
                &.cw-1{
                    width: 80px;
                }
                &.cw-2{
                    width: 120px;
                }
                &.cw-3{
                    width: 160px;
                }
                &.cw-4{
                    width: 200px;
                }
            }

            .list-headers {
                display: flex;
                padding: 16px;
                font-weight: bold;
                background: #fafafa;
            }

            .card-usage-list-item {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f0f0f0;
                padding: 8px 16px;

                .empty-transactions-text {
                    padding-left: 16px;
                }

                .card-usage-list-item__cell {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &:last-child {
                        padding: 8px 0 8px 16px;
                    }
                }
            }

            .transaction-list {
                display: flex;
                list-style: none;
                flex-direction: column;

                .list-headers {
                    font-weight: 400;
                    color: #999;
                    padding: 8px 0 8px 32px;

                    .list-header {
                        &:first-child {
                            min-width: 260px;
                            max-width: initial;
                        }
                    }
                }

                .transaction-list-item {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    border-bottom: 1px solid #f0f0f0;
                    padding: 8px 0 8px 32px;

                    .transaction-list-item__cell {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 1;

                        &:first-child {
                            min-width: 260px;
                            max-width: initial;
                        }
                    }
                }
            }
        }
    }

    .view-page-footer {
        grid-area: footer;
    }
}

@media only screen and (max-width: 968px) {
    .card-usage-list {
        max-width: 1200px;
    }

    .card-details-info {
        flex-direction: column;
        align-items: center;

        .card-preview {
            margin-bottom: 16px;
        }
    }
}
