.company-profile{

  .company-profile-title{
    font-size: 160%;
    padding: 8px 10px;
    padding-bottom: 0px;
  }
  .company-profile-desc{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

  .company-profile-footer{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

}



.admin-types{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  max-width: 450px;
  min-width: 400px;
  padding-left: 16px;
  padding-right: 16px;
  .admin-type{
    padding: 16px;
    border:1px solid #eee;
    border-radius: 4px;
    margin: 8px;
    cursor: pointer; 
    width: 100%;

    display: grid;
    grid-template-columns: 48px 1fr;
    grid-template-areas: 
    "icon title"
    "icon desc"
    ;

    &:hover{
      box-shadow: inset 0px 0px 1000px 10000px #00000011;
    }
    
    &.selected{
      background-color: #1147bb;
      color: #fff;
      .ficon{
        color:#fff;
      }
    }

    
    .admin-type-icon{
       grid-area: icon; 
       .ficon{
        font-size: 32px;
       }
    }
    .admin-type-title{
      grid-area: title;
      font-weight: 500;
    }
    .admin-type-desc{
      grid-area: desc;
      font-size: 80%;
    }
  }
}

.company-info{
  flex:1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 400px;

    .upload-logo{
      border:1px solid #ddd;
       border-radius: 4px !important;
    }

}
.company-info .formel{  
  margin-bottom: 16px;
}


.company-info-inner{
  display: flex;
  flex-direction: row;
}
