.switch {
    display: inline-flex;
    align-items: center;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.switch.disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.8;

    input:checked + .slider {
        background-color: #798187;
    }

    input:checked + .slider:before {
        background-color: #646a70;
    }
}

/* The switch - the box around the slider */
.switch .switch-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 36%;
    left: 3px;
    right: 3px;
    bottom: 36%;
    background-color: #aaa;
    transition: 0.3s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: -3px;
    bottom: -3px;
    background-color: #fff;
    transition: 0.3s;
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
}

input:checked + .slider {
    background-color: #2196f3;
}


.lighter-color input:checked + .slider {
    box-shadow: 1px 1px 5px 5px #00000022;
   
}

.lighter-color input:checked + .slider:before { 
    background-color: #237ec9;
}


input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    transform: translateX(16px);
    background-color: #0a62aa;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch .text {
    display: inline-block;
    padding-left: 4px;
    cursor: pointer;
    user-select: none;
}
