.custom-column-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;

    .custom-column-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 80%;
        padding: 8px;
        border: 1px solid #eee;
        cursor: pointer;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }

        .ficon {
            font-size: 200%;
            margin-bottom: 0;
        }
    }
}

.article-custom-code {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 8px;

    .custom-html-code {
        cursor: pointer;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 80%;
        padding: 8px;
        border: 1px solid #eee;

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }

        .ficon {
            font-size: 200%;
            margin-bottom: 0;
        }
    }
}

.article-content {
    position: relative;

    &.error:after {
        content: 'Error: ' attr(data-error);
        position: absolute;
        padding: 0 6px;
        transition: 150ms;
        display: flex;
        align-items: center;
        height: auto;
        font-size: 65%;
        background-color: #fff;
        bottom: -5px;
        left: 9px;
        color: #d69d9d;
    }

    &.error {
        border: 1px solid #d32f2f;
        border-left: 3px solid #d32f2f;

        .content-type-button {
            color: #d32f2f;
        }
    }
}
.editor-warning{
    background-color: #d32f2fd1; 
    color:#fff;
    padding: 16px;
}