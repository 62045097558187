.analytics-command-editor{
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: auto;
  max-width: 500px;
  
  border-radius: 4px;
  border:1px solid #ddd;
  padding:   8px;
  min-height: 44px;

  
  &:hover{
    box-shadow:  inset 0px 0px 1000px 10000px #00000005;
    .analytics-command-editor-title{
     
    }
  }
  &:active{
    box-shadow:  inset 0px 0px 1000px 10000px #00000011;
    .analytics-command-editor-title{
      background-color: transparent; 
    }

  }
  .analytics-command-editor-title{
     
    background-color: #fff;  
    padding: 0px 8px;
    color:#aaa;
    
  }


  &.has-value .analytics-command-editor-title{
    font-size: 65%;
    left:8px;
    top:-8px;
    background-color: #fff; 
    position: absolute;
    padding: 2px 4px;
    color:#666;
  }


  .analytics-command-editor-values{ 
      display: flex;
      flex:1;
      flex-wrap: wrap;

      .analytics-command-editor-value{
        border: 1px dashed #ccc;
        padding: 8px 12px; 
        margin: 4px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 100%;
        padding-left: 8px; 
        .ficon{
          font-size: 120%;
          margin-right: 4px;
        }
      }
      
  }
  .analytics-command-editor-arrow{

  }
}

.analytics-command-editor-commands{
   
  padding: 8px;
  &.has-focussed .analytics-command-editor-command{
    opacity: 0.2;
    user-select: none;
    pointer-events: none;
  }

  &.has-focussed .analytics-command-editor-command.focussed{
    opacity: 1;
    pointer-events: all;
  }

  .command-editor-command-new{
    margin-top: 16px;
  }
}
.analytics-command-editor-command{
  .expanded{
    background-color: #fff;
    border:1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 0px 30px 10px #00000011;
  }
  // margin-bottom: 16px;

  .analytics-command-editor-head-accordion{
    padding: 0px 16px;
    font-weight: normal;
    text-transform: capitalize;
  }

  .analytics-command-editor-head{
    display: flex;
    align-items: center;
    flex-wrap: wrap; 
    // border-bottom: 1px solid #ddd;
    // padding-bottom: 16px; 
    // padding: 16px;
  }


  .command-select{
    flex: 1;
    
  }
  .command-remove{
    margin-left: 16px;
  }
  .command-parameters{
    width: 100%;

    padding: 16px;
     padding-top: 16px;
    // width: calc(94% - 32px);
    // margin-left: 6%;
    // padding-left: 16px;
    // border-left: 3px solid #eee; 
 

   
  }
}






// .record-selector-list{
//   min-width: 300px;
//   max-width: 500px;
//   border:1px solid #ddd;
//   border-radius: 4px;
//   // padding-bottom: 16px;
//   .record-selector-title{
//     font-size:65%;
//     background-color: #fff;
//     margin-top: -8px;
//     width: auto;
//     display: inline-block;
//     transform: translate(8px, -10px);
//     padding: 1px 4px;
//   }
//   .select-record{
//     // margin-top: 16px;
//     width: 100%;
    
//   }
// }

// .record-selector-list .selected-record{
  
//   display: flex;
//   align-items: center;
//   width: 100%;
//  .record-item{
//    flex:1;
//  }
//  .remove-item{
//    margin-right: 16px;
//    font-size: 120%;
//    opacity: 0.6;
//  }
// }

