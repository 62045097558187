
.ak-list{


    background-color: #fff;
    border:1px solid #eee;
    border-radius: 4px;
    .ak-row{
      display:flex; 
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center; 
    border-top:1px solid #eee;
      &>div{ 
        padding: 16px  ;
        flex:1;
      }
       
      .ak-name{ 
        min-width: 300px; 
        flex: 1;
      }
      .ak-key{
            min-width: 200px; 
            max-width: 450px; 
          flex: 1;
      } 
      .ak-secret{
            min-width: 200px; 
            max-width: 450px; 
          flex: 1;
      } 
      .ak-updatedat{
        min-width: 160px; 
        max-width: 220px;  
      }
      .ak-createdat{
        min-width: 160px; 
        max-width: 220px;  
      } 
      .ak-switch{
        min-width: 100px; 
        max-width: 160px;  
      }
      .ak-actions{
        min-width: 100px; 
        max-width: 120px;  
      }
      
      .ak-detail {
        width:100%;
        flex: none;
        padding-left: 32px;
        border-top: 1px solid #f2f2f2; 
        background-color: #fafafa;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        &>div:not(.cell-label){
            font-size: 90%;
            opacity: 0.8;
        }
      }
    }


    .ak-scope,
    .ak-limits,
    .ak-rules,
    .ak-cost{
        width: 100%;
        display: block;
        margin-bottom: 16px;
    }


    .cell-label{
        font-size: 75%;
        opacity: 0.6;
        width: 100%;
        padding-bottom:4px;
    }
    .cell-value-hidden{
            display: inline-block;
            .cell-value-show-hidden{
                font-size: 120%;
                margin-left: 16px;
            }
        }
  }
   