.wizard-done{

  text-align: center;
  justify-content: center;
  align-items: center;
  .wizard-done-title{
    font-size: 240%;
    padding: 32px 10px;
    padding-bottom: 16px;
  }
  .wizard-done-desc{
    font-size: 120%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

  .wizard-done-footer{
    font-size: 90%;
    padding-left: 10px;
    padding-bottom: 16px;
    opacity: 0.7;
  }

}

 

.wizard-done-info{
  flex:1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.wizard-done-info .formel{  
  margin-bottom: 16px;
  text-align: left;
}


.wizard-done-info-inner{
  display: flex;
  flex-direction: row;
}

.finish-button{
  width: 300px;
  input{
    padding: 16px;
    text-transform: uppercase;
    font-size: 110%;
  }
  
}




.apps-section{
  margin-top: 32px;  
  border-top: 1px solid #eee;
  padding-top: 32px;
}
.apps-title{

  font-size: 130%;
  padding: 16px;
  text-align: left;
}

.apps{
  display: flex;
  flex-wrap: wrap;
  .app{
    text-decoration: none;
    color: #000;
    user-select: none;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    text-align: left;
    border:1px solid #eee;
    margin: 8px;
    max-width: 336px;
    min-width: 320px;
    width: 100%;
    .ficon{ 
      text-align: center;
      font-size: 18pt;
      display: inline-block;
    }

    &:hover{
      box-shadow: inset 0px 0px 1000px 1000px #00000011;
    }
    
  }
}