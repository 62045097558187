.material-item {
    margin: 0.5%;
    padding: 8px;
    min-width: 120px;
    max-width: 400px;
    cursor: pointer;
    font-size: 100%;
    text-align: center;
    border-radius: 4px;
    position: relative;
    border: 1px solid transparent;
    align-items: center;
    display: flex;
    flex-direction: column;

    &.selected {
        border: 1px solid #2994ff90;
    }

    &.selected,
    &.selected .material-item-image {
        box-shadow: inset 0 0 1000px 1000px #1c91ff23;
    }

    &:hover {
        box-shadow: inset 0 0 1000px 10000px #00000011;
    }

    &:not(.image):not(video) {
        .material-item-image {
            border: 1px solid #ddd;
            background-color: #00000011 !important;
        }
    }

    .material-item-image {
        width: 100%;
        min-width: 120px;
        max-width: 160px;
        height: 120px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f2f2;
        border-radius: 4px;
        padding: 4px;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 4px;
            background-color: #f2f2f2;
        }

        .play-icon {
            position: absolute;
            font-size: 40px;
            background-color: #ffffffaa;
            opacity: 0.6;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: none;
        }

        .file-icon {
            position: absolute;
            background-color: #000000aa;
            opacity: 0.9;
            border-radius: 5px;
            width: 60px;
            height: 60px;
            align-items: center;
            justify-content: center;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 8px;
            display: none;
        }
    }

    .material-id {
        font-size: 70%;
        opacity: 0;
        padding: 2px;
    }

    .material-crop-from {
        font-size: 70%;
        opacity: 0;
        padding: 2px;
    }

    &.video .material-item-image .play-icon {
        display: block;
    }

    &:not(.video):not(.image) .material-item-image .file-icon {
        display: block;
    }

    .material-item-name {
        padding: 6px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        vertical-align: middle;

        .ficon {
            font-size: 100%;
            display: inline-block;
            vertical-align: bottom;
            color: #3d54b5;
            font-weight: bold;
            margin-right: 2px;
            border-radius: 50%;
            padding: 2px;
        }
    }

    .material-item-info {
        width: 100%;
    }

    .material-item-date {
        font-size: 80%;
        opacity: 0.5;
    }

    .material-item-action-button {
        display: none;
        position: absolute;
        right: 5px;
        top: 5px;
        background-color: #000000bb;
        border-radius: 50%;

        .iconbutton {
            color: #fff;
            font-weight: bold;
            border-radius: 50%;
        }
    }

    &:hover {
        .material-item-action-button {
            display: block;
        }

        .material-id {
            opacity: 0.3;
        }
        .material-crop-from {
            opacity: 0.3;
        }
    }
}

.material-item.horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .material-item-info {
        text-align: left;
        flex: 1;

        & > * {
            margin: 4px 8px;
        }

        .material-item-tags {
            margin: 2px 6px;
        }

        .material-item-name {
            padding: 0;
            white-space: normal;
            word-break: break-all;
            text-overflow: unset;
            overflow: hidden;
            max-height: 80px;
        }
    }
}

.material-tags-popover{
    overflow: auto;
    width: 400px;
   
    padding: 16px ;
    
}
.material-item-tags {
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &.multiline{
        white-space: wrap;
    }

    .material-item-tag {
        pointer-events: none;
        display: inline-block;
        font-size: 80%;
        padding: 2px 8px;
        background-color: #00000011;
        border-radius: 3px;
        margin: 2px;
        opacity: 0.8;

        .material-item-tag-icon-wrapper {
            display: flex;
            align-items: center;

            .ficon {
                margin-right: 4px;
            }
        }
    }
}


.material-item-details {
    overflow: auto;
    max-width: 330px;
    min-width: 300px;

    .material-item-tags {
        display: inline-block;

        .material-item-tag {
            display: inline-block;
            font-size: 80%;
            padding: 2px 8px;
            background-color: #00000011;
            border-radius: 3px;
            margin: 2px;
            opacity: 1;

            .ficon {
                margin-right: 4px;
            }
        }
    }

    .material-item-detail-buttons {
        padding: 8px 8px;
        display: flex;
        flex-wrap: wrap;

        .material-item-button {
            margin: 2% 2%;
            min-width: 46%;
        }
    }
    .material-item-detail-line {
        padding: 4px 16px;
        border: 1px solid #eee;
        font-size: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .material-item-detail-line-label {
            min-width: 120px;
            display: inline-block;
        }

        .material-item-detail-line-button {
            display: inline-block;
            cursor: pointer;
            margin-left: 4px;
            color: #3949ab;
        }

        .material-item-detail-line-link {
            cursor: pointer;
            display: inline-block;
            margin-left: 8px;
            color: #133ab9aa;
        }
    }
}

.material-item {
    .popup:has(.material-item-edit-tag-dropdown) {
        position: relative;
        z-index: 1;
        min-width: 300px;

        .popup-content {
            padding: 16px;
            min-width: 220px;
        }
    }
}
