.personalized-content-buttons{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  user-select: none;
  width: auto;
  max-width: 500px;
  
  border-radius: 4px;
  border:1px solid #ddd;
  padding:   8px;
  min-height: 44px;

  
  &:hover{
   // box-shadow:  inset 0px 0px 1000px 10000px #00000005;
    
  }
  &:active{
   // box-shadow:  inset 0px 0px 1000px 10000px #00000011;
    .analytics-command-editor-title{
      background-color: transparent; 
    }

  }
  .personalized-content-title{
     
    background-color: #fff;  
    padding: 0px 8px;
    color:#aaa;
    
  }


  &.has-value .personalized-content-title{
    font-size: 65%;
    left:8px;
    top:-8px;
    background-color: #fff; 
    position: absolute;
    padding: 2px 4px;
    color:#666;
  }


  // .analytics-command-editor-values{ 
  //     display: flex;
  //     flex:1;
  //     flex-wrap: wrap;

  //     .analytics-command-editor-value{
  //       border: 1px dashed #ccc;
  //       padding: 8px 12px; 
  //       margin: 4px;
  //       border-radius: 4px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: flex-start;
  //       font-size: 100%;
  //       padding-left: 8px; 
  //       .ficon{
  //         font-size: 120%;
  //         margin-right: 4px;
  //       }
  //     }
      
  // }
  // .analytics-command-editor-arrow{

  // }
}

.personalized-content-buttons{
 
  padding: 8px;
  &.has-focussed .personalized-content-button{
    opacity: 0.2;
    user-select: none;
    pointer-events: none;
  }

  &.has-focussed .personalized-content-button.focussed{
    opacity: 1;
    pointer-events: all;
  }
}

.personalized-content-button{
  &.expanded{
    // background-color: #fff;
    //  border:1px solid #ddd;
     border-radius: 4px;
    // box-shadow: 0px 0px 30px 10px #00000011;
  }
  // margin-bottom: 16px;

  .personalized-content-button-head{
    padding: 0px 16px;
    font-weight: normal;
    text-transform: capitalize;
  }

  .personalized-content-button-head-items{
    display: flex;
    align-items: center;
    flex-wrap: wrap;  
    >*{
      flex: 1;
    }
    .command-remove{ 
      flex: none;
    }
  }

 
  .command-remove{
    margin-left: 16px;
  } 

  .personalized-content-button-content{ 
    padding: 16px;
    padding-top: 8px;
    padding-bottom: 32px;
    >*{
      margin-top: 16px;
    }
  }
}



 