.analytics:has(.overview-page) {
    .tab-header {
        margin: 0 8px 8px 8px;
    }

    .page {
        padding: 16px 8px;
    }

    .toolbar-wrapper {
        margin: 0;
        border: none;

        .toolbar {
            margin: 0 8px 0 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid #ddd;
        }
    }
}

.article-comment-view-page {
    .article-comparison {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }

    .article-data .card-content,
    .article-user-data .card-content {
        display: grid;
        grid-gap: 16px;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        grid-auto-rows: max-content;
        grid-auto-flow: row dense;
    }
}

.article-comment-view-page .data-card .card-content,
.article-comment-view-page .article-data .article-data-card,
.article-comment-view-page .article-user-data .article-data-card {
    display: grid;
    grid: auto / auto;
    gap: 8px;
    grid-gap: 8px;
    align-items: center;
    align-content: center;
    justify-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    border-right: 1px solid #eee;

    &:last-child {
        border: 0 none;
    }

    .sub-title {
        font-size: 80%;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.7;
    }

    .value {
        font-size: 140%;
    }

    .sticker {
        width: auto;
        margin-bottom: 8px;
    }

    .sub-value {}

    .foot-title {
        color: #aaa;
    }
}

.article-comment-view-page .article-data .article-data-card,
.article-comment-view-page .article-user-data .article-data-card {
    justify-items: center;
    justify-content: center;
    padding: 8px 0;
}

.article-comment-view-page .article-data-cards {
    padding: 16px 0;
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;

    .article-data,
    .article-user-data {
        flex: 1;
    }
}

.article-data-charts {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;

    margin-bottom: 16px;
}

.realtime-charts {
    display: grid;
    grid-template-columns: 1fr minmax(200px, 300px);
    grid-gap: 16px;
    gap: 16px;
    width: 100%;

    .realtime-chart-line {
        flex: 1;
        min-width: 300px;
        position: relative;
    }

    .realtime-chart-line:before {
        content: ' ';
        width: calc(9% + 16px);
        background: #00000011;
        height: 100%;
        position: absolute;
        right: 0;
    }
}

.active-visitors {
    min-width: 300px;

    .title {
        font-weight: bold;
        opacity: 0.5;
    }

    .value {
        font-weight: normal;
        font-size: 250%;
        padding: 8px 0;
    }

    .passive-value {
        opacity: 0.4;
        font-size: 50%;
        margin-left: 8px;
    }
}

.visitors {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 200px;
    max-height: 330px;
    overflow: auto;
}

.visitors .list-item {
    width: 100%;
    min-width: 200px;

    .item-image {
        width: 36px;
        height: 36px;
    }
}

.real-time-visits {
    width: 100%;
    height: 400px;
    padding-bottom: 44px;
    background-color: #ff0000;
}

.real-time-visits .real-time-visit-bars {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    background-color: #777;
}

.real-time-visits .real-time-visit-bars .real-time-visit-bar {
    height: 100%;
    width: 32px;
    position: relative;

    .real-time-visit-bar-graph {
        width: 30px;
        background-color: #ddd;
        position: absolute;
        bottom: 0;
    }

    .real-time-visit-bar-time {
        position: absolute;
        bottom: -28px;
        left: -2px;
        width: 100%;
        text-align: center;
        font-size: 80%;
        font-weight: bold;
        transform: rotate(-60deg);
    }

    .real-time-visit-bar-value {
        padding: 4px 8px;
        text-align: center;
        font-weight: bold;
        position: absolute;
        top: -20px;
        background-color: #fff;
        transform: translate(-50%, -50%);
        left: 50%;
    }
}

.card-full-width {
    display: flex;
    width: 100%;

    .sub-title {
        font-weight: 500;
    }
}

.card-full-width .card-content {
    width: 100%;
}

.overview-row {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
}

.overview-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
}

.card-full-width .card-content {
    flex-direction: column;
}

.user-statics-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .chart-wrapper {
        width: 20%;
        min-width: 200px;
        flex: 1;
    }

    .chart-wrapper:not(:last-of-type) {
        border-right: 1px solid #eeeeee;
    }

    .chart-data-options {
        text-align: center;
    }

    .chart-labels {
        justify-content: center;
    }
}

.analytics-tab-visualization {
    width: 100%;
    margin: 12px 0;
    background: white;
    padding-top: 16px;

    .visualization-title {
        font-size: 120%;
        font-weight: 500;
        margin: 40px 24px 40px 24px;
    }

    .tab-content {
        transition: 0.3s ease;
        min-height: 480px;
        background: linear-gradient(180deg, #3d54b5 0%, #3949ab 100%);
    }

    .tab-header-wrapper {
        padding-left: 24px;
    }

    .tab-header {
        margin-bottom: 0;
        border-bottom: 0;

        .tab-title {
            padding: 12px 16px 12px 0;
            border-bottom: 0;
            text-transform: capitalize;

            &:hover {
                box-shadow: none;
            }
        }

        .tab-title:not(.selected) {
            opacity: 0.5;
        }
    }
}

.card:has(.analytics-data-card-chart) {
    margin-bottom: 16px;
    max-width: 550px;

    .card-header {
        border: none;
    }
}

.row-wrapper {
    display: flex;
    justify-content: space-between;

    > :first-child {
        margin-right: 16px;
    }
}

.analytics-data-card-chart {
    display: flex;
    align-items: center;
    background: white;
    width: 100%;

    .card-data-chart-labels {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 16px 0 0 0;
    }

    .card-data-chart-label {
        width: 100%;
        padding: 8px;
        max-width: 220px;
        min-width: 180px;

        .card-data-chart-label-data {
            font-size: 120%;
        }

        .card-data-chart-label-title {
            font-weight: 500;
            opacity: 0.5;
            font-size: 90%;
        }
    }

    .chart-wrapper {
        max-width: 290px;
        margin-bottom: 24px;
    }
}

.analytics-overview {
    .overview-card {
        margin: 8px;
    }
}

@media (max-width: 768px) {
    .realtime-charts {
        grid-template-columns: 1fr;
    }

    .analytics-data-card-chart {
        .chart-wrapper {
            max-width: 180px;
            margin-bottom: 24px;
        }
    }

    .analytics-tab-visualization {
        .tab-content {
            transition: 0.3s ease;
            min-height: 320px;
            background: linear-gradient(180deg, #3d54b5 0%, #3949ab 100%);
        }
    }
}

@media (max-width: 968px) {
    .row-wrapper {
        flex-direction: column;

        > :first-child {
            margin-right: 0;
        }
    }

    .card:has(.analytics-data-card-chart) {
        margin-bottom: 16px;
        max-width: 100%;

        .card-header {
            border: none;
        }
    }
}