html,
body {
    padding: 0;
    margin: 0;
    // box-sizing: border-box;
    font-size: 10pt;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
    font-weight: 400;
    color: #444;
}

:root {
    --color-red: #cc0909;
    --color-green: #00b241;
    --color-blue: #3a51bc;
    --color-lightblue: #0098fa;
    --color-darkblue: #000449;
    --color-yellow: #d4bf00;
    --color-brown: #ad8551ba;
    --color-gray: #4d6f7b;
    --color-silver: #cecece;
    --color-orange: #f2a711;
    --color-purple: #b23ac0;
    --color-pink: #d13482;
    --color-lightsalmon: lightsalmon;
    --color-darksalmon: darksalmon;
    --color-salmon: salmon;
    --color-indianred: indianred;
    --color-white: #ffffff;
    --color-black: #000000;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    // color:#444;
}

html,
body {
    // will-change: transform;
    //     transform: translateZ(0);
    // -webkit-transform: translateZ(0);
}

html{

    height  : 100%;
    overflow: hidden;
    position: relative;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;

}

body {
    background-color: #fafafa;
    overflow-y: scroll;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;

    height  : 100%;
    position: relative;

}


body.disable-scroll {
    overflow: hidden !important;
    user-select: none;
}
// body.disable-popup-scroll .popup .popup-content {
//     overflow: hidden !important;
//     user-select: none;
// }

input,
select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;

    padding: 10px 16px;
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 4px;
}

a:has(.iconbutton) {
    color: inherit;
    text-decoration: none;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaa;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
}

input,
textarea,
keygen,
select,
button {
    font-size: 100%;
    color: #444;
}

textarea {
    flex: 1;
    // margin: 8px;
    padding: 12px;
    max-width: 100%;
    width: 500px;
    min-height: 55px;
    border: 1px solid #ddd;
    resize: none;
    outline: none;
    border-radius: 3px;
}

img[src='/noavatar.png'] {
    opacity: 0.16;
    padding: 8px;
    background-color: #999 !important;
}

img:not([src]) {
    width: 100%;
    height: 100%;
    background-color: #eee;
    position: relative;
    font-size: 0;
}
img:not([src]):after {
    font-size: 8pt;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    font-family: 'Helvetica';
    font-weight: 300;
    line-height: 2;
    text-align: center;
    // content: attr(alt);
    content: ' ';
}

img:not([src]):before {
    font-size: 8pt;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    width: 30px;
    height: 30px;
    z-index: 1;
    // content: attr(alt);
    content: ' ';
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: rgba(0, 0, 0, 0.4);

    animation: no-image-animation 1s;
    animation-iteration-count: infinite;
}

// img.rounded:not([src]):before, img.rounded:not([src]):after{
//   border-radius: 50%;
// }

@keyframes no-image-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
// [type=checkbox],
// [type=radio] {
//     color: dodgerblue;
//     vertical-align: middle;
//     -webkit-appearance: none;
//     background: none;
//     border: 0;
//     outline: 0;
//     flex-grow: 0;
//     border-radius: 2px;
//     background-color: #FFFFFF;
//     transition: background 150ms;
//     cursor: pointer;
//   }

//   /* Pseudo element for check styling */

//   [type=checkbox]::before,
//   [type=radio]::before {
//     content: "";
//     color: transparent;
//     display: block;
//     width: inherit;
//     height: inherit;
//     border-radius: inherit;
//     border: 0;
//     background-color: transparent;
//     background-size: 140%;
//     background-position: center;
//     box-shadow: inset 0 0 0 1px #CCD3D8;
//   }

//   /* Checked */

//   [type=radio]:checked,
//   [type=checkbox]:checked {
//     background-color: currentcolor;
//   }

//   [type=radio]:checked::before,
//   [type=checkbox]:checked::before {
//     box-shadow: none;
//     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
//   }

//   /* Disabled */

//   [type=radio]:disabled,
//   [type=checkbox]:disabled {
//     background-color: #CCD3D8;
//     opacity: 0.84;
//     cursor: not-allowed;
//   }

//   /* IE */

//   [type=radio]::-ms-check
//   [type=checkbox]::-ms-check {
//     content: "";
//     color: transparent;
//     display: block;
//     width: inherit;
//     height: inherit;
//     border-radius: inherit;
//     border: 0;
//     background-color: transparent;
//     background-size: contain;
//     box-shadow: inset 0 0 0 1px #CCD3D8;
//   }

//   [type=radio]:checked::-ms-check,
//   [type=checkbox]:checked::-ms-check {
//     box-shadow: none;
//     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
//   }

select {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A@{arrow}%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A');
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    padding: 10px 30px 10px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    // appearance: none;
    &::-ms-expand {
        display: none;
    }
}

// select{
//     ::-ms-expand {
//         display: none;
//     }
//     :root {
//         --select-border: #777;
//         --select-focus: blue;
//         --select-arrow: var(--select-border);
//       }
//     option {
//         &[data-color="red"] {
//             background-color: #ff0000;
//         }
//         &[data-color="green"] {
//             background-color: #10cf23;
//         }
//         &[data-color="blue"] {
//             background-color: #007bff;
//         }
//     }
// }

.user-avatar {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    &.online {
        img {
            border: 2px solid #069e18;
        }
        &:before {
            content: ' ';
            position: absolute;
            width: 8px;
            height: 8px;
            top: -2px;
            left: 31px;
            background-color: #069e18;
            border: 2px solid #fff;
            border-radius: 50%;
        }
    }

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ddd;
        border: 1px solid #eee;
        pointer-events: none;
    }

    &.square {
        img {
            border-radius: 4px;
        }
    }

    .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 6px;
        overflow: hidden;
        pointer-events: none;
    }

    .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
    }

    .city {
        opacity: 0.5;
        font-size: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
    }
}

@keyframes open {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeButton {
    0% {
        opacity: 0;
    }
    3% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
    // animation-timing-function: linear;
}

.tooltip {
    position: fixed;
    background-color: #000;
    padding: 12px;
    z-index: 99999;
    border-radius: 10px;
    box-shadow: 1px 1px 20px 1px #00000044;
    border: 1px solid #ddd;
    color: #fff;
    animation: fadeButton 18s infinite;
    //  animation-iteration-count: 1;
    max-width: 500px;

    text-shadow: 0px 0px 0px #ffffff;
    word-break: break-all;
    .column-header{
        display: none;
    }
    a{
        color:#ffffff66
    }
    img{
        max-width: 300px;
    }
     
    &.no-arrow:before {
        content:  '';
        display: none;
    }


    &:before {
        content: '  ';
        width: 10px;
        height: 10px;
        background-color: inherit;
        transform: rotate(45deg);
        position: absolute;
        top: -6px;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
    }

    &.reverse:before {
        top: auto;
        bottom: -6px;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;

        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
}

.color-1 {
    background-color: #ddd;
}

// .tags{
//   padding: 16px;
//   max-width: 300px;
// }

// .tags .tag{
//   margin: 4px;
//   font-size: 100%;
//   box-shadow: inset 1px 1px 100px 100px #00000011;
//    letter-spacing: 0.1px;
// }

// .tags .tag .ficon{
//   line-height: 0.84;
//   margin-left: 8px;
//   border:1px solid #ffffff44;
//   background-color: #00000022;
// }

// .tags .desc { color:#577682; line-height: 1.4; }
// .tags .desc b { display: block; color:inherit; margin-bottom: 4px; }
// .tags .desc .ficon {padding-left:4px;color:inherit; }

// .tag{
//   background-color: #ddd;
//   border-radius: 40px;
//   padding: 8px 12px;
//   margin-right: 8px;
//   // margin-bottom: 4px;
//   font-size:80%;
//   font-weight: 400;
//   display: inline-block;
//   color:#ffffffee;
//   vertical-align: middle;
//   pointer-events: none;
// }

// .tag span{
//   color:inherit;
//   vertical-align: middle;
// }

// .tag .highlight{
//   color:#fff !important;
//   font-weight: bold;
// }

// .tag.minimized{
//   width: 8px;
//   padding: 6px 0px;
//   margin-right: 4px;
//   color:transparent;
//   // font-size: 0px;
//   display: inline-block;

//   height: 20px;
// }

// .tag.more{
//   background-color: transparent;
//   color:#444;
//   font-size: 100%;
//   padding: 8px 0px;
// }

// .tag .ficon{
//   padding: 4px;
//   vertical-align: middle;
//   color:inherit;
//   border:1px solid #ffffff55;
//   margin-left: 4px;
//   margin-right: -10px;
//   border-radius: 50%;
//   font-size: 147%;
//   background-color: #00000011;
//   line-height: 0.6;
// }

// /** Safari fix*/
// @media not all and (min-resolution: 0.001dpcm) {

//   .tag{
//     height: 28px;
//   }

//   .tag.minimized{
//     height: 20px;
//   }

// }

// .tag.red{
//   background-color: #cc0909;
// }
// .tag.green{
//   background-color: #00b241;
// }
// .tag.blue{
//   background-color: #0098fa;
// }
// .tag.brown{
//   background-color: #ff9200;
// }
// .tag.gray{
//   background-color: #4d6f7b;
// }

.iconbutton {
    cursor: pointer;
    padding: 4px;
    -webkit-tap-highlight-color: #eee;
    position: relative;
    outline: #000;
    user-select: none;

    &.disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}

.iconbutton.loading:after {
    content: ' ';
    cursor: default;
    margin: auto;
    border: 4px solid #ddd;
    border-radius: 50%;
    border-top: 4px solid #0e31bd;
    width: 50%;
    height: 50%;
    position: absolute;
    animation: spinner 4s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.iconbutton:hover {
    box-shadow: inset 0 0 1000px 1000px #00000011;
}

.iconbutton:active {
    box-shadow: inset 0 0 1000px 1000px #00000011;
}

.iconbutton[data-off]:hover:before {
    text-shadow: 2px 0 0 #eee;
}

.iconbutton[data-off]:before {
    content: '\\';
    color: inherit;
    position: absolute;
    left: 8px;
    top: -8px;
    font-size: 27pt;
    font-weight: 100;
    transform: rotate(-22deg);
    text-shadow: 2px 0 0 #fff;
}

.iconbutton.notify:after {
    content: ' ';
    width: 8px;
    height: 8px;
    background-color: #de4949;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 50%;
}
 

 
.ficon[data-off]{
    position: relative
} 

.ficon[data-off]:before {
    content: 'x';
    color: inherit;
    position: absolute;
     transform: translate(-50%, -50%);
     top:50%;
     left:50%;
    font-size: 90%;
    font-weight: normal; 
    text-shadow: 1px 0 0 #fff, -1px 0 0 #fff;
    opacity: 0.8;
    color:#ff0000;
} 



.ficon.has-bg  {
    border-radius: 4px;
}


.ficon.has-bg.lightblue {
    background-color: var(--color-lightblue);
}
.ficon.has-bg.blue {
    background-color: var(--color-blue);
}
.ficon.has-bg.red {
    background-color: var(--color-red);
}
.ficon.has-bg.orange {
    background-color: var(--color-orange);
}
.ficon.has-bg.yellow {
    background-color: var(--color-yellow);
}
.ficon.has-bg.green {
    background-color: var(--color-green);
}
.ficon.has-bg.purple {
    background-color: var(--color-purple);
}
.ficon.has-bg.darkblue {
    background-color: var(--color-darkblue);
}
.ficon.has-bg.pink {
    background-color: var(--color-pink);
}
.ficon.has-bg.lightsalmon {
    background-color: var(--color-lightsalmon);
}
.ficon.has-bg.salmon {
    background-color: var(--color-salmon);
}
.ficon.has-bg.darksalmon {
    background-color: var(--color-darksalmon);
}
.ficon.has-bg.indianred {
    background-color: var(--color-indianred);
}
.ficon.has-bg.gray {
    background-color: var(--color-gray);
}
.ficon.has-bg.silver {
    background-color: var(--color-silver);
}
.ficon.has-bg.brown {
    background-color: var(--color-brown);
}
.ficon.has-bg.white {
    background-color: var(--color-white);
    color: var(--color-black);
}
.ficon.has-bg.black {
    background-color: var(--color-black);
    color: var(--color-white);
}




.ficon.color-lightblue {
    color: var(--color-lightblue);
}
.ficon.color-blue {
    color: var(--color-blue);
}
.ficon.color-red {
    color: var(--color-red);
}
.ficon.color-orange {
    color: var(--color-orange);
}
.ficon.color-yellow {
    color: var(--color-yellow);
}
.ficon.color-green {
    color: var(--color-green);
}
.ficon.color-purple {
    color: var(--color-purple);
}
.ficon.color-darkblue {
    color: var(--color-darkblue);
}
.ficon.color-pink {
    color: var(--color-pink);
}
.ficon.color-lightsalmon {
    color: var(--color-lightsalmon);
}
.ficon.color-salmon {
    color: var(--color-salmon);
}
.ficon.color-darksalmon {
    color: var(--color-darksalmon);
}
.ficon.color-indianred {
    color: var(--color-indianred);
}
.ficon.color-gray {
    color: var(--color-gray);
}
.ficon.color-silver {
    color: var(--color-silver);
}
.ficon.color-brown {
    color: var(--color-brown);
}
.ficon.color-white {
    color: var(--color-white); 
}
.ficon.color-black {
    color: var(--color-black); 
}


.--color-red {
    background-color: #cc0909;
}
.--color-green {
    background-color: #00b241;
}
.--color-blue {
    background-color: #0098fa;
}
.--color-yellow {
    background-color: #d4bf00;
}
.--color-brown {
    background-color: #ad8551ba;
}
.--color-gray {
    background-color: #4d6f7b;
}
.--color-silver {
    background-color: #cecece;
}
.--color-orange {
    background-color: #f2a711;
}
.--color-purple {
    background-color: #b23ac0;
}
.--color-pink {
    background-color: #d13482;
}

.--color-lightsalmon {
    background-color: lightsalmon;
}
.--color-darksalmon {
    background-color: darksalmon;
}

.--color-salmon {
    background-color: salmon;
}

.--color-indianred {
    background-color: indianred;
}

@media only screen and (max-width: 600px) {
    textarea {
        width: 100%;
    }
}




small{
    font-size: 60%;
    letter-spacing: -0.5px;
    opacity: 0.6;
    font-weight: normal;
}