 
.sync-setting{
  
  display: flex;
  flex-direction: row;
  gap:16px; 
   width: 100%;
   text-align: left;


  //  .chatbot-upload-file{
  //   font-size: 90%;
  //   font-weight: bold;
  //   padding: 16px;
     
    
  //   background-color: #eee;
  //   border-radius: 4px;
  //   margin-top: 4px;

  //   position: relative;
  //  .remove-selected-file{
  //   position: absolute;
  //   right: 0px;
  //   top:0px;  
  //  }

  //  }
  .sync-setting-options{

      display: flex;
      flex-direction: column;
      gap:16px;
      flex-wrap: wrap;
      flex:1;
        width: 100%;
      max-width: 500px; 
  }

  .sync-setting-preview{
    flex:1;
    position: relative;
  }
  .avatar-wrapper .avatar-uploader.empty .avatar-uploader-image{
    transform: scale(1);
  }
   
   
  .sync-csr{
    width: 100%;
    display: flex;
    gap:16px;
    padding: 16px;
    align-items: center;
    .avatar-wrapper{
      position: relative;
      width: 100px;
      img{
        width: 100px;
        height: 100px;
      }
    }
  }

.sync-avatar{
    
}


.accordion{
  margin: -16px;
  width: calc(100% + 32px);
  margin-top: 0%;
}

.theme-selection{
  display: flex;
  flex-direction: row;
  gap:8px ;
}
 
.advanced-settings{

  padding: 16px;

  text-align: left; 
  align-items: flex-start;
   
  display: flex;
  flex-direction: column;
  gap:16px;
}


.list-item-switch-group {
  width: 100%;
}

  code {
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Menlo, Monaco, "Courier New", monospace !important;
    background: #eee;
    padding: 8px 8px;
    line-height: 16px;
    white-space: pre-wrap;
    display: block;
    font-size: 12px;
  }

  .sync-limit-option{
    padding: 16px;  
    width: 100%;
  }

  .notification-options{
    padding: 16px;  
    width: 100%;
     
    
     textarea{
      width: 100%;
     }
  }


  .card .card-content  {
    text-align: left; 
    align-items: flex-start;
    padding-top: 0px;
    padding: 0px;
    b{
      font-size: 90%;
      opacity: 0.6;
    }
  }



  .sub-settings-for-application{
    padding: 16px 24px;
    background-color: #f2f2f2;
  }
  .l2-sub-settings-for-application{
    padding: 0px 24px;
    background-color: #f2f2f2;
  }

  .sub-desc{
    font-size: 80%;
    opacity: 0.8;
    padding-left: 36px;
    display: block;

  }
}


 