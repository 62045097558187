
         .edit-chatcsr.edit-page{
          .info{
            display: grid;
            grid-gap: 16px;
            gap:16px; 
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas: "is-available"
  "updated-at"
  "is-online"
  "work-hours"
  "csr-member-id"
  "name"
  "default-session-role"
  "parent-wecom-chat-csr-id"
  "group-name"; 
          }
          .is-available{
      grid-area:is_available;
    }
  .updated-at{
      grid-area:updated_at;
    }
  .is-online{
      grid-area:is_online;
    }
  .work-hours{
      grid-area:work_hours;
    }
  .csr-member-id{
      grid-area:csr_member_id;
    }
  .name{
      grid-area:name;
    }
  .default-session-role{
      grid-area:default_session_role;
    }
  .parent-wecom-chat-csr-id{
      grid-area:parent_wecom_chat_csr_id;
    }
  .group-name{
      grid-area:group_name;
    }
         }
      
