.list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eee;

    .item-label {
        padding: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
    }

    .item-content {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.list-item > .ficon {
    padding-left: 16px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.list-item.disabled > * {
    opacity: 0.5;
}

.list-item.has-desc .item-label,
.list-item.has-children .item-label {
    // width: 50%;
}

.list-item.has-desc {
    padding: 16px;
    gap: 2px 4px;
    grid-gap: 2px 4px;
    display: grid;
    align-items: center;
    position: relative;
    // justify-items: center;
    grid-template-columns: minmax(40px, auto) 7fr;
    grid-template-rows: auto;
    grid-template-areas:
        'icn lbl'
        'icn dsc';

    &.no-media {
        grid-template-columns: 7fr;
        grid-template-areas:
            'lbl'
            'dsc';
    }

    .item-image {
        grid-area: icn;
        height: 100%;
        background-position: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-color: #ddd;
        margin-right: 4px;
    }

    &:not(.no-media) .ficon:not(.item-arrow):not(.tag-icon) {
        grid-area: icn;
        font-size: 22pt;
        padding-left: 0;
    }

    .item-label {
        max-width: calc(100% - 70px);
        text-overflow: ellipsis;
        line-height: 1.1;
        grid-area: lbl;
        padding: 0;
        align-self: flex-end;

    }

    .item-desc {
        max-width: calc(100% - 70px);
        text-overflow: ellipsis;
        align-self: flex-start;
        grid-area: dsc;
        font-size: 80%;
        opacity: 0.5;
        line-height: 1;
    }

    .item-sticker {
        opacity: 1;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }

    .item-status {
        opacity: 1;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }

    .item-arrow {
        opacity: 1;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        grid-area: none;
    }




    .item-switch {
        opacity: 1;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        grid-area: none;
    }

    &.has-arrow.has-sticker {
        .item-arrow {
            right: 0;
            margin-right: 8px;
        }
        .item-sticker {
            right: 36px;
        }
    }

    &.has-arrow.has-status {
        .item-arrow {
            right: 0;
            margin-right: 8px;
        }
        .item-status {
            right: 36px;
        }
    }

    &.has-arrow.has-sticker.has-desc {
        .item-sticker {
            font-size: 90%;
            opacity: 0.6;
        }
        .item-arrow {
            opacity: 0.6;
        }
    }

    &.has-arrow.has-status.has-desc {
        .item-status {
            font-size: 90%;
            opacity: 0.6;
        }
        .item-arrow {
            opacity: 0.6;
        }
    }
}

.list-item.in-grid {
    padding: 0;
    border-bottom: 0 none;
    .item-label {
        max-width: calc(100% - 8px);
    }
    .item-desc {
        max-width: calc(100% - 8px);
    }
}

.list-item.has-arrow .item-desc {
    margin-right: 24px;
}

.list-item.clickable {
    cursor: pointer;
}

.list-item.clickable:hover {
    box-shadow: inset 0 0 1000px 1000px #00000005;
}

.list-item.clickable:active {
    box-shadow: inset 0 0 1000px 1000px #00000022;
}

.list-item.selected {
    background-color: #3949ab;
    color: #fff;
}

.list-item .item-arrow {
    margin-right: 16px;
}

.list-item.has-switch .item-content {
    padding: 0 16px;
}

.list-item.has-switch .item-switch {
    margin-right: 16px;
}

.list-item {
    &.tag {
        padding: 8px 16px;
        width: 100%;
        max-width: 100%;
        background-color: inherit;
        color: #444;
        font-size: 100%;
        margin-right: 0;
        border-radius: 0;

        .ficon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            font-size: 160%;
            margin-left: 0;
            padding: 0;
        }

        .item-content {
            padding: 0;
            width: 100%;

            .tag {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0;

                span {
                    margin-right: 16px;
                }
            }
        }
    }
}
