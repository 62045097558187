@keyframes show-list-search-input {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.list {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    user-select: none;
    // display:  flex;
    // border: 1px solid #aaa;
    border-radius: 4px;
    outline: none;
    line-height: 1.2;
    min-width: 0;
    left: -1px;
    background: #fff;
    // box-shadow: 1px 10px 10px 1px #00000022;
    // border-top:  0px none;
    // border-radius: 0px 0px 2px 2px;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.list .potentially-selected {
    position: absolute;
    padding: 10px;
    opacity: 0.2;
    text-align: right;
    width: 100%;
}

.list .options {
    overflow: auto;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
}

.list .options .cell-headers {
    display: flex;
    padding: 10px;
    font-weight: bold;
    font-size: 90%;
    opacity: 0.5;
}

.list .option {
    padding: 10px 12px;
    border-top: 1px solid #00000011;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}
.list .option-category{
    padding: 10px 12px;
    padding-top: 24px;
    font-weight: bold;
}
.list .option:hover {
    box-shadow: inset 0 0 500px 500px #0000000a;
}
.list .option.potential {
    box-shadow: inset 0 0 500px 500px #00bff422;
}

.list .option .highlight {
    text-decoration: underline;
    color: #ff0000;
}

.list .option .cell {
    img {
        width: 100%;
    }
}
.list .option .cell + .cell {
    padding-left: 6px;
}

.list.mode-bold .option.selected {
    font-weight: bold;
}

.list.mode-highlight .option.selected {
    background-color: #007af460;
}

.list.mode-check .option:before,
.list.mode-radio .option:before {
    content: ' ';
    width: 18px;
    height: 18px;
    border: 2px solid #aaa;
    line-height: 0;
    padding: 0;
    margin-right: 8px;
    border-radius: 4px;
}

.list.mode-check .option.selected:before,
.list.mode-radio .option.selected:before {
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    background-position: center;
    background-color: #1e90ff;
    border: 2px solid transparent;
}

.list.mode-radio .option:before {
    border-radius: 50%;
}

.list {
    // position: relative;
    //  .content{
    //   display: flex;
    //   overflow: hidden;
    //  }

    // .arrow{
    //   width:44px;
    //   cursor: pointer;
    //   border-left: 1px dotted #ddd;
    //   background-image: url('/star.png');
    //   background-size: 50%;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   // background-color: #fff;
    //   z-index: 1;
    // }
    // width: 100%;
    // border: 1px solid #ddd;
    // border-radius: 2px;
    // padding: 10px;
    &.multiselect {
        .value {
            // vertical-align: middle;
            padding: 6px;
        }

        .value.empty {
            // vertical-align: middle;
            padding: 12px;
        }
    }

    &.open {
        border-radius: 4px 4px 0 0;

        .value {
            // border-bottom: 1px solid transparent;
        }
    }
}

.list .search-input-wrapper.hide {
    display: none;
}

.list .search-input-wrapper.hide .search-input-icon {
    display: none;
}

.list .search-input-wrapper.show {
    position: relative;
}

.list .search-input {
    padding: 10px 12px;
    width: 100%;
    margin: 6px 0;
    padding-right: 36px;
    outline: none;
    animation: show-list-search-input 0.1s;
    animation-iteration-count: 1;
}

.list.child-selector .search-input {
    margin: 6px 12px;
    width: calc(100% - 24px);
}

.list .search-input-icon {
    position: absolute;
    top: 14px;
    right: 18px;
}

.list .search-input-icon.typing {
    background-color: #000;
}
.list .search-input-icon.loading {
    background-color: #ff0000;
}

.list .loading {
    text-align: center;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list .no-data {
    text-align: center;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80%;
    color: #aaa;
}

.list .option.color-red {
    background-color: #e90101;
    color: #fff;
}
.list .option.color-green {
    background-color: green;
    color: #fff;
}
.list .option.color-blue {
    background-color: blue;
    color: #fff;
}
.list .option.color-yellow {
    background-color: yellow;
}
.list .option.color-silver {
    background-color: silver;
}
.list .option.color-brown {
    background-color: brown;
    color: #fff;
}
.list .option.color-orange {
    background-color: orange;
    color: #fff;
}
.list .option.color-purple {
    background-color: purple;
    color: #fff;
}
.list .option.color-pink {
    background-color: pink;
}

.list .option.color-lightsalmon {
    background-color: lightsalmon;
}

.list .option.color-darksalmon {
    background-color: darksalmon;
    color: #fff;
}

.list .option.color-salmon {
    background-color: salmon;
    color: #fff;
}

.list .option.color-indianred {
    background-color: indianred;
    color: #fff;
}

// .value{
//   flex: 1;
//   padding: 12px;
//   cursor: pointer;
//   width: calc(100% - 44px);
//   overflow: hidden;

//   .placeholder{
//     opacity: 0.4;
//     overflow: hidden;
//     width: 100%;
//     display: block;
//   }
//   .items-count{
//     padding: 6px 0px;
//     display: block;
//   }
//   .selected-item{
//     display: inline-block;
//     padding: 4px;
//     padding-right:32px;
//     margin: 2px;
//     background-color: #00000011;
//     vertical-align: middle;
//     position: relative;

//     max-width: 100%;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;

//     &.with-full-width{
//       display: block;
//     }

//     .close{
//       background-color: #00000011;
//       width:24px;
//       height: 100%;
//       position: absolute;
//       right:0px;
//       top:0;
//       text-align: center;
//       vertical-align:middle;
//       font-style: normal;
//       font-weight: bold;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       cursor: pointer;

//       &:hover{
//         background-color: #00000022;
//       }
//     }
//   }
// }
