.edit-campaign.edit-page {
    .items-to-send {
        display: flex;
        flex-direction: column;

        .items-to-send-add {
            display: flex;
            flex-direction: row;

            .item-type {
                width: 300px;
                margin-right: 16px;
            }

            .button-wrapper {
                width: 200px;
            }
        }

        .items-to-send-add-buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex-direction: column;
            padding-top: 16px;
            .record-type-button {
                //flex:1;
                width: 220px;
                .button {
                    width: 100%;
                }
            }
            .button-wrapper:first-child {
                // margin-left: 0;
                // border-left: 0 none;
                // padding-left: 0;

                .button:not(:hover) {
                    //  padding-left: 4px;
                    min-width: 22px;
                    //   max-width: 28px;
                }
            }
            .button-wrapper {
                margin: 4px;

                .button {
                    overflow: hidden;
                    min-width: 32px;
                    // max-width: 44px;

                    white-space: nowrap;
                    //transition: 500ms;
                    color: #fff;

                    .ficon {
                        margin-right: 16px;
                        //  transition: 500ms;
                        font-size: 180%;
                    }
                }
                &:hover {
                    .button {
                        overflow: hidden;

                        //  max-width: 190px;
                        white-space: nowrap;

                        .ficon {
                            //  margin-right: 8px;
                        }
                    }
                }
            }
        }

        .item-to-send {
            display: flex;
            flex-direction: row;

            .media {
                width: 148px;
                height: 120px;
                padding: 8px;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                background-color: #ffffff55;

                &:has(.ficon) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .ficon {
                        font-size: 400%;
                    }
                }
            }

            .text {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 16px 16px;
            }

            .title {
                font-weight: 500;
                padding-bottom: 8px;
            }

            .description {
                opacity: 0.7;
                flex: 1;
            }

            .extras {
                display: flex;
                align-items: center;
                flex-direction: row;
                font-size: 90%;
            }

            .application {
                background-color: #00000044;
                display: inline-block;
                padding: 2px 12px;
                border-radius: 4px;
                align-self: flex-start;
                margin-left: 8px;
            }

            .record-type {
                background-color: #00000044;
                display: inline-block;
                padding: 2px 12px;
                border-radius: 4px;
                align-self: flex-start;
                margin-right: 8px;
            }

            .type {
                width: 150px;
            }

            .action {
                padding: 16px;
            }

            .application-item {
                border: 0;
                background-color: transparent;
                opacity: 0.9;
                font-size: 80%;

                &:hover {
                    box-shadow: none;
                    background-color: transparent;
                }
            }
        }
    }

    .sent-records {
        min-width: 320px;
    }

    .campaign-data {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 16px 8px;
        grid-gap: 16px 8px;

        .campaign-types {
            display: flex;

            .campaign-type {
                padding: 8px;
                text-align: center;
                cursor: pointer;
                background-color: #f2f2f2;
                border-radius: 0 4px 4px 0;

                &:first-child {
                    border-radius: 4px 0 0 4px;
                }

                &.selected {
                    background-color: #3d54b5;
                    color: #fff;
                }

                &:hover {
                    box-shadow: inset 0 0 10000px 100000px #00000011;
                }
            }
        }

        textarea {
            width: 100%;
        }

        .campaign-status {
            width: auto;
            flex:none;
            align-self: flex-start;
            justify-self: flex-start;
        }

        .send-desc {
            font-size: 80%;
            margin-top: -8px;
            opacity: 0.5;
        }
    }

    .main-content {
        min-height: 450px;
        align-items: flex-start;
        grid-template-rows: min-content auto;
    }

    .campaign-receivers {
        width: 320px;

        .add-button {
            width: calc(100% - 32px);
        }
    }

    .campaign-record-options-selection {
        display: flex;
        margin-top: 8px;
        margin-left: 0;

        .campaign-record-options-selector__button {
            margin-right: 8px;
        }
        &.disabled{
            filter: grayscale(1);
            opacity: 0.5;
            user-select: none;
            pointer-events: none;
        }
    }

    .campaign-option > .tab-header-wrapper > .tab-header {
        margin-bottom: 0;
    }

    .campaign-receiver-wrapper {
        overflow: auto;
    }

    .campaign-receiver-wrapper-card {
        align-items: flex-start;
        justify-content: flex-start;
        display: grid;
    }

    .campaign-header {
        display: flex;
        align-items: center;

        .campaign-header-title {
            font-size: 150%;
            flex: 1;
        }
    }

    .campaign-option {
        background-color: #3d54b5;
        border-radius: 4px;
        color: #fff;
        padding: 16px;
        padding-bottom: 4px;
        position: relative;
        display: grid;

        .campaign-expand {
            position: absolute;
            right: 10px;
            top: 60px;
        }

        .campaign-remove {
            position: absolute;
            right: 10px;
            top: 16px;
        }

        .campaign-option-text {
            font-weight: bold;
            padding: 12px 0;
        }

        .campaign-option-desc {
            font-size: 90%;
            opacity: 0.6;

            .ficon {
                font-size: 120%;
                font-weight: bold;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .campaign-status-badge {
            display: flex;
            align-items: center;
            position: absolute;
            top: 20px;
            right: 54px;
            border-radius: 4px;
            padding: 4px 8px;

            &:has(.failed) {
                background-color: pink;
                color: var(--color-red);

                &:hover {
                    cursor: pointer;
                }
            }

            &:has(.sent) {
                background-color: #c2e5c2;
                color: var(--color-green);
            }

            .campaign-record-status-icon {
                display: flex;
                font-size: 18px;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
                pointer-events: none;
            }

            span {
                color: #444;
                pointer-events: none;
            }
        }

        .campaign-record {
            .campaign-record-image {
                width: 80px;
                height: 80px;
            }

            .campaign-record-index {
                border-radius: 50%;
                margin: 4px;
                text-align: center;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff99;
                color: #000;
                font-size: 90%;
                font-weight: bold;
                width: 24px;
                height: 24px;
            }

            .campaign-record-type {
                display: inline-block;
                background-color: #ffffff66;
                color: #000;
                padding: 2px 8px;
                border-radius: 4px;
                margin: 4px;
            }

            .campaign-record-name {
                display: inline-block;
                background-color: #eee;
                color: #000;
                padding: 2px 8px;
                border-radius: 4px;
                margin: 4px;
            }

            .campaign-record-app {
                display: inline-block;
                background-color: #eee;
                color: #000;
                padding: 2px 8px;
                border-radius: 4px;
                margin: 4px;
            }
        }

        .campaign-receiver {
            display: inline-block;

            .campaign-receiver-tag {
                display: inline-block;
                background-color: #ffdba2;
                color: #000;
                padding: 2px 8px;
                border-radius: 4px;
                margin: 4px;
            }

            .campaign-receiver-customer {
                display: inline-block;
                background-color: #ffd2d2;
                color: #000;
                padding: 2px 8px;
                border-radius: 4px;
                margin: 4px;
            }

            .campaign-receiver-segment {
                display: inline-block;
                background-color: #b3ffd5;
                color: #000;
                padding: 2px 8px;
                border-radius: 4px;
                margin: 4px;
            }
        }

        .campaign-option-detail {
            .campaign-record-select-options-popup {
                color: #000;
                background-color: #fff;
            }
        }
    }

    .step-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #f2f2f2;
        margin: -16px;
        margin-bottom: 8px;

        .step-header {
            opacity: 0.3;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px;

            .done-icon,
            .doing-icon {
                font-size: 130%;
                font-weight: bold;
                margin-right: 8px;
                display: none;
            }
            &.done {
                opacity: 0.8;
                font-weight: bold;
                .done-icon {
                    display: block;
                }
                .doing-icon {
                    display: none;
                }
            }

            .doing-icon {
                display: block;
            }

            &.active {
                opacity: 0.8;
                font-weight: bold;
                background-color: #0d62b6;
                color: #fff;
            }
        }

        .step-arrow {
            opacity: 0.3;
            margin: 0 16px;
            font-weight: normal;

            .ficon {
                font-size: 130%;
            }
        }
    }

    .step-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .step-inner {
        width: 100%;
        overflow: auto;
    }
}

.status-log-tooltip {
    display: flex;
    flex-direction: column;

    strong {
        margin-bottom: 4px;
    }
}

@media (min-width: 1300px) {
    .campaign-receivers {
        width: 420px;
    }
}


.campaign-status-popover{
    padding: 16px;
    max-width: 300px;
}

.campaign-status-popover-item:not(:last-child){
   margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
}
.campaign-status-popover-item-time{
    font-size: 80%;
    opacity: 0.7;
    margin-bottom: 4px;
}