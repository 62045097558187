.auto-message-filters {
    border: 1px solid #eee;
    background-color: #fafafa;
    padding: 16px 8px;
    border-radius: 4px;

    .filter-panel {
        padding: 0;
        justify-content: stretch;

        .filter-row {
            grid-template-columns: 6fr 4fr 6fr 1fr;

            .filter-op {
                min-width: 140px;
            }

            .filter-value {
                min-width: 250px;
            }
        }
    }

    .add-filter-button {
        margin-top: 16px;
    }

    .auto-message-filters-message {
        padding: 16px 0;
        padding-bottom: 8px;
        font-size: 80%;
        opacity: 0.5;
    }
    .auto-message-filters-ai-warn {
        padding: 16px 8px;
        font-size: 80%;
        opacity: 0.5;
        background-color: #ffe600;
        margin-bottom: -16px;
        margin-left: -8px;
    }
}

.reply-wrapper {
    display: flex;
    flex-direction: column;

    .reply-field {
        margin-bottom: 16px;
    }
}

.article-selector {
    display: flex;
    align-items: center;

    .article-selected {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;

        .iconbutton {
            margin-left: 4px;
            font-size: 14px;
        }
    }

    .article-selector-button {
        display: flex;
        justify-content: flex-start;
    }
}
