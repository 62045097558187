.load-more-button{  
    padding: 8px 16px;
    background-color: #ddd;
    border-radius:  100px;
    width: 220px;
    flex: none;
    align-self: center;
    justify-self: center;
    text-align: center;
    margin: 32px auto;
    margin-bottom: 64px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00000077;
    user-select: none;
    outline: none;
    position: relative;
    .ficon{
      font-size: 150%;
      margin-left:8px;
    }
  
    &:hover{
      background-color: #eee;
    }

  &.loading{
    .ficon {  
        animation:  rotate-loading-button-icon 400ms linear;
        animation-iteration-count: infinite;
     }
  }
  &.nothing-to-load{
    animation:  fade-nothing-button 4000ms forwards;
    animation-iteration-count: 1;
  }

  .loading-error{
    display: block;
    position: absolute;
    top:0px;
    height: 100%;
    width: 100%;
    left:0px;
    transform: translateY(100%);
    background-color: transparent;
    color:#f8a3a3;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes rotate-loading-button-icon {
    0% {  transform: rotate(0deg);} 
    100% {transform: rotate(359deg); } 
  }

  @keyframes fade-nothing-button {
    0% {  opacity: 1;} 
    80% {  opacity: 1;} 
    100% {opacity: 0; } 
  }


}