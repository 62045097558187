.attribute-component {
    margin-bottom: 8px;
}

/////////EDIT PAGE////////
///
///
///
///
.edit-page.has-leftbar {
    grid-template-columns: 280px 1fr auto;
    grid-template-areas:
        'mssg   mssg mssg'
        'header header header'
        'leftbar info  rightbar'
        'leftbar selector  rightbar'
        'footer  footer footer';
}

.edit-page:not(.has-leftbar) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
        ' mssg mssg'
        ' header header'
        ' info  rightbar'
        ' selector  rightbar'
        ' footer footer';
}

.edit-page {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
        ' mssg mssg'
        ' header header'
        ' info  rightbar'
        ' selector  rightbar'
        ' footer footer';

    .deleted-message {
        grid-area: mssg;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0;
        color: #bf0a0a;

        .button-wrapper {
            margin: 0 16px;
        }
    }

    .edit-page-header {
        grid-area: header;
        align-items: center;
        display: grid;
        grid-gap: 0 16px;
        gap: 0 16px;
        grid-template-columns: 1fr auto auto;
        grid-template-rows: auto;
        grid-template-areas:
            'title  right actions'
            'subtitle  right actions';

        .title {
            grid-area: title;
            display: flex;
            align-items: center;

            .iconbutton {
                margin-right: 4px;
            }
        }

        .sub-select {
            grid-area: subtitle;
            padding-left: 36px;
            font-size: 90%;
            opacity: 0.8;
            display: flex;
            flex-direction: row;
            align-items: center;
            vertical-align: middle;

            .iconbutton,
            .ficon {
                font-size: 120%;
                vertical-align: middle;
            }
        }

        .actions {
            grid-area: actions;
            justify-items: flex-end;
            align-items: center;
            justify-content: flex-end;
            display: flex;
            padding: 0 8px;
        }

        .right-side {
            grid-area: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > * {
                margin-right: 16px;
            }

            > *:last-child {
                margin-right: 0;
            }
        }
    }

    .info {
        grid-area: info;
    }

    .edit-form-content {
        display: grid;
        padding-bottom: 32px;
        grid-gap: 16px;
        gap: 16px;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .record-select {
        grid-area: selector;
    }

    .leftbar {
        grid-area: leftbar;
    }

    .rightbar {
        grid-area: rightbar;
    }

    .main-content {
        display: grid;
        padding-bottom: 32px;
        grid-gap: 16px;
        gap: 16px;

        align-items: center;

        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'name'
            'desc';
    }

    .tag-select {
        .value {
            padding: 8px 0;
            padding-top: 0;
        }

        .search-input {
            width: 100%;
            margin: 6px 0;
        }
    }

    .loading-edit-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .ficon {
            margin-bottom: 32px;
            font-size: 500%;
            animation: rotate-loading-icon 400ms linear;
            animation-iteration-count: infinite;
        }

        .loading-edit-content-title {
            font-size: 250%;
            margin-bottom: 32px;
        }

        .loading-edit-content-desc {
            text-align: center;
        }

        @keyframes rotate-loading-icon {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(359deg);
            }
        }
    }
}

.edit-page {
    width: 100%;
    max-width: 1200px;

    .card-content {
        // padding: 16px;
    }

    h3 {
        // text-transform: uppercase;
        color: #687b85;
        padding: 8px 0;
        margin: 0;
        font-size: 100%;
        display: flex;
        align-items: center;

        .iconbutton {
            font-size: 130%;
            font-weight: bold;
            margin: 0 8px;
        }
    }

    .hr {
        width: 100%;
        height: 1px;
        background-color: #eee;
        margin: 16px 0;
    }
}

.edit-page-header {
    display: grid;
    grid: 1fr / 1fr 1fr 1fr;

    .title {
        font-size: 180%;
    }

    .right-side {
    }

    .actions {
    }
}

.edit-page-footer {
    grid-area: footer;
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 'fmain fmain fmain fright';

    .edit-page-footer-main {
        grid-area: fmain;
        display: flex;
        // justify-content: center;
        align-items: center;
        gap: 8px;
        grid-gap: 8px;
        padding: 8px;
        // > * {
        //     margin-right: 16px;
        //     margin-bottom: 16px;
        // }
    }

    .edit-page-footer-right {
        grid-area: fright;
    }
}

.edit-page.has-leftbar .edit-page-footer {
    grid-template-columns: 280px 1fr 1fr 2fr;
    grid-template-areas: 'fleft fmain fmain fright';
}

.edit-page:not(.has-leftbar) .edit-page-footer {
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-template-areas: 'fmain fmain fmain fright';
}

.non-editable-row {
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: #f2f2f2;

    .non-editable-row-label {
        height: auto;
        font-size: 65%;
        color: #666;
        margin-left: 6px;
        margin-top: -5px;
        background-color: #f2f2f2;
        position: absolute;
        padding: 0 6px;
    }

    .non-editable-row-value {
        padding: 10px 12px;
        padding-top: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .help-icon {
            font-size: 120%;
            margin-left: 16px;
            cursor: pointer;
            background-color: #eee;
            border-radius: 50%;
        }

        .ficon:not(.help-icon) {
            padding: 4px;
            border-radius: 4px;
            box-shadow: 0 0 4px 0 #00000011;
            margin-right: 8px;
        }
    }
}

.edit-page-more-popover-content {
    .edit-page-more-popover-line-item {
        padding: 8px 12px;
        padding-right: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .iconbutton {
            margin-right: 8px;
        }

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }
    }
}

.page-reload-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 470px;
    padding: 16px;

    .page-reload-icon {
        font-size: 45px;
        color: #3d54b5;
        margin-bottom: 24px;
    }

    .page-reload-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            margin-bottom: 8px;
        }

        p {
            text-align: center;
            margin-bottom: 8px;
        }
    }

    .popup-header {
        display: none;
    }

    .popup-content {
        background: none;
    }

    .popup-footer {
        .button-wrapper {
            align-self: center;

            .primary {
                margin-right: 12px;
            }
        }
    }
}

.edit-page-panel-openers {
    display: none;
}

.edit-page-panel-openers-bg {
    display: none;
}

@media only screen and (max-width: 768px) {
    .edit-page.has-leftbar {
        grid-template-columns: 1fr;
        grid-gap: 8px;
        gap: 8px;
        grid-template-areas:
            'mssg'
            'header'
            'mobiletabs'
            'leftbar'
            'info'
            'selector'
            'rightbar'
            'footer';
    }

    .edit-page.has-leftbar .edit-page-footer,
    .edit-page:not(.has-leftbar) .edit-page-footer {
        grid-template-columns: 1fr;
        grid-template-areas:
            'fleft'
            'fmain'
            'fright';
    }

    .edit-page {
        grid-gap: 0;
        gap: 0;

        .edit-page-header {
            margin-bottom: 16px;
        }

        .title {
            font-size: 140% !important;
        }

        .right-side {
            .button {
                display: flex;
                justify-content: center;
                min-width: initial;
                width: 32px;
                height: 32px;
                margin-left: 4px;
                border-radius: 0;

                .ficon {
                    margin-right: 0;
                }

                span {
                    display: none;
                }
            }

            .button-wrapper {
                margin-right: 0;
            }
        }
    }

    .edit-page:not(.has-leftbar) .edit-page-panel-openers,
    .edit-page:not(.has-leftbar) .edit-page-panel-openers-bg {
        display: none;
    }

    .edit-page-panel-openers {
        display: flex;
        grid-area: mobiletabs;
        width: 100%;

        .edit-page-panel-opener-left {
            display: flex;
            align-items: center;
        }

        .edit-page-panel-opener-placeholder {
            flex: 1;
        }

        .edit-page-panel-opener-right {
            display: flex;
            align-items: center;
        }

        .edit-page-tab {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .edit-page-panel-openers-bg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10;
        position: fixed;
        background-color: #00000011;
        pointer-events: all;
        display: none;
    }

    @keyframes fadeleftbar {
        0% {
            opacity: 0;
            left: -100%;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
            left: 0;
        }
    }

    @keyframes faderightbar {
        0% {
            opacity: 0;
            right: -100%;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
            right: 0;
        }
    }

    .leftbar {
        position: fixed;
        width: 85%;
        height: calc(100% - var(--header-menu-height));
        left: 0;
        top: var(--header-menu-height);
        z-index: 11;
        background-color: #fff;
        box-shadow: 0 20px 10px 10px #00000022;
        display: none;

        animation: fadeleftbar 400ms forwards;
        animation-iteration-count: 1;
        overflow: auto;
        padding-top: 16px;
    }

    .rightbar {
        position: fixed;
        width: 85%;
        height: calc(100% - var(--header-menu-height));
        right: 0;
        top: var(--header-menu-height);
        z-index: 11;
        background-color: #fff;
        box-shadow: 0 20px 10px 10px #00000022;
        display: none;
        animation: faderightbar 400ms forwards;
        animation-iteration-count: 1;
        overflow: auto;
    }

    .leftbar-open {
        .leftbar {
            display: block;
        }

        .rightbar {
            display: none;
        }

        .edit-page-panel-openers-bg {
            display: block;
        }
    }

    .rightbar-open {
        .leftbar {
            display: none;
        }

        .rightbar {
            display: block;
        }

        .edit-page-panel-openers-bg {
            display: block;
        }
    }
}
