.app-menu {
    width: 44px;
    background: #f2f2f2;
    z-index: 90;
    padding-top: 8px;
    border-right: 1px solid #ddd;
    overflow: auto;
    user-select: none;
    transition: 300ms;
    padding-bottom: 24px;

    .menu-item.menu-open {
        display: none;
    }

    .menu-accordion {
        position: relative;

        &.expanded {
            &:before {
                content: ' ';
                width: 5px;
                height: 100%;
                position: absolute;
                background-color: #00000033;
                left: 0;
                top: 0;
                z-index: 89;
            }

            .accordion-head {
                border-left: 3px solid #00000033;
            }
        }

        &.parent-selected:not(.expanded) {
            .accordion-head {
                border-left: 3px solid #29719b;
            }
        }

        &.parent-selected.expanded {
            &:before {
                background-color: #00000055;
            }
            .accordion-head {
                border-left: 3px solid transparent;
            }
        }

        &.parent-selected {
            box-shadow: inset 0 0 1000px 1000px #ffffff88;
        }

        .accordion-head {
            text-decoration: none;
            padding: 0 8px;
            white-space: nowrap;
            cursor: pointer;
            color: #627a82;
            border-left: 3px solid transparent;
            border-bottom: 0 none transparent;

            .accordion-icon {
                text-align: center;
                font-size: 22px;
                padding: 1px;
            }
        }
    }
    .menu-item {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 8px;
        white-space: nowrap;
        cursor: pointer;
        color: #627a82;
        border-left: 3px solid transparent;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        .label {
            display: none;
            flex: 1;
            margin-left: 16px;
            font-size: 110%;
        }

        &.selected {
            color: #000;
            border-left: 5px solid #29719b;
            background: #fff;
            border-top: 1px solid #eeeeeeaa;
            border-bottom: 1px solid #eeeeeeaa;
        }

        &:hover {
            box-shadow: inset 0 0 1000px 1000px #00000011;
        }

        .ficon {
            text-align: center;
            font-size: 22px;
            padding: 1px;
        }
    }
}

.app-menu:hover {
    width: 210px;
    position: absolute;
    height: 100%;
    min-height: 500px;
    box-shadow: 0 10px 5px 5px #00000022;
    background: #fafafa;

    .menu-item {
        cursor: pointer;

        .label {
            display: block;
        }
    }
}
.app-menu-wrapper {
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 88;
    background-color: #00000000;
    transition: 300ms;
}

@media (max-width: 768px) {
    .app-menu {
        position: absolute;
        height: 48px;
        padding: 0;
        border: 0 none;
        width: 40px;

        .menu-item.menu-open {
            display: block;
            color: #fff;
            background-color: #3d54b5;
            height: 48px;
            border: 0 none;
            outline: none;
        }

        &:hover {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            box-shadow: 0 10px 5px 5px #00000022;
            min-height: auto;
        }
    }
    .app-menu:hover + .app-menu-wrapper {
        background-color: #00000088;

        width: 100%;
        box-shadow: 0 10px 50px 50px #00000066;
    }

    .disable-scroll .app-menu {
        z-index: -1;
    }
}

@media (min-width: 1600px) {
    .app-menu {
        width: 210px;

        .menu-item {
            .label {
                display: block;
            }
        }

        &:hover {
            box-shadow: none;
        }
    }
}

@media (min-height: 700px) and (min-width: 768px) {
    .header {
        position: fixed;
        top: 0;
        z-index: 12;
    }

    .page {
        margin-top: 48px;
    }

    .app-menu {
        position: fixed;
        top: 48px;
        height: 100vh;

        &:hover {
            position: fixed;
            height: 100%;
            min-height: 500px;
            box-shadow: 0 10px 5px 5px #00000022;
            background: #fafafa;
        }
    }
}
