
.import-data-panel {
  padding: 8px;
}
.import-data-panel .description {
  user-select:text;

}
.import-data-panel .description a{
  text-decoration: underline;
  cursor: pointer;
  color:rgb(26, 98, 179);
}
.import-data-panel .description b{
  margin-bottom: 4px;
  margin-top: 16px;
  display: block;
}
.import-data-panel .description ul{
  list-style: none;
  padding-left: 8px;
}
.import-data-panel .description li{
  padding: 4px;
}
.import-data-panel .description p{
  padding: 4px; 
}