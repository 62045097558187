.app.wechat.page-article.subpage-comment {
    overflow: hidden;

    .page {
        overflow: hidden;
        padding-bottom: 0;
        min-height: 0;
        height: calc(100vh - 50px);

        .phone-preview {
            zoom: 0.85;
        }
    }
}

.article-comments-page {
    .card .card-header {
        margin-bottom: 0;
    }

    .article-groups {
        width: 300px;
    }

    .article-preview-content {
        width: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .article-comments {
        flex: 1;

        .article-comment-list {
            .article-comment {
                position: relative;
                display: grid;
                grid-gap: 4px 8px;
                gap: 4px 8px;
                grid-template-columns: 48px 1fr auto;
                grid-template-rows: auto;
                grid-template-areas:
                    'avatar name name'
                    'avatar date date'
                    'content content content'
                    'send send send'
                    'reply reply reply';
                padding-bottom: 4px;
                margin-bottom: 8px;
                border-bottom: 1px solid #eee;

                &:last-child {
                    border-bottom: 0 none;
                }

                .comment-avatar {
                    grid-area: avatar;
                    background-position: center;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    border-radius: 50%;
                    border: 1px solid #ddd;
                    height: 48px;
                    width: 48px;
                }

                .comment-name {
                    grid-area: name;
                    align-self: flex-end;
                }

                .comment-date {
                    grid-area: date;
                    align-self: flex-start;
                    opacity: 0.6;
                    font-size: 80%;
                }

                .comment-content {
                    grid-area: content;
                    padding-left: 56px;
                }

                .comment-replies {
                    grid-area: reply;
                    padding-left: 56px;
                    width: 100%;
                    padding-top: 16px;

                    .article-comment {
                        grid-gap: 2px 8px;
                        gap: 2px 8px;
                        grid-template-columns: 32px 1fr auto;

                        .comment-avatar {
                            height: 32px;
                            width: 32px;
                        }

                        .comment-content {
                            padding-left: 40px;
                            padding-top: 4px;
                        }
                    }
                }

                .comment-actions {
                    display: flex;
                    position: absolute;
                    right: 0;
                    top: 0;

                    .iconbutton {
                        font-size: 140%;
                    }
                }
            }
        }
    }
}

.article-comments-page {
    .comments-title {
        display: flex;
        align-items: center;

        .comments-title-text {
            flex: 1;
        }
    }

    .article-send-comment-button {
        cursor: pointer;
        text-decoration: underline;
        opacity: 0.6;

        &:hover {
            opacity: 0.8;
        }
    }

    .article-comment {
        .article-send-comment {
            padding-left: 56px;
        }
    }
    .comment-replies {
        .article-send-comment {
            padding-left: 40px;
        }
    }
    .article-send-comment {
        padding-top: 8px;
        grid-area: send;
        width: 100%;
        display: flex;
        flex-direction: column;

        .comment-text {
            width: 100%;
            flex: 1;
            margin-bottom: 8px;
        }

        .article-send-comment-buttons {
            display: flex;

            > .button-wrapper {
                margin-right: 4px;
            }
        }
    }

    .article-group {
        .article-group-item {
            width: 100%;
            display: grid;

            grid-gap: 2px 8px;
            gap: 2px 8px;

            grid-template-columns: 56px 1fr auto;
            grid-template-rows: auto;
            grid-template-areas:
                'image title'
                'image desc'
                'image status';
            min-width: 280px;
            position: relative;
            background-color: #fff;
            padding: 16px;
            box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);

            cursor: pointer;
            user-select: none;

            & + .button-wrapper {
                border-radius: 0 0 4px 4px;
            }

            &:hover,
            &:hover:after {
                box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%),
                    inset 0 0 100px 1000px #00000011;
            }

            &.selected,
            &.selected:after {
                background-color: #3d54b5;
                color: #fff;
            }

            &.selected:after {
                display: block;
            }

            &.article-group-ghost {
                opacity: 0.7;
            }

            &:after {
                content: ' ';
                width: 12px;
                height: 12px;
                background-color: #fff;
                position: absolute;
                right: -5px;
                top: 20px;
                transform: rotate(45deg);
                border-radius: 2px;
                display: none;
            }

            .article-image {
                grid-area: image;
                height: 56px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                background-color: #eee;
                border-radius: 2px;
            }

            .article-title {
                grid-area: title;
                align-self: flex-end;
                font-weight: bold;
            }

            .article-desc {
                grid-area: desc;
                align-self: flex-start;
                font-size: 80%;
                opacity: 0.8;
            }

            .article-status {
                grid-area: status;
                align-self: flex-start;
            }

            .article-status-bar {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }
    }
}

.comment-permissions-popup {
    .comment-permissions-container {
        display: flex;
        flex-direction: column;
        padding: 16px;
    }
}

@media (max-width: 768px) {
    .article-group-items {
        .article-group {
            width: 100%;

            &:after {
                display: none;
            }
        }
    }

    .article-comments-page {
        .view-page-header {
            max-width: calc(100vw - 32px);
        }

        .article-comment {
            .article-send-comment {
                position: fixed;
                bottom: 0;
                left: 0;
                padding: 16px;
                background-color: #fff;
            }
        }
    }
}
