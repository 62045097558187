.app.electron.chat:not(.wecom) .page {
    width: calc(100vw - 60px);
}

.app.chat:not(.wecom) .page {
    padding: 0 !important;
    overflow: hidden !important;
    min-height: 0;

    .page-footer {
        display: none;
    }
}

.chat-page {
    width: 100%;
    gap: 4px 0;
    grid-gap: 4px 0;
    display: grid;
    position: relative;
    grid-template-columns: 180px 340px 1fr;
    grid-template-rows: calc(100vh - 50px);
    grid-template-areas: 'left sess mess';

    .chat-left-panel {
        grid-area: left;
        border-right: 1px solid #ddd;
    }

    .chat-session-list {
        grid-area: sess;
        border-right: 1px solid #ddd;
    }

    .messenger {
        grid-area: mess;
    }

    .chat-right-panel-wrapper.right-panel-hidden {
        display: none;
    }
}

@media (min-width: 1700px) {
    .chat-page {
        grid-template-columns: 180px 340px 1fr 364px;
        grid-template-rows: calc(100vh - 50px);
        grid-template-areas: 'left sess mess right';

        .chat-right-panel-wrapper.right-panel-hidden {
            display: block;
        }

        .chat-right-panel-wrapper {
            grid-area: right;
            position: static;
            display: block;
            z-index: 1;

            .chat-right-panel {
                display: block;
                padding-left: 24px;
                padding-right: 24px;
                width: 364px;
                box-shadow: none;
                border-left: 1px solid #ddd;
            }
        }
    }
}

@media (max-width: 1000px) {
    .chat-page {
        grid-template-columns: 320px 1fr;
        grid-template-areas: 'sess mess';
    }

    .chat-left-panel {
        display: none;
    }
}

// @media (max-width:768px) {

//     .chat-page{

// grid-template-rows: calc(100vh - 52px);
//         grid-template-columns: 80px 1fr;
//         grid-template-areas:
//         "sess mess"
//         ;
//     }

//     .chat-left-panel{
//         position: absolute;
//         display: none;
//    }
//     .chat-session-list{
//         grid-area: sess;
//         padding: 0px;

//     }

// }

// @media (max-width:600px) {

//     .chat-page{
//         grid-template-columns: 1fr;

//         grid-template-areas:
//         "sess"
//         "mess"
//         ;
//     }

//     .chat-left-panel{
//         position: absolute;
//         display: none;
//    }
//    .chat-session-list{
//         display: none;
//         &.active{
//             display: flex;
//         }
//    }
//     .messenger{
//         display: none;
//         position: fixed;

//         &.active{
//             display: flex;
//             background-color: #f2f2f2;
//             width: 100%;
//            height: calc(100vh - 50px);
//         }
//     }

// }

@media (max-width: 768px) {
    .chat-page {
        transition: 500ms;
        background: #f2f2f2;

        .chat-session-list {
            display: none;
        }

        .messenger {
            display: none;
        }

        .menu-close {
            display: none;
        }

        .menu-open {
            vertical-align: middle;
        }
    }

    .chat-page.left-panel {
        grid-template-columns: 165px 1fr;
        grid-template-areas: 'left sess';

        .menu-open {
            display: none;
            vertical-align: middle;
        }

        .menu-close {
            display: inline-block;
            vertical-align: middle;
        }

        .chat-left-panel {
            display: block;
        }

        .chat-session-list {
            display: flex;
        }
    }

    .chat-page.sessions {
        grid-template-areas: 'sess';
        .chat-session-list {
            display: flex;
        }
    }

    .chat-page.messages {
        grid-template-areas: 'mess';
        .messenger {
            display: flex;
        }
    }

    .app {
        .header {
        }
    }

    .message-box {
        background-color: #f2f2f2;
        z-index: 2;
        width: 100%;
        height: 52px;
    }

    .message-box .message-text {
        border: 0 none;
        outline: none;
        resize: none;
        position: absolute;
        bottom: 8px;
        width: auto;
        height: 36px;
        min-height: 0;
        padding: 6px 8px;
        right: 120px;
        left: 36px;
        line-height: 24px;
        top: auto;
        background-color: #fff;
        border-radius: 4px;
    }

    .message-box .message-box-tools {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 36px;
        overflow: hidden;
        padding: 8px 0;
        height: 48px;
        padding-left: 4px;
        background-color: #f2f2f2;

        .iconbutton {
            padding: 4px 3px;
        }
    }

    .message-box .message-box-tools.open {
        width: auto;
        right: 0;
    }

    .chat-page {
        width: 100%;
        height: inherit;
        left: 0;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .app.chat:not(.wecom) {
        top: 0;
        width: 100%;
        position: fixed !important;
        height: 100%;
        overflow: hidden !important;
        background-color: #ff0000;
        grid-template-rows: 48px 1fr !important;

        .page {
            min-height: 0;
            height: 100%;
            flex: 1;
        }
        .messenger {
            min-height: 0;
        }
    }

    .chat-session-list {
        &.active {
            height: 100%;
        }
    }
    .messenger {
        &.active {
            height: inherit;
            .message-box {
            }
        }
    }
}
