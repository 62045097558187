@keyframes loading {
    0% {
        transform: skewX(-10deg) translateX(-100%);
    }
    100% {
        transform: skewX(-10deg) translateX(200%);
    }
}

.leaflet-pane {
    z-index: 9;
}

.overview-card:has(.map-chart) {
    .card-header {
        margin-bottom: 0;
    }

    .card-footer {
        margin-top: 0;
    }
}

.map-chart {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 0;

    .map {
        overflow: hidden;
        border-radius: 4px;
        flex: 1.5;
        min-width: 300px;
        padding: 12px 8px;
    }
}

.locations {
    padding: 8px;
    flex: 1;
    min-width: 300px;
    max-width: 770px;
    overflow: hidden;
}

.location-list {
    display: flex;
    max-height: 525px;
    flex-wrap: wrap;
    flex: 1;
    overflow: hidden;
}

.region-location {
    display: flex;
    margin: 4px 2px 4px 8px;
    min-width: 220px;
    flex: 1;
    align-self: flex-start;
    align-items: flex-start;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0 1px 1px #00000011;
    cursor: pointer;

    &:hover {
        box-shadow: inset 0 0 1000px 1000px #00000011;
    }

    &.location-all {
        width: 100%;
        flex: none;
    }
}
.see-all-locations {
    margin: 0 8px;
    margin-top: 16px;
}

.location-values {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    width: 100%;
}

.location-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
    flex: 1;
}

.region-location .location-item {
    font-weight: 500;
    text-transform: uppercase;
}

.location-item.location-name {
    color: #546e7aff;
    font-size: 80%;
    text-transform: uppercase;
    margin-top: 4px;
}

.location-item {
    margin-right: 8px;
}

.location-percent {
    justify-self: flex-end;
    margin-right: 4%;
}

.location-item.location-human {
    display: flex;
    opacity: 0.4;
    align-items: center;
    flex: 1;

    .ficon {
        font-size: 100%;
    }
}

.location-item.location-total {
    color: #263238ff;
    font-size: 160%;
}

.location-flag {
    font-size: 180%;
    box-shadow: inset 0 0 3px 3px #00000033;
    border-radius: 3px;
}

.world-map-regions-popup-content {
    display: flex;
    flex: 1;
    max-height: 525px;
    max-width: 1200px;
    flex-wrap: wrap;
    overflow: auto;

    .region-location {
        width: 100%;
        max-width: calc(100% - 16px);
    }
}

.analytics-data-tooltip {
    .tooltip-title {
        font-size: 120%;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .tooltip-label {
        color: var(--color-gray);
        margin-right: 4px;
    }

    .fi {
        font-size: 120%;
        margin-right: 8px;
    }
}

.locations {
    .region-location {
        &:has(.skeleton) {
            padding: 0;
            box-shadow: 0 0 1px 1px #00000011;
            cursor: initial;

            &:hover {
                box-shadow: 0 0 1px 1px #00000011;
            }

            .skeleton {
                position: relative;
                height: 55px;
                width: 100%;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    background: linear-gradient(90deg, transparent, #e8e8e8, transparent);
                    width: 50%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    animation: loading 1s infinite;
                }

                .skeleton-content {
                    height: 100%;
                    width: 100%;
                    background: #ddd;
                    border-radius: 4px;
                }
            }
        }
    }
}

@media only screen and (min-width: 548px) {
    .world-map-regions-popup-content {
        .region-location {
            min-width: calc(50% - 10px);
            max-width: calc(50% - 10px);
        }
    }
}

@media only screen and (min-width: 768px) {
    .world-map-regions-popup-content {
        .region-location {
            min-width: calc(33% - 10px);
            max-width: calc(33% - 10px);
        }
    }
}

@media only screen and (min-width: 991px) {
    .world-map-regions-popup-content {
        .region-location {
            min-width: calc(25% - 10px);
            max-width: calc(25% - 10px);
        }
    }
}
