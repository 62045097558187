.account-list {
    // background-color: #fff;
    // border: 1px solid #ddd;
    border-radius: 4px;
    .account-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        border-top: 1px solid #eee;

        border-radius: 4px;
        box-shadow: 0px 0px 10px 4px #00000011;
        margin: 16px 0px;
        border:1px solid #ddd;
        background: #fff;
        & > div {
            padding: 16px;
            flex: 1;
        }
        .account-logo {
            flex: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .account-logo-image {
                background-color: #eee;
                background-size: 84%;
                border-radius: 4px;
                background-repeat: no-repeat;
                background-position: center;
                width: 60px;
                height: 60px;
            }
        }
        .account-name {
            max-width: 300px;
        }
        .account-admin {
            width: 200px;
            flex: none;
        }
        .account-updatedat {
            width: 160px;
            flex: none;
        }
        .account-actions {
            width: 136px;
            flex: none;
        }
        .account-applications {
            width: 100%;
            flex: none;
            padding-left: 32px;
            border-top: 1px solid #f2f2f2;
            background-color: #fafafa;
            padding-bottom: 24px;
        }
    }
}

.account-list .account-row .account-applications .account-application-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    &:last-child {
        border-bottom: 0px none;
    }
    & > div {
        padding: 8px;
        flex: 1;
    }
    .application-logo {
        width: 230px;
        flex: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        // font-weight: bold;
        // font-size: 120%;
        .ficon {
            font-size: 150%;
            margin-right: 8px;
        }
    }
    .application-name {
        flex: 1;
        min-width: 200px;
    }
    .application-url {
        overflow: hidden;
        min-width: 80px;
    }
    .application-actions {
        width: 104px;
        flex: none;
        opacity: 0.7;
        .iconbutton {
            font-size: 140%;
        }
    }
    .application-updatedat {
        width: 160px;
        flex: none;
    }
}

.account-list {
    .cell-label {
        font-size: 75%;
        opacity: 0.6;
        width: 100%;
        padding-bottom: 4px;
    }
}

.entity-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; 
    background: repeating-linear-gradient(45deg, #fafafa, #fafafa 10px, #fff 10px, #fff 20px);
    margin-bottom: 32px;
    border-radius: 4px;
    .entity-data{
        width: 100%;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding: 8px;
        & > div {
            padding: 8px 16px;
            flex: 1;
        }
        .entity-name {
            max-width: 392px;
        }
        .entity-admin {
            // width: 200px;
            // flex: none;
        }
        .entity-updatedat {
            width: 160px;
            flex: none;
        }
        .entity-actions {
            width: 136px;
            flex: none;
        }
       

    }
   
    .entity-accounts {
        padding: 0px 16px;
        // background: #fafafa;
        width: 100%;
        flex: none;
        // border-top: 1px solid #f2f2f2;
        padding-bottom: 24px;
         
        .account-row:first-child {
        }
    }
}

.enterprise-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px solid #eee;


  .enterprise-card{
    width: 100%;
  }
    .enterprise-data{
        
        // display: flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        // align-items: center;
        
        background-color: #fff;
        width: 100%;
         
        
        grid-gap: 16px;
        gap:16px;
        padding: 16px;
        display: grid;
        grid-template-columns: 80px 1fr;
        grid-template-rows: auto ;
        grid-template-areas: 
            'logo name'
            'logo admin'
            'logo contact'
            'logo updatedat';

            align-items: flex-start;
    }

    .enterprise-logo {
        grid-area: logo;
        flex: none; 
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        
        .enterprise-logo-image {
            background-color: #eee;
            background-size: 84%;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-position: center;
            width: 80px;
            height: 80px;
           
        }
    }
    .enterprise-name {
        grid-area: name;
      max-width: 300px;
    }
    // .application-name{
 
    //   flex:1;
    //   min-width:200px; 
    // }
    // .application-key{
        
    //   flex:1;
    //   max-width:180px;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
    .enterprise-admin {

        grid-area: admin;  
    }
    .enterprise-contact {
        grid-area: contact; 
    }
    .enterprise-updatedat {
        grid-area: updatedat; 
    }
 
    .enterprise-entities {
        margin-top: 32px;
        width: 100%;
        flex: none;
        // border-top: 1px solid #f2f2f2;
        padding-bottom: 24px;
        padding: 0px;
    }
}

.popup-wrapper:has(.delete-application-popover__content) {
    .popup-content {
        background: none;

        h2 {
            display: none;
        }
    }
}
