.messenger{

  display: flex;
  flex-direction: column;

  .messenger-placeholder{
    font-size: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 120%;
    .ficon{
      font-size: 400%;
      margin-bottom: 16px;
    }
  }

  .assign-box, .reopen-box, .expired-box{
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-top: 1px solid #ddd;
    align-items: center;
    justify-content: center;
    height: 120px;
    .button-wrapper{
      margin: 8px;
    }
  }
 

  .messenger-header{ 
    background-color: #fff; 
    border-bottom: 1px solid #ddd;  
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;

    .blank-placeholder{ 
      flex:1; 
    }
    .messenger-title{
      font-size: 100%; 
        display: flex;
        flex-direction: row;
        align-items: center;
       .iconbutton{ 
          margin-left: 8px;
          font-size: 130%; 
       }
    }

    .messenger-title-edit{ 
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        padding: 16px;
        background-color: #fff;
        top:8px; 
        box-shadow: 0px 0px 10px 2px #00000022;
        z-index: 1;
        input{
          width:250px;
          // padding: 6px 12px;
        }
       .iconbutton{ 
          margin-left: 8px;
          font-size: 180%; 
       }
    }

    .session-status{   
      margin-left: 32px;
      align-self: center; 
      user-select: none;
    }
    
    .right-buttons{  
      display: flex;
      flex-direction: row;
      align-items: center;
      .iconbutton{
        font-weight: 500;
      }
      
    }
     
}

}
