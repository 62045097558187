.application-settings, .application-info{
    display: flex;
    flex-direction: row;
   flex:1;
    

.application-settings-left{   
    min-width: 300px; 
    flex:3;
    display: flex;
    flex-direction: column;
      padding-right: 8px;
      border-right: 1px solid #eee; 
  }


.application-settings-right{ 
    flex:2;
    padding-left: 8px; 
  }

  .application-settings-subtitle{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px; 
    padding: 8px 8px;
    border-bottom: 1px solid #eee;
    font-size: 100%;
     font-weight: 500;
    .application-settings-subtitle-text{
        
    }
    .application-settings-subtitle-icon{ 
        font-size: 180%;
        margin-right: 8px;
    }
  }

  .application-setting-line{
    border-top: 1px dashed #ddd;
    margin-top: 8px;
    margin-bottom: 12px;
  }
    .application-setting-row{ 
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        padding: 8px;
        padding-top: 4px;
        &.file-upload-row{
            padding-top: 24px;
        }
 
    }

    .application-setting-label{
        font-size: 75%; 
        width: 100%; 
        height: 16px;
        padding-left: 12px;
        padding-top: 4px;
    }
    .application-setting-desc{ 
        font-size: 75%;
        opacity: 0.4;
        width: 100%; 
        height: 16px;
        padding-left: 12px;
        padding-top: 4px;
    }
    .application-setting-value{
        padding-right: 36px;
        white-space: wrap;
        word-break: break-all;
        &.dropdown{
         
           width: calc(100% - 36px);
           padding-right: 0px;
        }
        input{
            padding-right: 36px;
        }
    }
    .application-setting-check{

    }
    .application-setting-select{
       width: calc(100% - 40px);
    }

    .file-upload-input{
        background-color: #fff;
        border:1px solid #ddd;
        border-radius: 4px !important;
        .filepond--root{
            margin-bottom: 0px;
        }
        .filepond--drop-label.filepond--drop-label label{
            opacity: 0.6;
            font-size: 80%;
        }
    }
    .application-setting-info{
        position: absolute;
        right: 16px;
        bottom:16px;
        width: 22px;
        height: 22px;
        font-size: 22px;
        padding: 0px;
        border-radius: 50%;
        opacity: 0.3;
        &:hover{
            opacity: 0.8;
        }
        // transform: translate(0px, -40%);
        
    }
}



.application-settings-faq .application-settings-faq-row{ 
    &:first-child{
        padding-top: 24px;
    }
    padding: 8px;  
    display: flex;
    a{
        text-decoration: none;
        display: flex;
        align-items: center;
        color:#000;
        &:hover{
            text-decoration: underline;
            
        } 
    }
    .ficon{
        margin-right: 8px;
        font-size: 140%;
        opacity: 0.8;
    }
}

.application-info{


    .application-setting-row{  
        padding: 4px;  

        border-bottom: 1px dashed #ddd;
        &.file-upload-row{
            padding-top: 24px;
        }
    

        .application-setting-info{
            
            top:12px; 
            
        }
    }


    .application-setting-label{ 
        padding-left: 0px;
        padding-top: 4px;
        opacity: 0.8;
    }

}

.app-setting-advanced-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        box-shadow: inset 0 0 1000px 1000px #00000011;
    }
}