
.notifications {  

  position: fixed; 
  top:50px;
  right:0px;
  // bottom: 0px; 
  overflow: auto;
  display: flex;
  flex-direction: column; 
  padding: 10px;
  padding-bottom: 10px;
  text-align: left;
  z-index: 10000;

  .notification {  
    cursor: pointer;
    user-select: none;
      background-color: #fafafa;
      box-shadow: 1px 1px 10px 5px #00000011; 
      width:300px; 
      border-radius: 4px;
      padding: 8px; 
      position: relative; 
      display: flex;
      flex-direction: row;

      

      animation: fade-notification 300ms forwards;
      animation-iteration-count: 1;

      @keyframes fade-notification {
        0% {  opacity: 0; transform: translateX(100%); }  
        100% {  opacity: 1; transform: translateX(0); } 
      } 

      .notification-content{
          padding: 2px;
      }
      .image{
        width: 40px;
        height: 40px;
        background-color: #eee;
        margin-right: 8px;
        border-radius: 4px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
     }
     .notification-icon{
      width: 40px;
      height: 40px;
      background-color:transparent;  
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
     }
      .title{
        font-weight: 500;
        padding-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
        max-width: 200px;
      }
    
      .desc{ 
        font-size: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
        max-width: 200px;
      }

      .close{ 
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          position: absolute;
          top:10px;
          right: 10px;
          width: 20px;
          height: 20px;
          text-align: center;
      }

 
    
  }
}


.notifications .notification:hover{
  box-shadow: inset 0px 0px 1000px 1000px #00000011;
}

.notifications .notification.error{
  background-color: rgb(248, 169, 169);
  color:#000;
}

  