.card-group{ 
  .card-category{
    padding: 16px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    background-color: #f2f2f2;
  }
  .card-category-inner{
    padding: 8px;
  }

  .skeleton-item{
    width: 100%;
    height: 46px;
    margin: 8px 0px;
    background-color: #eee;
    opacity: 0.7;
    background: linear-gradient(217deg, #eeeeee55, #eeeeee99 70.71%);
    border-radius: 4px;
  }
}

.card-selector-popup{
  .popup-header{
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    .popup-title{
      padding: 0px 16px;
    }
  }
}
.card-selector-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  .card-selector-header-label{
    flex:1;
  }
  .card-selector-header-input{
    width: 200px;
    font-weight: normal;
    
  }
  
}