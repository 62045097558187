.added-apps-card {
    max-height: initial !important;

    .added-apps {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        max-height: initial;

        .application-item {
            &.inactive {
                cursor: initial;

                &:hover {
                    background-color: inherit;
                }
            }
        }

        .app-item {
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .sticker {
                margin-top: 8px;
            }

            .ficon {
                margin-bottom: 8px;
                font-size: 200%;
            }
        }

        .application-item-name {
            word-break: break-word;
            width: 100%;
        }
    }
}

.process-log-modal {
    display: flex;
    // min-width: 540px;
    // max-height: 785px;
}
