.assistant-search-bg{
  position: fixed;
  top:0%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #00000066;
  user-select: none;
  .assistant-search{
    display: flex;
    flex-wrap: wrap;
    align-items: center; 
    left:50%;
    top:18%;
    transform: translate(-50%, 0%);
    box-shadow: 0px 0px 50px 20px #00000033;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    font-size: 100%;
    width: 800px;
    position: relative;
    .ficon{
      font-size: 250%;
      padding: 16px;
      opacity: 0.8;
    }
    textarea{
      font-size: 150%;
      height: auto;
      min-height: 0px;
      border-radius: 4px;
      padding: 16px; 
      padding-left: 8px;
      border: 0px;
      background-color: transparent;
    }
    .bg-text{
      position: absolute;
      opacity: 0.3;
      top:21px;
      font-size: 150%;
      left:74px;
      .bg-text-hidden{
        display: inline-block;
        visibility: hidden;
      }
    }
    .assistant-search-result{ 
      border-top: 1px solid #eee;
      min-height: 50px;
      width: 100%;
    }
  }
}


.assistant-search-result{  
  align-items: flex-start;
display: flex;
flex-direction: row; 
max-height: 420px;
  .assistant-search-list{
    flex:1;
    .assistant-search-list-item{
    
      gap:2px ;
  grid-gap:2px  ;
  display: grid; 
  position: relative; 
  grid-template-columns: 32px auto 32px;
  grid-template-rows:  auto;
  grid-template-areas: 
    "icon name go"
    "icon desc go"  
    ;
 
  width: 100%;
  cursor: pointer;
      padding: 12px;
  padding-right: 0px;
  &:hover  {
    box-shadow: inset 0px 0px 1000px 1000px #00000011;
  }
   &.selected{
    box-shadow: inset 0px 0px 1000px 1000px #4468bc;
    color: #fff;
    padding-right: 0px;
    .item-go{ 
      display: block;
      grid-area: go;
    }
  }
    .item-name{  
      width: 100%;
      grid-area: name;
    }
    .item-icon{ 
      font-size: 160%; 
      align-items: flex-start;
      align-self: flex-start;
      padding: 0px;
      text-align: center;
      grid-area: icon;
    }
    .item-go{
      font-size: 150%; 
      display: none;
      justify-self: flex-end;
      align-self: center;
      padding:   0px;
      width: 32px;
    }
    .item-desc{
      opacity: 0.5;
      font-size: 80%; 
      grid-area: desc;
    }
  }
}
  .assistant-search-preview{ 
   
    min-width: 300px;
    min-height: 300px;

    max-width: 300px; 
    padding: 8px;
    zoom: 1;
    .preview-content{
      border: 0px none;
      width: 300px;
      height: 300px;  
    }
  }
}