.basket-detail {
 
    padding: 8px 16px;
    background-color: #fafafa;
    box-shadow: inset 4px 4px 6px 2px #00000005;
    padding-bottom: 32px;
    border-bottom: 3px solid #eee;
    font-size: 90%;
    .tab-header-wrapper .tab-header{
        margin-bottom: 0px !important;
    } 

    .addresses {
        display: flex;
        flex-wrap: wrap;
        .address {
            width: 50%;
            padding: 10px;
            .address-title {
                font-weight: bold; 
                margin-bottom: 10px;
            }
            .address-row {
                display: flex;
                margin-bottom: 10px;
                font-size: 90%;
                .address-row-label {
                    width: 100px;
                    font-weight: bold;
                }
                .address-row-value {
                    flex: 1;
                }
            }
        }
    
    }
}