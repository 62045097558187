.customer-profile-summary {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .summary-head {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 16px 0;

        .image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }

        .title {
            font-size: 150%;
            padding-top: 4px;
            text-transform: capitalize;
        }

        .login-time {
            color: #aaa;
            font-size: 90%;
            margin-top: 4px;
            margin-bottom: 16px;
        }
    }

    .gender {
        font-size: 100%;
        font-weight: bold;
        display: inline-block;

        &.gender-male {
            color: rgb(55, 45, 185);
        }

        &.gender-female {
            color: rgb(242, 25, 148);
        }
    }

    .tag-list {
        padding: 8px 12px;

        .tag {
            margin-bottom: 8px;
        }
    }

    .location .location-country {
        font-size: 60%;
    }

    .location .location-city {
    }

    .list-item {
        &.list-item-multiple-data {
            .item-label {
                padding-right: 0;
                overflow: initial;
            }

            .item-desc {
                max-width: initial;
            }

            .item-content {
                text-align: right;

                &:has(.location) {
                    flex-direction: column;
                }

                .location {
                    margin-bottom: 4px;
                }
            }
        }
    }

    .customer-company{
        padding: 16px;
        border-bottom: 1px solid #eee;
        .company-image{ 
            max-width: 80px;
            height: auto;
            max-height: 80px;
            float: left;
            margin-right: 8px;
        }
        .company-name{
            font-weight: bold;
        }
        .company-description{
            font-size: 80%;
            // white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
                    
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

 



    .social-channel-list-item{
        > span[data-value]:after {
            content: attr(data-value);
            margin-bottom: -12px;
            transform: translate(-1px, -3px);
            margin-right: -24px;
            width: 16px;
            height: 16px; 
            border-radius: 50%;
            font-size: 80%;
            text-align: center;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom:20px;
            left:52px;
            position: absolute;
            top:18px;
            background-color: #f10b0b;
        }

        position: relative;
        grid-template-columns: 44px auto 330px minmax(min-content, 100px);
        grid-template-rows: auto;
        grid-template-areas:
            'icon label chart expand'
            'icon desc chart expand'
            'content content content content';
       
            padding: 16px;
        gap: 4px 16px;
        grid-gap: 4px 16px;
        display: grid;
        align-items: center;
        border-bottom: 1px solid #eee;
    
        .list-icon {
            grid-area: icon;
            font-size: 28px;
            width: 44px;
            height:44px;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            color: #fff;
        }
    
        .title {
            grid-area: label;
            font-size: 100%;
            align-self: flex-end;
        }
    
        .desc {
            grid-area: desc;
            font-size: 70%;
            align-self: flex-start;
            text-transform: uppercase;
            opacity: 0.7;
        }
    }
 
}
