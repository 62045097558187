.edit-csr-form{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 400px;
    padding: 16px;

    .switch{
        margin-bottom: -4px;
    }
}

.new-csr-button{
    width: 100px;
    align-self: flex-end;
}


.edit-csr-types{
    display: flex;
    flex-direction: row; 
    gap:16px;

    &.disabled{
        user-select: none;
        pointer-events: none;
        filter: grayscale(1);
        opacity: 0.7;
    }
    .edit-csr-type{
        padding: 16px;
        width: 100px;
        border: 1px solid #ddd;
        cursor: pointer;
        border-radius: 8px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        &:hover{
            background-color: #eee;
        }

        &.selected{
            background-color: #3949ab;
            color:#fff;
        }
    }
}