.app-level-popup-content {
    display: flex;
    width: 600px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    .attention {
        font-size: 100px;
        background-color: #e65100;
        color: #fff;
        font-weight: bold;
        border-radius: 50%;
        padding: 32px;
    }

    h2 {
        font-size: 140%;
        font-weight: 500;
        padding: 16px;
    }

    > div {
        color: #70838f;
        text-align: center;
        padding: 0;
    }

    label {
        font-weight: bold;
    }

    .paragraph {
        padding: 8px;
        display: block;
    }
}

input.app-level-popup-left-button {
    background-color: transparent;
}
